// @flow
import React from 'react';
import { X, Plus } from 'react-feather';
import { formatDate } from 'helpers';

type PreferenceOptionPropsType = {
  prefNumber: number,
  prefType: string,
  selectedPref: Object,
  setPreference: () => void,
  unsetPreference: () => void,
};

const PreferenceOption = (props: PreferenceOptionPropsType) => {
  const {
    prefNumber,
    prefType,
    selectedPref,
    setPreference,
    unsetPreference,
  } = props;

  const isPreference = prefType === 'pref';
  const prefDescription = isPreference
    ? `Set preference #${prefNumber}`
    : "Can't compete this day";
  const prefClass = isPreference ? 'icon-color-green' : 'icon-color-gold';
  const prefLabel = isPreference ? `Pref ${prefNumber}` : ' ';

  return (
    <li>
      <div onClick={() => setPreference()}>
        <Plus className={`set-pref-icon ${prefClass}`} />
        {prefDescription}
      </div>
      {selectedPref && selectedPref.length > 0 && (
        <div>
          {prefLabel}
          {formatDate(selectedPref.date)}{' '}
          <X onClick={() => unsetPreference()} />
        </div>
      )}
    </li>
  );
};

export default PreferenceOption;
