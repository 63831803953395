// @flow
import { withRouter } from 'react-router-dom';
import { reduxForm } from 'redux-form';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { fetchUserByToken } from 'actions/user';
import { GET_USER_PROFILE } from 'queries/GetUserProfile';
import { getInitialYouthAthleteValues } from 'selectors/youthAthlete';
import EditProfile from 'components/EditProfile';
import { editYouthAthlete } from 'actions/youthAthlete';
import get from 'lodash/get';

const mapStateToProps = (state) => ({
  userToken: get(
    state.auth.get('data'),
    'authPayload.token.refreshToken',
    null,
  ),
  loading: state.youthAthlete.get('loading'),
  initialValues: getInitialYouthAthleteValues(state),
  user: state.youthAthlete.get('data'),
});

const mapDispatchToProps = (dispatch) => ({
  dispatch,
  onSubmit: (youthAthlete) => dispatch(editYouthAthlete(youthAthlete)),
  fetchUser: (token) => dispatch(fetchUserByToken(GET_USER_PROFILE, token)),
});

export default compose(
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
  reduxForm({
    form: 'profile',
    enableReinitialize: true,
  }),
)(EditProfile);
