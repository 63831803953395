// @flow
import React, { useState } from 'react';
import { compose } from 'redux';
import { Async } from 'react-select';
import client from 'api/apollo';

import ErrorMessage from 'components/ErrorMessage';
import { withBuddyGroups } from 'context/BuddyGroups';
import { withSelectedDisciplines } from 'context/SelectedDisciplines';
import { BUDDY_SEARCH } from 'queries/BuddySearch';
import { EVENT_GET_ENTRY_COUNTS } from 'queries/EventGetEntryCounts';
import { asyncDebounce, getNestedProperty, logError } from 'helpers';

type BuddySearchProps = {|
  EventUID: ?string,
  selectedBuddyOption: Object,
  selectAthlete: (Object, Function) => Promise<void>,
  toggleIsBuddySelected: (boolean) => null,
  setSelectedBuddyOption: (boolean) => null,
  switchAthlete: (ERAUID: string) => Promise<any>,
  clearSelectedDisciplines: () => null,
  setBuddyEntriesCount: (Object, string) => null,
  managerERAUID: ?number,
|};

const BuddySearch = ({
  EventUID,
  selectAthlete,
  toggleIsBuddySelected,
  selectedBuddyOption,
  setSelectedBuddyOption,
  switchAthlete,
  clearSelectedDisciplines,
  setBuddyEntriesCount,
  managerERAUID,
}: BuddySearchProps) => {
  const [error, setError] = useState('');

  const MIN_CHAR_INPUT = 2;
  const DEBOUNCE_WAIT_MS = 500;

  const loadBuddyOptions = async (inputValue) => {
    if (inputValue.length < MIN_CHAR_INPUT) {
      return Promise.resolve({ options: [] });
    }

    // @TODO: Temporary fix until the API has access to manager's ERAUID via GraphQL.
    const input = { name: inputValue, EventUID, managerERAUID };
    const athletePayload = {
      query: BUDDY_SEARCH,
      variables: { input },
      fetchPolicy: 'network-only',
    };

    try {
      const response = await client.query(athletePayload);
      const availableBuddies = getNestedProperty(
        'data.buddySearch',
        response,
        [],
      );

      // @TODO: Temporary fix until the API has access to manager's ERAUID via GraphQL.
      const filteredBuddyOptions = availableBuddies.filter(
        (buddy) => parseInt(buddy.ERAUID, 10) !== parseInt(managerERAUID, 10),
      );
      const buddyOptions = filteredBuddyOptions.map((buddy) => {
        return {
          value: buddy.ERAUID,
          label: buddy.fullNameWithNickname,
          fullName: buddy.fullName,
          isYouth: buddy.isYouth,
          isEligibleBuddy: buddy.isEligibleBuddy,
          ineligibleReason: buddy.ineligibleReason,
        };
      });

      return { options: buddyOptions };
    } catch (error) {
      logError(error);
      setError('Unable to search for buddies for this event');
    }
  };

  const getBuddyEntryCounts = async (ERAUID, EventUID) => {
    const payload = {
      query: EVENT_GET_ENTRY_COUNTS,
      variables: { id: EventUID, ERAUID },
      fetchPolicy: 'network-only',
    };
    try {
      const response = await client.query(payload);
      const [buddyEntryCount] = getNestedProperty(
        'data.eventGet.entryCounts',
        response,
        {},
      );
      setBuddyEntriesCount(buddyEntryCount, ERAUID);
      switchAthlete(ERAUID);
    } catch (error) {
      logError(error);
      setError('Unable to find buddy entries for this event');
    }
  };

  const changeBuddy = (buddyOption) => {
    setSelectedBuddyOption(buddyOption);

    if (buddyOption === null) {
      selectAthlete({ value: null, label: null, fullName: null });
      clearSelectedDisciplines();
      toggleIsBuddySelected(false);
      setError('');
      return;
    }
    if (!buddyOption.isEligibleBuddy) {
      selectAthlete({ value: null, label: null, fullName: null });
      clearSelectedDisciplines();
      toggleIsBuddySelected(false);

      if (buddyOption.ineligibleReason) {
        setError(buddyOption.ineligibleReason);
        return;
      }

      setError('Selected buddy is ineligible to be added to a buddy group.');
      return;
    }

    const { value: buddyERAUID } = buddyOption;
    selectAthlete(buddyOption);
    setError('');
    toggleIsBuddySelected(true);
    getBuddyEntryCounts(buddyERAUID, EventUID);
  };

  return (
    <div className="buddy-search">
      <hr />
      <Async
        name="form-field-name"
        className="select-wrapper"
        autosize={false}
        value={selectedBuddyOption}
        placeholder="SEARCH FOR ATHLETES"
        loadOptions={asyncDebounce(loadBuddyOptions, DEBOUNCE_WAIT_MS)}
        onChange={changeBuddy}
      />
      <hr />
      <ErrorMessage errorMessage={error} />
    </div>
  );
};

export default compose(
  withBuddyGroups,
  withSelectedDisciplines,
)(BuddySearch);
