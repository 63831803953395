import React from 'react';
import PropTypes from 'prop-types';
import Close from '@material-ui/icons/Close';

const Notification = ({
  type,
  text,
  showCloseButton,
  onClose,
  icon,
  style,
}) => {
  return !!text && text !== '' ? (
    <div style={style} className={`notification-container ${type}`}>
      {icon && <div className="notification-icon column">{icon}</div>}
      <div className="notification-content notification-column">
        <div className={`notification-${type}Message`}>{text}</div>
      </div>
      {showCloseButton && (
        <div className="notification-close-icon">
          <button>
            <Close onClick={onClose} />
          </button>
        </div>
      )}
    </div>
  ) : null;
};

Notification.propTypes = {
  type: PropTypes.string,
  text: PropTypes.string,
  showCloseButton: PropTypes.bool,
  onClose: PropTypes.func,
  icon: PropTypes.node,
  style: PropTypes.object,
};

Notification.defaultProps = {
  type: 'error',
  text: 'error',
  icon: null,
  showCloseButton: false,
  onClose: () => {},
  style: {},
};

export default Notification;
