// @flow
import React, { useState, useEffect, useCallback } from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment';
import { get, isEmpty } from 'lodash';

import { submitEntryPayment } from 'actions/entryChargeRequest';
import {
  changeRoute,
  getTheme,
  getNestedProperty,
  logError,
  getYouthAthletesLabel,
} from 'helpers';
import Layout from 'components/Layout';
import BackButton from 'components/BackButton';
import DisciplineAddButton from 'components/DisciplineAddButton';
import EntryEventDescription from 'components/EntryEventDescription';
import EntryAgreement from 'components/Event/EntryAgreement';
import EventFees from 'components/Event/EntryFees';
import Notification from 'components/Notification';
import SelectedDisciplinesList from './SelectedDisciplinesList';
import SelectedDisciplinesListWithClasses from './SelectedDisciplinesListWithClasses';
import {
  EVENT_ENTRY_CHECKOUT,
  EVENT_ENTRY_SEARCH,
  EVENT_ENTRY_LEGAL_INFO,
  EVENT_ENTRY_PREFERENCES,
  EVENT_ENTRY_YOUTH_ATHLETE_INFO,
} from 'constants/routes';

import { setShowEventMessagingBanner } from 'actions/event';
import { getShowEventMessagingBanner } from 'selectors/event';
import { adultAge, taxInfoRenewPeriodInMonths } from 'constants/personal';
import { PAY_LATER } from 'constants/paymentTypes';
import dbVars from 'constants/dbvars';
import { EVENT_ENTRY } from 'constants/routes';
import ErrorMessage from 'components/ErrorMessage';

import type { EventType } from 'models/Event';
import type {
  EntryChargeType,
  NominationPayloadType,
} from 'models/EntryChargeRequest';
import type { DisciplineType } from 'context/SelectedDisciplines/type';
import type { AssociationMembershipType } from 'models/AssociationMembership';
import { getAthleteAssociationMembershipNumbers } from 'models/AssociationMembership';
import { differenceInYears } from 'date-fns';
import specialCases from 'constants/specialCases';
import { IS_MEMBER } from 'constants/associationMembership';
import { isYouthEvent } from 'helpers';

type ReviewBaseProps = {|
  availableDisciplines: DisciplineType[],
  calculateEntryCharge: () => Promise<any>,
  charge: EntryChargeType,
  event: EventType,
  loading: boolean,
  loadingCharge: boolean,
  history: Object,
  selectedDisciplines: DisciplineType[],
  athleteFullName: string,
  ERAUID: ?number,
  toggleIsEditing: (boolean) => void,
  isYouthAthlete: ?boolean,
  user: Object,
  dispatch: Function,
  getEntryDisciplines: Function,
  isBuddyEntry: boolean,
  associationMembership: AssociationMembershipType,
  errorMessage: string,
  getNominationDisciplines: (
    DisciplineType[],
    ?string,
  ) => NominationPayloadType[],
|};

export const ReviewBase = (props: ReviewBaseProps) => {
  const {
    history,
    event,
    charge,
    selectedDisciplines,
    availableDisciplines,
    loadingCharge,
    loading,
    athleteFullName,
    toggleIsEditing,
    calculateEntryCharge,
    isYouthAthlete,
    user,
    isBuddyEntry,
    getNominationDisciplines,
    associationMembership,
    dispatch,
    ERAUID,
    errorMessage,
  } = props;
  const { TaxInfoUpdateDate } = user;
  const { AllowClasses } = event;
  const [acceptedTerms, setAcceptedTerms] = useState<boolean>(false);
  const [shouldHideNomination, setShouldHideNomination] = useState<boolean>(
    false,
  );

  const getCustomFees = () => {
    return selectedDisciplines.map((d) => {
      const dis = charge.disciplineFees.find(
        (x) => x.disciplineId === d.DisciplineUID,
      );
      const count = d.isPayingForPartner
        ? d.newEntryCount * 2
        : d.newEntryCount;
      if (dis) {
        return {
          name: d.DisciplineFeeName || dis.disciplineFeeName,
          value: dis.disciplineFee * count,
          count,
        };
      }
      return {
        name: d.DisciplineFeeName,
        value: d.DisciplineFee * count,
        count,
      };
    });
  };

  const customFees = getCustomFees();
  const { selectedMembershipStatus } = associationMembership;
  const showEventMessagingBanner = useSelector(getShowEventMessagingBanner);
  const athletes = getYouthAthletesLabel(user, event.rank, false, true);

  const onCloseEventNotification = () => {
    dispatch(setShowEventMessagingBanner(false));
  };

  useEffect(() => {
    if (Array.isArray(athletes) && !isNaN(Number(ERAUID))) {
      const defaultAthlete = athletes.find(
        ({ value }) => parseInt(value) === parseInt(ERAUID),
      );

      if (defaultAthlete) {
        setShouldHideNomination(!!defaultAthlete.hideNomination ? true : false);
      }
    }
  }, [athletes, ERAUID]);

  const athleteMembershipNumbers =
    selectedMembershipStatus === IS_MEMBER
      ? getAthleteAssociationMembershipNumbers(
          associationMembership.athleteMembershipData,
          event.Associations,
          event.PrimaryAssociationUID || 0,
        )
      : [];

  const editEntryDiscipline = useCallback((action: string) => {
    if (action === 'add') {
      changeRoute({
        history,
        route: EVENT_ENTRY,
        persistState: true,
        replace: true,
      });
    }
    if (action === 'edit') {
      changeRoute({
        history,
        route: EVENT_ENTRY_PREFERENCES,
        persistState: true,
      });
    }
  }, []);

  const acceptTerms = useCallback(() => {
    setAcceptedTerms(!acceptedTerms);
  }, []);

  const submitPayLater = () => {
    const {
      dispatch,
      getEntryDisciplines,
      selectedDisciplines,
      user,
      associationMembership,
    } = props;

    const values = {
      theme: getTheme(),
      paymentType: PAY_LATER,
      charge: {
        ...charge,
        Disciplines: getEntryDisciplines(selectedDisciplines, AllowClasses),
      },
      buddyGroupData: {
        isBuddyEntry,
        BuddyManagerERAUID: isBuddyEntry
          ? getNestedProperty('ERAUID', user)
          : null,
      },
      selectedMembershipStatus: associationMembership.selectedMembershipStatus,
      nominations: getNominations(),
      HasAgreed: acceptedTerms,
    };
    dispatch(submitEntryPayment(values));
  };

  const getNominations = () => {
    const nominationsArray = selectedDisciplines.length
      ? selectedDisciplines
      : [];
    const performancesArray = nominationsArray.find((nomination) =>
      getNestedProperty('performances.length', nomination),
    );
    if (performancesArray) {
      const { performances } = performancesArray;
      const performanceDate =
        performances.length > 0
          ? performances[0].PerformanceDate
          : event && event.dateStart;
      return getNominationDisciplines(nominationsArray, performanceDate);
    }
    return getNominationDisciplines(nominationsArray, event && event.dateStart);
  };

  const goBack = () => {
    const showTaxInfoViewOnGoBack = getNestedProperty(
      'location.state.showTaxInfoViewOnGoBack',
      history,
      false,
    );

    const showYouthAthleteViewOnGoBack = getNestedProperty(
      'location.state.showYouthAthleteInfoViewOnGoBack',
      history,
      false,
    );

    if (showTaxInfoViewOnGoBack || showYouthAthleteViewOnGoBack) {
      return changeRoute({ history, route: EVENT_ENTRY, persistState: true });
    }

    return history.goBack();
  };

  const checkoutOrPayLater = ({ payLater = false }) => {
    if (payLater) {
      return submitPayLater();
    }

    return changeRoute({ history, route: EVENT_ENTRY_CHECKOUT });
  };

  const checkout = async ({ payLater = false }) => {
    const { EventRank } = event;
    const { user } = props;

    if (!charge.ERAUID) {
      await calculateEntryCharge();
    }

    if (isBuddyEntry) {
      checkoutOrPayLater({ payLater });
      return;
    }

    if (isYouthEvent(EventRank) || isYouthAthlete) {
      const userAge = differenceInYears(new Date(), new Date(user.DOB));
      let youthData =
        [18, 19].includes(userAge) || specialCases.has(parseInt(user.ERAUID))
          ? user
          : user.youthAthletes.find((youth) => youth.ERAUID === charge.ERAUID);
      const youthAge = differenceInYears(new Date(), new Date(youthData.DOB));
      const isStillYouth = youthAge < adultAge;

      const hasYouthClasses = () => {
        if (!event.AllowClasses) {
          return false;
        }
        return selectedDisciplines.some(({ Division }) =>
          isYouthEvent(Division),
        );
      };

      // logic to enter youth athlete details page
      if (isStillYouth && (isYouthEvent(EventRank) || hasYouthClasses())) {
        const { TaxInfoUpdateDate } = youthData;
        const isPreviousTaxInfoExisting = !!TaxInfoUpdateDate;

        const showYouthAthleteInfoViewOnGoBack = get(
          history,
          'location.state.showYouthAthleteInfoViewOnGoBack',
        );

        const previousTaxInfoDate = moment(TaxInfoUpdateDate);
        const currentDate = moment.utc();
        const isTimeToUpdateTaxInfo = isPreviousTaxInfoExisting
          ? currentDate.diff(previousTaxInfoDate, 'months') >=
            taxInfoRenewPeriodInMonths
          : false;

        if (
          isPreviousTaxInfoExisting &&
          !isTimeToUpdateTaxInfo &&
          !showYouthAthleteInfoViewOnGoBack
        ) {
          checkoutOrPayLater({ payLater });
          return;
        }

        if (
          (isPreviousTaxInfoExisting && isTimeToUpdateTaxInfo) ||
          showYouthAthleteInfoViewOnGoBack
        ) {
          changeRoute({
            history,
            route: EVENT_ENTRY_YOUTH_ATHLETE_INFO,
            state: {
              isTimeToUpdateTaxInfo: true,
              showYouthAthleteInfoViewOnGoBack: true,
              payLater,
              acceptedTerms,
              event,
              youthAthlete: youthData,
            },
          });
          return;
        }

        if (!isPreviousTaxInfoExisting) {
          changeRoute({
            history,
            route: EVENT_ENTRY_YOUTH_ATHLETE_INFO,
            state: {
              showYouthAthleteInfoViewOnGoBack: true,
              payLater,
              acceptedTerms,
              youthAthlete: youthData,
            },
          });
          return;
        }
      } else if (isStillYouth) {
        checkoutOrPayLater({ payLater });
        return;
      }
    }

    if (event.ShouldCollectTaxInfo) {
      const isPreviousTaxInfoExisting = !!TaxInfoUpdateDate;
      const showTaxInfoViewOnGoBack = get(
        history,
        'location.state.showTaxInfoViewOnGoBack',
      );

      const previousTaxInfoDate = moment(TaxInfoUpdateDate);
      const currentDate = moment.utc();
      const isTimeToUpdateTaxInfo = isPreviousTaxInfoExisting
        ? currentDate.diff(previousTaxInfoDate, 'months') >=
          taxInfoRenewPeriodInMonths
        : false;

      if (
        isPreviousTaxInfoExisting &&
        !isTimeToUpdateTaxInfo &&
        !showTaxInfoViewOnGoBack
      ) {
        checkoutOrPayLater({ payLater });
        return;
      }

      if (
        (isPreviousTaxInfoExisting && isTimeToUpdateTaxInfo) ||
        showTaxInfoViewOnGoBack
      ) {
        changeRoute({
          history,
          route: EVENT_ENTRY_LEGAL_INFO,
          state: {
            isTimeToUpdateTaxInfo: true,
            showTaxInfoViewOnGoBack: true,
            payLater,
            acceptedTerms,
            event,
          },
        });
        return;
      }

      if (!isPreviousTaxInfoExisting) {
        changeRoute({
          history,
          route: EVENT_ENTRY_LEGAL_INFO,
          state: {
            showTaxInfoViewOnGoBack: true,
            payLater,
            acceptedTerms,
          },
        });
        return;
      }
    }

    if (payLater) {
      checkoutOrPayLater({ payLater });
      return;
    }

    changeRoute({ history, route: EVENT_ENTRY_CHECKOUT, persistState: true });
  };

  useEffect(() => {
    window.onpopstate = null;
    calculateEntryCharge()
      .then(() => {
        toggleIsEditing(true);
        if (!getNestedProperty('name', event)) {
          changeRoute({ history, route: EVENT_ENTRY_SEARCH });
        }
        if (!getNestedProperty('length', selectedDisciplines)) {
          changeRoute({ history, route: EVENT_ENTRY });
        }
      })
      .catch((error) => {
        logError(error);
        changeRoute({ history, route: EVENT_ENTRY_SEARCH });
      });
  }, [selectedDisciplines.length]);

  const newDisciplines = availableDisciplines.filter((disc) => {
    if (AllowClasses) {
      const { Classes } = disc;
      return Classes.some(
        (Class) =>
          Class.maxNumberOfEntries &&
          Class.maxNumberOfEntries >
            Class.athleteEntryCount + Class.newEntryCount,
      );
    } else {
      return (
        disc.maxNumberOfEntries &&
        disc.maxNumberOfEntries > disc.athleteEntryCount + disc.newEntryCount
      );
    }
  });

  const reachedMaximumEntries = newDisciplines.length === 0;

  if (!event.name) {
    return <div />;
  }

  const title = isBuddyEntry ? 'Buddy Cart' : 'Cart';
  const subtitle = isBuddyEntry ? 'Buddy' : 'Athlete';
  const agreementUrl =
    event.EventAthleteAgreementURL || dbVars.CONTESTANT_AGREEMENT;
  const hasSelectedDisciplines =
    selectedDisciplines && selectedDisciplines.length > 0;

  const checkoutDisabled = !acceptedTerms || !hasSelectedDisciplines;

  const hasNominations = !isEmpty(getNominations());
  return (
    <Layout className="bg-nominate" loading={loading || loadingCharge}>
      <div className="grid-container review-view">
        <div className="grid-x">
          <div className="small-12 large-12 desktop-only">
            <BackButton goBack={goBack} />
            <h2 className="section-title padtop-0 padbot-0">{title}</h2>
          </div>
          <div className="small-12 large-6 large-offset-3 theme-content-container entry">
            <div className="small-12 large-12 mobile-only">
              <ErrorMessage errorMessage={errorMessage} />
              {showEventMessagingBanner && (
                <Notification
                  type="info"
                  showCloseButton
                  text={event.EventMessaging}
                  onClose={onCloseEventNotification}
                />
              )}
              <BackButton goBack={goBack} />
              <h2 className="section-title padtop-0">{title}</h2>
            </div>
            <div className="desktop-only">
              <ErrorMessage errorMessage={errorMessage} />
              {showEventMessagingBanner && (
                <Notification
                  type="info"
                  showCloseButton
                  text={event.EventMessaging}
                  onClose={onCloseEventNotification}
                />
              )}
            </div>
            <EntryEventDescription event={event} />
            <hr />
            <div className="section-review-subtitle padtop-2">{subtitle}</div>
            <div className="label-review">{athleteFullName}</div>
            {athleteMembershipNumbers.map((amd) => (
              <div
                key={`association_sn_${amd.associationShortName}`}
                className="label-review"
              >
                {amd.associationShortName} #: {amd.membershipNumber}
              </div>
            ))}
            <div className="section-review-subtitle padtop-5">Entries</div>
            <div className="selected-disciplines-container">
              {AllowClasses ? (
                <SelectedDisciplinesListWithClasses
                  event={event}
                  loadingCharge={loadingCharge}
                  charge={charge}
                  history={history}
                  shouldHideNomination={shouldHideNomination}
                />
              ) : (
                <SelectedDisciplinesList
                  event={event}
                  loadingCharge={loadingCharge}
                  charge={charge}
                  history={history}
                  shouldHideNomination={shouldHideNomination}
                />
              )}
              <div className="text-center padtop-1 form-link-container">
                {!reachedMaximumEntries && (
                  <DisciplineAddButton
                    addDiscipline={() => {
                      editEntryDiscipline('add');
                    }}
                    selectedDisciplines={selectedDisciplines}
                  />
                )}
              </div>
            </div>
            <EventFees customFees={customFees} charge={charge} />

            <div className="padbot-1">
              <EntryAgreement
                onClick={acceptTerms}
                acceptedTerms={acceptedTerms}
                agreementUrl={agreementUrl}
              />
            </div>
            {!event.AllowsPayLater ? (
              <button
                className="btn-main marbot-2"
                disabled={checkoutDisabled}
                onClick={checkout}
              >
                Checkout
              </button>
            ) : null}

            {event.AllowsPayLater && !hasNominations ? (
              <button
                className="btn-main secondary"
                disabled={checkoutDisabled}
                onClick={() => {
                  checkout({ payLater: true });
                }}
              >
                Pay Later
              </button>
            ) : null}
          </div>
        </div>
      </div>
    </Layout>
  );
};
