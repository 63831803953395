// @flow
import React, { PureComponent } from 'react';
import { FormControl, FormHelperText } from '@material-ui/core';

import 'react-select/dist/react-select.css';
import Select from 'react-select';

type renderSelectBoxFieldProps = {|
  className: ?string,
  closeOnSelect: ?boolean,
  disabled: ?boolean,
  error: ?string,
  helpertext: ?string,
  input: Object,
  invalid: boolean,
  keepFullWidth: boolean,
  label: ?string,
  labelKey: string,
  multi: ?boolean,
  name: ?string,
  onChangeAction: (value: string) => void,
  optionRenderer?: Function,
  options: Object[],
  parentClass: string,
  placeholder: ?string,
  required: ?boolean,
  searchable: ?boolean,
  touched: ?boolean,
  valueKey: string,
  valueRenderer?: Function,
  variant: ?string,
  showError: ?boolean,
  meta: ?Object,
|};

export const renderSelectBoxField = (props: renderSelectBoxFieldProps) => {
  const {
    className,
    closeOnSelect,
    disabled,
    error,
    helpertext,
    input,
    invalid,
    keepFullWidth,
    label,
    labelKey,
    multi,
    name,
    onChangeAction,
    optionRenderer,
    options,
    parentClass,
    placeholder,
    required,
    searchable,
    touched,
    valueKey,
    valueRenderer,
    variant,
    showError,
    meta,
  } = props;
  return (
    <FormFieldSelectBox
      className={className}
      closeOnSelect={closeOnSelect}
      disabled={disabled}
      error={error}
      helpertext={helpertext}
      input={input}
      invalid={invalid}
      keepFullWidth={keepFullWidth}
      label={label}
      labelKey={labelKey}
      multi={multi}
      name={name}
      onChangeAction={onChangeAction}
      optionRenderer={optionRenderer}
      options={options}
      parentClass={parentClass}
      placeholder={placeholder}
      required={required}
      searchable={searchable}
      touched={touched}
      valueKey={valueKey}
      valueRenderer={valueRenderer}
      variant={variant}
      showError={showError}
      meta={meta}
    />
  );
};

class FormFieldSelectBox extends PureComponent<renderSelectBoxFieldProps> {
  static defaultProps = {
    className: '',
    closeOnSelect: true,
    disabled: false,
    error: '',
    invalid: false,
    helpertext: '',
    keepFullWidth: true,
    label: '',
    labelKey: 'name',
    multi: false,
    name: '',
    parentClass: '',
    placeholder: '',
    required: false,
    searchable: false,
    valueKey: 'id',
    variant: '',
    showError: false,
    meta: {},
  };

  updateValue = (val: Object) => {
    if (this.props.multi) {
      return this.props.input.onChange(val.map((v) => v[this.props.valueKey]));
    }
    const { valueKey, onChangeAction } = this.props;
    const value = val ? val[valueKey] : null;

    if (onChangeAction !== undefined) {
      onChangeAction(val);
    }

    this.props.input.onChange(value);
  };

  render() {
    const {
      className,
      closeOnSelect,
      disabled,
      error,
      helpertext,
      input,
      invalid,
      label,
      labelKey,
      multi,
      name,
      optionRenderer,
      options,
      parentClass,
      placeholder,
      searchable,
      touched,
      valueKey,
      valueRenderer,
      variant,
      showError,
      meta,
    } = this.props;

    const hasError = showError
      ? meta && meta.touched && meta.invalid && meta.error
      : (touched || invalid) && error;

    let errorMessage = (touched || invalid) && error ? error : '';
    if (
      showError &&
      (meta && meta.touched && meta.invalid) &&
      meta &&
      meta.error
    ) {
      errorMessage = meta.error;
    }

    let variantValClass = '';

    if (variant === 'filled') {
      variantValClass += 'filled-input ';
    }

    variantValClass += parentClass;

    return (
      <FormControl
        className={variantValClass}
        error={!!hasError}
        margin="none"
        fullWidth
        disabled={disabled}
      >
        <label className="form-label" htmlFor={name}>
          {label}
        </label>
        <Select
          {...input}
          className={className}
          closeOnSelect={closeOnSelect}
          disabled={disabled}
          labelKey={labelKey}
          multi={multi}
          name={name}
          onChange={this.updateValue}
          optionRenderer={optionRenderer}
          options={options}
          placeholder={placeholder}
          searchable={searchable}
          valueKey={valueKey}
          valueRenderer={valueRenderer}
        />
        {helpertext && !error && (
          <FormHelperText className="form-helper">{helpertext}</FormHelperText>
        )}
        {errorMessage && (
          <FormHelperText className="form-helper">
            {errorMessage}
          </FormHelperText>
        )}
      </FormControl>
    );
  }
}

export default FormFieldSelectBox;
