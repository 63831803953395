// @flow
// $FlowFixMe
import { useSelector as useReduxSelector, shallowEqual } from 'react-redux';
import { toJS } from 'helpers';

/**
 * @description: This is a modification of the useSelector hook.
 * Reason for this is that we are still needing toJS to convert immutable types to normal js types.
 * This function will no longer be necessary when immer is fully implemented in the application.
 * @argument fn: A function that either returns an object of multiple pieces of state or a single item.
 * @example const event = useSelector(state => state.event.get('data'))
 * @argument withShallowEqual?: an optional boolean that if passed will run the shallowEqual helper
 * on useSelector.
 * This is especially useful when passing in a function that returns an object of multiple pieces
 * of state in order to know when to rerun the function
 * @example const selectedProps = useSelector(state => ({
 *  event: state.event.get('data'),
 *  eventList: state.event.get('list'),
 * }), true);
 */

export const useSelector = (fn: Function, withShallowEqual: ?boolean) => {
  const callback = withShallowEqual ? shallowEqual : undefined;
  const selectedState = useReduxSelector(fn, callback);
  const target = withShallowEqual ? selectedState : { target: selectedState };
  const newTarget = toJS(target);

  if ('target' in newTarget) return newTarget.target;
  return newTarget;
};
