// @flow
import React, { type ComponentType } from 'react';
import { Plus } from 'react-feather';
import { getEntryLimitText } from 'helpers';
import { SectionSubtitle } from 'components/Section';
import {
  getPartnerDisciplineId,
  isTeamDiscipline,
} from 'constants/disciplines';
import { isYouthEvent } from '../../../helpers';

export type ListBoxOption = {
  label: string,
  value: string,
  [string]: any,
} & any;

export type ListBoxProps = {|
  list: Array<ListBoxOption>,
  onClick: ?(ListBoxOption) => any,
  subtitle: ?string,
  isYouthAthlete: ?boolean,
|};

const DisciplineListWithClasses: ComponentType<$ReadOnly<any>> = (
  props: ListBoxProps,
) => {
  const { onClick, list, subtitle, isYouthAthlete } = props;

  const ClassItem = (props) => {
    const { Class } = props;
    const {
      athleteEntryCount,
      newEntryCount,
      MaxNumberOfEntriesPerAthlete,
      value,
    } = Class;
    let { ClassName, Fee, label, DisciplineFeeName, DisciplineFee } = Class;

    if (!ClassName && isTeamDiscipline(value)) {
      const partnerDisciplineId = getPartnerDisciplineId(value);
      const item: any = list.find(
        (itemDiscipline) => itemDiscipline.value === partnerDisciplineId,
      );
      const itemClass = item.Classes.find(
        (itemClass) => itemClass.DisciplineUID === item.value,
      );
      ClassName = itemClass ? itemClass.ClassName : '';
      Fee = itemClass.Fee;
    }

    const entriesRemaining =
      MaxNumberOfEntriesPerAthlete - (athleteEntryCount + newEntryCount);
    return (
      <div
        className="discipline-with-classes discipline-entry-limit"
        onClick={entriesRemaining > 0 ? () => onClick && onClick(Class) : null}
      >
        <div>
          <div data-testid={label}>{`${ClassName} ($${Fee})`}</div>
          <div>
            {getEntryLimitText(
              MaxNumberOfEntriesPerAthlete,
              athleteEntryCount + newEntryCount,
            )}
          </div>
          {DisciplineFeeName ? (
            <div
              data-testid={label}
            >{`${DisciplineFeeName} ($${DisciplineFee})`}</div>
          ) : null}
        </div>
        <div className="discipline-icons">
          <button type="button">
            <Plus
              className={`add-button ${!entriesRemaining ? 'disabled' : ''}`}
            />
          </button>
        </div>
      </div>
    );
  };

  const renderClasses = (Classes) =>
    Classes.map((Class, index) => {
      if (!isYouthAthlete && isYouthEvent(Class.Division)) return null;

      return (
        <div key={Class.EventEntryDisciplineFeeUID}>
          <ClassItem Class={Class} />
          {index < Classes.length - 1 && (
            <hr className="discipline-with-classes-hr" />
          )}
        </div>
      );
    });

  const filteredList = list.filter((item) => {
    const { Classes } = item;
    return Classes.some((Class) => {
      const invalidClass = !isYouthAthlete && isYouthEvent(Class.Division);
      return !invalidClass;
    });
  });

  return (
    <>
      <div className="section-enter-landing-view-subtitle martop-1">
        Discipline(s)
      </div>
      {subtitle && subtitle.length > 0 && (
        <SectionSubtitle>{subtitle}</SectionSubtitle>
      )}
      {filteredList.map((item) => {
        const { Classes } = item;
        return (
          <div className="selected-discipline list-item" key={item.value}>
            <div className="discipline-with-classes-detail">
              <div className="discipline-with-classes-label">{item.label}</div>
              {renderClasses(Classes)}
            </div>
          </div>
        );
      })}
    </>
  );
};

export default DisciplineListWithClasses;
