// @flow
import React from 'react';

import {
  disciplinesByName,
  isTeamDiscipline,
  isPersonalHorseDiscipline,
} from 'constants/disciplines';
import { getPreferencesText, capitalizeString } from 'helpers';
import { orderBy } from 'lodash';

type EnteredDisciplinesType = {
  entries: Array<Object>,
};

export const EnteredDisciplines = ({
  entries = [],
}: EnteredDisciplinesType) => {
  const entriesByGroup = orderBy(entries, 'EPUID', 'ASC').reduce((rv, x) => {
    (rv[x['disciplineId']] = rv[x['disciplineId']] || []).push(x);
    return rv;
  }, {});
  return (
    <>
      {Object.keys(entriesByGroup).map((value) => {
        const isTeam = isTeamDiscipline(value);
        const disciplineName = disciplinesByName[value];
        const entryIsHorseDiscipline = isPersonalHorseDiscipline(value);

        return (
          <div key={value} className="discipline-entered">
            <div className="confirmation-discipline">
              {disciplineName}{' '}
              {entriesByGroup[value].length > 1
                ? ` (X${entriesByGroup[value].length})`
                : null}
            </div>
            {entriesByGroup[value].map((entry, index) => {
              const {
                partnerFullName,
                HorseName,
                prefCount,
                notCount,
                NominationSegments,
                ClassName,
              } = entry;
              return (
                <>
                  <div className="confirmation-discipline">{ClassName}</div>
                  {NominationSegments &&
                  NominationSegments !== null &&
                  index == 0 ? (
                    <div>Nomination: {NominationSegments}</div>
                  ) : null}
                  {(prefCount || notCount) && index == 0 ? (
                    <div className="entry-description">
                      <span>{getPreferencesText(prefCount, notCount)}</span>
                    </div>
                  ) : null}

                  {entryIsHorseDiscipline && (
                    <div
                      key={`${HorseName}-${index + 1}`}
                      className="horse-label"
                    >
                      {`Horse${
                        entriesByGroup[value].length > 1
                          ? ` #${index + 1}: `
                          : ': '
                      }`}{' '}
                      {HorseName}
                    </div>
                  )}
                  {isTeam && (
                    <div className="partner-section">
                      {entriesByGroup[value].length > 1
                        ? `Partner #${index + 1}:`
                        : 'Partner:'}{' '}
                      <span>{capitalizeString(partnerFullName)}</span>
                    </div>
                  )}
                </>
              );
            })}
          </div>
        );
      })}
    </>
  );
};
