// @flow
import React, { useContext, useCallback } from 'react';
import { compose } from 'redux';

import withCalculateEntryCharge from 'enhancers/withCalculateEntryCharge';
import { DisciplineContext } from 'context/SelectedDisciplines';
import {
  getEntryDiscFee,
  getPartnerEntryDiscFee,
  preferencesAndOutCounts,
  getPreferencesAndNotsData,
  getPartnersData,
} from 'helpers';
import type { EventType } from 'models/Event';
import type { DisciplineType } from 'context/SelectedDisciplines/type';
import { isTeamDiscipline } from 'constants/disciplines';
import DisciplineCardDescription from './DisciplineCardDescription';
import { pluralize, changeRoute } from 'helpers';
import { EVENT_ENTRY } from 'constants/routes';

type SelectedDisciplinesListProps = {|
  event: EventType,
  loadingCharge: boolean,
  charge: Object,
  history: Object,
  showConfirmPartnerRemoveModal: boolean,
  shouldHideNomination: boolean,
|};

const SelectedDisciplinesList = (props: SelectedDisciplinesListProps) => {
  const { selectedDisciplines, activeDisciplineSelect } = useContext(
    DisciplineContext,
  );

  const onEditDiscipline = useCallback(async (discipline: DisciplineType) => {
    await activeDisciplineSelect(discipline);
    changeRoute({ history: props.history, route: EVENT_ENTRY });
  }, []);

  const getDisciplineEntryFee = (disciplineId, disciplineFees, customFee) => {
    const fee = getEntryDiscFee(disciplineFees, disciplineId, customFee);
    return fee;
  };

  const getPartnerEntryFee = (disciplineId, disciplineFees, customFee) => {
    const fee = getPartnerEntryDiscFee(disciplineFees, disciplineId, customFee);
    return fee;
  };

  const getCustomEntyFeeName = (disciplineId, disciplineFees) => {
    const discipline = disciplineFees.find(
      (entry) => entry.disciplineId === disciplineId,
    );

    if (!discipline) return '';

    return discipline.disciplineFeeName;
  };

  const getHorseNames = (discipline: DisciplineType) => {
    const { horseNames } = discipline;
    return horseNames && horseNames.length ? horseNames : [];
  };

  const {
    event,
    charge: { disciplineFees },
  } = props;
  const { NumberOfNots, NumberOfPrefs, DisablePartnerSelection } = event;

  return (
    <>
      {selectedDisciplines.map((dis, index) => {
        const discipline = { ...dis };
        const disciplineFee = getDisciplineEntryFee(
          discipline.value,
          disciplineFees,
        );
        const partnerDisciplineFee = getPartnerEntryFee(
          discipline.value,
          disciplineFees,
        );
        const disciplineCustomFee = getDisciplineEntryFee(
          discipline.value,
          disciplineFees,
          true,
        );
        const partnerDisciplineCustomFee = getPartnerEntryFee(
          discipline.value,
          disciplineFees,
          true,
        );

        const customFeeName = getCustomEntyFeeName(
          discipline.value,
          disciplineFees,
        );

        if (!discipline.DisciplineFeeName || !discipline.DisciplineFee) {
          discipline.DisciplineFeeName = customFeeName;
          discipline.DisciplineFee = disciplineCustomFee;
        }

        const totalDisciplineFee =
          disciplineFee * discipline.newEntryCount + partnerDisciplineFee;

        const totalDisciplineCustomFee =
          disciplineCustomFee * discipline.newEntryCount +
          partnerDisciplineCustomFee;

        const { preferenceCount, outCount } = preferencesAndOutCounts(
          discipline,
        );

        const preferenceText = NumberOfPrefs
          ? pluralize(`${preferenceCount} preference`, preferenceCount !== 1)
          : '';
        const outText = NumberOfNots
          ? pluralize(`${outCount} not`, outCount !== 1)
          : '';

        const horseNames = getHorseNames(discipline);

        const prefComma = NumberOfPrefs && NumberOfNots ? ', ' : ' ';

        const elKey = `${discipline.value}-${String(
          discipline.EPUID,
        )}-${index}`;

        const preferencesAndNots = getPreferencesAndNotsData(
          disciplineFees,
          discipline,
        );

        let { partners } = discipline;
        const { isPendingEntry, partner } = discipline;

        if (isPendingEntry) {
          partners = [partner];
        }

        const partnersData =
          !DisablePartnerSelection && isTeamDiscipline(discipline.value)
            ? getPartnersData(partners)
            : [];

        let performanceUIDs = [];

        if ('performances' in discipline && discipline.performances) {
          performanceUIDs = discipline.performances.map(
            ({ PerformanceUID }) => PerformanceUID,
          );
        }

        const eventPerformances = event.performances.filter(
          ({ PerformanceUID }) => performanceUIDs.includes(PerformanceUID),
        );

        const showPreferenceText = eventPerformances
          .reduce((acc, curr) => {
            const foundDiscipline = curr.Disciplines.find(
              ({ id }) => id === discipline.value,
            );

            if (!!foundDiscipline) {
              if ('PreferenceEligible' in foundDiscipline) {
                return [...acc, foundDiscipline.PreferenceEligible];
              } else {
                return [...acc];
              }
            } else {
              return [...acc];
            }
          }, [])
          .some((preferenceEligible) => !!preferenceEligible);

        return (
          <DisciplineCardDescription
            key={elKey}
            onClick={onEditDiscipline}
            discipline={discipline}
            preferenceText={preferenceText}
            outText={outText}
            customFeeName={customFeeName}
            prefComma={prefComma}
            fee={totalDisciplineFee}
            customFee={totalDisciplineCustomFee}
            horseNames={horseNames}
            preferencesAndNots={preferencesAndNots}
            partnersData={partnersData}
            showPreferenceText={showPreferenceText}
          />
        );
      })}
    </>
  );
};

export default compose(withCalculateEntryCharge)(SelectedDisciplinesList);
