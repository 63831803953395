import gql from 'graphql-tag';

export const GET_USER_PROFILE = gql`
  query GET_USER_PROFILE($token: String) {
    user(token: $token) {
      id
      firstName
      lastName
      nickname
      hometown
      DOBVerified
      email
      ERAUID
      DOB
      Gender
      city
      street
      street2
      state
      zip
      country
      phone
      disciplineId
      isParent
      isAthlete
      worldChampionshipEarned
      careerEarnings
      height
      weight
      socialTags
      facebook
      twitter
      instagram
      TaxInfoUpdateDate
      SSNLastFour
      LegalFirstName
      LegalLastName
      youthAthletes {
        id
        ERAUID
        FullName
        FName
        LName
        DOB
        TaxInfoUpdateDate
        Gender
        city
        state
        hometown
        entryData {
          confirmedEntries {
            EventUID
            entries {
              EPUID
              CompType
              HasAgreed
              PaymentStatus
              EventUID
              EventName
              EventDate
              EventDateTo
              EntryDateTo
              TimeZoneUID
              EventImage
              EventLocation
              rank
              isUpcoming
              isAlliancePartner
            }
          }
          pendingEntries {
            EventUID
            entries {
              EPUID
              CompType
              HasAgreed
              PaymentStatus
              EventUID
              EventName
              EventDate
              EventDateTo
              EntryDateTo
              TimeZoneUID
              EventImage
              EventLocation
              rank
              isUpcoming
              isAlliancePartner
              Pref1
              Pref2
              Pref3
              Out1
              Out2
              Pref1AsDate
              Pref2AsDate
              Pref3AsDate
              Out1AsDate
              Out2AsDate
              OutIfPrefNotMet
              partner {
                EPUID
                id
                fullNameWithNickname
                city
                state
              }
            }
          }
          pendingWithMembershipEntries {
            EventUID
            entries {
              EPUID
              CompType
              HasAgreed
              PaymentStatus
              EventUID
              EventName
              EventDate
              EventDateTo
              EntryDateTo
              TimeZoneUID
              EventImage
              EventLocation
              rank
              isUpcoming
              isAlliancePartner
              Pref1
              Pref2
              Pref3
              Out1
              Out2
              Pref1AsDate
              Pref2AsDate
              Pref3AsDate
              Out1AsDate
              Out2AsDate
              OutIfPrefNotMet
              partner {
                EPUID
                id
                fullNameWithNickname
                city
                state
              }
            }
          }
        }
      }
      restrictionData {
        restrictions {
          EventUID
          CompType
          ERAUID
        }
      }
      entryData {
        confirmedEntries {
          EventUID
          entries {
            EPUID
            CompType
            HasAgreed
            PaymentStatus
            EventUID
            EventName
            EventDate
            EventDateTo
            EntryDateTo
            TimeZoneUID
            EventImage
            EventLocation
            rank
            isUpcoming
            isAlliancePartner
          }
        }
        pendingEntries {
          EventUID
          entries {
            EPUID
            CompType
            HasAgreed
            PaymentStatus
            EventUID
            EventName
            EventDate
            EventDateTo
            EntryDateTo
            TimeZoneUID
            EventImage
            EventLocation
            rank
            isUpcoming
            isAlliancePartner
            Pref1
            Pref2
            Pref3
            Out1
            Out2
            Pref1AsDate
            Pref2AsDate
            Pref3AsDate
            Out1AsDate
            Out2AsDate
            OutIfPrefNotMet
            partner {
              EPUID
              id
              fullNameWithNickname
              city
              state
            }
          }
        }
        pendingWithMembershipEntries {
          EventUID
          entries {
            EPUID
            CompType
            HasAgreed
            PaymentStatus
            EventUID
            EventName
            EventDate
            EventDateTo
            EntryDateTo
            TimeZoneUID
            EventImage
            EventLocation
            rank
            isUpcoming
            isAlliancePartner
            Pref1
            Pref2
            Pref3
            Out1
            Out2
            Pref1AsDate
            Pref2AsDate
            Pref3AsDate
            Out1AsDate
            Out2AsDate
            OutIfPrefNotMet
            partner {
              EPUID
              id
              fullNameWithNickname
              city
              state
            }
          }
        }
      }
    }
  }
`;
