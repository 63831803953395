// @flow
import React, { useEffect, useState } from 'react';
import Select, { components } from 'react-select-latest';
import { Checkbox } from '@material-ui/core';
import { get, set } from 'lodash';

type Option = {
  label: string,
  value: string,
  selected?: boolean,
  parentProperty?: string,
  parentValue?: string,
  isChild?: boolean,
  additionalInfo?: Object,
  isLabel?: boolean,
  isDisabled?: boolean,
};

type SelectCheckboxesProps = {|
  options: Option[],
  onChange: (Option[]) => void,
  placeholder?: string,
  value?: Option[],
  disabled?: boolean,
  name?: string,
  sliceLabel?: boolean,
  onBlur?: (SyntheticFocusEvent<HTMLElement>) => void,
|};

const SelectCheckboxes = ({
  options,
  onChange,
  placeholder,
  value,
  name,
  onBlur,
  sliceLabel = false,
  disabled = false,
}: SelectCheckboxesProps) => {
  const [selectedOptions, setSelectedOptions] = useState<Array<Option>>([]);
  const inputLabels = sliceLabel
    ? selectedOptions
        .map(({ label }) => `${label[0]}${label.slice(9)}`)
        .join(', ')
    : selectedOptions.map(({ label }) => label).join(', ');

  const MultiValue = ({ index }) => {
    return index === 0 ? <p>{inputLabels}</p> : null;
  };

  const getParent = (option, optionsSelected: Array<Option>) => {
    const parentValue = option.parentValue;
    return optionsSelected.find(
      (option) => option && option.value === parentValue,
    );
  };

  const InputOption = ({
    getStyles,
    isFocused,
    children,
    innerProps,
    data,
    ...rest
  }) => {
    const parent = getParent(data, selectedOptions);
    const { isChild, isLabel, isDisabled } = data;
    const isParentSelected = isChild
      ? get(parent, data.parentProperty, false)
      : false;
    const isSelected = data.isChild
      ? isParentSelected
      : selectedOptions.some((option) => option.value === data.value);

    const style = {
      alignItems: 'center',
      backgroundColor: 'transparent',
      color: 'inherit',
      display: 'flex ',
    };
    const handleCheckboxChange = () => {
      if (isDisabled) {
        return;
      }
      setSelectedOptions((prev) => {
        let newOptions = [...prev];
        const isChild = data.isChild;
        const parentProperty = data.parentProperty;
        const parentValue = data.parentValue;
        if (isChild) {
          const parent = getParent(data, newOptions);
          if (!parent) {
            const newParent = options.find(
              (item) => item.value === parentValue,
            );
            if (newParent) {
              set(newParent, parentProperty, true);
              newOptions = newOptions.concat(newParent);
            }
          } else {
            const isChildSelected = get(parent, parentProperty, false);
            set(parent, parentProperty, !isChildSelected);
          }
        } else {
          const index = newOptions.findIndex(
            (item) => item && item.value === data.value,
          );
          if (index === -1) {
            newOptions = newOptions.concat(data);
          } else {
            newOptions.splice(index, 1);
          }
        }
        onChange(newOptions);
        return newOptions;
      });
    };

    // prop assignment
    const props = {
      ...innerProps,
      onClick: handleCheckboxChange,
      style,
    };

    return (
      <components.Option
        {...rest}
        isFocused={isFocused}
        data={data}
        isDisabled={isDisabled}
        className={isChild ? 'child' : ''}
        isSelected={isSelected}
        getStyles={getStyles}
        innerProps={props}
      >
        {isLabel ? (
          <span>{children}</span>
        ) : (
          <>
            <Checkbox checked={isSelected} disabled={isDisabled} />
            {children}
          </>
        )}
      </components.Option>
    );
  };

  useEffect(() => {
    setSelectedOptions(value || []);
  }, [value]);

  const defaultOptions = options.filter((option) => option.selected);

  return (
    <div className="select-checkboxes-container">
      <Select
        defaultValue={defaultOptions}
        isMulti
        isClearable={false}
        isSearchable={false}
        closeMenuOnSelect={false}
        isFocused={true}
        isActive={true}
        name={name}
        onBlur={onBlur}
        isDisabled={disabled}
        controlShouldRenderValue={selectedOptions.length > 0}
        placeholder={selectedOptions.length > 0 ? inputLabels : placeholder}
        hideSelectedOptions={false}
        options={options}
        className={`react-select-container ${
          selectedOptions.length > 0 ? 'has-value' : ''
        }`}
        classNamePrefix="react-select"
        components={{
          Option: InputOption,
          MultiValue: MultiValue,
        }}
        menuPosition="fixed"
      />
    </div>
  );
};

export default SelectCheckboxes;
