import moment from 'moment';
import { getDisciplineFilterOptions } from 'constants/disciplines';
import { EVENT_DIVISIONS_TYPE } from 'constants/event';
const { YOUTH_EVENT_TYPE, OPEN_EVENT_TYPE } = EVENT_DIVISIONS_TYPE;

export const FILTER_FORM_NAME = 'filterForm';

export const allianceOptions = [
  { label: 'ALLIANCE EVENT', value: true },
  { label: 'NON ALLIANCE EVENT', value: false },
];

export const defaultCompetitionLevelOptions = [
  { label: 'Open Events', value: OPEN_EVENT_TYPE },
  { label: 'Youth Events', value: YOUTH_EVENT_TYPE },
];

export const filtersFields = {
  Alliance: {
    name: 'Alliance',
    label: 'Alliance',
    getStringResult: (values) => {
      return values.map((value) => value.label).join(', ');
    },
  },
  CompetitionLevel: {
    name: 'CompetitionLevel',
    label: 'Competition Level',
    getStringResult: (value) =>
      defaultCompetitionLevelOptions.find((option) => option.value === value)
        ?.label,
  },
  Division: {
    name: 'Division',
    label: 'Division',
    getStringResult: (options) =>
      options
        .map((option) => {
          const label = option.label;
          return `${label[0]}${label.slice(9)}`;
        })
        .join(', '),
  },
  Rodeo: {
    name: 'Rodeo',
    label: 'Rodeo/Jackpot/Other',
  },
  Discipline: {
    name: 'Discipline',
    label: 'Discipline',
    getStringResult: (value) =>
      getDisciplineFilterOptions().find((option) => option.value === value)
        ?.label,
  },
  Location: {
    label: 'Location',
    name: 'Location',
    getStringResult: (value) => value,
  },
  StartDate: {
    label: 'Start Date',
    name: 'StartDate',
    getStringResult: (value) => moment(value)?.format('MM/DD/YYYY'),
  },
  EndDate: {
    name: 'EndDate',
    label: 'End Date',
    getStringResult: (value) => moment(value)?.format('MM/DD/YYYY'),
  },
};

export const eventTypeOptions = [
  { label: 'Rodeo', value: 3 },
  { label: 'Jackpot', value: 1 },
  { label: 'Qualifier Series', value: 216 },
];
