import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { compose } from 'redux';

import { entryRemoveAsPartner } from 'actions/user';
import { getBuddyGroup, getEntryTransactionsForEvent } from 'actions/entryPool';

import { withSelectedDisciplines } from 'context/SelectedDisciplines';
import { withSelectedAthletes } from 'context/SelectedAthletes';
import { withBuddyGroups } from 'context/BuddyGroups';
import withCalculateEntryCharge from 'enhancers/withCalculateEntryCharge';
import DisciplineFlowSteps from './DisciplineFlowSteps';
import { getUser } from 'selectors/user';

const mapStateToProps = (state) => ({
  buddyGroup: state.entryPool.get('buddyGroup'),
  buddyGroupManager: state.entryPool.get('buddyGroupManager'),
  charge: state.entryChargeRequest.get('data'),
  event: state.event.get('data'),
  payment: state.payment.get('data'),
  user: getUser(state),
  transactions: state.entryPool.get('transactions'),
  loading: state.entryPool.get('loading'),
});

const mapDispatchToProps = (dispatch) => ({
  dispatch,
  getBuddyGroup: () => dispatch(getBuddyGroup()),
  getBuddyGroupTransactions: (eventId, ERAUID) =>
    dispatch(getEntryTransactionsForEvent(eventId, ERAUID)),
  entryRemoveAsPartner: (activeDiscipline, user) =>
    dispatch(entryRemoveAsPartner(activeDiscipline, user)),
});

export default compose(
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
  withBuddyGroups,
  withSelectedDisciplines,
  withSelectedAthletes,
  withCalculateEntryCharge,
)(DisciplineFlowSteps);
