import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import AlertCircle from 'react-feather/dist/icons/alert-circle';

import ErrorMessage from 'components/ErrorMessage';
import Notification from 'components/Notification';
import { required, exactLength2 } from 'components/Forms/Validation';
import { renderField } from 'helpers';

class AddEventForm extends Component {
  static propTypes = {
    errorMessage: PropTypes.string,
    loading: PropTypes.bool.isRequired,
    onSubmit: PropTypes.func.isRequired,
  };

  state = {
    EventDate: moment.utc().toDate(),
    EventDateTo: moment
      .utc()
      .add(1, 'day')
      .toDate(),
  };

  renderDatePicker = ({ input, selected, value, meta: { touched, error } }) => {
    const minDate = moment.utc();
    return (
      <div>
        <DatePicker
          {...input}
          selected={selected}
          value={value}
          minDate={minDate}
          dateFormat="MM/dd/yyyy"
        />
        {touched && error && <span className="error_field">{error}</span>}
      </div>
    );
  };

  onChangeEventDate = (EventDate) => {
    this.setState({
      EventDate,
    });
  };

  onChangeEventDateTo = (EventDateTo) => {
    this.setState({
      EventDateTo,
    });
  };

  onSubmit = (values) => {
    if (this.invalidDates()) {
      return;
    }

    this.props.onSubmit(values);
  };

  invalidDates() {
    const { EventDate, EventDateTo } = this.state;
    return moment(EventDate).isAfter(EventDateTo, 'day');
  }

  render() {
    const { EventDate, EventDateTo } = this.state;
    const { loading } = this.props;

    return (
      <form onSubmit={this.onSubmit}>
        <ErrorMessage errorMessage={this.props.errorMessage} />
        <fieldset>
          <hr />
          <label htmlFor="EventName">Event Name</label>
          <Field
            name="EventName"
            component={renderField}
            type="text"
            placeholder="Event Name"
            validate={[required]}
          />
          <hr className="martop-0" />
          <label htmlFor="EventCity">City</label>
          <Field
            name="EventCity"
            component={renderField}
            type="text"
            placeholder="Cities"
            validate={[required]}
          />
          <hr className="martop-0" />
          <label htmlFor="EventState">State</label>
          <Field
            name="EventState"
            component={renderField}
            type="text"
            placeholder="State"
            validate={[required, exactLength2]}
          />
          <hr />
          <label htmlFor="StartDate">Start Date</label>
          <Field
            name="StartDate"
            component={this.renderDatePicker}
            placeholder={EventDate}
            selected={EventDate}
            value={EventDate}
            onChange={this.onChangeEventDate}
          />
          <hr className="martop-0" />
          <label htmlFor="EndDate">End Date</label>
          <Field
            name="EndDate"
            component={this.renderDatePicker}
            placeholder={EventDateTo}
            selected={EventDateTo}
            value={EventDateTo}
            onChange={this.onChangeEventDateTo}
          />
          <hr className="martop-0" />
          {this.invalidDates() && (
            <Notification
              text={'End date must be later than start date'}
              icon={<AlertCircle />}
            />
          )}
        </fieldset>
        <div className="text-center">
          {loading ? (
            <button className="btn-main" disabled type="submit">
              Saving...
            </button>
          ) : (
            <button action="submit" className="btn-main" type="submit">
              Add Rodeo
            </button>
          )}
        </div>
      </form>
    );
  }
}

export default AddEventForm;
