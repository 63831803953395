import get from 'lodash/get';

export const getDisciplineSegments = (state) =>
  state.disciplineSegments.toJS().data;
export const getDisciplineSegmentsSelected = (state) => {
  return get(state.disciplineSegments.toJS(), 'selected');
};
export const getDisciplineSegmentsLoading = (state) => {
  return get(state.disciplineSegments.toJS(), 'loading');
};
