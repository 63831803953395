// @flow
import React from 'react';
import type { Element } from 'react';
import { Field } from 'redux-form-normalize-on-blur';

type NameFieldPropsType = {
  component: Element<any> | Function,
  name: string,
  placeholder: string,
  validate?: Array<Function> | ((f: Function) => void),
  className?: string,
};

const NameField = (props: NameFieldPropsType) => {
  const onBlurNormalize = (value) => {
    return value && value.trim();
  };
  const { component, className, name, placeholder, validate } = props;

  return (
    <Field
      className={className}
      name={name}
      component={component}
      type="text"
      placeholder={placeholder}
      validate={validate}
      normalizeOnBlur={onBlurNormalize}
    />
  );
};

export default NameField;
