// @flow
import React from 'react';
import classNames from 'classnames';

import withToJS from 'enhancers/withToJS';
import type { Node, StatelessFunctionalComponent } from 'react';

type NominationContentProps = {|
  children: Node,
  className?: string,
  removeOriginalClassName?: boolean,
|};

type ContentComponent = StatelessFunctionalComponent<NominationContentProps>;

const NominationContent: ContentComponent = withToJS(
  (props: NominationContentProps) => {
    const { children, className, removeOriginalClassName } = props;

    return (
      <div
        className={classNames(className, {
          'selectable-area': !removeOriginalClassName,
        })}
      >
        {children}
      </div>
    );
  },
);

export default NominationContent;
