// @flow
import React from 'react';

import BaseFormField from 'components/FormField';

type RenderFieldType = {|
  input: string,
  name: string,
  placeholder: string,
  className: string,
  type: string,
  min: number,
  max: number,
  checked: boolean,
  meta: Object,
|};

const FormField = ({
  input,
  name,
  placeholder,
  className,
  type,
  min,
  max,
  checked,
  meta: { touched, error },
}: RenderFieldType) => (
  <BaseFormField
    input={input}
    placeholder={placeholder}
    className={className}
    type={type}
    name={name}
    checked={checked}
    touched={touched}
    error={error}
    min={min}
    max={max}
  />
);

export default FormField;
