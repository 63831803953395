// @flow
import { useEffect, useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';

import { getSegments } from 'actions/segments';
import { useSelector } from 'hooks/useSelector';

import type { SegmentType } from './type';
import { isYouthRank } from '../../constants/event';

export const useSegments = () => {
  const [availableSegments, setAvailableSegments] = useState([]);
  const [selectedSegments, setSelectedSegments] = useState<any>([]);

  const selectedProps = useSelector(
    (state) => ({
      event: state.event.get('data'),
      segments: state.segments.get('list').map((segment) => ({
        label: segment.Name,
        NominatableByYouth: segment.NominatableByYouth,
        value: segment.SegmentUID,
        ShortName: segment.ShortName,
        segment,
      })),
    }),
    true,
  );
  const dispatch = useDispatch();

  const fetchSegments = useCallback(
    (hasYouth: ?boolean) => {
      dispatch(getSegments({ hasYouth }));
    },
    [dispatch],
  );

  const getCurrentSegment = useCallback((): ?SegmentType => {
    const { segments } = selectedProps;
    const now = new Date();
    return segments.find(
      (segment) =>
        new Date(segment.StartDate) < now && new Date(segment.EndDate) > now,
    );
  }, [selectedProps.event.id]);

  const concatSegmentArray = useCallback(
    (segment) => (arr): SegmentType[] => [...arr, segment],
    [],
  );
  const filterSegmentFromArray = useCallback(
    (segment) => (arr): SegmentType[] =>
      arr.filter((asg) => asg.value !== segment.value),
    [],
  );

  const getDefaultSelectedSegments = useCallback(() => {
    const currentSegment = getCurrentSegment();
    if (currentSegment) {
      const filter = filterSegmentFromArray(currentSegment);
      const { segments } = selectedProps;
      setSelectedSegments([currentSegment]);
      setAvailableSegments(filter(segments));
    }
  }, [selectedProps.event.id]);

  const toggleSelect = async (segment: SegmentType) => {
    const selectedSegment = selectedSegments.find(
      (seg) => seg.value === segment.value,
    );

    const concat = concatSegmentArray(segment);
    const filter = filterSegmentFromArray(segment);

    if (selectedSegment) {
      setSelectedSegments(filter(selectedSegments));
      setAvailableSegments(concat(availableSegments));

      return;
    }
    setSelectedSegments(concat(selectedSegments));
    setAvailableSegments(filter(availableSegments));
  };

  useEffect(() => {
    fetchSegments();
  }, []);
  useEffect(() => {
    const hasYouth = isYouthRank(selectedProps.event.rank);
    fetchSegments(hasYouth);
  }, [selectedProps.event.id]);
  useEffect(() => {
    getDefaultSelectedSegments();
  }, [selectedProps.event.id]);

  return {
    getCurrentSegment,
    availableSegments,
    selectedSegments,
    toggleSelect,
    segments: selectedProps.segments,
    resetSegments: getDefaultSelectedSegments,
    setSelectedSegments: setSelectedSegments,
  };
};
