// @flow
import { useImmerReducer } from 'use-immer';
import { current } from 'immer';
import { get } from 'lodash';
import nanoid from 'short-unique-id';
import { getNestedProperty, hasPreferences } from 'helpers';
import {
  getEntryCount,
  getAvailableDisciplinesForAthlete,
  setEntryAddedPartners,
  updateSamePartners,
} from './helpers';
import type { DisciplineType } from './type';
import type { PreferenceType } from 'context/SelectedDisciplines';
import type { EventType } from 'models/Event';
import {
  disciplinesByName,
  getPartnerDisciplineId,
} from 'constants/disciplines';

type UseDisciplinesState = {|
  availableDisciplines: DisciplineType[],
  availablePreferences: PreferenceType[],
  selectedDisciplines: DisciplineType[],
  unavailableDisciplines: DisciplineType[],
  defaultDisciplines: Object,
  activeDiscipline?: Object,
  entries: Object,
  partnerErrorMessage: string,
  isEditing: boolean,
  showConfirmPartnerRemoveModal: boolean,
  HorseName: ?string,
  event: EventType,
|};
type ActionType = {|
  type: string,
  data: any,
  method?: string,
|};

export const initialState: UseDisciplinesState = {
  availableDisciplines: [],
  selectedDisciplines: [],
  unavailableDisciplines: [],
  availablePreferences: [],
  defaultDisciplines: {},
  activeDiscipline: {},
  entries: {},
  isEditing: false,
  partnerErrorMessage: '',
  showConfirmPartnerRemoveModal: false,
  HorseName: null,
  event: {},
};

export const LOAD_DISCIPLINES = 'LOAD_DISCIPLINES';
export const SET_DEFAULT_DISCIPLINES = 'SET_DEFAULT_DISCIPLINES';
export const SET_AVAILABLE_PREFERENCES = 'SET_AVAILABLE_PREFERENCES';
export const TOGGLE_SELECT_DISCIPLINE = 'TOGGLE_SELECT_DISCIPLINE';
export const TOGGLE_PREFERENCE = 'TOGGLE_PREFERENCE';
export const SET_PREFERENCE = 'SET_PREFERENCE';
export const SET_PARTNER_ERROR_MESSAGE = 'SET_PARTNER_ERROR_MESSAGE';
export const TOGGLE_OUT_IF_PREFERENCE_NOT_MET =
  'TOGGLE_OUT_IF_PREFERENCE_NOT_MET';
export const ACTIVE_DISCIPLINE_SELECT = 'ACTIVE_DISCIPLINE_SELECT';
export const UPDATE_ACTIVE_DISCIPLINE = 'UPDATE_ACTIVE_DISCIPLINE';
export const ACTIVE_DISCIPLINE_DESELECT = 'ACTIVE_DISCIPLINE_DESELECT';
export const TOGGLE_SEGMENT = 'TOGGLE_SEGMENT';
export const TOGGLE_SELECT_SEGMENT = 'TOGGLE_SELECT_SEGMENT';
export const SET_COUNT_ACTIVE_SEGMENT = 'SET_COUNT_ACTIVE_SEGMENT';
export const SET_PARTNER_ATHLETE = 'SET_PARTNER_ATHLETE';
export const SET_PAY_FOR_PARTNER = 'SET_PAY_FOR_PARTNER';
export const INVITE_PARTNER_ATHLETE = 'INVITE_PARTNER_ATHLETE';
export const SWITCH_ATHLETE = 'SWITCH_ATHLETE';
export const SET_ENTRIES_COUNT = 'SET_ENTRIES_COUNT';
export const ADD_ENTRY = 'ADD_ENTRY';
export const ADD_PENDING_ENTRIES = 'ADD_PENDING_ENTRIES';
export const REMOVE_ENTRY = 'REMOVE_ENTRY';
export const REMOVE_DISC_EVENT_ENTRY = 'REMOVE_DISC_EVENT_ENTRY';
export const UPDATE_ENTRY = 'UPDATE_ENTRY';
export const TOGGLE_IS_EDITING = 'TOGGLE_IS_EDITING';
export const SET_HORSE_NAME = 'SET_HORSE_NAME';
export const TOGGLE_CONFIRM_PARTNER_REMOVAL_MODAL =
  'TOGGLE_CONFIRM_PARTNER_REMOVAL_MODAL';
export const CLEAR_SELECTED_DISCIPLINES = 'CLEAR_SELECTED_DISCIPLINES';
export const SET_BUDDY_ENTRIES_COUNT = 'SET_BUDDY_ENTRIES_COUNT';

const getDisciplineWithUpdatedPreferences = (discipline, preferenceId) => {
  const { Pref1, Pref2, Pref3, Out1, Out2 } = discipline;

  if (Pref1 === preferenceId) discipline.Pref1 = null;
  if (Pref2 === preferenceId) discipline.Pref2 = null;
  if (Pref3 === preferenceId) discipline.Pref3 = null;
  if (Out1 === preferenceId) discipline.Out1 = null;
  if (Out2 === preferenceId) discipline.Out2 = null;

  return discipline;
};

const setDisciplinePreference = (
  discipline,
  { disciplineId, preferenceCategory, preferenceId, ClassName },
) => {
  if (
    String(discipline.value) !== String(disciplineId) ||
    String(discipline.ClassName) !== String(ClassName)
  )
    return discipline;
  const updatedDiscipline = getDisciplineWithUpdatedPreferences(
    discipline,
    preferenceId,
  );
  updatedDiscipline[preferenceCategory] = preferenceId;
  updatedDiscipline.hasPreference = hasPreferences(updatedDiscipline);

  return updatedDiscipline;
};

const toggleDiscOutIfPrefNotMet = (discipline, disciplineId) => {
  if (discipline && discipline.value === disciplineId) {
    discipline.OutIfPrefNotMet = !discipline.OutIfPrefNotMet;
  }
  return discipline;
};
const getNewDiscipline = (discipline, method, isEditing) => {
  let isSelected;
  let partner = getNestedProperty('partner', discipline);
  let isPayingForPartner = getNestedProperty('isPayingForPartner', discipline);
  switch (method) {
    case 'ADD': {
      isSelected = true;
      if (!isEditing) {
        partner = null;
        isPayingForPartner = false;
      }
      break;
    }
    case 'REMOVE': {
      isSelected = false;
      partner = null;
      isPayingForPartner = false;
      break;
    }
    default: {
      isSelected = !discipline.isSelected;
      break;
    }
  }

  const { Pref1, Pref2, Pref3, Out1, Out2 } = discipline;
  const hasPreference = !!Pref1 || !!Pref2 || !!Pref3 || !!Out1 || !!Out2;

  const defaults = {
    Out1: null,
    Out2: null,
    Pref1: null,
    Pref2: null,
    Pref3: null,
    EPUID: null,
    segments: [],
    partner: getNestedProperty('partner', discipline),
    isPayingForPartner: false,
  };

  return {
    ...defaults,
    ...discipline,
    isSelected,
    partner,
    isPayingForPartner,
    hasPreference,
  };
};
export const disciplinesReducer = (
  draft: UseDisciplinesState,
  action: ActionType,
) => {
  switch (action.type) {
    case LOAD_DISCIPLINES: {
      const {
        availableDisciplines,
        selectedDisciplines,
        unavailableDisciplines,
        event,
      } = action.data;
      draft.unavailableDisciplines = unavailableDisciplines;
      draft.selectedDisciplines = selectedDisciplines;
      draft.availableDisciplines = availableDisciplines;
      draft.event = event;
      break;
    }
    case SET_DEFAULT_DISCIPLINES: {
      draft.defaultDisciplines = action.data;
      break;
    }
    case SET_PARTNER_ERROR_MESSAGE: {
      draft.partnerErrorMessage = action.data;
      break;
    }
    case SET_AVAILABLE_PREFERENCES: {
      draft.availablePreferences = action.data;
      break;
    }
    case SWITCH_ATHLETE: {
      const { entries } = draft;
      const ERAUID = action.data;
      const AllowClasses = get(draft.event, 'AllowClasses', false);
      draft.availableDisciplines = getAvailableDisciplinesForAthlete(
        AllowClasses,
        entries,
        ERAUID,
        draft.availableDisciplines,
        getEntryCount,
      );
      draft.entries = entries;
      const hasAnyPendingEntryDiscipline = draft.selectedDisciplines.find(
        (disc) => disc.isPendingEntry,
      );
      if (!hasAnyPendingEntryDiscipline) draft.selectedDisciplines = [];
      break;
    }
    case SET_ENTRIES_COUNT: {
      const { entries, selectedERAUID } = action.data;
      if (
        Object.keys(entries).length === 0 ||
        entries[selectedERAUID] === undefined
      ) {
        break;
      }

      const AllowClasses = get(draft.event, 'AllowClasses', false);

      draft.availableDisciplines = getAvailableDisciplinesForAthlete(
        AllowClasses,
        entries,
        selectedERAUID,
        draft.availableDisciplines,
        getEntryCount,
      );
      draft.entries = entries;

      break;
    }
    case SET_BUDDY_ENTRIES_COUNT: {
      const entries = draft.entries;
      const { ERAUID, buddyEntries } = action.data;
      const disciplines = getNestedProperty('disciplines', buddyEntries, []);

      entries[ERAUID] = {};
      disciplines.forEach(({ compType, entryCount }) => {
        entries[ERAUID][compType] = entryCount;
      });
      draft.entries = entries;

      break;
    }
    case TOGGLE_SELECT_DISCIPLINE: {
      const { availableDisciplines, isEditing } = draft;
      const newDisciplines = availableDisciplines.map((disc) => {
        if (disc.value === action.data.value) {
          const activeDiscipline = draft.activeDiscipline || {};
          const discipline = { ...disc, ...activeDiscipline };
          return getNewDiscipline(discipline, action.method, isEditing);
        }
        return disc;
      });
      draft.selectedDisciplines = newDisciplines.filter((d) => d.isSelected);
      draft.availableDisciplines = newDisciplines;
      break;
    }
    case ADD_ENTRY: {
      let {
        availableDisciplines,
        activeDiscipline,
        event,
        selectedDisciplines,
      } = draft;

      activeDiscipline = {
        ...activeDiscipline,
        uuid: new nanoid({ length: 6 }),
      };

      const partners = get(activeDiscipline, 'partners');
      const isPayingForPartnerActive = get(
        partners[0],
        'isPayingForPartner',
        false,
      );
      const { AllowClasses } = event;
      const { value, pendingEntry = null } = action.data;

      activeDiscipline.isPayingForPartner = isPayingForPartnerActive;

      if (AllowClasses) {
        const newDisciplines = availableDisciplines.map((disc) => {
          if (disc.value === value) {
            const activeDiscipline = draft.activeDiscipline || {};
            activeDiscipline.partners = setEntryAddedPartners(
              activeDiscipline.partners,
            );
            const { Classes } = disc;
            const newClasses = Classes.map((Class) => {
              if (
                Class.EventEntryDisciplineFeeUID ===
                activeDiscipline.EventEntryDisciplineFeeUID
              ) {
                Class.athleteEntryCount = activeDiscipline.athleteEntryCount;
                Class.newEntryCount = activeDiscipline.newEntryCount;
                Class.partners = activeDiscipline.partners;
              }
              return Class;
            });
            return {
              ...disc,
              ...activeDiscipline,
              ...pendingEntry,
              Classes: newClasses,
              uuid: new nanoid({ length: 6 }),
            };
          }
          disc.uuid = new nanoid({ length: 6 });
          return disc;
        });

        if (activeDiscipline) {
          const activeDisciplineIndex = selectedDisciplines.findIndex(
            ({ EventEntryDisciplineFeeUID }) =>
              activeDiscipline &&
              activeDiscipline.EventEntryDisciplineFeeUID ===
                EventEntryDisciplineFeeUID,
          );

          if (activeDisciplineIndex > -1) {
            draft.selectedDisciplines[activeDisciplineIndex] = activeDiscipline;
          } else {
            draft.selectedDisciplines = draft.selectedDisciplines.concat(
              activeDiscipline,
            );
          }
        }

        draft.availableDisciplines = newDisciplines;
        break;
      } else {
        const newDisciplines = availableDisciplines.map((disc) => {
          if (disc.value === value) {
            const activeDiscipline = draft.activeDiscipline || {};
            activeDiscipline.partners = setEntryAddedPartners(
              activeDiscipline.partners,
            );
            return {
              ...disc,
              ...activeDiscipline,
              ...pendingEntry,
              athleteEntryCount: activeDiscipline.athleteEntryCount,
              newEntryCount: activeDiscipline.newEntryCount,
              uuid: new nanoid({ length: 6 }),
            };
          }
          disc.uuid = new nanoid({ length: 6 });
          return disc;
        });

        if (activeDiscipline) {
          const activeDisciplineIndex = selectedDisciplines.findIndex(
            ({ value }) => activeDiscipline && activeDiscipline.value === value,
          );

          if (activeDisciplineIndex > -1) {
            draft.selectedDisciplines[activeDisciplineIndex] = activeDiscipline;
          } else {
            draft.selectedDisciplines = draft.selectedDisciplines.concat(
              activeDiscipline,
            );
          }
        }

        draft.availableDisciplines = newDisciplines;
        break;
      }
    }
    case ADD_PENDING_ENTRIES: {
      const { availableDisciplines, event } = draft;
      const { AllowClasses } = event;
      const { data: pendingEntries } = action;
      draft.selectedDisciplines = pendingEntries.map((pendingEntry) => {
        let availableDiscipline;
        if (AllowClasses) {
          const discipline = availableDisciplines.find(
            (disc) => disc.value === parseInt(pendingEntry.CompType),
          );
          if (
            discipline &&
            discipline.value === parseInt(pendingEntry.CompType)
          ) {
            const { Classes } = discipline;
            availableDiscipline =
              Classes.find(
                ({ EventEntryDisciplineFeeUID }) =>
                  parseInt(pendingEntry.EventEntryDisciplineFeeUID) ===
                  EventEntryDisciplineFeeUID,
              ) || {};
          }
        } else {
          availableDiscipline =
            availableDisciplines.find(
              (availableDiscipline) =>
                availableDiscipline.value === parseInt(pendingEntry.CompType),
            ) || {};
        }

        const label = get(availableDiscipline, 'label');
        const Fee = get(availableDiscipline, 'Fee', 0);

        const { Pref1, Pref2, Pref3, Out1, Out2 } = pendingEntry;
        const hasPreference = !!Pref1 || !!Pref2 || !!Pref3 || !!Out1 || !!Out2;

        return {
          ...pendingEntry,
          Discipline: disciplinesByName[parseInt(pendingEntry.CompType)],
          DisciplineUID: parseInt(pendingEntry.CompType),
          value: parseInt(pendingEntry.CompType),
          hasPreference,
          label,
          EventEntryDisciplineFeeUID: parseInt(
            pendingEntry.EventEntryDisciplineFeeUID,
          ),
          athleteEntryCount: 1,
          Fee,
          maxNumberOfEntries: 1,
          isSelected: true,
          newEntryCount: 1,
          isPendingEntry: true,
        };
      });

      if (AllowClasses) {
        draft.availableDisciplines = availableDisciplines.map((disc) => {
          for (let i = 0; i < pendingEntries.length; i++) {
            if (disc.value === parseInt(pendingEntries[i].CompType)) {
              const { Classes } = disc;
              const newClasses = Classes.map((Class) => {
                if (
                  Class.EventEntryDisciplineFeeUID ===
                  pendingEntries[i].EventEntryDisciplineFeeUID
                ) {
                  Class.newEntryCount = 0;
                }
                return Class;
              });

              return {
                ...disc,
                Classes: newClasses,
              };
            }
          }
          return disc;
        });
      } else {
        draft.availableDisciplines = availableDisciplines.map((disc) => {
          for (let i = 0; i < pendingEntries.length; i++) {
            if (disc.value === parseInt(pendingEntries[i].CompType)) {
              return {
                ...disc,
                newEntryCount: 0,
              };
            }
          }
          return disc;
        });
      }

      break;
    }
    case REMOVE_ENTRY: {
      const { availableDisciplines, event } = draft;
      const { AllowClasses } = event;
      if (AllowClasses) {
        draft.selectedDisciplines = draft.selectedDisciplines.filter(
          (disc) =>
            disc.EventEntryDisciplineFeeUID !==
            action.data.EventEntryDisciplineFeeUID,
        );

        const newDisciplines = availableDisciplines.map((disc) => {
          if (disc.value === action.data.value) {
            const activeDiscipline = draft.activeDiscipline || {};
            const { Classes } = disc;
            const newClasses = Classes.map((Class) => {
              if (
                Class.EventEntryDisciplineFeeUID ===
                activeDiscipline.EventEntryDisciplineFeeUID
              ) {
                Class.athleteEntryCount = activeDiscipline.athleteEntryCount;
                Class.newEntryCount = 0;
                Class.partners = {};
              }
              return Class;
            });
            return {
              ...disc,
              ...activeDiscipline,
              Classes: newClasses,
              Pref1: null,
              Pref2: null,
              Pref3: null,
              Out1: null,
              Out2: null,
              segments: [],
            };
          }

          return disc;
        });

        draft.availableDisciplines = newDisciplines;
        draft.activeDiscipline = {};
        break;
      } else {
        draft.selectedDisciplines = draft.selectedDisciplines.filter(
          (disc) => disc.value !== action.data.value,
        );

        const newDisciplines = availableDisciplines.map((disc) => {
          if (disc.value === action.data.value) {
            const activeDiscipline = draft.activeDiscipline || {};
            return {
              ...disc,
              ...activeDiscipline,
              athleteEntryCount: activeDiscipline.athleteEntryCount,
              newEntryCount: 0,
              partners: {},
              Pref1: null,
              Pref2: null,
              Pref3: null,
              Out1: null,
              Out2: null,
              segments: [],
            };
          }

          return disc;
        });

        draft.availableDisciplines = newDisciplines;
        draft.activeDiscipline = {};
        break;
      }
    }
    case REMOVE_DISC_EVENT_ENTRY: {
      const { availableDisciplines } = draft;
      draft.selectedDisciplines = draft.selectedDisciplines.filter((disc) => {
        return !(
          disc.value == action.data.value && disc.EPUID == action.data.EPUID
        );
      });

      const newDisciplines = availableDisciplines.map((disc) => {
        if (disc.value === action.data.value) {
          const activeDiscipline = draft.activeDiscipline || {};
          const discipline = {
            ...disc,
            ...activeDiscipline,
            athleteEntryCount: disc.athleteEntryCount - 1,
          };
          return discipline;
        }
        return disc;
      });

      draft.availableDisciplines = newDisciplines;
      break;
    }
    case UPDATE_ENTRY: {
      const { availableDisciplines, event } = draft;
      const { AllowClasses } = event;

      if (AllowClasses) {
        const newSelectedDisciplines = draft.selectedDisciplines.map((disc) => {
          if (
            disc.EventEntryDisciplineFeeUID ===
            action.data.EventEntryDisciplineFeeUID
          ) {
            return get(draft, 'activeDiscipline');
          } else {
            return disc;
          }
        });

        const newDisciplines = availableDisciplines.map((disc) => {
          if (disc.value === action.data.value) {
            const activeDiscipline = draft.activeDiscipline || {};
            const partners = get(activeDiscipline, 'partners');
            const isPayingForPartnerActive = get(
              partners[0],
              'isPayingForPartner',
              false,
            );

            activeDiscipline.isPayingForPartner = isPayingForPartnerActive;
            activeDiscipline.partners = setEntryAddedPartners(
              activeDiscipline.partners,
            );
            const { Classes } = disc;
            const newClasses = Classes.map((Class) => {
              if (
                Class.EventEntryDisciplineFeeUID ===
                activeDiscipline.EventEntryDisciplineFeeUID
              ) {
                Class.athleteEntryCount = activeDiscipline.athleteEntryCount;
                Class.newEntryCount = activeDiscipline.newEntryCount;
                Class.partners = activeDiscipline.partners;
              }
              return Class;
            });
            return {
              ...disc,
              ...activeDiscipline,
              Classes: newClasses,
            };
          }

          return disc;
        });

        newSelectedDisciplines.forEach((discipline) => {
          discipline.partners = setEntryAddedPartners(
            updateSamePartners(discipline.partners),
          );
        });
        draft.selectedDisciplines = newSelectedDisciplines;
        draft.availableDisciplines = newDisciplines;
        break;
      } else {
        draft.selectedDisciplines = draft.selectedDisciplines.filter((disc) => {
          if (disc.value === action.data.value) {
            return get(draft, 'activeDiscipline');
          } else {
            return disc;
          }
        });

        const activeDiscipline = draft.activeDiscipline || {};
        activeDiscipline.partners = setEntryAddedPartners(
          activeDiscipline.partners,
        );

        const newDisciplines = availableDisciplines.map((disc) => {
          if (disc.value === action.data.value) {
            return {
              ...disc,
              ...activeDiscipline,
              athleteEntryCount: activeDiscipline.athleteEntryCount,
              newEntryCount: activeDiscipline.newEntryCount,
              partners: activeDiscipline.partners,
            };
          }

          return disc;
        });

        draft.availableDisciplines = newDisciplines;

        const indexOfDisciplineToUpdate = draft.selectedDisciplines.findIndex(
          (o) => o.uuid === action.data.uuid,
        );

        // $FlowFixMe
        draft.selectedDisciplines[indexOfDisciplineToUpdate] = activeDiscipline;
        break;
      }
    }
    case TOGGLE_PREFERENCE: {
      const { selectedDisciplines } = draft;
      const { disciplineId, ClassName } = action.data;
      const newSelectedDisciplines = selectedDisciplines.map((disc) => {
        if (disc.value === action.data) {
          disc.hasPreference = !disc.hasPreference;
        }
        return disc;
      });
      draft.selectedDisciplines = newSelectedDisciplines;
      if (
        draft.activeDiscipline &&
        draft.activeDiscipline.value === disciplineId &&
        draft.activeDiscipline.ClassName === ClassName
      ) {
        const hasPreference = !draft.activeDiscipline.hasPreference;
        draft.activeDiscipline.hasPreference = hasPreference;
      }
      break;
    }
    case SET_PREFERENCE: {
      const { selectedDisciplines } = draft;
      draft.selectedDisciplines = selectedDisciplines.map((discipline) =>
        setDisciplinePreference(discipline, action.data),
      );

      if (
        draft.activeDiscipline &&
        String(draft.activeDiscipline.value) ===
          String(action.data.disciplineId) &&
        String(draft.activeDiscipline.ClassName) ===
          String(action.data.ClassName)
      ) {
        draft.activeDiscipline = setDisciplinePreference(
          draft.activeDiscipline,
          action.data,
        );
      }
      break;
    }
    case TOGGLE_OUT_IF_PREFERENCE_NOT_MET: {
      const { selectedDisciplines } = draft;
      const disciplineId = action.data;
      draft.selectedDisciplines = selectedDisciplines.map((discipline) =>
        toggleDiscOutIfPrefNotMet(discipline, action.data),
      );
      if (
        draft.activeDiscipline &&
        draft.activeDiscipline.value === disciplineId
      ) {
        const OutIfPrefNotMet = !draft.activeDiscipline.OutIfPrefNotMet;
        draft.activeDiscipline.OutIfPrefNotMet = OutIfPrefNotMet;
      }
      break;
    }
    case ACTIVE_DISCIPLINE_SELECT: {
      let discipline = getNewDiscipline(action.data, 'ADD', draft.isEditing);
      const { newEntryCount } = discipline;
      if (draft.selectedDisciplines.length) {
        const currentDiscipline = draft.selectedDisciplines.find(
          (selectedDiscipline) =>
            selectedDiscipline.value === discipline.DisciplineUID &&
            selectedDiscipline.ClassName === discipline.ClassName,
        );
        if (currentDiscipline) {
          currentDiscipline.segments = discipline.segments;
          discipline = {
            ...currentDiscipline,
            DisciplineFeeName: action.data.DisciplineFeeName,
            DisciplineFee: action.data.DisciplineFee,
          };
        }
      }
      discipline.newEntryCount = newEntryCount === 0 ? 1 : newEntryCount;
      draft.activeDiscipline = discipline;
      break;
    }
    case UPDATE_ACTIVE_DISCIPLINE: {
      const newEntryCount = action.data;
      let partners = get(draft.activeDiscipline, 'partners', {});
      partners = setEntryAddedPartners(partners);
      if (draft.activeDiscipline) {
        draft.activeDiscipline.newEntryCount = newEntryCount;
        [...Array(newEntryCount).keys()].forEach((key) => {
          if (partners[key] === undefined) partners[key] = {};
        });
        Object.keys(partners).forEach((key) => {
          key >= newEntryCount && delete partners[key];
        });
        // $FlowFixMe
        draft.activeDiscipline.partners = partners;
      }
      break;
    }
    case ACTIVE_DISCIPLINE_DESELECT: {
      draft.activeDiscipline = null;
      draft.partnerErrorMessage = '';
      break;
    }
    case TOGGLE_SEGMENT: {
      if (
        draft.activeDiscipline &&
        draft.activeDiscipline.value === action.data
      ) {
        const { hasSegments } = draft.activeDiscipline;
        draft.activeDiscipline.hasSegments = !hasSegments;
      }
      break;
    }
    case TOGGLE_SELECT_SEGMENT: {
      const { disciplineId, segment } = action.data;
      if (
        draft.activeDiscipline &&
        draft.activeDiscipline.value === disciplineId
      ) {
        const { segments } = draft.activeDiscipline;
        const listWithoutTargetSegment = segments.filter(
          (s) => s.value !== segment.value,
        );
        const hasSegment = listWithoutTargetSegment.length !== segments.length;
        if (hasSegment) {
          // $FlowFixMe
          draft.activeDiscipline.segments = listWithoutTargetSegment;
        } else {
          // $FlowFixMe
          draft.activeDiscipline.segments.push(segment);
        }
      }
      break;
    }

    case SET_COUNT_ACTIVE_SEGMENT: {
      const { disciplineId, segmentValue, newSegmentCount } = action.data;
      if (
        draft.activeDiscipline &&
        draft.activeDiscipline.value === disciplineId
      ) {
        const segments = [...draft.activeDiscipline.segments];
        if (segments) {
          const segmentIndex =
            segments.length &&
            segments.findIndex(({ value }) => value === segmentValue);

          if (segmentIndex > -1) {
            segments[segmentIndex].newSegmentCount = newSegmentCount;
            draft.activeDiscipline.segments = segments;
          }
        }
      }
      break;
    }
    case SET_PARTNER_ATHLETE: {
      const { disciplineId, partner, index } = action.data;
      const { availableDisciplines, event, activeDiscipline } = draft;
      const partners = get(activeDiscipline, 'partners', {});
      const selectedClassName = get(activeDiscipline, 'ClassName', null);
      const { AllowClasses } = event;
      let newPartner = { ...partner };
      if (AllowClasses && Object.getOwnPropertyNames(newPartner).length) {
        const partnerDisciplineId = getPartnerDisciplineId(disciplineId);

        const discipline = availableDisciplines.find(
          (disc) => disc.value === partnerDisciplineId,
        );
        const Classes = get(discipline, 'Classes', []);
        const currentClass = Classes.find(
          (Class) => Class.ClassName === selectedClassName,
        );

        let EventEntryDisciplineFeeUID = null;
        if (currentClass) {
          EventEntryDisciplineFeeUID = currentClass.EventEntryDisciplineFeeUID;
        }
        newPartner = { ...newPartner, EventEntryDisciplineFeeUID };
      }
      if (get(draft, 'activeDiscipline.value') === disciplineId) {
        partners[index] = newPartner;
        // $FlowFixMe
        draft.activeDiscipline.partners = updateSamePartners(partners);
        draft.partnerErrorMessage = '';
      }
      break;
    }
    case SET_PAY_FOR_PARTNER: {
      const { disciplineId, isPayingForPartner, index } = action.data;
      const { activeDiscipline } = draft;
      const partners = get(activeDiscipline, 'partners', {});

      if (
        getNestedProperty('activeDiscipline.value', draft) === disciplineId &&
        Object.getOwnPropertyNames(partners).length &&
        index !== undefined
      ) {
        partners[index].isPayingForPartner = isPayingForPartner;
        // $FlowFixMe
        draft.activeDiscipline.partners = updateSamePartners(partners);
      }
      break;
    }
    case INVITE_PARTNER_ATHLETE: {
      const { disciplineId, partnerName } = action.data;
      draft.selectedDisciplines = draft.selectedDisciplines.map(
        (discipline: DisciplineType) => {
          const partner = current(getNestedProperty('partner', discipline));
          if (
            partner &&
            partner.id === partnerName &&
            discipline.value === disciplineId
          ) {
            // $FlowFixMe
            discipline.partner.invited = true;
          }
          return discipline;
        },
      );
      break;
    }
    case TOGGLE_IS_EDITING: {
      draft.isEditing = action.data;
      break;
    }
    case TOGGLE_CONFIRM_PARTNER_REMOVAL_MODAL: {
      draft.showConfirmPartnerRemoveModal = action.data;
      break;
    }
    case SET_HORSE_NAME: {
      if (!draft.activeDiscipline) {
        break;
      }

      const { value, newEntryCount, index, entryCountChanged } = action.data;
      let currentHorses = [...Array.from({ length: newEntryCount }, () => '')];

      if (
        draft.activeDiscipline &&
        draft.activeDiscipline.horseNames &&
        draft.activeDiscipline.horseNames.length
      ) {
        draft.activeDiscipline.horseNames.forEach((horseName, index) => {
          if (newEntryCount < index + 1) return;
          currentHorses[index] = horseName;
        });
      }

      currentHorses[index] = entryCountChanged ? currentHorses[index] : value;

      if (draft.activeDiscipline)
        draft.activeDiscipline.horseNames = currentHorses;
      break;
    }
    case CLEAR_SELECTED_DISCIPLINES: {
      draft.selectedDisciplines = [];
      draft.availableDisciplines.forEach((disc) => {
        disc.isSelected = false;
      });
      break;
    }
    default:
      break;
  }
};

export default () =>
  useImmerReducer<UseDisciplinesState>(disciplinesReducer, initialState);
