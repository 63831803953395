// @flow
import React from 'react';
import moment from 'moment';

type BuddyEntryDateType = {|
  entryDate?: string,
  className?: string,
|};

const BuddyEntryDate = ({
  entryDate = undefined,
  className = '',
}: BuddyEntryDateType) => {
  if (!moment(entryDate).isValid()) {
    return null;
  }

  return (
    <div className={`${className} buddy-entry-date`}>
      {moment(entryDate).format('MM/DD/YYYY [AT] LT zz')}
    </div>
  );
};

export default BuddyEntryDate;
