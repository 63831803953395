export const ROUGH_STOCK_TYPE = 'ROUGH_STOCK_TYPE';
export const TIMED_DISCIPLINE_TYPE = 'TIMED_DISCIPLINE_TYPE';
export const BARREL_RACING_TYPE = 'BARREL_RACING_TYPE';

const ALABAMA = 8;
const ALASKA = 9;
const ARIZONA = 10;
const ARKANSAS = 11;
const CALIFORNIA = 13;
const COLORADO = 12;
const CONNECTICUT = 14;
const DELAWARE = 15;
const FLORIDA = 149;
const GEORGIA = 165;
const HAWAII = 166;
const IDAHO = 167;
const ILLINOIS = 168;
const INDIANA = 169;
const IOWA = 170;
const KANSAS = 171;
const KENTUCKY = 172;
const LOUISIANA = 173;
const MAINE = 174;
const MARYLAND = 175;
const MASSACHUSETTS = 182;
const MICHIGAN = 183;
const MINNESOTA = 184;
const MISSISSIPPI = 185;
const MISSOURI = 181;
const MONTANA = 186;
const NEBRASKA = 187;
const NEVADA = 188;
const NEWHAMPSHIRE = 189;
const NEWJERSEY = 190;
const NEWMEXICO = 191;
const NEWYORK = 192;
const NORTHCAROLINA = 194;
const NORTHDAKOTA = 195;
const OHIO = 196;
const OKLAHOMA = 197;
const OREGON = 198;
const PENNSYLVANIA = 199;
const RHODEISLAND = 201;
const SOUTHCAROLINA = 202;
const SOUTHDAKOTA = 203;
const TENNESSEE = 204;
const TEXAS = 205;
const UTAH = 206;
const VERMONT = 207;
const VIRGINIA = 208;
const WASHINGTON = 209;
const WESTVIRGINA = 210;
const WISCONSIN = 211;
const WYOMING = 212;

export const statesById = {
  ALABAMA,
  ALASKA,
  ARIZONA,
  ARKANSAS,
  CALIFORNIA,
  COLORADO,
  CONNECTICUT,
  DELAWARE,
  FLORIDA,
  GEORGIA,
  HAWAII,
  IDAHO,
  ILLINOIS,
  INDIANA,
  IOWA,
  KANSAS,
  KENTUCKY,
  LOUISIANA,
  MAINE,
  MARYLAND,
  MASSACHUSETTS,
  MICHIGAN,
  MINNESOTA,
  MISSISSIPPI,
  MISSOURI,
  MONTANA,
  NEBRASKA,
  NEVADA,
  NEWHAMPSHIRE,
  NEWJERSEY,
  NEWMEXICO,
  NEWYORK,
  NORTHCAROLINA,
  NORTHDAKOTA,
  OHIO,
  OKLAHOMA,
  OREGON,
  PENNSYLVANIA,
  RHODEISLAND,
  SOUTHCAROLINA,
  SOUTHDAKOTA,
  TENNESSEE,
  TEXAS,
  UTAH,
  VERMONT,
  VIRGINIA,
  WASHINGTON,
  WESTVIRGINA,
  WISCONSIN,
  WYOMING,
};

export const statesByName = {
  [ALABAMA]: 'Alabama',
  [ALASKA]: 'Alaska',
  [ARIZONA]: 'Arizona',
  [ARKANSAS]: 'Arkansas',
  [CALIFORNIA]: 'California',
  [COLORADO]: 'Colorado',
  [CONNECTICUT]: 'Connecticut',
  [DELAWARE]: 'Delaware',
  [FLORIDA]: 'Florida',
  [GEORGIA]: 'Georgia',
  [HAWAII]: 'Hawaii',
  [IDAHO]: 'Idaho',
  [ILLINOIS]: 'Illinois',
  [INDIANA]: 'Indiana',
  [IOWA]: 'Iowa',
  [KANSAS]: 'Kansas',
  [KENTUCKY]: 'Kentucky',
  [LOUISIANA]: 'Louisiana',
  [MAINE]: 'Maine',
  [MARYLAND]: 'Maryland',
  [MASSACHUSETTS]: 'Massachusetts',
  [MICHIGAN]: 'Michigan',
  [MINNESOTA]: 'Minnesota',
  [MISSISSIPPI]: 'Mississippi',
  [MISSOURI]: 'Missouri',
  [MONTANA]: 'Montana',
  [NEBRASKA]: 'Nebraska',
  [NEVADA]: 'Nevada',
  [NEWHAMPSHIRE]: 'New Hampshire',
  [NEWJERSEY]: 'New Jersey',
  [NEWMEXICO]: 'New Mexico',
  [NEWYORK]: 'New York',
  [NORTHCAROLINA]: 'North Calorina',
  [NORTHDAKOTA]: 'North Dakota',
  [OHIO]: 'Ohio',
  [OKLAHOMA]: 'Oklahoma',
  [OREGON]: 'Oregon',
  [PENNSYLVANIA]: 'Pennsylvania',
  [RHODEISLAND]: 'Rhode Island',
  [SOUTHCAROLINA]: 'South Carolina',
  [SOUTHDAKOTA]: 'South Dakota',
  [TENNESSEE]: 'Tennessee',
  [TEXAS]: 'Texas',
  [UTAH]: 'Utah',
  [VERMONT]: 'Vermont',
  [VIRGINIA]: 'Virginia',
  [WASHINGTON]: 'Washington',
  [WESTVIRGINA]: 'West Virginia',
  [WISCONSIN]: 'Wisconsin',
  [WYOMING]: 'Wyoming',
};

export const disciplinesByAbbreviation = {
  [ALABAMA]: 'AL',
  [ALASKA]: 'AK',
  [ARIZONA]: 'AZ',
  [ARKANSAS]: 'AR',
  [CALIFORNIA]: 'CA',
  [COLORADO]: 'CO',
  [CONNECTICUT]: 'CT',
  [DELAWARE]: 'DE',
  [FLORIDA]: 'FL',
  [GEORGIA]: 'GA',
  [HAWAII]: 'HI',
  [IDAHO]: 'ID',
  [ILLINOIS]: 'IL',
  [INDIANA]: 'IN',
  [IOWA]: 'IA',
  [KANSAS]: 'KS',
  [KENTUCKY]: 'KY',
  [LOUISIANA]: 'LA',
  [MAINE]: 'ME',
  [MARYLAND]: 'MD',
  [MASSACHUSETTS]: 'MA',
  [MICHIGAN]: 'MI',
  [MINNESOTA]: 'MN',
  [MISSISSIPPI]: 'MS',
  [MISSOURI]: 'MO',
  [MONTANA]: 'MT',
  [NEBRASKA]: 'NE',
  [NEVADA]: 'NV',
  [NEWHAMPSHIRE]: 'NH',
  [NEWJERSEY]: 'NJ',
  [NEWMEXICO]: 'NM',
  [NEWYORK]: 'NY',
  [NORTHCAROLINA]: 'NC',
  [NORTHDAKOTA]: 'ND',
  [OHIO]: 'OH',
  [OKLAHOMA]: 'OK',
  [OREGON]: 'OR',
  [PENNSYLVANIA]: 'PA',
  [RHODEISLAND]: 'RI',
  [SOUTHCAROLINA]: 'SC',
  [SOUTHDAKOTA]: 'SD',
  [TENNESSEE]: 'TN',
  [TEXAS]: 'TX',
  [UTAH]: 'UT',
  [VERMONT]: 'VT',
  [VIRGINIA]: 'VA',
  [WASHINGTON]: 'WA',
  [WESTVIRGINA]: 'WV',
  [WISCONSIN]: 'WI',
  [WYOMING]: 'WY',
};

export const defaultStateId = ALABAMA;

export const getStateOptions = () => {
  return Object.keys(statesByName).map((stateId) => {
    return {
      value: stateId,
      label: statesByName[stateId],
    };
  });
};

export const getStateAbbreviationOptions = [
  { value: 'AL', label: 'AL' },
  { value: 'AK', label: 'AK' },
  { value: 'AZ', label: 'AZ' },
  { value: 'AR', label: 'AR' },
  { value: 'CA', label: 'CA' },
  { value: 'CO', label: 'CO' },
  { value: 'CT', label: 'CT' },
  { value: 'DE', label: 'DE' },
  { value: 'FL', label: 'FL' },
  { value: 'GA', label: 'GA' },
  { value: 'HI', label: 'HI' },
  { value: 'ID', label: 'ID' },
  { value: 'IL', label: 'IL' },
  { value: 'IN', label: 'IN' },
  { value: 'IA', label: 'IA' },
  { value: 'KS', label: 'KS' },
  { value: 'KY', label: 'KY' },
  { value: 'LA', label: 'LA' },
  { value: 'ME', label: 'ME' },
  { value: 'MD', label: 'MD' },
  { value: 'MA', label: 'MA' },
  { value: 'MI', label: 'MI' },
  { value: 'MN', label: 'MN' },
  { value: 'MS', label: 'MS' },
  { value: 'MO', label: 'MO' },
  { value: 'MT', label: 'MT' },
  { value: 'NE', label: 'NE' },
  { value: 'NV', label: 'NV' },
  { value: 'NH', label: 'NH' },
  { value: 'NJ', label: 'NJ' },
  { value: 'NM', label: 'NM' },
  { value: 'NY', label: 'NY' },
  { value: 'NC', label: 'NC' },
  { value: 'ND', label: 'ND' },
  { value: 'OH', label: 'OH' },
  { value: 'OK', label: 'OK' },
  { value: 'OR', label: 'OR' },
  { value: 'PA', label: 'PA' },
  { value: 'RI', label: 'RI' },
  { value: 'SC', label: 'SC' },
  { value: 'SD', label: 'SD' },
  { value: 'TN', label: 'TN' },
  { value: 'TX', label: 'TX' },
  { value: 'UT', label: 'UT' },
  { value: 'VT', label: 'VT' },
  { value: 'VA', label: 'VA' },
  { value: 'WA', label: 'WA' },
  { value: 'WV', label: 'WV' },
  { value: 'WI', label: 'WI' },
  { value: 'WY', label: 'WY' },
];
