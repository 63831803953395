// @flow
import React from 'react';
import { getPartnerAthleteInfo } from 'helpers';
import type { PartnerAthleteType } from 'models/PartnerAthlete';

type Option = {
  id: string | number,
  fullName: string,
  fullNameWithNickname: string,
  city?: string,
  state?: string,
  invited?: boolean,
};

const PartnerOption = (option: Option) => {
  // Only for create new option
  if (option.id === option.fullNameWithNickname) {
    return option.fullNameWithNickname;
  }
  // For options returned from database
  const { fullNameWithNickname, location } = getPartnerAthleteInfo(option);
  const renderFullName = fullNameWithNickname ? (
    <div className={'partner-option-full-name'}>{fullNameWithNickname}</div>
  ) : null;
  const renderLocation = location ? (
    <div className={'partner-option-location'}>{location}</div>
  ) : null;

  return (
    <div
      id={`partner-athlete-option-${option.id}`}
      className="partner-athlete-option"
    >
      {renderFullName}
      {renderLocation}
    </div>
  );
};

export const partnersToOptions = (
  partners: Array<PartnerAthleteType>,
  userId: string,
): Object => ({
  // Filter out user from list of possible partner options
  options: partners.filter((partner) => partner.id !== userId),
});

export default PartnerOption;
