// @flow
import React, { useState, useContext, useRef, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { get } from 'lodash';
import client from 'api/apollo';
import { isEmpty } from 'lodash';
import { Check, Lock } from 'react-feather';
// $FlowIgnore
import { AsyncCreatable } from 'react-select';
import { getAssociationMembership } from 'selectors/associationMembership';
import { DisciplineContext } from 'context/SelectedDisciplines';
import { AthletesContext } from 'context/SelectedAthletes';
import { teamDisciplines } from 'constants/disciplines';
import type { ContextValue as DisciplineContextType } from 'context/SelectedDisciplines/type';
import type { ContextValue as AthleteContextValue } from 'context/SelectedAthletes';
import type { EventType } from 'models/Event';
import { ATHLETE_SEARCH } from 'queries/AthleteSearch';
import { EVENT_GET_ENTRY_COUNTS } from 'queries/EventGetEntryCounts';
import ErrorMessage from 'components/ErrorMessage';
import { asyncDebounce, isYouthEvent, capitalizeString } from 'helpers';
import PartnerOption, { partnersToOptions } from './PartnerOption';
import { getPartnerDisciplineId } from 'constants/disciplines';
import SamePartnerAbove from './SamePartnerAbove';
import { getNestedProperty, getCollectMembershipAssociations } from 'helpers';
import { hasFeatureFlag } from 'utils/flagsmith';

type Props = {|
  disciplineId: string,
  event: EventType,
  setUpdateButtonDisabled: (boolean) => void,
  getBuddyGroupTransactions: (string, string) => Promise<Object>,
  isEditing: boolean,
  isReading: boolean,
  partnerNumber: number,
  index: number,
  requirePartnerSelection: boolean,
|};

const MIN_CHAR_INPUT = 3;
const DEBOUNCE_WAIT_MS = 1500;

const Partner = (props: Props) => {
  const {
    disciplineId,
    event,
    setUpdateButtonDisabled,
    getBuddyGroupTransactions,
    isEditing,
    isReading,
    partnerNumber,
    index,
    requirePartnerSelection,
  } = props;

  const disciplineContext: DisciplineContextType = useContext<DisciplineContextType>(
    DisciplineContext,
  );

  const {
    activeDiscipline,
    setPartnerAthlete,
    setPayForPartner,
    selectedDisciplines,
    partnerErrorMessage,
  } = disciplineContext;

  const { AllowClasses } = event;

  const hasPartnerError = !!partnerErrorMessage[index];
  const maxNumberOfEntries = get(activeDiscipline, 'maxNumberOfEntries');
  const athleteContext: AthleteContextValue = useContext<AthleteContextValue>(
    AthletesContext,
  );
  let partners = get(activeDiscipline, 'partners', {});
  if (activeDiscipline && activeDiscipline.isPendingEntry) {
    partners = [activeDiscipline.partner];
  }

  const PartnerMultipleEntries = get(
    activeDiscipline,
    'PartnerMultipleEntries',
    null,
  );

  const lockRenderer = () => {
    return <Lock className={'partner-lock-icon'} />;
  };

  const showPayForPartner = hasFeatureFlag('show_pay_for_partner');

  const Line = <hr className={`${hasPartnerError ? 'error' : ''}`} />;

  const userId = athleteContext.ERAUID;
  const selectRef = useRef('');
  const [errorMessage, setErrorMessage] = useState('');
  const [selectedOption, setSelectedOption] = useState(partners[index] || null);
  const [selectedAssociations, setSelectedAssociations] = useState([]);
  const [loading, setLoading] = useState(false);
  const [newAthlete, setNewAthlete] = useState(false);
  const [sameAsAbove, setSameAsAbove] = useState(
    (index >= 1 && !!get(partners[index - 1], 'id')) || false,
  );

  const { selectedMemberAssociationUIDs } = useSelector(
    getAssociationMembership,
  );

  const selectedPartners = Object.values(partners);

  const partnerValue = () => {
    let partner = isEditing ? get(partners, `[${index}]`, {}) : selectedOption;

    return get(partner, 'id', null) === null ? null : partner;
  };

  useEffect(() => {
    !PartnerMultipleEntries && setSameAsAbove(false);
    if (!partnerValue() && requirePartnerSelection && !sameAsAbove) {
      setUpdateButtonDisabled(true);
    }

    const collectMembershipAssociations = getCollectMembershipAssociations(
      event.PrimaryAssociationUID,
      event.Associations,
    );
    const athleteSelectedAssociations = collectMembershipAssociations.filter(
      (a) => selectedMemberAssociationUIDs.includes(a.AssociationUID),
    );

    setSelectedAssociations(athleteSelectedAssociations);
  }, []);

  useEffect(() => {
    setSelectedOption(partners[index] || null);
  }, [partners[index], sameAsAbove]);

  const setError = (message) => {
    setUpdateButtonDisabled(Boolean(message));
    setErrorMessage(message);
  };

  const samePartnerSelectedAbove = (selectedOption) => {
    return selectedPartners.some(
      (partner) => get(partner, 'id') === selectedOption.id,
    );
  };

  const handleOnChange = async (selectedOption) => {
    selectAthlete(selectedOption);
    setNewAthlete(false);

    const data = await getBuddyGroupTransactions(
      getNestedProperty('id', event),
      userId,
    );
    data.map((transaction) => {
      transaction.entries.map((entry) => {
        if (selectedOption && !isNaN(get(selectedOption, 'id'))) {
          if (
            PartnerMultipleEntries === false &&
            entry.partnerFullName === selectedOption.fullName
          ) {
            return setError(
              'You cannot select the same partner on multiple transactions',
            );
          }
        }
      });
    });
    if (selectedOption && !isNaN(get(selectedOption, 'id'))) {
      if (!PartnerMultipleEntries && samePartnerSelectedAbove(selectedOption)) {
        setError('You cannot select the same partner.');
      }
      const partnerDisciplineId = getPartnerDisciplineId(disciplineId);
      const numberOfEntries = await getEntryCounts(
        selectedOption.id,
        partnerDisciplineId,
      );
      if (numberOfEntries >= maxNumberOfEntries) {
        setError(
          `${capitalizeString(
            selectedOption.fullName,
          )} has reached the max amount of entries for this event.`,
        );
      }
    }
  };

  /**
   * Calculate the current number of entries for an event given the user and
   * discipline.
   *
   * @param {string} ERAUID partner ERAUID
   * @param {string} disciplineId partner discipline id
   *
   * @returns {number} total entries pending and that already exist
   */
  const getEntryCounts = async (ERAUID, disciplineId) => {
    const payload = {
      query: EVENT_GET_ENTRY_COUNTS,
      variables: { id: event.id, ERAUID },
      fetchPolicy: 'network-only',
    };
    try {
      const entryCountResponse = await client.query(payload);
      const entries = entryCountResponse.data.eventGet.entryCounts.find(
        (entry) => entry.ERAUID === ERAUID,
      );
      const disciplineEntry = get(entries, 'disciplines', []).find(
        (discipline) => discipline.compType == disciplineId,
      );

      const currentNumberOfEntries = get(disciplineEntry, 'entryCount', 0);
      const partnerDisciplineId = getPartnerDisciplineId(disciplineId);
      const pendingNumberOfEntries = selectedDisciplines.filter(
        (discipline) =>
          get(discipline, 'partner.id') == ERAUID &&
          get(discipline, 'value') == partnerDisciplineId,
      ).length;
      const numberOfEntries = currentNumberOfEntries + pendingNumberOfEntries;
      return numberOfEntries;
    } catch (error) {
      console.error(error);
      setError('Problem searching for athletes.');
      return 0;
    }
  };

  const handleNewOptionClick = (selectedNewOption) => {
    // Closes menu on new option click as it does not happen by default
    // $FlowIgnore
    selectRef.current.select.selectValue(selectedNewOption);
    selectAthlete(selectedNewOption);
    setNewAthlete(true);
  };

  const selectAthlete = (selectedOption) => {
    setPartnerAthlete(disciplineId, selectedOption, index);
    !!selectedOption && setPayForPartner(disciplineId, false, index);
    setSelectedOption(selectedOption);
    setError('');
  };

  const loadOptions = (inputValue) => {
    if (inputValue.length < MIN_CHAR_INPUT) {
      return Promise.resolve({ options: [] });
    }
    setLoading(true);

    const eventType = isYouthEvent(event.EventRank) ? 'YOUTH' : 'OPEN';
    const associations = selectedAssociations
      .reduce((acc, association) => {
        acc.push(association.AssociationUID);
        return acc;
      }, [])
      .toString();

    const athletePayload = {
      query: ATHLETE_SEARCH,
      variables: { name: inputValue, eventType, associations },
      fetchPolicy: 'network-only',
    };
    return client
      .query(athletePayload)
      .then((response) => {
        const options = partnersToOptions(
          response.data.searchTeamPartner,
          userId,
        );
        return options;
      })
      .catch((error) => {
        console.error(error);
        setError('Problem searching for athletes.');

        return [];
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const isValidNewOption = (option) => {
    const memberIdMatches =
      !!option.label && /[KP]\d+/gm.test(option.label.toUpperCase());
    return (
      !loading &&
      !isEmpty(option.label) &&
      isNaN(parseInt(option.label)) &&
      !memberIdMatches
    );
  };

  const isOptionUnique = ({ option, options, labelKey, valueKey }) => {
    if (!options || !options.length) {
      return true;
    }

    return (
      options.filter(
        (existingOption) =>
          existingOption[labelKey] === option[labelKey] &&
          existingOption[valueKey] === option[valueKey],
      ).length === 0
    );
  };

  const promptTextCreator = (filterString) => {
    return `Create "${filterString}"`;
  };

  const getPayForPartnerCheckboxSetup = () => {
    const partner = get(activeDiscipline, `partners[${index}]`, {});
    let className = 'input-checkbox';
    const isPayingForPartner = get(
      activeDiscipline,
      `partners[${index}].isPayingForPartner`,
      false,
    );
    className = className.concat(isPayingForPartner ? ' checked' : '');
    className = className.concat(!partner || isReading ? ' disabled' : '');
    return className;
  };

  const onClickCheckbox = () => {
    if (isReading) {
      return;
    }

    const partner = get(activeDiscipline, `partners[${index}]`, {});
    const isPayingForPartner = get(
      activeDiscipline,
      `partners[${index}].isPayingForPartner`,
      false,
    );
    Boolean(get(partner, 'id'))
      ? setPayForPartner(disciplineId, !isPayingForPartner, index)
      : '';
  };

  // Removes default filtering from AsyncCreatable
  const filterOption = (option) => option;

  const onChangeSamePartnerToggle = (value) => {
    setSameAsAbove(value);
    setSelectedOption(partners[index]);
  };

  const getIsEditing = () => {
    if (isEditing) return true;

    if (selectedDisciplines.length > 0) {
      return AllowClasses
        ? selectedDisciplines.some((disc) => {
            const EventEntryDisciplineFeeUID = get(
              activeDiscipline,
              'EventEntryDisciplineFeeUID',
            );
            return (
              disc.EventEntryDisciplineFeeUID === EventEntryDisciplineFeeUID
            );
          })
        : selectedDisciplines.some((disc) => {
            const DisciplineUID = get(activeDiscipline, 'value');
            return disc.value === DisciplineUID;
          });
    } else {
      return false;
    }
  };

  const renderNewAthleteLabel = newAthlete ? (
    <label htmlFor="AsyncCreatable" className="new-partner-explanation">
      {
        "Your partner doesn't have a RET/WCRA account. Don't worry, we will add them as your partner."
      }
    </label>
  ) : null;

  if (!teamDisciplines.includes(disciplineId)) return null;

  return (
    <>
      <div className="partner-async-container marbot-2 padtop-1">
        <div className="partner-number">{`Partner #${partnerNumber}`}</div>
        {PartnerMultipleEntries && index > 0 ? (
          <SamePartnerAbove
            index={index}
            disciplineId={disciplineId}
            onChangeSamePartnerToggle={onChangeSamePartnerToggle}
            selectedOption={selectedOption}
            isEditing={getIsEditing()}
          />
        ) : null}
        {!sameAsAbove ? (
          <>
            {Line}
            <AsyncCreatable
              autoload={false}
              autosize={false}
              arrowRenderer={isReading ? lockRenderer : undefined}
              disabled={isReading}
              isOptionUnique={isOptionUnique}
              isValidNewOption={isValidNewOption}
              labelKey={'fullNameWithNickname'}
              loadOptions={asyncDebounce(loadOptions, DEBOUNCE_WAIT_MS)}
              onChange={handleOnChange}
              onNewOptionClick={handleNewOptionClick}
              optionRenderer={PartnerOption}
              placeholder="SEARCH BY NAME OR MEMBERSHIP"
              promptTextCreator={promptTextCreator}
              ref={selectRef}
              value={partnerValue()}
              valueKey={'id'}
              filterOption={filterOption}
            />
            {hasPartnerError ? (
              <span className="validation-partner-message">
                {partnerErrorMessage[index]}
              </span>
            ) : null}
            {Line}
            {renderNewAthleteLabel}
            <ErrorMessage errorMessage={errorMessage} />

            {showPayForPartner && !event.AllowsPayLater && (
              <div className="form-checkbox-wrapper">
                <span
                  className={getPayForPartnerCheckboxSetup()}
                  onClick={onClickCheckbox}
                >
                  <Check className="checkmark" />
                </span>
                <label
                  htmlFor="PayPartnerFees"
                  className="partner-fees-description"
                >
                  Pay partner{"'"}s entry fees
                </label>
              </div>
            )}
          </>
        ) : null}
      </div>
    </>
  );
};

export default Partner;
