// @flow
import React, { type ComponentType } from 'react';
import { Plus } from 'react-feather';
import { getEntryLimitText } from 'helpers';
import { SectionSubtitle } from 'components/Section';

export type ListBoxOption = {
  label: string,
  value: string,
  [string]: any,
} & any;

export type ListBoxProps = {|
  list: Array<ListBoxOption>,
  onClick: ?(ListBoxOption) => any,
  subtitle: ?string,
|};

const ListBox: ComponentType<$ReadOnly<any>> = (props: ListBoxProps) => {
  const { onClick, list, subtitle } = props;
  return (
    <>
      <div className="section-enter-landing-view-subtitle martop-1">
        Discipline(s)
      </div>
      {subtitle && subtitle.length > 0 && (
        <SectionSubtitle>{subtitle}</SectionSubtitle>
      )}
      {list.map((item) => {
        const { maxNumberOfEntries, athleteEntryCount, newEntryCount } = item;
        const entriesRemaining =
          maxNumberOfEntries - (athleteEntryCount + newEntryCount);

        return (
          <div
            className="selected-discipline list-item"
            key={item.value}
            onClick={
              entriesRemaining > 0 ? () => onClick && onClick(item) : null
            }
          >
            <div className="discipline-detail">
              <div className="discipline-title">
                {item.label} {`($${''}${item.Fee})`}
              </div>
              <div className="discipline-entry-limit" data-testid={item.label}>
                {getEntryLimitText(
                  maxNumberOfEntries,
                  athleteEntryCount + newEntryCount,
                )}
              </div>
              {item.DisciplineFeeName ? (
                <div className="discipline-entry-limit">
                  {`${item.DisciplineFeeName}: ${item.DisciplineFee}`}
                </div>
              ) : null}
            </div>
            <div className="discipline-icons">
              <button type="button">
                <Plus
                  className={`add-button ${
                    !entriesRemaining ? 'disabled' : ''
                  }`}
                />
              </button>
            </div>
          </div>
        );
      })}
    </>
  );
};

export default ListBox;
