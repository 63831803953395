import { compose } from 'redux';
import { connect } from 'react-redux';

import withCalculateEntryCharge from 'enhancers/withCalculateEntryCharge';
import withToJS from 'enhancers/withToJS';
import { withSelectedAthletes } from 'context/SelectedAthletes';
import { withSelectedDisciplines } from 'context/SelectedDisciplines';
import { withBuddyGroups } from 'context/BuddyGroups';

import { mapDispatchToProps } from 'helpers';
import { ReviewBase } from './EntryReview';

const mapStateToProps = (state) => ({
  charge: state.entryChargeRequest.get('data'),
  event: state.event.get('data'),
  loading: state.event.get('loading'),
  loadingCharge: state.entryChargeRequest.get('loading'),
  payment: state.payment.get('data'),
  associationMembership: state.associationMembership.get('data'),
  segments: state.segments.get('list'),
  errorMessage: state.entryChargeRequest.get('errorMessage'),
});

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
  withBuddyGroups,
  withSelectedAthletes,
  withSelectedDisciplines,
  withCalculateEntryCharge,
  withToJS,
)(ReviewBase);
