import { WCRA } from 'constants/theme/options';

const { THEME } = process.env;

const retToggleColors = {
  overlay: {
    backgroundColor: 'rgba(43, 42, 42, 0.55)',
  },
  content: {
    border: '1px solid',
    borderRadius: '2px',
    boxShadow:
      '0 2px 4px 0 rgba(0, 0, 0, 0.14), 0 3px 4px 0 rgba(0, 0, 0, 0.12), 0 1px 5px 0 rgba(0, 0, 0, 0.2)',
  },
};

const wcraToggleColors = {
  overlay: {
    backgroundColor: 'rgba(43, 42, 42, 0.75)',
  },
  content: {
    outline: 'none',
    border: 'none',
    borderColor: '#000000',
    boxShadow: 'none',
  },
};

export const themeModal = THEME === WCRA ? wcraToggleColors : retToggleColors;
