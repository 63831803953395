import React from 'react';
import PropTypes from 'prop-types';
import Dropzone from 'react-dropzone';

const ImageUpload = ({ children, handleImageSelected }) => {
  const onDrop = (acceptedFiles) => {
    handleImageSelected(acceptedFiles);
  };

  return (
    <Dropzone
      className="ignore"
      multiple={false}
      accept="image/jpeg, image/png"
      onDrop={onDrop}
    >
      {children}
    </Dropzone>
  );
};

ImageUpload.propTypes = {
  children: PropTypes.element.isRequired,
  handleImageSelected: PropTypes.func.isRequired,
};

export default ImageUpload;
