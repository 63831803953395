// @flow
import React from 'react';

// $FlowIgnore
import warningIcon from 'assets/img/icons/warning-icon.svg';
const DEFAULT_LABEL = 'TOTAL PAID:';

type TotalLabelType = {
  payLater?: boolean,
  defaultLabel?: string,
};

export const TotalLabel = ({
  payLater = false,
  defaultLabel = DEFAULT_LABEL,
}: TotalLabelType) => {
  return payLater ? (
    <div className="confirmation-title">
      <div className="warning-icon">
        <img src={warningIcon} alt="Total Due" />
      </div>
      TOTAL DUE:
    </div>
  ) : (
    <div className="confirmation-title">{defaultLabel}</div>
  );
};
