// @flow
import React, { createContext, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import type { Node, AbstractComponent } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import useDeepCompareEffect from 'use-deep-compare-effect';

import withToJS from 'enhancers/withToJS';
import { useDisciplines } from './disciplinesHook';
import { withSelectedAthletes } from 'context/SelectedAthletes';
import {
  getSelectedDisciplinesId,
  getEntryDisciplines,
  getNominationDisciplines,
} from './helpers';

import { getDisciplinesNominatableBySegment } from 'actions/disciplineSegments';

import {
  TOGGLE_SELECT_SEGMENT,
  TOGGLE_SEGMENT,
  SET_COUNT_ACTIVE_SEGMENT,
  TOGGLE_SELECT_DISCIPLINE,
  TOGGLE_PREFERENCE,
  SET_PREFERENCE,
  SET_PARTNER_ATHLETE,
  TOGGLE_OUT_IF_PREFERENCE_NOT_MET,
  ACTIVE_DISCIPLINE_SELECT,
  UPDATE_ACTIVE_DISCIPLINE,
  ACTIVE_DISCIPLINE_DESELECT,
  SET_PAY_FOR_PARTNER,
  SWITCH_ATHLETE,
  ADD_ENTRY,
  ADD_PENDING_ENTRIES,
  REMOVE_ENTRY,
  REMOVE_DISC_EVENT_ENTRY,
  UPDATE_ENTRY,
  TOGGLE_IS_EDITING,
  SET_HORSE_NAME,
  TOGGLE_CONFIRM_PARTNER_REMOVAL_MODAL,
  CLEAR_SELECTED_DISCIPLINES,
  SET_BUDDY_ENTRIES_COUNT,
  SET_PARTNER_ERROR_MESSAGE,
} from './disciplinesReducer';

import { useSegments } from '../SelectedSegments/useSegments';

import type { DisciplineType, ContextValue } from './type';
import type { PartnerAthleteType } from 'models/PartnerAthlete';
import type { SegmentType } from '../SelectedSegments/type';
import type { EventType } from 'models/Event';

export type PreferenceType = {|
  prefType: string,
  prefNumber: number,
  prefName: string,
|};

type SelectedDisciplinesProps = {|
  children: Node,
  category: string,
  errorMessage: ?string,
  event: EventType,
  ERAUID: string,
|};

export const DisciplineContext = createContext<ContextValue>({
  activeDiscipline: null,
  availableDisciplines: [],
  availableDisciplinesIds: [],
  availablePreferences: [],
  selectedDisciplines: [],
  selectedDisciplinesIds: [],
  unavailableDisciplines: [],
  partnerErrorMessage: {},
  verifyPartnerSelection: () => false,
  activeDisciplineSelect: async () => {},
  updateActiveDiscipline: async () => {},
  activeDisciplineDeselect: async () => {},
  toggleDiscipline: async () => {},
  togglePreference: async () => {},
  hasError: () => false,
  getEntryDisciplines: () => [],
  setPreference: () => {},
  toggleDiscOutIfPrefNotMet: () => {},
  addDiscipline: async () => {},
  removeDiscipline: async () => {},
  toggleSelectSegment: async () => {},
  setCountActiveSegment: async () => {},
  toggleHasSegments: async () => {},
  setPartnerAthlete: async () => {},
  getNominationDisciplines: () => [],
  setPayForPartner: async () => {},
  switchAthlete: async () => {},
  addEntry: async () => {},
  addPendingEntries: async () => {},
  setHorseName: async () => {},
  removeEntry: async () => {},
  removeDiscEventEntry: async () => {},
  updateEntry: async () => {},
  entries: {},
  isEditing: false,
  toggleIsEditing: () => {},
  showConfirmPartnerRemoveModal: false,
  toggleShowConfirmPartnerRemoveModal: () => {},
  clearSelectedDisciplines: () => {},
  setBuddyEntriesCount: () => {},
});

const { Provider, Consumer } = DisciplineContext;

const mapStateToProps = (state) => ({
  event: state.event.get('data'),
  errorMessage: state.entryChargeRequest.get('errorMessage'),
  user: state.event.get('data'),
});

const SelectedDisciplinesProviderBase = (props: SelectedDisciplinesProps) => {
  const [state, dispatch] = useDisciplines(props.category, props.ERAUID);
  const reduxDispatch = useDispatch();
  const { getCurrentSegment } = useSegments();
  const {
    availableDisciplines,
    availablePreferences,
    selectedDisciplines,
    unavailableDisciplines,
    activeDiscipline,
    entries,
    isEditing,
    partnerErrorMessage,
    showConfirmPartnerRemoveModal,
  } = state;
  const selectedDisciplinesIds = getSelectedDisciplinesId(selectedDisciplines);
  const availableDisciplinesIds = getSelectedDisciplinesId(
    availableDisciplines,
  );

  useDeepCompareEffect(() => {
    const asyncGetDisciplineNominatableSegments = async () => {
      await reduxDispatch(
        getDisciplinesNominatableBySegment(selectedDisciplinesIds),
      );
    };

    asyncGetDisciplineNominatableSegments();
  }, [selectedDisciplinesIds]);

  const hasError = () => {
    const { errorMessage } = props;
    const { selectedDisciplines } = state;
    return !!errorMessage || selectedDisciplines.length === 0;
  };
  const disciplineMethod = useCallback(
    (method: string) => async (discipline: DisciplineType) => {
      return dispatch({
        type: TOGGLE_SELECT_DISCIPLINE,
        data: discipline,
        method,
      });
    },
    [],
  );

  const toggleDiscipline = useCallback(disciplineMethod('TOGGLE'), []);
  const addDiscipline = useCallback(disciplineMethod('ADD'), []);
  const removeDiscipline = useCallback(disciplineMethod('REMOVE'), []);
  const togglePreference = useCallback(
    (disciplineId: string | number, ClassName: string) =>
      dispatch({ type: TOGGLE_PREFERENCE, data: { disciplineId, ClassName } }),
    [],
  );

  const switchAthlete = useCallback(
    (ERAUID) => dispatch({ type: SWITCH_ATHLETE, data: ERAUID }),
    [],
  );

  const addEntry = useCallback(
    (discipline) => dispatch({ type: ADD_ENTRY, data: discipline }),
    [],
  );

  const addPendingEntries = useCallback(
    (pendingEntries) =>
      dispatch({ type: ADD_PENDING_ENTRIES, data: pendingEntries }),
    [],
  );

  const removeEntry = useCallback(
    (discipline) => dispatch({ type: REMOVE_ENTRY, data: discipline }),
    [],
  );

  const removeDiscEventEntry = useCallback(
    (discipline) =>
      dispatch({ type: REMOVE_DISC_EVENT_ENTRY, data: discipline }),
    [],
  );

  const updateEntry = useCallback(
    (discipline) => dispatch({ type: UPDATE_ENTRY, data: discipline }),
    [],
  );

  const setPreference = useCallback(
    (
      disciplineId: string,
      preferenceCategory: string,
      preferenceId: ?number = null,
      ClassName?: string,
    ) =>
      dispatch({
        type: SET_PREFERENCE,
        data: { disciplineId, preferenceCategory, preferenceId, ClassName },
      }),
    [],
  );

  const toggleDiscOutIfPrefNotMet = useCallback(
    (disciplineId: string) =>
      dispatch({ type: TOGGLE_OUT_IF_PREFERENCE_NOT_MET, data: disciplineId }),
    [],
  );

  const activeDisciplineSelect = useCallback(
    async (discipline: DisciplineType) => {
      return dispatch({ type: ACTIVE_DISCIPLINE_SELECT, data: discipline });
    },
    [],
  );

  const updateActiveDiscipline = useCallback((newEntryCount) =>
    dispatch({ type: UPDATE_ACTIVE_DISCIPLINE, data: newEntryCount }),
  );

  const activeDisciplineDeselect = useCallback(
    () => dispatch({ type: ACTIVE_DISCIPLINE_DESELECT }),
    [],
  );

  const toggleIsEditing = useCallback(
    (bool) => dispatch({ type: TOGGLE_IS_EDITING, data: bool }),
    [],
  );

  const clearSelectedDisciplines = () => {
    dispatch({
      type: CLEAR_SELECTED_DISCIPLINES,
    });
  };

  const toggleShowConfirmPartnerRemoveModal = useCallback(
    (bool) =>
      dispatch({ type: TOGGLE_CONFIRM_PARTNER_REMOVAL_MODAL, data: bool }),
    [],
  );

  const toggleSelectSegment = useCallback(
    async (disciplineId: string, segment: SegmentType) => {
      dispatch({
        type: TOGGLE_SELECT_SEGMENT,
        data: { disciplineId, segment },
      });
    },
    [],
  );

  const setCountActiveSegment = useCallback(
    async (
      disciplineId: string,
      segmentValue: any,
      newSegmentCount: number,
    ) => {
      dispatch({
        type: SET_COUNT_ACTIVE_SEGMENT,
        data: { disciplineId, segmentValue, newSegmentCount },
      });
    },
    [],
  );

  const toggleHasSegments = useCallback(
    async (disciplineId: string) => {
      await dispatch({ type: TOGGLE_SEGMENT, data: disciplineId });
      const currentSegment = getCurrentSegment();
      if (currentSegment) {
        return toggleSelectSegment(disciplineId, currentSegment);
      }
    },
    [props.event.id],
  );

  const setPartnerAthlete = useCallback(
    async (
      disciplineId: string,
      partner: PartnerAthleteType,
      index: number,
    ) => {
      await dispatch({
        type: SET_PARTNER_ATHLETE,
        data: { disciplineId, partner, index },
      });
    },
    [props.event.id],
  );

  const verifyPartnerSelection = useCallback(() => {
    const { partners, RequirePartnerSelection } = activeDiscipline;
    const invalidPartnersMessages = {};
    Object.values(partners).forEach((p, i) => {
      // $FlowIgnore
      if (!p.id) invalidPartnersMessages[i] = 'Select your partner.';
    });
    const showError =
      RequirePartnerSelection &&
      Object.keys(invalidPartnersMessages).length !== 0;

    dispatch({
      type: SET_PARTNER_ERROR_MESSAGE,
      data: showError ? invalidPartnersMessages : {},
    });
    return !showError;
  }, [activeDiscipline]);

  const setHorseName = useCallback(async (HorseName: string) => {
    await dispatch({
      type: SET_HORSE_NAME,
      data: HorseName,
    });
  }, []);

  const setPayForPartner = useCallback(
    async (
      disciplineId: string,
      isPayingForPartner: ?boolean,
      index: ?number,
    ) => {
      await dispatch({
        type: SET_PAY_FOR_PARTNER,
        data: { disciplineId, isPayingForPartner, index },
      });
    },
    [props.event.id],
  );

  const setBuddyEntriesCount = (buddyEntries, ERAUID) => {
    dispatch({
      type: SET_BUDDY_ENTRIES_COUNT,
      data: { buddyEntries, ERAUID },
    });
  };

  return (
    <Provider
      value={{
        toggleSelectSegment,
        setCountActiveSegment,
        toggleHasSegments,
        addDiscipline,
        removeDiscipline,
        partnerErrorMessage,
        activeDiscipline,
        activeDisciplineSelect,
        updateActiveDiscipline,
        activeDisciplineDeselect,
        availableDisciplines,
        availablePreferences,
        selectedDisciplines,
        setPartnerAthlete,
        verifyPartnerSelection,
        setPayForPartner,
        setHorseName,
        unavailableDisciplines,
        toggleDiscipline,
        selectedDisciplinesIds,
        availableDisciplinesIds,
        hasError,
        getEntryDisciplines,
        togglePreference,
        setPreference,
        toggleDiscOutIfPrefNotMet,
        getNominationDisciplines,
        switchAthlete,
        addEntry,
        addPendingEntries,
        removeEntry,
        removeDiscEventEntry,
        updateEntry,
        entries,
        isEditing,
        toggleIsEditing,
        showConfirmPartnerRemoveModal,
        toggleShowConfirmPartnerRemoveModal,
        clearSelectedDisciplines,
        setBuddyEntriesCount,
      }}
    >
      {props.children}
    </Provider>
  );
};

export const SelectedDisciplinesProvider = compose(
  // $FlowFixMe
  connect(mapStateToProps),
  withToJS,
  withSelectedAthletes,
)(SelectedDisciplinesProviderBase);

export const withSelectedDisciplines = (
  ComposedComponent: AbstractComponent<any>,
) => {
  return function WithSelectedDisciplines(props: any) {
    return (
      <Consumer>
        {(contextValues: ContextValue) => (
          <ComposedComponent {...props} {...contextValues} />
        )}
      </Consumer>
    );
  };
};

export const withSelectedDisciplinesIds = (
  ComposedComponent: AbstractComponent<any>,
) => {
  return function WithSelectedDisciplinesIds(props: any) {
    return (
      <Consumer>
        {(contextValues: ContextValue) => {
          const {
            selectedDisciplinesIds,
            availableDisciplinesIds,
            hasError,
          } = contextValues;
          return (
            <ComposedComponent
              {...props}
              hasError={hasError}
              selectedDisciplinesIds={selectedDisciplinesIds}
              availableDisciplinesIds={availableDisciplinesIds}
            />
          );
        }}
      </Consumer>
    );
  };
};
