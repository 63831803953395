import moment from 'moment';
import { getNestedProperty, hasPreferences } from 'helpers';

export const getInitialTransactionPerDiscipline = (transactions) => {
  const initialTransactionPreferencesForDisciplines = {};
  transactions.forEach((transaction) => {
    transaction.entries.forEach((entry) => {
      if (!!!initialTransactionPreferencesForDisciplines[entry.disciplineId]) {
        initialTransactionPreferencesForDisciplines[
          entry.disciplineId
        ] = transaction;
      } else if (
        transactions.every((lookAheadTransaction) =>
          moment(transaction.date).isBefore(lookAheadTransaction.date),
        )
      ) {
        initialTransactionPreferencesForDisciplines[
          entry.disciplineId
        ] = transaction;
      }
    });
  });
  return Object.values(initialTransactionPreferencesForDisciplines);
};

export const isExistingBuddyPreference = (entry, activeDiscipline) => {
  return (
    (String(entry.CompType) ===
      String(getNestedProperty('value', activeDiscipline)) ||
      String(entry.disciplineId) ===
        String(getNestedProperty('value', activeDiscipline)) ||
      String(entry.value) ===
        String(getNestedProperty('value', activeDiscipline))) &&
    hasPreferences(entry, true)
  );
};
