// @flow
import React from 'react';
import classNames from 'classnames';
import { useDisciplineModalHooks } from './hooks';

type Props = {|
  closeModal: () => Promise<any>,
  saveEntry: () => void,
  removeAsPartner: () => void,
  goToNextStep: () => void,
  activeStep: number,
  totalSteps: number,
  className?: string,
  isEditing: boolean,
  disabled: boolean,
  isAddedAsPartner: boolean,
|};

const Footer = (props: Props) => {
  const methods = useDisciplineModalHooks();
  const {
    activeDiscipline,
    addEntry,
    updateEntry,
    removeEntry,
    verifyPartnerSelection,
  } = methods;
  const {
    closeModal,
    saveEntry,
    className,
    isEditing,
    disabled,
    isAddedAsPartner,
    removeAsPartner,
    goToNextStep,
    activeStep,
    totalSteps,
  } = props;

  const onClick = async (actionCreator) => {
    await actionCreator(activeDiscipline);
    return saveEntry();
  };

  const handleCreateAndUpdate = async (actionCreator) => {
    if (verifyPartnerSelection()) {
      await onClick(actionCreator);
    }
  };
  return (
    <div className={classNames('footer', className)}>
      {isEditing ? (
        <>
          <button
            disabled={disabled}
            className="btn-main"
            onClick={() => handleCreateAndUpdate(updateEntry)}
          >
            UPDATE ENTRY
          </button>
          {isAddedAsPartner ? (
            <button className="clear-filter-btn" onClick={removeAsPartner}>
              REMOVE ME AS PARTNER
            </button>
          ) : (
            <button
              className="clear-filter-btn"
              onClick={() => {
                onClick(removeEntry);
              }}
            >
              REMOVE FROM CART
            </button>
          )}
        </>
      ) : (
        <>
          {activeStep < totalSteps ? (
            <button
              disabled={disabled}
              className="btn-main"
              onClick={goToNextStep}
            >
              NEXT
            </button>
          ) : (
            <button
              disabled={disabled}
              className="btn-main"
              onClick={() => handleCreateAndUpdate(addEntry)}
            >
              ADD TO CART
            </button>
          )}
          <button className="clear-filter-btn" onClick={closeModal}>
            CANCEL
          </button>
        </>
      )}
    </div>
  );
};

export default Footer;
