// @flow
import React from 'react';
import { menuRoutes } from 'constants/routes';
import { getPendingEntriesCount } from 'helpers';
import MenuItem from './Item';
import { EVENT_ENTRY_SEARCH } from 'constants/routes';

type MenuProps = {|
  menuId: string,
  user: Object,
|};

const Menu = ({ menuId, user }: MenuProps) => {
  return (
    <ul className="menu" id={menuId}>
      {menuRoutes().map((menuRoute) => {
        const menuItemProps = {
          ...menuRoute,
        };
        // Add count indicator to MenuItem
        if (menuRoute.itemCount && menuRoute.itemUrl === EVENT_ENTRY_SEARCH) {
          menuItemProps.itemCount = getPendingEntriesCount(user);
        }
        return <MenuItem key={menuRoute.itemUrl} {...menuItemProps} />;
      })}
    </ul>
  );
};

export default Menu;
