// @flow
import { get } from 'lodash';
import React, {
  useState,
  useCallback,
  useContext,
  useEffect,
  useMemo,
} from 'react';
import { isEmpty } from 'lodash';
import { useSelector } from 'react-redux';
import EntryPreferenceModal from 'components/EntryPreferenceModal';
import BackButton from 'components/BackButton';
import Loader from 'components/Loader';
import Section, { SectionTitle } from 'components/Section';
import { useDisciplineModalHooks } from './hooks';
import { getDisciplineSegments } from 'selectors/disciplineSegments';
import { DisciplineContext } from 'context/SelectedDisciplines';
import { getFilteredSegments } from 'models/DisciplineSegment';
import DateAndTime from './DateAndTime';
import EntryFees from './EntryFees';
import NumberOfEntries from './NumberOfEntries';
import {
  changeRoute,
  getNestedProperty,
  isEntryPerformanceInEvent,
  areSegmentDatesDifferentThanEventDates,
  getEventSegmentFilter,
} from 'helpers';
import { getSegments } from 'selectors/segment';
import { isNominatableDiscipline } from 'constants/disciplines';

import {
  getInitialTransactionPerDiscipline,
  isExistingBuddyPreference,
} from './helpers';
import SelectPreferences from './SelectPreferences';
import SelectSegments from './SelectSegments';
import Footer from './Footer';
import {
  EVENT_ENTRY_UPCOMING_EVENTS,
  EVENT_ENTRY_REVIEW,
} from 'constants/routes';
import {
  ENTRY_STEP,
  PREFERENCE_STEP,
  NOMINATION_STEP,
} from 'constants/entryFlowSteps';
import type { EventType } from 'models/Event';
import type { BuddyGroupType, BuddyType } from 'models/Buddy';

import PartnerV1 from './Partner';
import PartnerV2 from './Partner.v2';

import {
  isTeamDiscipline,
  isPersonalHorseDiscipline,
  getPartnerDisciplineId,
} from 'constants/disciplines';
import ConfirmPartnerRemoveModal from './ConfirmPartnerRemoveModal';
import ViewPreferences from './ViewPreferences';

type DisciplineModalProps = {|
  event: EventType,
  onSave: () => void,
  isEditing: boolean,
  history: Object,
  calculateEntryCharge: () => void,
  entryRemoveAsPartner: (Object, Object) => void,
  user: Object,
  dispatch: (f: Function) => any,
  showConfirmPartnerRemoveModal: boolean,
  toggleShowConfirmPartnerRemoveModal: (f: Function) => any,
  getBuddyGroup: () => Promise<Object>,
  getBuddyGroupTransactions: (string, string) => Promise<Object>,
  buddyGroup: BuddyGroupType,
  buddyGroupManager: BuddyType,
  transactions: Object[],
  loading: boolean,
  isBuddyEntry: boolean,
  isYouthAthlete: boolean,
  isFemaleAthlete: boolean,
  shouldHideNomination: boolean,
|};

const DisciplineFlowSteps = (props: DisciplineModalProps) => {
  const methods = useDisciplineModalHooks();
  const disciplineSegments = useSelector(getDisciplineSegments);
  const segments = useSelector(getSegments);
  const {
    buddyGroup = [],
    buddyGroupManager,
    calculateEntryCharge,
    dispatch,
    entryRemoveAsPartner,
    event,
    getBuddyGroup,
    getBuddyGroupTransactions,
    history,
    isBuddyEntry,
    isEditing,
    isYouthAthlete,
    isFemaleAthlete,
    loading,
    onSave,
    showConfirmPartnerRemoveModal,
    toggleShowConfirmPartnerRemoveModal,
    transactions = [],
    shouldHideNomination = false,
    user,
  } = props;
  const Partner =
    Number(process.env.REACT_APP_FUZZY) === 1 ? PartnerV2 : PartnerV1;

  const {
    activeDisciplineDeselect,
    updateActiveDiscipline,
    activeDiscipline,
    availablePreferences,
    setPreference,
    unsetPreference,
    getEntryPreferences,
    togglePreference,
    setHorseName,
    removeDiscEventEntry,
    availableDisciplines,
    verifyPartnerSelection,
  } = methods;

  const newEntryCount = get(activeDiscipline, 'newEntryCount', 1);

  let ClassName = activeDiscipline && get(activeDiscipline, 'ClassName', null);
  const performances =
    activeDiscipline && get(activeDiscipline, 'performances', null);

  const requirePartnerSelection = get(
    activeDiscipline,
    'RequirePartnerSelection',
    false,
  );

  const eventSegmentFilter = getEventSegmentFilter(activeDiscipline, event);

  const filteredSegments = getFilteredSegments(
    segments,
    !!isYouthAthlete,
    !!isFemaleAthlete,
    eventSegmentFilter,
  );
  const areDifferentEventDates = areSegmentDatesDifferentThanEventDates(
    disciplineSegments,
    event,
    filteredSegments,
  );

  const showNominationStep = () => {
    return (
      activeDiscipline &&
      isNominatableDiscipline(activeDiscipline.value) &&
      !shouldHideNomination &&
      ((!event.AllowClasses && event.AllowsNominationOnEntry) ||
        (event.AllowClasses && activeDiscipline.Nominatable))
    );
  };

  useEffect(() => {
    let performanceUIDs = {},
      showPreferenceStep = [];
    // $FlowIgnore
    if (
      activeDiscipline !== null &&
      activeDiscipline.performances !== undefined &&
      activeDiscipline.performances.length > 0
    ) {
      performanceUIDs = activeDiscipline.performances.map(
        ({ PerformanceUID }) => PerformanceUID,
      );
      const eventPerformances = event.performances.filter(
        ({ PerformanceUID }) => performanceUIDs.includes(PerformanceUID),
      );

      showPreferenceStep = eventPerformances
        .reduce((acc, curr) => {
          const foundDiscipline = curr.Disciplines.find(
            // $FlowIgnore
            ({ id }) => id === activeDiscipline.value,
          );

          if (!!foundDiscipline) {
            if ('PreferenceEligible' in foundDiscipline) {
              return [...acc, foundDiscipline.PreferenceEligible];
            } else {
              return [...acc];
            }
          } else {
            return [...acc];
          }
        }, [])
        .some((preferenceEligible) => !!preferenceEligible);
    }

    if (activeDiscipline) {
      if (showNominationStep() && showPreferenceStep) {
        setScreenToShow([ENTRY_STEP, PREFERENCE_STEP, NOMINATION_STEP]);
      } else if (areDifferentEventDates) {
        setScreenToShow([ENTRY_STEP]);
      } else if (showNominationStep() && !showPreferenceStep) {
        setScreenToShow([ENTRY_STEP, NOMINATION_STEP]);
      } else if (!showNominationStep() && showPreferenceStep) {
        setScreenToShow([ENTRY_STEP, PREFERENCE_STEP]);
      } else {
        setScreenToShow([ENTRY_STEP]);
      }
    }
  }, [activeDiscipline]);

  useEffect(() => {
    return () => setBuddyEntries([]);
  }, []);

  useEffect(() => {
    if (!loading && event.id && isBuddyEntry) {
      loadBuddyGroupData();
    }

    return () => {
      setBuddyEntries([]);
    };
  }, [event.id]);

  useEffect(() => {
    if (!loading && transactions.length > 0) {
      const initialTransactionPerDisciplines = getInitialTransactionPerDiscipline(
        transactions,
      );
      const buddyEntries = [
        ...initialTransactionPerDisciplines,
        ...buddyGroup,
        buddyGroupManager,
      ].reduce(
        (acc, buddy) => [
          ...acc,
          ...getNestedProperty('entries', buddy, []).filter((entry) =>
            isEntryPerformanceInEvent(entry, event),
          ),
        ],
        [],
      );
      setBuddyEntries(buddyEntries);
    }
  }, [transactions.length, loading, buddyGroup.length]);

  const loadBuddyGroupData = () => {
    getBuddyGroupTransactions(getNestedProperty('id', event), user.ERAUID);
    getBuddyGroup();
  };

  const isReading =
    (getNestedProperty('location.state.isReading', history, false) &&
      !getNestedProperty('performances', activeDiscipline)) ||
    getNestedProperty('isPendingEntry', activeDiscipline);

  const preferencesOrNots = event.NumberOfNots || event.NumberOfPrefs;
  const { selectedDisciplines } = useContext(DisciplineContext);
  const [preferenceModalOpen, setPreferenceModalOpen] = useState<boolean>(
    false,
  );
  const [updateButtonDisabled, setUpdateButtonDisabled] = useState<boolean>(
    false,
  );
  const [selectedPerformance, setSelectedPerformance] = useState<?Object>(null);
  const [buddyEntries, setBuddyEntries] = useState<Array<Object>>([]);
  const [screenToShow, setScreenToShow] = useState<string[]>([ENTRY_STEP]);
  const [activeStep, setActiveStep] = useState<number>(1);
  const existingBuddyPreference = buddyEntries.find((entry) => {
    return isExistingBuddyPreference(entry, activeDiscipline);
  });

  const closeModal = async () => {
    await activeDisciplineDeselect();
    await calculateEntryCharge();
  };

  const saveEntry = () => {
    activeDisciplineDeselect();
    return onSave();
  };

  const onPerformanceSelected = (performance: Object) => {
    setSelectedPerformance(performance);
    setPreferenceModalOpen(true);
  };

  const closeEntryPreferenceModal = useCallback(() => {
    setPreferenceModalOpen(false);
  }, []);

  const onPreferenceSelected = useCallback(
    async (preferenceCategory: string, performanceId: number) => {
      if (activeDiscipline) {
        const { value, ClassName } = activeDiscipline;
        if (!activeDiscipline.hasPreference) {
          togglePreference(value, ClassName);
        }
        await setPreference(
          value,
          preferenceCategory,
          performanceId,
          ClassName,
        );
        closeEntryPreferenceModal();
      }
    },
    [activeDiscipline],
  );

  const disciplinePreferences = activeDiscipline
    ? getEntryPreferences(activeDiscipline)
    : [];

  const nextButtonDisabledByPreferenceSelection = useMemo(
    () =>
      screenToShow[activeStep - 1] === PREFERENCE_STEP &&
      event.RequirePreferenceSelection &&
      !disciplinePreferences.length,
    [activeStep, event, disciplinePreferences],
  );

  const removeAsPartner = () => {
    toggleShowConfirmPartnerRemoveModal(true);
  };

  const confirmPartnerRemoval = async () => {
    await entryRemoveAsPartner(activeDiscipline, user);
    await removeDiscEventEntry(activeDiscipline);

    closeModal();

    const pendingEntries = selectedDisciplines.filter((d) => {
      return getNestedProperty('EPUID', activeDiscipline) !== d.EPUID;
    });

    if (pendingEntries.length > 0) {
      changeRoute({ history, route: EVENT_ENTRY_REVIEW });
    } else {
      changeRoute({ history, route: EVENT_ENTRY_UPCOMING_EVENTS });
    }
  };

  if (!activeDiscipline) return null;

  const hasPartner = !isEmpty(
    getNestedProperty('partner.EPUID', activeDiscipline),
  );

  const partnerName = getNestedProperty(
    'partner.fullNameWithNickname',
    activeDiscipline,
  );

  const isTeam = isTeamDiscipline(activeDiscipline.value);
  const isAddedAsPartner = isTeam && hasPartner;
  let teamDisciplineFee = 0;
  const { AllowClasses } = event;
  if (AllowClasses && !ClassName && activeDiscipline && isTeam) {
    const partnerDisciplineId = getPartnerDisciplineId(activeDiscipline.value);
    const item: any = availableDisciplines.find(
      (itemDiscipline) => itemDiscipline.value === partnerDisciplineId,
    );
    const itemClass = item.Classes.find(
      (itemClass) => itemClass.DisciplineUID === item.value,
    );
    ClassName = itemClass ? itemClass.ClassName : '';
    teamDisciplineFee = itemClass.Fee;
  }

  const handleHorseNameChange = async (
    { target: { value } },
    newEntryCount,
    index,
  ) => {
    setHorseName({ value, newEntryCount, index });
  };

  const renderPreferences = () => {
    if (!preferencesOrNots) return null;

    if (existingBuddyPreference) {
      return (
        <ViewPreferences
          preferences={getEntryPreferences(existingBuddyPreference)}
          disciplineId={
            getNestedProperty('CompType', existingBuddyPreference) ||
            getNestedProperty('disciplineId', existingBuddyPreference) ||
            getNestedProperty('value', existingBuddyPreference)
          }
          outIfPrefNotMet={existingBuddyPreference.OutIfPrefNotMet}
        />
      );
    }

    if (getNestedProperty('performances', activeDiscipline)) {
      return (
        <SelectPreferences
          event={event}
          onPerformanceSelected={onPerformanceSelected}
          dispatch={dispatch}
        />
      );
    }

    // For viewing preference, user cannot edit
    if (isReading) {
      return (
        <ViewPreferences
          preferences={disciplinePreferences}
          outIfPrefNotMet={getNestedProperty(
            'OutIfPrefNotMet',
            activeDiscipline,
          )}
        />
      );
    }

    return null;
  };

  const entryIsHorseDiscipline = isPersonalHorseDiscipline(
    activeDiscipline.value,
  );

  const horseNames = getNestedProperty('horseNames', activeDiscipline, '');

  const goToPrevStep = () => {
    setActiveStep((prevState) => prevState - 1);
  };

  const goToNextStep = () => {
    if (verifyPartnerSelection()) {
      setActiveStep((prevState) => prevState + 1);
    }
  };

  return (
    <div className="grid-container padbot-0 modal-content entry-event-discipline">
      <div className="grid-x">
        <div className="small-12 large-12 desktop-only">
          <BackButton goBack={activeStep > 1 ? goToPrevStep : closeModal} />
          <h2 className="section-title padtop-0 padbot-0">Entry</h2>
        </div>
        <div className="small-12 large-6 large-offset-3 theme-content-container entry">
          <div className="small-12 large-12 mobile-only">
            {<BackButton goBack={activeStep > 1 ? goToPrevStep : closeModal} />}
          </div>
          <div className="top-heading">
            <h2 className="discipline-title padtop-0">
              {activeDiscipline.label}
            </h2>
            {screenToShow ? (
              <div className="display-user-steps">{`STEP ${activeStep}/${screenToShow.length}`}</div>
            ) : null}
          </div>
          <div className="user-steps">
            {screenToShow &&
              screenToShow.map((value, indx) => {
                return (
                  <span
                    key={value}
                    className={`${
                      activeStep > indx ? 'passed-step' : 'not-passed-step'
                    }`}
                  />
                );
              })}
          </div>
          <Section className="entry-disciplines-modal-content">
            <div className="main-content">
              <div
                className={
                  screenToShow[activeStep - 1] == ENTRY_STEP ? 'show' : 'hide'
                }
              >
                <DateAndTime performances={performances} />
                <NumberOfEntries
                  updateActiveDiscipline={updateActiveDiscipline}
                  activeDiscipline={activeDiscipline}
                  entryIsHorseDiscipline={entryIsHorseDiscipline}
                  setHorseName={setHorseName}
                />
                <EntryFees teamDisciplineFee={teamDisciplineFee} />
                {entryIsHorseDiscipline ? (
                  <>
                    <SectionTitle optional>HORSE(S)</SectionTitle>
                    <hr className="martop-0" />
                    <form className="profile-edit">
                      {[...Array(newEntryCount).keys()].map((item, index) => (
                        <fieldset key={`horse-${index + newEntryCount}`}>
                          <label
                            key={`label-horse-${index + newEntryCount}`}
                            htmlFor="HorseName"
                          >{`Horse #${index + 1}`}</label>
                          <input
                            name="HorseName"
                            key={`input-horse-${index + newEntryCount}`}
                            type="text"
                            placeholder="Enter Horse's Name"
                            className="horse-input"
                            onChange={(e) =>
                              handleHorseNameChange(e, newEntryCount, index)
                            }
                            value={horseNames[index]}
                          />
                          <hr className="martop-0" />
                        </fieldset>
                      ))}
                    </form>
                  </>
                ) : null}
                {isTeam && !event.DisablePartnerSelection && (
                  <div className="partner-container">
                    <div className="partner-section-title">PARTNER(S)</div>
                    {isReading || requirePartnerSelection ? null : (
                      <span className="optional-label">optional</span>
                    )}
                    {requirePartnerSelection && (
                      <span className="optional-label">REQUIRED</span>
                    )}
                  </div>
                )}
                {!event.DisablePartnerSelection &&
                  [...Array(newEntryCount).keys()].map((item, index) => (
                    <Partner
                      key={`partner-${item + 1}`}
                      index={index}
                      partnerNumber={item + 1}
                      disciplineId={activeDiscipline.value}
                      event={event}
                      setUpdateButtonDisabled={setUpdateButtonDisabled}
                      isEditing={isEditing}
                      isReading={isReading}
                      getBuddyGroupTransactions={getBuddyGroupTransactions}
                      requirePartnerSelection={requirePartnerSelection}
                    />
                  ))}
              </div>
              <div
                className={
                  screenToShow[activeStep - 1] == PREFERENCE_STEP
                    ? 'show'
                    : 'hide'
                }
              >
                {!loading ? (
                  renderPreferences()
                ) : (
                  <div className="spinner-relative-container">
                    <Loader className="spinner-relative" loading={loading} />
                  </div>
                )}
              </div>
              <div
                className={
                  screenToShow[activeStep - 1] == NOMINATION_STEP
                    ? 'show'
                    : 'hide'
                }
              >
                {event.AllowsNominationOnEntry &&
                !shouldHideNomination &&
                isNominatableDiscipline(activeDiscipline.value) ? (
                  <SelectSegments
                    isYouthAthlete={isYouthAthlete}
                    isFemaleAthlete={isFemaleAthlete}
                    event={event}
                    areDifferentEventDates={areDifferentEventDates}
                    filteredSegments={filteredSegments}
                  />
                ) : null}
              </div>

              <EntryPreferenceModal
                availablePreferences={availablePreferences}
                isOpen={
                  !!activeDiscipline &&
                  !!selectedPerformance &&
                  preferenceModalOpen &&
                  !showConfirmPartnerRemoveModal
                }
                closeModal={closeEntryPreferenceModal}
                selectedPerformance={selectedPerformance}
                setPreference={onPreferenceSelected}
                unsetPreference={unsetPreference}
                selectedPreferences={disciplinePreferences}
              />
              <ConfirmPartnerRemoveModal
                eventName={getNestedProperty('EventName', event)}
                isOpen={showConfirmPartnerRemoveModal}
                closeModal={() => toggleShowConfirmPartnerRemoveModal(false)}
                confirm={confirmPartnerRemoval}
                partnerName={partnerName}
              />
            </div>
          </Section>
          <div className="footer">
            <Footer
              disabled={
                updateButtonDisabled || nextButtonDisabledByPreferenceSelection
              }
              className="martop-5"
              closeModal={closeModal}
              saveEntry={saveEntry}
              isEditing={isEditing}
              isAddedAsPartner={isAddedAsPartner}
              removeAsPartner={removeAsPartner}
              goToNextStep={goToNextStep}
              activeStep={activeStep}
              totalSteps={screenToShow ? screenToShow.length : 1}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default DisciplineFlowSteps;
