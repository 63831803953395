import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';
import { compose } from 'redux';
import { fetchUserByToken } from 'actions/user';
import { GET_USER_PROFILE } from 'queries/GetUserProfile';
import { NominateSearch } from './components/Search';
import { clearGiftCardBalances } from 'reducers/giftCardBalances';
import { withNominationFlow } from 'context/NominationFlow';
import get from 'lodash/get';

const mapStateToProps = (state) => ({
  userToken: get(
    state.auth.get('data'),
    'authPayload.token.refreshToken',
    null,
  ),
  errorMessage: state.event.get('errorMessage'),
  loading: state.event.get('loading'),
  loadingAuth: state.auth.get('loading'),
  events: state.event.get('list'),
  settings: state.adminSetting.get('data'),
  user: state.user.get('data'),
});
const mapDispatchToProps = (dispatch) => ({
  dispatch,
  fetchUser: (token) => dispatch(fetchUserByToken(GET_USER_PROFILE, token)),
  clearGiftCardBalances: () => dispatch(clearGiftCardBalances()),
});

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
  reduxForm({
    form: 'add-event',
  }),
  withNominationFlow,
)(NominateSearch);
