import gql from 'graphql-tag';

export const ASSOCIATION_MEMBER_FEE = gql`
  query ASSOCIATION_MEMBER_FEE($EventUID: ID!, $ERAUID: ID!) {
    associationMemberFees(EventUID: $EventUID, ERAUID: $ERAUID) {
      AssociationMemberTypeUID
      AssociationUID
      AssociationTypeFeeUID
      AssociationShortName
      MembershipType
      FeeAmount
      IsRenewalFee
      IsPermitFee
      IsNonMember
      YouthOnly
      AdultOnly
    }
  }
`;
