// @flow
import React, { createContext, PureComponent } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import withToJS from 'enhancers/withToJS';
import { mapDispatchToProps } from 'helpers';
import { validateCoupon, clearCoupon } from 'actions/payment';

import type { Node, AbstractComponent } from 'react';
import type { Dispatch } from 'redux';
import type { EventType } from 'models/Event';

export type CouponType = {|
  CouponCode: string,
  DollarDisc: ?number,
  NomDisc: ?number,
  FeeDisc: ?number,
  isPercentage: boolean,
|};

export type ContextValue = {|
  errorCoupon: string | null,
  selectedCoupon: CouponType | null,
  selectCoupon: (coupon: ?string) => Promise<CouponType | string>,
  reset: () => any,
|};

type SelectedCouponProps = {|
  children: Node,
  dispatch: Dispatch<any>,
  event: EventType,
|};

type SelectedCouponState = {|
  selectedCoupon: CouponType | null,
  error: string | null,
|};
export const CouponContext = createContext<ContextValue>({
  errorCoupon: null,
  selectedCoupon: ({}: $Shape<CouponType>),
  selectCoupon: async () => '',
  reset: () => null,
});

const { Provider, Consumer } = CouponContext;

const mapStateToProps = (state) => ({
  event: state.event.get('data'),
  user: state.user.get('data'),
});

class SelectedCouponProviderBase extends PureComponent<
  SelectedCouponProps,
  SelectedCouponState,
> {
  constructor(props) {
    super(props);
    this.state = {
      selectedCoupon: null,
      error: null,
    };
  }

  resetCoupon = () => {
    this.setState({
      selectedCoupon: null,
      error: null,
    });
  };

  selectCoupon = async (
    coupon: ?string,
    ERAUID: ?number,
    segmentUIDs: ?Array<number>,
    selectedDisciplineIds: ?Array<number>,
  ): Promise<CouponType | string> => {
    const { dispatch, event } = this.props;
    if (!coupon) {
      const { selectedCoupon } = this.state;
      if (selectedCoupon && selectedCoupon.CouponCode) {
        await dispatch(clearCoupon());
        this.setState({
          selectedCoupon: null,
        });
        return '';
      }
      return 'Invalid coupon';
    }
    this.setState({ error: null });
    const responseCoupon: CouponType | string = await dispatch(
      validateCoupon({
        couponCode: coupon,
        eventId: event.id,
        ERAUID,
        segmentUIDs,
        selectedDisciplineIds,
      }),
    );
    if (typeof responseCoupon === 'string') {
      this.setState({
        selectedCoupon: null,
      });
      return responseCoupon;
    }
    this.setState({
      selectedCoupon: responseCoupon,
    });
    return responseCoupon;
  };

  reset = () => {
    this.setState({
      selectedCoupon: null,
    });
  };

  render() {
    const { selectedCoupon, error } = this.state;

    return (
      <Provider
        value={{
          errorCoupon: error,
          selectedCoupon,
          selectCoupon: this.selectCoupon,
          reset: this.reset,
        }}
      >
        {this.props.children}
      </Provider>
    );
  }
}

export const SelectedCouponProvider = compose(
  connect<_, SelectedCouponProps, _, _, _, _>(
    mapStateToProps,
    mapDispatchToProps,
  ),
  withToJS,
)(SelectedCouponProviderBase);

export const withSelectedCoupon = (
  ComposedComponent: AbstractComponent<any>,
) => {
  return function WithSelectedCoupon(props: any) {
    return (
      <Consumer>
        {(contextValues: ContextValue) => (
          <ComposedComponent {...props} {...contextValues} />
        )}
      </Consumer>
    );
  };
};
