// @flow
import React from 'react';

import Modal from 'components/Modal';
import { addPossessiveToNoun, capitalizeString } from 'helpers';

type RemnovePartnerModalProps = {|
  eventName: string,
  isOpen: boolean,
  partnerName: string,
  closeModal: () => void,
  confirm: () => Promise<void>,
|};

const ConfirmPartnerRemoveModal = (props: RemnovePartnerModalProps) => {
  const { eventName, isOpen, partnerName, closeModal, confirm } = props;
  const capitalizedPartnerName = capitalizeString(partnerName);

  return (
    <Modal
      isOpen={isOpen}
      onClose={closeModal}
      title="Remove Me as Partner"
      className="confirm-partner-remove-modal full-screen-mobile"
    >
      <>
        <div className="prompt">
          <p>{`Are you sure you want to remove yourself as ${addPossessiveToNoun(
            capitalizedPartnerName,
          )} Team Roping partner for ${eventName}?`}</p>
          <p>{`This will leave ${capitalizedPartnerName} without a partner for this event.`}</p>
        </div>
        <div className="footer">
          <button className="btn-main" onClick={confirm}>
            CONFIRM REMOVAL
          </button>
          <button className="cancel-btn" onClick={closeModal}>
            Cancel
          </button>
        </div>
      </>
    </Modal>
  );
};

export default ConfirmPartnerRemoveModal;
