import React, { PureComponent } from 'react';
import { change } from 'redux-form';
import PropTypes from 'prop-types';
import moment from 'moment';

import Layout from 'components/Layout';
import EventsList from 'components/Event/EventsList';
import EventsSearch from 'components/Event/EventsSearch';
import AddEventForm from 'components/Forms/AddEventForm';
import { LEADERBOARD, NOMINATE } from 'constants/routes';
import SelectFilter from 'components/Event/SelectFilter';
import {
  selectEvent,
  eventActionSuccess,
  getEventSearchResults,
} from 'actions/event';
import { isUserYouth } from 'helpers';
import withToJS from 'enhancers/withToJS';
import { EVENT_DIVISIONS, EVENT_DIVISIONS_TYPE } from 'constants/event';
import { isYouthEvent } from 'helpers/isYouthEvent';
import { isOpenEvent } from 'helpers/isOpenEvent';
import { isOpenYouthEvent } from 'helpers/isOpenYouthEvent';
import Filters from './Filters';
import { hasFeatureFlag } from 'utils/flagsmith';
import CloseIcon from '@material-ui/icons/Close';
import TuneIcon from 'assets/img/icons/filter.svg';

const filtersV2 = hasFeatureFlag('vrq-filters-v2');

const {
  YOUTH_EVENT_TYPE,
  OPEN_EVENT_TYPE,
  OPEN_YOUTH_EVENT_TYPE,
} = EVENT_DIVISIONS_TYPE;
const { DIVISION_YOUTH_2, DIVISION_3 } = EVENT_DIVISIONS;

let eventTypes = [
  { value: YOUTH_EVENT_TYPE, label: 'youth' },
  { value: OPEN_EVENT_TYPE, label: 'open' },
  { value: OPEN_YOUTH_EVENT_TYPE, label: 'open/youth' },
];

export class NominateSearchBase extends PureComponent {
  static propTypes = {
    dispatch: PropTypes.func.isRequired, // from react-redux connect
    history: PropTypes.object.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    resetNomination: PropTypes.func.isRequired,
    errorMessage: PropTypes.string,
    loading: PropTypes.bool.isRequired,
    loadingAuth: PropTypes.bool.isRequired,
    events: PropTypes.array.isRequired,
    settings: PropTypes.object.isRequired,
    user: PropTypes.object.isRequired,
    fetchUser: PropTypes.func,
    clearGiftCardBalances: PropTypes.func,
    userToken: PropTypes.string,
  };

  static defaultProps = {
    errorMessage: '',
  };

  constructor(props) {
    super(props);
    this.state = {
      searchTerm: '',
      showAddEventForm: false,
      showFilters: false,
      eventType: OPEN_EVENT_TYPE,
      eventsList: [],
      filters: {
        StartDate: '',
        EndDate: '',
        Discipline: '',
        Rodeo: 0,
        CompetitionLevel: '',
        Alliance: [],
        Division: [],
        Location: '',
      },
    };
  }

  async UNSAFE_componentWillMount() {
    const { searchTerm, filters } = this.state;
    const { settings, history } = this.props;
    const nominationsDisabled = settings.Value === false;
    if (nominationsDisabled) {
      history.push(LEADERBOARD);
    }
    this.loadEvents({ searchTerm, ...filters });
  }

  componentDidMount() {
    const {
      userToken,
      resetNomination,
      user,
      clearGiftCardBalances,
    } = this.props;
    const { youthAthletes } = user;
    if (youthAthletes.length > 0) {
      eventTypes = [
        { value: YOUTH_EVENT_TYPE, label: 'youth' },
        { value: OPEN_EVENT_TYPE, label: 'open' },
        { value: OPEN_YOUTH_EVENT_TYPE, label: 'open/youth' },
      ];
    }
    clearGiftCardBalances();
    this.props.fetchUser(userToken);
    resetNomination();
  }

  componentWillUnmount() {
    if (filtersV2) {
      document.body.style.overflowY = 'auto';
    }
  }

  handleFiltersSubmit = (values) => {
    this.setState({ filters: values });
    this.loadEvents({ searchTerm: this.state.searchTerm, ...values });
  };

  onChangeEventSearch = (e) => {
    e.preventDefault();
    const searchTerm = e.target.value;
    this.setState({ searchTerm });
    this.loadEvents({ searchTerm, ...this.state.filters });
  };

  handleShowFilters = () => {
    this.setState((prev) => ({
      showFilters: !prev.showFilters,
    }));
  };

  onSubmit = async (values) => {
    // Transform to UTC. Attention that this will be relative to local timezone of creator.
    // If I want to say that an event must end by April 17th 23:59 (GMT -03:00),
    // I must save April 18th -02:59 UTC on database.
    const { eventType } = this.state;
    const startDate = moment
      .utc(values.StartDate)
      .format('YYYY-MM-DD HH:mm:ss.SSS');
    const endDate = moment
      .utc(values.EndDate)
      .format('YYYY-MM-DD HH:mm:ss.SSS');

    const [EventDate, EventDateTo] =
      moment(startDate).isSameOrAfter(endDate, 'day') &&
      moment(startDate) > moment(endDate)
        ? [endDate, startDate]
        : [startDate, endDate];

    const event = {
      EventName: values.EventName,
      name: values.EventName,
      location: `${values.EventCity}, ${values.EventState}`,
      dateStart: EventDate,
      dateEnd: EventDateTo,
      firstPerformance: EventDateTo,
      firstPerformanceRiding: EventDateTo,
      EventRank: eventType === YOUTH_EVENT_TYPE ? DIVISION_YOUTH_2 : DIVISION_3,
      rank: eventType === YOUTH_EVENT_TYPE ? DIVISION_YOUTH_2 : DIVISION_3,
    };

    const { dispatch, history } = this.props;
    if (event.id) {
      await dispatch(selectEvent(event.id));
    } else {
      // Handles user created events
      await dispatch(eventActionSuccess(event));
    }
    history.push(NOMINATE);
  };

  async loadEvents(values) {
    const { dispatch } = this.props;
    const searchType = 'nomination';
    await dispatch(getEventSearchResults(values, searchType));
    setTimeout(() => {
      const { eventType } = this.state;
      const eventsList = this.getEventsList(eventType);
      this.setState({
        eventsList,
      });
    });
  }

  showCreateEventForm = () => {
    const { dispatch } = this.props;
    const { searchTerm: eventName } = this.state;

    dispatch(change('add-event', 'EventName', eventName));

    this.setState({ showAddEventForm: true });
  };

  onChangeEventType = ({ value }) => {
    const eventsList = this.getEventsList(value);
    this.setState({ eventType: value, eventsList });
  };

  getEventsListNewFilters = () => {
    const { events } = this.props;
    const { filters } = this.state;
    if (!filters.CompetitionLevel) {
      return events;
    } else if (filters.CompetitionLevel === YOUTH_EVENT_TYPE) {
      return events.filter(
        (event) =>
          isYouthEvent(event.EventRank) ||
          (isOpenYouthEvent(event.EventRank) &&
            (!event.hasYouthMixedClass && event.hasOpenMixedClass)),
      );
    } else if (filters.CompetitionLevel === OPEN_YOUTH_EVENT_TYPE) {
      return events.filter(
        (event) =>
          isOpenYouthEvent(event.EventRank) &&
          event.hasYouthMixedClass === event.hasOpenMixedClass,
      );
    } else {
      return events.filter(
        (event) =>
          isOpenEvent(event.EventRank) ||
          (isOpenYouthEvent(event.EventRank) &&
            (event.hasYouthMixedClass && !event.hasOpenMixedClass)),
      );
    }
  };

  getEventsList = (eventType) => {
    const { events, user } = this.props;
    if (filtersV2) {
      return this.getEventsListNewFilters();
    }
    const { youthAthletes, DOB } = user;
    if (youthAthletes.length > 0 || isUserYouth(DOB)) {
      if (eventType === YOUTH_EVENT_TYPE) {
        return events.filter(
          (event) =>
            isYouthEvent(event.EventRank) ||
            (isOpenYouthEvent(event.EventRank) &&
              (!event.hasYouthMixedClass && event.hasOpenMixedClass)),
        );
      } else if (eventType === OPEN_YOUTH_EVENT_TYPE) {
        return events.filter(
          (event) =>
            isOpenYouthEvent(event.EventRank) &&
            event.hasYouthMixedClass === event.hasOpenMixedClass,
        );
      } else {
        return events.filter(
          (event) =>
            isOpenEvent(event.EventRank) ||
            (isOpenYouthEvent(event.EventRank) &&
              (event.hasYouthMixedClass && !event.hasOpenMixedClass)),
        );
      }
    }

    return events.filter(
      (event) =>
        isOpenEvent(event.EventRank) || isOpenYouthEvent(event.EventRank),
    );
  };

  onSelectEvent = async (event) => {
    await this.props.dispatch(selectEvent(event.id));
    this.props.history.push(NOMINATE);
  };

  renderOption = (option) => {
    return (
      <div
        className={
          option.value === OPEN_YOUTH_EVENT_TYPE ? 'option-margin-bottom' : ''
        }
      >
        {option.label} <br />
        {option.value === OPEN_YOUTH_EVENT_TYPE ? (
          <small className="option-description">
            Events with Open and Youth classes within one event
          </small>
        ) : (
          ''
        )}
      </div>
    );
  };

  render() {
    const {
      loading,
      loadingAuth,
      handleSubmit,
      errorMessage,
      user,
    } = this.props;

    const DOB = user.DOB;
    const { showAddEventForm, eventsList } = this.state;
    const youthAthletes = user.youthAthletes;

    const renderSearch = () => {
      if (!filtersV2) {
        return <EventsSearch onChange={this.onChangeEventSearch} />;
      }

      return (
        <div className="grid-x">
          <div className="search-and-filters large-6 large-offset-3 small-12">
            <div className="search-container">
              <EventsSearch onChange={this.onChangeEventSearch} />
            </div>
            <div
              className="filter-icon-container"
              onClick={this.handleShowFilters}
            >
              {this.state.showFilters ? (
                <CloseIcon className="filter-icon" />
              ) : (
                <img src={TuneIcon} className="filter-icon" alt="filters" />
              )}
            </div>
          </div>
        </div>
      );
    };

    return (
      <Layout className="bg-events" loading={loading || loadingAuth}>
        <div className="grid-container">
          <div className="grid-x">
            <div className="small-12">
              <h2 className="section-title">Nominate</h2>
            </div>
            <div
              className={`small-12 nominations-event-results-container ${
                !filtersV2 ? 'large-6 large-offset-3' : ''
              }`}
            >
              {!showAddEventForm ? (
                <div>
                  {(youthAthletes.length > 0 || isUserYouth(DOB)) &&
                    !filtersV2 && (
                      <SelectFilter
                        placeholder="Choose event type"
                        label="Event Type"
                        value={this.state.eventType}
                        options={eventTypes}
                        onChange={this.onChangeEventType}
                        optionRenderer={this.renderOption}
                      />
                    )}
                  {renderSearch()}
                  <Filters
                    isYouth={youthAthletes.length > 0 || isUserYouth(DOB)}
                    showFilters={this.state.showFilters}
                    onSubmit={this.handleFiltersSubmit}
                  />
                  <EventsList
                    events={eventsList}
                    loading={loading}
                    showCreateEventForm={this.showCreateEventForm}
                    onSelectEvent={this.onSelectEvent}
                  />
                </div>
              ) : (
                <AddEventForm
                  loading={loading}
                  onSubmit={handleSubmit(this.onSubmit)}
                  errorMessage={errorMessage}
                />
              )}
            </div>
          </div>
        </div>
      </Layout>
    );
  }
}

export const NominateSearch = withToJS(NominateSearchBase);
