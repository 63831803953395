import React from 'react';
import { reduxForm } from 'redux-form';
import { compose } from 'redux';
import { FILTER_FORM_NAME } from './constants';
import { useSelector } from 'react-redux';
import get from 'lodash/get';

const FiltersResults = ({ onClearAll, fieldsFilterResults }) => {
  const formValues = useSelector((state) =>
    get(state, `form.${FILTER_FORM_NAME}.values`, {}),
  );

  const renderFilterResults = () => {
    const numberOfFilters = Object.keys(formValues).length;
    return Object.keys(formValues).map((key, index) => {
      const field = fieldsFilterResults[key];
      return (
        <div className="filter-result" key={key}>
          <span className="filter-result-title">{`${field.label}:`}</span>
          <div>
            <span className="filter-result-value">
              {field.getStringResult(formValues[key])}
            </span>
            {index !== numberOfFilters - 1 && ','}
          </div>
        </div>
      );
    });
  };
  return (
    <div className="filters-results-container">
      <div className="filters-header">
        <span className="title">Applied Filter</span>
        <div />
        <button className="back-button" onClick={onClearAll}>
          Clear all
        </button>
      </div>
      <div className="filter-results">{renderFilterResults()}</div>
    </div>
  );
};

export default compose(
  reduxForm({
    form: FILTER_FORM_NAME,
  }),
)(FiltersResults);
