// @flow
import React, { createContext, useCallback } from 'react';
import type { AbstractComponent } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import withToJS from 'enhancers/withToJS';
import type { ContextValue, SelectedSegmentsProps } from './type';
import { useSegments } from './useSegments';

export const SegmentsContext = createContext<ContextValue>({
  availableSegments: [],
  selectedSegments: [],
  segments: [],
  toggleSelect: async () => {},
  hasError: () => false,
  resetSegments: () => undefined,
  setSelectedSegments: () => undefined,
});

const { Provider, Consumer } = SegmentsContext;

const mapStateToProps = (state) => ({
  errorMessage: state.entryChargeRequest.get('errorMessage'),
});

const SelectedSegmentsProviderBase = (props: SelectedSegmentsProps) => {
  const {
    availableSegments,
    selectedSegments,
    toggleSelect,
    segments,
    resetSegments,
    setSelectedSegments,
  } = useSegments();

  const hasError = useCallback(() => {
    const { errorMessage } = props;
    return !!errorMessage || selectedSegments.length === 0;
  }, [props.errorMessage, selectedSegments.length]);

  return (
    <Provider
      value={{
        availableSegments,
        selectedSegments,
        segments,
        toggleSelect,
        hasError,
        resetSegments,
        setSelectedSegments,
      }}
    >
      {props.children}
    </Provider>
  );
};

export const SelectedSegmentsProvider = compose(
  // $FlowFixMe
  connect(mapStateToProps),
  withToJS,
)(SelectedSegmentsProviderBase);

export const withSelectedSegments = (
  ComposedComponent: AbstractComponent<any>,
) => {
  return function WithSelectedSegments(props: any) {
    return (
      <Consumer>
        {(contextValues: ContextValue) => (
          <ComposedComponent {...props} {...contextValues} />
        )}
      </Consumer>
    );
  };
};
