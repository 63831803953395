import React from 'react';
import PropTypes from 'prop-types';

const RadioField = (props) => {
  const { selection, label, id, onSelect, input, className, isChecked } = props;
  const { name } = input;

  return (
    <div className={className} key={selection}>
      <input
        id={`${id}${selection}`}
        type="radio"
        name={name}
        value={selection}
        onChange={onSelect}
        checked={isChecked}
      />
      <label htmlFor={`${id}${selection}`}>{label}</label>
    </div>
  );
};

RadioField.propTypes = {
  selection: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  className: PropTypes.string,
  input: PropTypes.object.isRequired,
  onSelect: PropTypes.func.isRequired,
  isChecked: PropTypes.bool.isRequired,
};

RadioField.defaultProps = {
  className: '',
  name: '',
  selection: '',
  label: '',
  error: '',
};

export default RadioField;
