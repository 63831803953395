export const getSegments = (state) =>
  state.segments
    .get('list')
    .map((segment) => ({
      label: segment.Name,
      NominatableByYouth: segment.NominatableByYouth,
      value: segment.SegmentUID,
      ShortName: segment.ShortName,
      StartDate: segment.StartDate,
      EndDate: segment.EndDate,
      HasOpen: segment.HasOpen,
      HasYouth: segment.HasYouth,
      WomensOnly: segment.WomensOnly,
    }))
    .toJS();
