// @flow
import React from 'react';
import Event from '../Event';
import withToJS from 'enhancers/withToJS';
import NoEventsResults from './NoEventsResults';
import { hasFeatureFlag } from 'utils/flagsmith';

type EventListProps = {|
  events: Object[],
  showCreateEventForm: () => void,
  loading: boolean,
  onSelectEvent: (Object) => void,
  clearFilters: ?() => void,
|};

const filtersV2 = hasFeatureFlag('vrq-filters-v2');

const EventsList = (props: EventListProps) => {
  const { events, showCreateEventForm, loading, onSelectEvent } = props;
  return (
    <div id="event-results">
      {events.length > 0 || loading ? (
        <div className={filtersV2 ? 'new-list grid-x' : ''}>
          <div className={filtersV2 ? 'small-12 large-6 large-offset-3' : ''}>
            {events.map((event) => {
              return (
                <Event
                  event={event}
                  key={event.id}
                  onSelectEvent={() => onSelectEvent(event)}
                />
              );
            })}
          </div>
        </div>
      ) : (
        <NoEventsResults
          showCreateEventForm={showCreateEventForm}
          clearFilters={props.clearFilters}
        />
      )}
    </div>
  );
};

export default withToJS(EventsList);
