import React from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import CountIndicator from 'components/CountIndicator';

const MenuItem = (props) => {
  const { itemUrl, itemImgSrc, itemClass, itemTitle, itemCount } = props;
  return (
    <li>
      <NavLink to={itemUrl} exact activeClassName="active">
        <div className="menu-item">
          <img alt="" src={itemImgSrc} className={itemClass} />
          {itemTitle}
        </div>
        <CountIndicator
          count={itemCount}
          active={false}
          className={'nav-link-counter'}
        />
      </NavLink>
    </li>
  );
};

MenuItem.propTypes = {
  itemUrl: PropTypes.string,
  itemImgSrc: PropTypes.string,
  itemClass: PropTypes.string,
  itemTitle: PropTypes.string,
  itemCount: PropTypes.number,
};

MenuItem.defaultProps = {
  itemUrl: '',
  itemImgSrc: '',
  itemClass: '',
  itemTitle: '',
  itemCount: 0,
};

export default MenuItem;
