// @flow
import React from 'react';

import { getNestedProperty, getTheme, convertToDateWithTz } from 'helpers';

import { DisciplineEntered } from 'components/DisciplineEntered';
import { TotalLabel } from 'components/TotalLabel';
import BuddyEntryDate from './BuddyEntryDate';
import PriceFormat from 'components/PriceFormat';
import { DisplayMembershipFees } from 'components/DisplayMembershipFees';
import type { DisciplineType } from 'context/SelectedDisciplines/type';

import type { EventType } from 'models/Event';
import type { BuddyGroupType } from 'models/Buddy';

import { WCRA } from 'constants/theme';

type BuddyManager = {
  ERAUID: string,
  FullName: string,
  associationMembers: Object,
};

type EntryAddBuddyCardPropsType = {
  handleAddBuddy: Function,
  openInvitePartnerModal: Function,
  selectedDisciplines: DisciplineType[],
  isBuddyManager: boolean,
  buddyManager: BuddyManager,
  loading: boolean,
  event: EventType,
  buddyGroup: BuddyGroupType,
  charge: Object,
};

export const EntryAddBuddyCard = (props: EntryAddBuddyCardPropsType) => {
  const {
    handleAddBuddy,
    buddyManager,
    isBuddyManager,
    openInvitePartnerModal,
    selectedDisciplines,
    event,
    buddyGroup,
    loading,
    charge,
  } = props;

  const theme = getTheme();

  const sortedBuddyGroup = buddyGroup.reduce((acc, buddy) => {
    buddyManager && buddyManager.ERAUID === buddy.ERAUID
      ? acc.unshift(buddy) // Put the buddy manager first in the list
      : acc.push(buddy);
    return acc;
  }, []);

  const { MaxNumberOfBuddies } = event;
  let buddyGroupFull = false;
  let countPerDiscipline = 0;

  const getSelectedDisciplines = () => {
    const { DisciplineFees } = event;
    // $FlowIgnore
    return DisciplineFees.filter((disc) => disc.IsDisciplineInEvent === 1);
  };

  // Added buddy group per discipline by 4
  if (getSelectedDisciplines().length === 1) {
    buddyGroupFull = sortedBuddyGroup.length >= MaxNumberOfBuddies;
  }
  if (getSelectedDisciplines().length > 1) {
    if (MaxNumberOfBuddies <= 4) {
      buddyGroupFull = sortedBuddyGroup.length >= MaxNumberOfBuddies;
    }

    if (MaxNumberOfBuddies > 4) {
      if (selectedDisciplines.length != 0) {
        buddyGroup.forEach((discipline) => {
          if (String(discipline.CompType) == selectedDisciplines[0].value) {
            countPerDiscipline++;
            if (countPerDiscipline === 4) {
              buddyGroupFull = true;
            } else {
              if (sortedBuddyGroup.length < MaxNumberOfBuddies) {
                buddyGroupFull = false;
              } else {
                buddyGroupFull = true;
              }
            }
          }
          if (sortedBuddyGroup.length == MaxNumberOfBuddies) {
            buddyGroupFull = true;
          }
        });
      } else {
        buddyGroupFull = true;
      }
    }
  }

  const dateRegistrationEnd = convertToDateWithTz(
    event.dateRegistrationEnd || '',
    event.TimezoneNameIana || '',
  );
  const closedForEntry = dateRegistrationEnd.isBefore(Date.now());

  const renderAddBuddyButton = () => {
    return !loading && isBuddyManager ? (
      <div className="text-center padtop-3">
        {buddyGroupFull ? <p>{"You've reached your buddy limit."}</p> : null}
        {closedForEntry ? <p>{'Entry is closed for this event.'}</p> : null}
        <button
          disabled={buddyGroupFull || closedForEntry}
          onClick={handleAddBuddy}
          className="btn-main h-auto link"
        >
          {buddyGroup.length > 0 ? 'Add Another buddy' : 'Add buddy'}
        </button>
      </div>
    ) : null;
  };

  const renderBuddyConfirmationText = () => {
    const buddyManagerText = isBuddyManager
      ? `A buddy group allows you and your buddies to be placed in the same performances. You can have up to ${MaxNumberOfBuddies} buddies.`
      : `You've been entered into a buddy group by the buddy manager. Only the buddy manager can add new buddies to the entry. Buddy groups can have a max of ${MaxNumberOfBuddies} buddies.`;

    return <div className="confirmation-text">{buddyManagerText}</div>;
  };

  const renderBuddyEntries = (buddy) => {
    const buddyEntries = getNestedProperty('entries', buddy, []);
    return buddyEntries.map((entry, index) => {
      const TeammateERAUID = getNestedProperty('TeammateERAUID', entry);
      const TeammateName = getNestedProperty('TeammateName', entry);
      const TeammateUserId = getNestedProperty('TeammateUserId', entry);

      const entryWithPartnerData = {
        ...entry,
        isPartnerInSystem: Boolean(TeammateUserId),
        partnerName: TeammateName,
        partnerUserId: TeammateERAUID,
      };

      return (
        <DisciplineEntered
          key={entry.EPUID}
          index={index}
          event={event}
          entry={entryWithPartnerData}
          openInvitePartnerModal={openInvitePartnerModal}
          isBuddyManager={isBuddyManager}
        />
      );
    });
  };

  const renderBuddies = () => {
    return sortedBuddyGroup.map((buddy, index) => {
      if (!buddy || buddy.ERAUID === getNestedProperty('ERAUID', charge)) {
        return null;
      }

      const {
        ERAUID,
        FullName,
        createdAt,
        AssociationFees,
        PayLater,
        TotalAmountPaid,
        associationMembers,
      } = buddy;

      return (
        <div key={ERAUID} className="buddy">
          <div className="buddy-name">{FullName}</div>
          {associationMembers &&
            associationMembers.map((amd) => (
              <div
                key={`association_sn_${amd.Name}`}
                className="confirmation-athlete-name padtop-1"
              >
                {amd.Name} #: {amd.MemberId}
              </div>
            ))}
          {getNestedProperty('ERAUID', buddyManager) === ERAUID ? (
            <div className="buddy-manager padtop-1">Buddy Manager</div>
          ) : null}
          <BuddyEntryDate entryDate={createdAt} />
          {renderBuddyEntries(buddy)}
          <div>
            <DisplayMembershipFees associationTypeFees={AssociationFees} />
          </div>
          {isBuddyManager ? (
            <div className="confirmation-text-container">
              <div className="total-container">
                <TotalLabel payLater={PayLater} />
                <div className="small-6 price-total text-right">
                  <PriceFormat price={TotalAmountPaid} />
                </div>
              </div>
            </div>
          ) : null}
          {index !== buddyGroup.length - 1 && <hr />}
        </div>
      );
    });
  };

  return (
    <div className="theme-content-container card-content stacked-content-container entry-add-buddy-card">
      {theme === WCRA ? <hr /> : null}
      <div className="confirmation-text-container marbot-2 padtop-1">
        <div className="buddy-group-text">Buddy group</div>
        {renderBuddyConfirmationText()}
      </div>
      {renderBuddies()}
      {renderAddBuddyButton()}
    </div>
  );
};
