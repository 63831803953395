// @flow
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';

import { calculateEntryCharge } from 'actions/entryChargeRequest';
import { withSelectedDisciplines } from 'context/SelectedDisciplines';
import { withBuddyGroups } from 'context/BuddyGroups';
import { withSelectedAthletes } from 'context/SelectedAthletes';
import withToJS from 'enhancers/withToJS';
import { getNestedProperty } from 'helpers';
import type { EventType } from 'models/Event';
import type {
  EntryChargeType,
  EntryChargeDisciplineType,
  NominationPayloadType,
} from 'models/EntryChargeRequest';
import type { AbstractComponent } from 'react';
import type { DisciplineType } from 'context/SelectedDisciplines/type';

const mapStateToProps = (state) => ({
  event: state.event.get('data'),
  charge: state.entryChargeRequest.get('data'),
  associationMembership: state.associationMembership.get('data'),
});

const mapDispatchToProps = (dispatch) => ({
  calculateEntryCharge: (data) => dispatch(calculateEntryCharge(data)),
});

type WithCalculateEntryChargeProps = {|
  associationMembership: Object,
  event: EventType,
  charge: EntryChargeType,
  selectedDisciplines: DisciplineType[],
  calculateEntryCharge: (Object) => Promise<void>,
  ERAUID: string,
  isBuddyEntry: boolean,
  athleteFullName: string,
  getEntryDisciplines: (
    DisciplineType[],
    ?boolean,
  ) => EntryChargeDisciplineType[],
  getNominationDisciplines: (
    DisciplineType[],
    ?string,
    AllowClasses: ?boolean,
  ) => NominationPayloadType[],
  activeDiscipline: ?DisciplineType,
  isEditing: boolean,
|};

export type CalculateEntryChargeArgs = {|
  ERAUID: string,
  athleteFullName: string,
|};

const withCalculateEntryCharge = (ComposedComponent: AbstractComponent<any>) =>
  class WithCalculateEntryCharge extends PureComponent<WithCalculateEntryChargeProps> {
    buildDisciplinesArray = (selectedDisciplines, activeDiscipline) => {
      const disciplinesArray = selectedDisciplines.filter((discipline) => {
        return (
          (discipline.label != activeDiscipline.label ||
            discipline.ClassName != activeDiscipline.ClassName) &&
          discipline.athleteEntryCount !== activeDiscipline.athleteEntryCount
        );
      });
      disciplinesArray.push(activeDiscipline);
      return disciplinesArray;
    };
    getDisciplines = (AllowClasses: ?boolean) => {
      const {
        getEntryDisciplines,
        selectedDisciplines,
        activeDiscipline,
        isEditing,
      } = this.props;
      let disciplinesArray = [];

      if (
        activeDiscipline &&
        Object.keys(activeDiscipline).length > 0 &&
        selectedDisciplines.length > 0 &&
        !isEditing
      ) {
        disciplinesArray = this.buildDisciplinesArray(
          selectedDisciplines,
          activeDiscipline,
        );
      } else {
        disciplinesArray =
          activeDiscipline &&
          Object.keys(activeDiscipline).length > 0 &&
          !isEditing
            ? [...selectedDisciplines, activeDiscipline]
            : selectedDisciplines;
      }
      return getEntryDisciplines(disciplinesArray, AllowClasses);
    };

    getNominations = () => {
      const {
        event,
        getNominationDisciplines,
        selectedDisciplines,
      } = this.props;
      const { AllowClasses } = event;
      const nominationsArray = selectedDisciplines.length
        ? selectedDisciplines
        : [];
      const performancesArray = nominationsArray.find((nomination) =>
        getNestedProperty('performances.length', nomination),
      );
      if (performancesArray) {
        const { performances } = performancesArray;
        const performanceDate =
          performances.length > 0
            ? performances[0].PerformanceDate
            : event && event.dateStart;
        return getNominationDisciplines(
          nominationsArray,
          performanceDate,
          AllowClasses,
        );
      }
      return getNominationDisciplines(
        nominationsArray,
        event && event.dateStart,
        AllowClasses,
      );
    };

    calculateEntryCharge = async () => {
      const {
        associationMembership,
        athleteFullName,
        calculateEntryCharge,
        charge,
        ERAUID,
        event,
        isBuddyEntry,
      } = this.props;

      const { athleteMembershipData } = associationMembership;
      const AssociationTypeFeeUIDs = athleteMembershipData.map(
        (amd) => amd.typeFeeUID,
      );
      const { AllowClasses } = event;
      const Disciplines = this.getDisciplines(AllowClasses);

      const nominations = this.getNominations();

      const { EntryChargeRequestUID } = charge;

      const calculateEntryChargeData = {
        EntryChargeRequestUID,
        ERAUID,
        isBuddyEntry,
        EventUID: event.id,
        Disciplines,
        athleteFullName,
        nominations,
        AssociationTypeFeeUIDs,
      };
      return calculateEntryCharge(calculateEntryChargeData);
    };

    render() {
      return (
        <ComposedComponent
          {...this.props}
          calculateEntryCharge={this.calculateEntryCharge}
        />
      );
    }
  };

// $FlowFixMe
export default compose(
  // $FlowFixMe
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
  withToJS,
  withSelectedAthletes,
  withSelectedDisciplines,
  withBuddyGroups,
  withCalculateEntryCharge,
);
