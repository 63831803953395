import React, { useState, useEffect } from 'react';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';

const NumberOfEntries = (props) => {
  const {
    activeDiscipline,
    updateActiveDiscipline,
    entryIsHorseDiscipline,
    setHorseName,
  } = props;

  const { ClassName, label } = activeDiscipline;
  const {
    athleteEntryCount,
    newEntryCount,
    maxNumberOfEntries,
  } = activeDiscipline;

  const [entryCount, setEntryCount] = useState(newEntryCount);
  const leftDisabled = entryCount <= 1;
  const rightDisabled =
    maxNumberOfEntries <= 1 ||
    maxNumberOfEntries === athleteEntryCount + newEntryCount;

  useEffect(() => {
    updateActiveDiscipline(entryCount);

    if (entryIsHorseDiscipline) {
      setHorseName({
        value: '',
        newEntryCount: entryCount,
        index: entryCount - 1,
        entryCountChanged: true,
      });
    }
  }, [entryCount]);

  const onClickLeftArrow = () => {
    setEntryCount(entryCount - 1);
  };

  const onClickRightArrow = () => {
    setEntryCount(entryCount + 1);
  };

  const entryNext = `You will be entered into ${
    ClassName ? ClassName : label
  } ${entryCount} ${newEntryCount === 1 ? 'time' : 'times'}.`;

  return (
    <>
      <div className="entry-discipline-numberOfEntries-container">
        <div className="entry-discipline-numberOfEntries-title">
          # OF ENTRIES:
        </div>
        <div className="numberOfEntries-arrow-container">
          <button
            className="numberOfEntries-arrow"
            disabled={leftDisabled}
            onClick={onClickLeftArrow}
          >
            <ArrowBackIosIcon
              className={leftDisabled ? 'disabled' : ''}
              fontSize="small"
            />
          </button>
          <span className="numberOfEntries">{entryCount}</span>
          <button
            className="numberOfEntries-arrow"
            disabled={rightDisabled}
            onClick={onClickRightArrow}
          >
            <ArrowForwardIosIcon
              className={rightDisabled ? 'disabled' : ''}
              fontSize="small"
            />
          </button>
        </div>
      </div>
      <div>{entryNext}</div>
    </>
  );
};

export default NumberOfEntries;
