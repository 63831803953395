import gql from 'graphql-tag';

export const ATHLETE_SEARCH = gql`
  query ATHLETE_SEARCH(
    $name: String!
    $eventType: EventCategoryType!
    $associations: String!
  ) {
    searchTeamPartner(
      name: $name
      eventType: $eventType
      associations: $associations
    ) {
      id
      fullName
      fullNameWithNickname
      city
      state
      MemberId
    }
  }
`;
