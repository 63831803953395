import React from 'react';
import { Link } from 'react-router-dom';

import { LOGIN } from 'constants/routes';
import { useAuth0 } from '@auth0/auth0-react';
import { hasFeatureFlag } from '../../utils/flagsmith';

const enableAuth0 = hasFeatureFlag('auth0');

const SignIn = () => {
  const { loginWithRedirect } = useAuth0();
  const openAuth0 = () => {
    return loginWithRedirect();
  };
  return !enableAuth0 ? (
    <Link to={LOGIN} className="link-gold">
      I want to sign in
    </Link>
  ) : (
    <Link to="/#" onClick={openAuth0} className="link-gold">
      I want to sign in
    </Link>
  );
};

export default SignIn;
