import { isEmailValid, isPhoneValid } from 'actions/registration';

export const validate = (values) => {
  const errors = {};
  if (!values.email) {
    errors.email = 'Please enter an email address.';
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
    errors.email = 'Invalid email address.';
  }

  if (!values.password) {
    errors.password = 'Please enter a password.';
  } else if (!/^\S*$/.test(values.password)) {
    errors.password = 'Spaces are not allowed.';
  } else if (!/.{6}/i.test(values.password)) {
    errors.password = 'Password must be at least six characters.';
  }

  if (!values.firstName) {
    errors.firstName = 'Please enter a first name.';
  }

  if (!values.lastName) {
    errors.lastName = 'Please enter a last name.';
  }

  if (!values.passwordConfirmation) {
    errors.passwordConfirmation = 'Please enter a password confirmation.';
  }

  if (values.password !== values.passwordConfirmation) {
    errors.passwordConfirmation = 'Passwords do not match';
  }

  if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.Email)) {
    errors.Email = 'This is not a Valid Email';
  }

  if (!values.RequestEmail) {
    if (
      !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.RequestEmail)
    ) {
      errors.RequestEmail = 'This is not a Valid Email';
    }
  }

  if (!values.RequestPhone) {
    if (!/^\d{3}-\d{3}-\d{4}$/i.test(values.RequestPhone)) {
      errors.RequestPhone = 'This is not a Valid Phone Number';
    }
  }

  return errors;
};

export const required = (value) => {
  if (typeof value === 'string') {
    return value.trim().length ? undefined : 'This field is required';
  }
  return value ? undefined : 'This field is required';
};

export const name = (value) => {
  if (!value) {
    return 'This field is required';
  } else if (!/^[a-zA-Z\s]*$/.test(value)) {
    return 'Letters only';
  }
  return undefined;
};

export const number = (value) => {
  return value && isNaN(Number(value)) ? 'Must be a number' : undefined;
};

export const maxLength = (max) => (value) => {
  return value && value.length > max
    ? `Must be ${max} characters or less`
    : undefined;
};

export const minLength = (min) => (value) => {
  return value && value.length < min
    ? `Must be at least ${min} characters`
    : undefined;
};

export const maxValue = (max) => (value) => {
  return value && value > max ? `Cannot be more than ${max}` : undefined;
};

export const minValue = (min) => (value) => {
  return value && value < min ? `Cannot be < ${min}` : undefined;
};

export const minValue0 = minValue(0);

export const exactLength = (length) => (value) => {
  return value && value.length !== length
    ? `Must be exactly ${length} characters`
    : undefined;
};

export const exactLength2 = exactLength(2);
export const exactLength4 = exactLength(4);
export const exactLength5 = exactLength(5);
export const exactLength16 = exactLength(16);

export const email = (value) => {
  return value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)
    ? 'Invalid email address'
    : undefined;
};

export const emailValid = async (value) => {
  if (email(value) === undefined && required(value) === undefined) {
    return isEmailValid(value);
  }
  return false;
};

export const phoneNumber = (value) => {
  return value &&
    !/^(\+\d{1,3}\s)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/i.test(value)
    ? 'Invalid phone number'
    : undefined;
};

export const phoneNumberValid = async (value) => {
  if (required(value) === undefined) {
    return isPhoneValid(value);
  }
  return false;
};

export const password = (value) => {
  if (!value) {
    return 'Please enter a password.';
  } else if (!/^\S*$/.test(value)) {
    return 'Spaces are not allowed.';
  } else if (!/.{6}/i.test(value)) {
    return 'Password must be at least six characters.';
  }
  return undefined;
};

export const url = (value) => {
  return value &&
    !/^(http:\/\/)?(www+\.)?[a-zA-Z0-9_-]+\.([a-zA-Z‌]{2,})+$/g.test(value)
    ? 'Invalid url'
    : undefined;
};

export const currency = (value) => {
  return value && !/^-?[\d]*?(.\d{2})?$/.test(value)
    ? 'Invalid. Ex 10.99'
    : undefined;
};
export const cityTown = (value) => {
  return value && !/^[a-zA-Z]+([\s]*[a-zA-Z]*)*$/.test(value)
    ? 'Invalid city/town'
    : undefined;
};

export const hometown = (value) => {
  return value && !/^[a-zA-Z ,.'-]+$/.test(value)
    ? 'Invalid hometown'
    : undefined;
};

export const state = (value) => {
  return value && !/^[a-zA-Z]{2}$/g.test(value) ? 'Invalid state' : undefined;
};
export const match = (compareValue) => (value) => {
  return value && compareValue !== value ? 'Fields do not match' : undefined;
};

export const inlineCurrency = (value) => {
  return (value && /^-?[\d]*?(.\d{2})?$/.test(value)) || false;
};

export const inlineNumber = (value) => {
  return (value && /^[0-9]*$/.test(value)) || false;
};

export const signatureRequirement = (value) => {
  return value && !/^[a-zA-Z]+([\s]*[a-zA-Z]*)*$/.test(value)
    ? 'Invalid signature'
    : undefined;
};

export const postalCode = (postalCode, countryCode) => {
  switch (countryCode) {
    default:
    case 'US':
      return !/^([0-9]{5})(?:[-\s]*([0-9]{4}))?$/.test(postalCode)
        ? 'ZIP Code Invalid'
        : undefined;
    case 'CA':
      return !/^([A-Za-z][0-9][A-Za-z])\s*([0-9][A-Za-z][0-9])$/.test(
        postalCode,
      )
        ? 'Postal Code invalid'
        : undefined;
  }
};

export const zipCode = (value) => {
  if (!value) {
    return 'ZIP Code Invalid';
  }

  value = value.toString().trim();

  var us = new RegExp('^\\d{5}(-{0,1}\\d{4})?$');
  var ca;

  if (value.length === 3) {
    ca = new RegExp('^[ABCEGHJ-NPRSTVXY]{1}[0-9]{1}[ABCEGHJ-NPRSTV-Z]{1}$');
  } else {
    ca = new RegExp(
      '^[ABCEGHJ-NPRSTVXY]{1}[0-9]{1}[ABCEGHJ-NPRSTV-Z]{1}[ ]?[0-9]{1}[ABCEGHJ-NPRSTV-Z]{1}[0-9]{1}$',
    );
  }

  if (us.test(value.toString())) {
    return undefined;
  }

  if (ca.test(value.toString().replace(/\W+/g, ''))) {
    return undefined;
  }
  return 'ZIP Code Invalid';
};
