// @flow
import React from 'react';
import { Edit3 } from 'react-feather';
import { formatDateTimeToCST } from 'helpers';

import type { DisciplineType } from 'context/SelectedDisciplines/type';
import { getFormattedDollarAmount } from 'helpers';

type DisciplineCardDescriptionType = {
  discipline: DisciplineType,
  classNames: string,
  feeTotal: number,
  preferenceText: string,
  outText: string,
  prefComma: string,
  customFee: ?number,
  onClick: (DisciplineType) => Promise<any>,
  horseNames: ?Array<string>,
  preferencesAndNots: Array<Object>,
  partnersData: any,
  customFeeName: ?string,
  showPreferenceText: boolean,
};

const DisciplineCardDescriptionWithClasses = (
  props: DisciplineCardDescriptionType,
) => {
  const {
    discipline,
    classNames,
    feeTotal,
    preferenceText,
    outText,
    prefComma,
    customFeeName,
    customFee,
    onClick,
    horseNames,
    preferencesAndNots,
    partnersData,
    showPreferenceText,
  } = props;

  const {
    newEntryCount,
    segments,
    isPendingEntry,
    isPayingForPartner,
  } = discipline;

  return (
    <div
      className="selected-discipline-with-classes list-item"
      onClick={() => onClick(discipline)}
    >
      <div className="discipline-review-card">
        <div className="discipline-review-card-label">
          <div className="discipline-label">
            {discipline.Discipline}
            <span className="and-partner-fees">
              {discipline.isPayingForPartner && ' & partner entry fees'}
            </span>
            <div className="classnames">{classNames}</div>
          </div>
          <div className="fee-amount discipline-label">
            {getFormattedDollarAmount(feeTotal)}
          </div>
        </div>
        {partnersData.length
          ? partnersData.map((partner) => (
              <div key={partner.index} className="partner-label">
                {partner.label}: {partner.name || partner.id}
              </div>
            ))
          : ''}
        <div>
          <span className="entry-description">
            {horseNames && horseNames.length ? (
              <div className="horse-names">
                {horseNames.map((horseName, index) => (
                  <div
                    key={`${horseName}-${index + 1}`}
                    className="horse-label"
                  >
                    {`Horse${
                      horseNames && horseNames.length > 1
                        ? ` #${index + 1}: `
                        : ': '
                    }`}{' '}
                    {horseName}
                  </div>
                ))}
              </div>
            ) : null}
            {showPreferenceText && (preferenceText.length || outText.length) ? (
              <div>
                Entry:
                {preferenceText.length
                  ? ` ${preferenceText}${prefComma}${outText}`
                  : outText}
              </div>
            ) : (
              ''
            )}
            {preferencesAndNots.map((preferenceAndNot) => (
              <div key={preferenceAndNot.PerformanceUID}>
                {preferenceAndNot.label}:{' '}
                {formatDateTimeToCST(
                  preferenceAndNot.PerformanceDate,
                  preferenceAndNot.TimeZoneUID,
                )}
              </div>
            ))}
          </span>
          <div className="nominations">
            {segments && segments.length > 0
              ? segments.map((segment) => (
                  <span key={segment.value} className="nomination-description">
                    NOMINATION:{' '}
                    {`SEGMENT ${segment.ShortName} ${
                      segment.newSegmentCount > 1
                        ? `(X${segment.newSegmentCount})`
                        : ''
                    }`}
                  </span>
                ))
              : ''}
          </div>
          {customFeeName ? (
            <span className="nomination-description">
              {customFeeName}{' '}
              {newEntryCount >= 1
                ? ` (X${
                    isPayingForPartner ? newEntryCount * 2 : newEntryCount
                  })`
                : null}
              : $ {customFee}
            </span>
          ) : null}

          {isPendingEntry && isPendingEntry === true ? (
            ''
          ) : (
            <button>
              <div className="discipline-icons edit-btn-icon">
                <Edit3 />
                <span className="edit-btn-label">Edit with class</span>
              </div>
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default DisciplineCardDescriptionWithClasses;
