import React from 'react';

export const DisplayMembershipFees = (props) => {
  const { associationTypeFees } = props;
  if (!associationTypeFees.length) {
    return null;
  }

  const { purchaseMembershipFees, renewalFees, permitFees } =
    associationTypeFees.length > 0
      ? associationTypeFees.reduce(
          (acc, fee) => {
            if (fee.IsNonMember) {
              return acc;
            } else if (fee.IsPermitFee) {
              fee.name = `${fee.AssociationShortName} Permit`;
              acc.permitFees.push(fee);
            } else if (fee.IsRenewalFee) {
              fee.name = `${fee.MembershipType} Membership Renewal`;
              acc.renewalFees.push(fee);
            } else {
              if (fee.AdultOnly || fee.YouthOnly) {
                fee.name = `${fee.MembershipType}`;
              } else {
                fee.name = `${fee.MembershipType} Membership`;
              }
              acc.purchaseMembershipFees.push(fee);
            }
            return acc;
          },
          {
            purchaseMembershipFees: [],
            renewalFees: [],
            permitFees: [],
          },
        )
      : {
          purchaseMembershipFees: [],
          renewalFees: [],
          permitFees: [],
        };

  const membershipFees = [...purchaseMembershipFees, ...renewalFees];

  return (
    <div className="confirmation-text-container">
      {membershipFees.length > 0 ? (
        <div>
          <div className="confirmation-title disciplines-entered">
            MEMBERSHIP
          </div>
          {membershipFees.map((fee) => (
            <div
              key={fee.AssociationTypeFeeUID}
              className="confirmation-athlete-name"
            >
              {fee.name}
            </div>
          ))}
        </div>
      ) : null}
      {permitFees.length > 0 ? (
        <div>
          <div className="confirmation-title disciplines-entered">PERMIT</div>
          {permitFees.map((fee) => (
            <div
              key={fee.AssociationTypeFeeUID}
              className="confirmation-athlete-name"
            >
              {fee.name}
            </div>
          ))}
        </div>
      ) : null}
    </div>
  );
};
