import React from 'react';
import { getLengthOfNominations } from 'helpers';
import PriceFormat from 'components/PriceFormat';

const EntryFees = ({ charge, customFees }) => {
  const {
    associationTypeFees,
    IsPaid,
    entrySubtotal,
    totalPayment,
    totalTransactionFee,
    disciplineFees,
    baseFee,
    lateFee,
    subtotalEntryAndNomination,
  } = charge;
  const nominationsLength = getLengthOfNominations(disciplineFees);
  const getAssociationFeeTitle = (AssociationFees) => {
    return AssociationFees.map((associationTypeFee) => {
      let AssociationTypeFeeTitle;
      if (associationTypeFee.IsPermitFee) {
        AssociationTypeFeeTitle = 'Permit Fees';
      } else if (associationTypeFee.IsNonMember) {
        AssociationTypeFeeTitle = 'Non Member Fees';
      } else {
        AssociationTypeFeeTitle = 'Membership Fees';
      }
      return {
        ...associationTypeFee,
        AssociationTypeFeeTitle,
      };
    });
  };

  const associationFeesWithTitle = getAssociationFeeTitle(associationTypeFees);
  const parsedTotalTransactionFee = parseFloat(totalTransactionFee);
  const parsedBaseFee = parseFloat(baseFee);
  const parsedLateFee = parseFloat(lateFee);
  const transactionFee =
    parsedTotalTransactionFee - (parsedBaseFee + (parsedLateFee || 0));

  return (
    <>
      {!IsPaid && (
        <>
          <div className="nominate-totals">
            <div className="price-total-label">Summary</div>
            <div className="grid-x">
              <div className="small-7 price-label">{'Entry Fees'}</div>
              <div className="small-5 price" id="entry-fee">
                <PriceFormat price={entrySubtotal} />
              </div>
            </div>
            {customFees.map((fee) =>
              fee.name && fee.value ? (
                <div className="grid-x" key={fee.value}>
                  <div className="small-7 price-label">
                    {fee.name} (X{fee.count})
                  </div>
                  <div className="small-5 price">
                    <PriceFormat price={fee.value} />
                  </div>
                </div>
              ) : null,
            )}
            {associationFeesWithTitle.length > 0
              ? associationFeesWithTitle.map(
                  ({
                    AssociationTypeFeeTitle,
                    AssociationTypeFeeUID,
                    FeeAmount,
                  }) => {
                    return (
                      <div className="grid-x" key={AssociationTypeFeeUID}>
                        <div className="small-7 price-label">
                          {AssociationTypeFeeTitle}
                        </div>
                        <div className="small-5 price" id="membership-fees">
                          <PriceFormat price={FeeAmount} />
                        </div>
                      </div>
                    );
                  },
                )
              : null}
            {nominationsLength > 0 && (
              <div className="grid-x">
                <div className="small-7 price-label">
                  WCRA Nomination x {nominationsLength}
                </div>
                <div className="small-5 price" id="nomination-fee">
                  <PriceFormat
                    price={
                      parseFloat(subtotalEntryAndNomination) -
                      parseFloat(entrySubtotal)
                    }
                  />
                </div>
              </div>
            )}
            {lateFee && lateFee > 0 ? (
              <div className="grid-x">
                <div className="small-7 price-label">Late Fee</div>
                <div className="small-5 price" id="late-fee">
                  <PriceFormat price={lateFee} />
                </div>
              </div>
            ) : null}
            {baseFee > 0 && (
              <div className="grid-x">
                <div className="small-7 price-label">
                  One Time Office Charge
                </div>
                <div className="small-5 price" id="office-fee">
                  <PriceFormat price={baseFee} />
                </div>
              </div>
            )}
            <div className="grid-x">
              <div className="small-7 price-label">Transaction Fee</div>
              <div className="small-5 price" id="transaction-fee">
                <PriceFormat price={transactionFee} />
              </div>
            </div>

            <hr />

            <div className="grid-x">
              <div className="small-7 price-total-label">Your Total:</div>
              <div className="small-5 price-total">
                <PriceFormat price={totalPayment} />
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default EntryFees;
