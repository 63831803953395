// @flow
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import moment from 'moment';

import { selectEvent } from 'actions/event';

import { RET } from 'constants/theme/options';

import withToJS from 'enhancers/withToJS';

import { formatDate, formatDateTimeToCST } from 'helpers';

import EventDivisionPartner from './EventDivisionPartner';

// $FlowFixMe
import eventIconRET from 'assets/img/icons/event-ret.svg';
// $FlowFixMe
import eventIconWCRA from 'assets/img/icons/event-wcra.svg';
// $FlowFixMe
import ticketIconRET from 'assets/img/icons/ticket-ret.svg';
// $FlowFixMe
import ticketIconWCRA from 'assets/img/icons/ticket-wcra.svg';

import type { EventType } from 'models/Event';

const { THEME } = process.env;

type EventProps = {|
  ERAUID: string,
  event: EventType,
  onSelectEvent: () => void,
  selectEvent: (Object, string) => void,
|};
const mapStateToProps = (state) => ({
  ERAUID: state.user.get('data').ERAUID,
});
const mapDispatchToProps = (dispatch) => ({
  selectEvent: async (id, ERAUID) => dispatch(selectEvent(id, ERAUID)),
});
class Event extends PureComponent<EventProps> {
  static defaultProps = {
    isForEntry: false,
  };

  async onTouchEvent(event) {
    const { selectEvent, onSelectEvent, ERAUID } = this.props;
    event.dateStart = moment
      .utc(event.dateStart)
      .set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
      .format('YYYY-MM-DD HH:mm:ss.SSS');
    event.dateEnd = moment
      .utc(event.dateEnd)
      .set({ hour: 23, minute: 59, second: 59, millisecond: 0 })
      .format('YYYY-MM-DD HH:mm:ss.SSS');
    await selectEvent(event.id, ERAUID);
    onSelectEvent();
  }

  render() {
    const { event } = this.props;
    const {
      name,
      location,
      dateEnd,
      dateRegistrationEnd,
      dateStart,
      rank,
      rankLabel,
      eventImage,
      isAlliancePartner,
      Associations,
      TimeZoneUID,
    } = event;

    const dateStartFormatted = formatDate(dateStart);
    const dateEndFormatted = formatDate(dateEnd);
    const dateRange =
      !!dateStartFormatted && !!dateEndFormatted
        ? `${dateStartFormatted} - ${dateEndFormatted}`
        : dateStartFormatted;

    return (
      <span
        onClick={() => {
          this.onTouchEvent(event);
        }}
        className="event-result"
      >
        {!!eventImage && (
          <span className="event-image">
            <img src={eventImage} alt={name} />
          </span>
        )}

        {Associations && Associations.length > 0 && (
          <div className="association-container">
            {Associations.length > 0 &&
              Associations.map((association, i) => {
                return (
                  <span
                    className="association-short-name"
                    key={association.AssociationUID}
                  >
                    {association ? association.ShortName : ''}
                    {i !== Associations.length - 1 ? (
                      <span className="and-separator">&</span>
                    ) : (
                      <span className="and-separator" />
                    )}
                  </span>
                );
              })}
            <div className="associations-finish-tag" />
          </div>
        )}

        <span className="event-title">{name}</span>
        <span className="event-location">{location}</span>
        <span className="event-dates">
          <img
            alt="event dates"
            src={THEME === RET ? eventIconRET : eventIconWCRA}
          />
          <strong>Event Dates: </strong>
          {dateRange}
        </span>
        <span className="event-entry-deadline">
          <img
            alt="entry deadline"
            src={THEME === RET ? ticketIconRET : ticketIconWCRA}
          />
          <strong>Entry Deadline: </strong>
          {formatDateTimeToCST(dateRegistrationEnd, TimeZoneUID)}
        </span>
        <EventDivisionPartner
          rank={rank}
          rankLabel={rankLabel}
          isAlliancePartner={isAlliancePartner}
        />
      </span>
    );
  }
}

export default compose(
  // prettier-ignore
  connect<_, EventProps, _, _, _, _>(mapStateToProps, mapDispatchToProps),
  withToJS,
)(Event);
