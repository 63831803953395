// @flow
import React, { useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import classNames from 'classnames';
import { getInvalid, getValues } from 'selectors/partnerContactInfoForm';
import { getNestedProperty, getTheme } from 'helpers';
import type { PartnerAthleteType } from 'models/PartnerAthlete';
import type { EntryChargeType } from 'models/EntryChargeRequest';
import type { EventType } from 'models/Event';
import { sendEntryInvite } from 'actions/entryInvite';

type Props = {|
  closeModal: () => void,
  className?: string,
  partner?: PartnerAthleteType,
  charge: EntryChargeType,
  event: EventType,
  inviterName: string,
|};

const Footer = (props: Props) => {
  const { inviterName, className, closeModal, event, charge, partner } = props;

  const dispatch = useDispatch();
  const values = useSelector(getValues);

  const email = getNestedProperty('email', values);
  const phoneNumber = getNestedProperty('phoneNumber', values);
  const partnerName = getNestedProperty('fullName', partner, '');
  const partnerERAUID = getNestedProperty('ERAUID', partner, '');

  const partnerEntries = getNestedProperty('entries', charge, []).filter(
    (entry) => entry.ERAUID === partnerERAUID && entry.isPartner,
  );

  const isInvalid = useSelector(getInvalid);
  const hasPhoneOrEmailSupplied = phoneNumber || email;
  const isSubmitDisabled = !hasPhoneOrEmailSupplied || isInvalid;

  const invitePartner = useCallback(async () => {
    if (partnerName && (email || phoneNumber)) {
      const invite = {
        email,
        phone: phoneNumber,
        inviter: inviterName,
        invitee: partnerName,
        invitedERAUID: partnerERAUID,
        EventUID: getNestedProperty('id', event),
        entries: partnerEntries,
        theme: getTheme(),
      };

      const response = await dispatch(sendEntryInvite(invite));

      if (response.success) {
        return closeModal();
      }
    }
  }, [partner, values]);

  const upperCase = useCallback((str) => str.toUpperCase(), []);

  return (
    <div className={classNames('footer', className)}>
      <button
        className="btn-main"
        onClick={invitePartner}
        disabled={isSubmitDisabled}
      >
        {upperCase('invite partner')}
      </button>
      <button className="clear-filter-btn" onClick={closeModal}>
        {upperCase('cancel')}
      </button>
    </div>
  );
};

export default Footer;
