// @flow
import React from 'react';
import type { Node } from 'react';

type SectionContentProps = {|
  children: Node,
  className?: string,
|};

const SectionContent = ({ children, className }: SectionContentProps) => {
  return (
    <div className={className}>
      {children}
      <hr />
    </div>
  );
};

export default SectionContent;
