// @flow
import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import withIsAuthenticated from 'enhancers/withIsAuthenticated';
import Menu from 'components/Menu';

const mapStateToProps = (state) => ({
  user: state.user.get('data'),
});

type FooterPropType = {|
  user: Object,
|};

const FooterBase = (props: FooterPropType) => {
  const { user } = props;
  return (
    <div className="footer">
      <div className="menu-centered mobile-menu-wrapper">
        <Menu menuId="mobile-menu" user={user} />
      </div>
    </div>
  );
};

export const Footer = compose(
  // $FlowIgnore
  connect(mapStateToProps),
  withIsAuthenticated,
)(FooterBase);

export default Footer;
