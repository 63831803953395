// @flow
import React, { createContext, PureComponent } from 'react';
import type { Node, AbstractComponent } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import withToJS from 'enhancers/withToJS';
import { mapDispatchToProps } from 'helpers';

export type ContextValue = {|
  nominationDate: any,
  selectedCompetitions: Object[],
  competitionsLoading: boolean,
  addCompetition: (string, Object) => any,
  removeCompetition: (string) => any,
  resetCompetitions: () => void,
|};

type SelectedCompetitionsProps = {|
  children: Node,
|};

type SelectedCompetitionsState = {|
  nominationDate: any,
  selectedCompetitions: Object[],
  loading: boolean,
|};
export const CompetitionsContext = createContext<ContextValue>({
  nominationDate: null,
  selectedCompetitions: [],
  addCompetition: () => {},
  removeCompetition: () => {},
  resetCompetitions: () => undefined,
  competitionsLoading: false,
});

const { Provider, Consumer } = CompetitionsContext;

const mapStateToProps = (state) => ({
  event: state.event.get('data'),
  user: state.user.get('data'),
});

class SelectedCompetitionsProviderBase extends PureComponent<
  SelectedCompetitionsProps,
  SelectedCompetitionsState,
> {
  constructor(props) {
    super(props);
    this.state = {
      nominationDate: null,
      selectedCompetitions: [],
      loading: false,
    };
  }

  componentWillUnmount() {
    this.setStateWithLoading({
      selectedCompetitions: [],
    });
  }

  setStateWithLoading = (updatedState) => {
    this.setState(
      {
        ...updatedState,
        loading: false,
      },
      () =>
        this.setState({
          loading: true,
        }),
    );
  };

  resetCompetitions = () => {
    this.setStateWithLoading({
      selectedCompetitions: [],
    });
  };

  addCompetition = (disciplineId: string, performance: Object) => {
    const { selectedCompetitions: previousSelections } = this.state;

    const selectedCompetitions = previousSelections.filter((comp) => {
      const { disciplineId: discId } = comp;
      return discId !== disciplineId;
    });
    const updatedSelections = [
      ...selectedCompetitions,
      { disciplineId, performance },
    ];

    this.setStateWithLoading({
      nominationDate: performance.PerformanceDate,
      selectedCompetitions: updatedSelections,
    });

    return updatedSelections;
  };

  removeCompetition = (disciplineId: string) => {
    const { selectedCompetitions: previousSelections } = this.state;

    const selectedCompetitions = previousSelections.filter((comp) => {
      const { disciplineId: discId } = comp;
      return parseInt(discId) !== parseInt(disciplineId);
    });
    this.setStateWithLoading({
      selectedCompetitions,
    });

    return selectedCompetitions;
  };

  render() {
    const { selectedCompetitions, nominationDate, loading } = this.state;

    return (
      <Provider
        value={{
          nominationDate,
          selectedCompetitions,
          competitionsLoading: loading,
          addCompetition: this.addCompetition,
          removeCompetition: this.removeCompetition,
          resetCompetitions: this.resetCompetitions,
        }}
      >
        {this.props.children}
      </Provider>
    );
  }
}

export const SelectedCompetitionsProvider = compose(
  // $FlowFixMe
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
  withToJS,
)(SelectedCompetitionsProviderBase);

export const withSelectedCompetitions = (
  ComposedComponent: AbstractComponent<any>,
) => {
  return function WithSelectedCompetitions(props: any) {
    return (
      <Consumer>
        {(contextValues: ContextValue) => (
          <ComposedComponent {...props} {...contextValues} />
        )}
      </Consumer>
    );
  };
};
