import React from 'react';
import PropTypes from 'prop-types';

const RadioField = (props) => {
  const { checkedGender, gender, id, onGenderSelect, input, className } = props;
  const { name } = input;

  return (
    <div className={className} key={gender.value}>
      <input
        id={`${id}${gender.value}`}
        type="radio"
        name={name}
        value={gender.value}
        checked={checkedGender === gender.value}
        onChange={onGenderSelect}
      />
      <label htmlFor={`${id}${gender.value}`}>{gender.label}</label>
    </div>
  );
};

RadioField.propTypes = {
  checkedGender: PropTypes.string,
  gender: PropTypes.object.isRequired,
  id: PropTypes.string.isRequired,
  className: PropTypes.string,
  input: PropTypes.object.isRequired,
  onGenderSelect: PropTypes.func.isRequired,
};

RadioField.defaultProps = {
  className: '',
  name: '',
  label: '',
  error: '',
  checked: '',
};

export default RadioField;
