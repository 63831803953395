// @flow
import React, { useState, createContext } from 'react';
import type { Node } from 'react';

import type { EntryChargeType } from 'models/EntryChargeRequest';
import { defaultEntryChargeValues } from 'models/EntryChargeRequest';

export type ContextValue = {|
  isBuddyEntry: boolean,
  isBuddySelected: boolean,
  selectedBuddyOption: Object,
  buddyManagerEntryCharge: EntryChargeType,
  setIsBuddyEntry: () => boolean,
  addBuddy: () => void,
  removeBuddy: () => void,
  setPreferences: () => void,
  toggleIsBuddySelected: () => void,
|};

type BuddyGroupsProps = {|
  children: Node,
|};

export const BuddyGroupsContext: Object = createContext<ContextValue>({
  isBuddyEntry: false,
  isBuddySelected: false,
  selectedBuddyOption: null,
  buddyManagerEntryCharge: defaultEntryChargeValues,
  setIsBuddyEntry: () => false,
  addBuddy: () => {},
  removeBuddy: () => {},
  setPreferences: () => {},
  toggleIsBuddySelected: () => {},
});

const { Provider, Consumer } = BuddyGroupsContext;

export const BuddyGroupsProvider = ({ children }: BuddyGroupsProps) => {
  const [isBuddyEntry, setIsBuddyEntry] = useState(false);
  const [isBuddySelected, setIsBuddySelected] = useState(false);
  const [selectedBuddyOption, setSelectedBuddyOption] = useState(null);
  const [buddyCount, setBuddyCount] = useState(0);
  const [buddyManagerEntryCharge, setBuddyManagerEntryCharge] = useState(
    defaultEntryChargeValues,
  );

  const addBuddy = () => {
    const newBuddyCount = buddyCount + 1;
    setBuddyCount(newBuddyCount);
  };

  const removeBuddy = () => {
    const newBuddyCount = buddyCount - 1;
    setBuddyCount(newBuddyCount);
    newBuddyCount === 0 && setIsBuddyEntry(false);
  };

  const toggleIsBuddySelected = (isSelectingBuddy) => {
    setIsBuddySelected(isSelectingBuddy);
  };

  return (
    <Provider
      value={{
        isBuddyEntry,
        setIsBuddyEntry,
        isBuddySelected,
        toggleIsBuddySelected,
        setSelectedBuddyOption,
        selectedBuddyOption,
        buddyManagerEntryCharge,
        setBuddyManagerEntryCharge,
        addBuddy: addBuddy,
        removeBuddy: removeBuddy,
      }}
    >
      {children}
    </Provider>
  );
};

export const withBuddyGroups = (ComposedComponent: any) => {
  return function WithBuddyGroups(props: any) {
    return (
      <Consumer>
        {(contextValues: any) => (
          <ComposedComponent {...props} {...contextValues} />
        )}
      </Consumer>
    );
  };
};
