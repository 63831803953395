// @flow
import React from 'react';
import { X } from 'react-feather';
import MainModal from 'react-modal';

import type { Node } from 'react';
import classNames from 'classnames';
import { themeModal } from 'constants/theme';

type ModalProps = {|
  isOpen: boolean,
  title: string,
  className?: string,
  ClassName?: string | null,
  children: Node,
  onClose: () => void,
|};

const Modal = ({
  isOpen,
  className,
  ClassName,
  onClose,
  title,
  children,
}: ModalProps) => {
  return (
    <MainModal
      isOpen={isOpen}
      onRequestClose={onClose}
      ariaHideApp={false}
      style={themeModal}
      contentLabel={title}
      className={classNames('app-modal', className)}
    >
      <div className="modal-header">
        <div>
          <h2 className="section-title padtop-0 padbot-0">{title}</h2>
          {ClassName && (
            <h2 className="section-className padtop-0 padbot-0">{ClassName}</h2>
          )}
        </div>
        <button className="btn-close" onClick={onClose}>
          <X />
        </button>
      </div>
      <div className="modal-content">{children}</div>
    </MainModal>
  );
};

export default Modal;
