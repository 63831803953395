// @flow
import React, { useState, useContext, useEffect } from 'react';
import { get } from 'lodash';
import { DisciplineContext } from 'context/SelectedDisciplines';
import type { ContextValue as DisciplineContextType } from 'context/SelectedDisciplines/type';
import ToggleButton from 'react-toggle-button';
import { toggleButtonColors } from 'constants/theme';
const { colors, trackStyle, thumbStyle, boxShadow } = toggleButtonColors;

type Props = {|
  disciplineId: string,
  onChangeSamePartnerToggle: (boolean) => void,
  // eslint-disable-next-line react/no-unused-prop-types
  selectedOption: Object,
  index: number,
  isEditing: boolean,
|};

const SamePartnerAbove = (props: Props) => {
  const { disciplineId, index, onChangeSamePartnerToggle, isEditing } = props;

  const disciplineContext: DisciplineContextType = useContext<DisciplineContextType>(
    DisciplineContext,
  );
  const { activeDiscipline, setPartnerAthlete } = disciplineContext;
  const partners = get(activeDiscipline, 'partners', {});
  const [samePartnerToggle, setSamePartnerToggle] = useState(false);
  const partnerAbove = partners[index - 1];
  const currentPartner = partners[index];
  const samePartnerAsAbove =
    get(partnerAbove, 'id') === get(currentPartner, 'id');
  const isPartnerAboveSelected = Object.prototype.hasOwnProperty.call(
    partnerAbove,
    'id',
  );

  useEffect(() => {
    if (!isEditing) {
      setSamePartnerToggle(!!isPartnerAboveSelected);
      setPartner(isPartnerAboveSelected, index);
    } else {
      const currentPartner = get(partners[index], 'id', null);
      if (
        isPartnerAboveSelected &&
        currentPartner !== null &&
        samePartnerAsAbove
      ) {
        setSamePartnerToggle(true);
        onChangeSamePartnerToggle(true);
      } else if (currentPartner !== null && !samePartnerAsAbove) {
        setSamePartnerToggle(false);
        onChangeSamePartnerToggle(false);
      } else if (
        isPartnerAboveSelected &&
        currentPartner === null &&
        !partners[index].isEntryAdded
      ) {
        setSamePartnerToggle(true);
        onChangeSamePartnerToggle(true);
        setPartner(true, index);
      } else {
        setSamePartnerToggle(false);
        onChangeSamePartnerToggle(false);
      }
    }
  }, []);

  useEffect(() => {
    if (
      !isPartnerAboveSelected &&
      get(partners[index], 'samePartnerKey') === index - 1
    ) {
      setSamePartnerToggle(isPartnerAboveSelected);
      onChangeSamePartnerToggle(isPartnerAboveSelected);
      setPartnerAthlete(disciplineId, partners[index - 1], index);
    }
  }, [partners[index - 1]]);

  const setPartner = (sameAsAbove, index) => {
    let partner;
    if (sameAsAbove) {
      partner = {
        ...partners[index - 1],
      };
      partner.samePartnerKey = index - 1;
    } else {
      partner = {};
    }
    // $FlowFixMe
    setPartnerAthlete(disciplineId, partner, index);
  };

  const onChangeSwitch = (value) => {
    if (isPartnerAboveSelected) {
      const sameAsAbove = !value;
      setPartner(sameAsAbove, index);
      setSamePartnerToggle(sameAsAbove);
      onChangeSamePartnerToggle(sameAsAbove);
    } else {
      setSamePartnerToggle(false);
    }
  };
  return (
    <div className="same-partner-container">
      <div className="same-partner-section-title">Same Partner As Above</div>
      <ToggleButton
        className="same-partner-toggle"
        inactiveLabel=""
        activeLabel=""
        colors={colors}
        trackStyle={trackStyle}
        thumbStyle={thumbStyle}
        activeThumbStyle={{
          boxShadow: { boxShadow },
        }}
        thumbStyleHover={{
          boxShadow: { boxShadow },
        }}
        value={samePartnerToggle}
        onToggle={onChangeSwitch}
      />
    </div>
  );
};

export default SamePartnerAbove;
