import api from 'api';
import client from 'api/apollo';
import { GET_ENTRY_INVITE } from 'queries/GetEntryInvite';
import { getFromState, getAccessToken, logError } from 'helpers';
import { invitePartnerSuccess } from 'actions/entryChargeRequest';
import { inviteBuddyPartnerSuccess } from 'actions/entryPool';
import { updateSyncErrors } from 'redux-form';

export function sendEntryInvite(values) {
  return async (dispatch, getState) => {
    try {
      const { authPayload } = getFromState(getState, 'auth');
      const accessToken = getAccessToken(authPayload);

      const response = await api({
        path: 'entry-pool/invite',
        method: 'POST',
        body: values,
        accessToken,
      });

      dispatch(invitePartnerSuccess(values.invitedERAUID));
      dispatch(inviteBuddyPartnerSuccess(values.invitedERAUID));

      return response;
    } catch (error) {
      logError(error);
      dispatch(
        updateSyncErrors('partnerContactInfo', {
          phoneNumber: error.message,
        }),
      );
      return error.message;
    }
  };
}
export async function getEntryInvite(inviteToken) {
  if (!inviteToken) return null;
  try {
    const entryInvitePayload = {
      query: GET_ENTRY_INVITE,
      variables: { input: { inviteToken } },
      fetchPolicy: 'network-only',
    };
    const response = await client.query(entryInvitePayload);
    const entryInvite = response.data.entryInviteGet;

    return entryInvite;
  } catch (error) {
    logError(error);
    return null;
  }
}
