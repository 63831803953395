// @flow
import React from 'react';
import Notification from 'components/Notification';
import AlertCircle from 'react-feather/dist/icons/alert-circle';

type ErrorMessageProps = {|
  errorMessage: ?string | boolean,
|};

const ErrorMessage = (props: ErrorMessageProps) => {
  const { errorMessage } = props;
  if (errorMessage) {
    window.scrollTo(0, 0);
    return <Notification text={errorMessage} icon={<AlertCircle />} />;
  }
  return null;
};

export default ErrorMessage;
