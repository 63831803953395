import React from 'react';
import hoistNonReactStatics from 'hoist-non-react-statics';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { getDisplayName } from 'helpers';
import withToJS from './withToJS';

const mapStateToProps = (state) => ({
  auth: state.auth.get('data'),
});

const withIsAuthenticated = (WrappedComponent) => {
  const Wrapper = (props) => {
    if (props.auth.authenticated) {
      return <WrappedComponent {...props} />;
    }
    return null;
  };

  Wrapper.displayName = `withIsAuthenticated(${getDisplayName(
    WrappedComponent,
  )})`;

  hoistNonReactStatics(Wrapper, WrappedComponent);

  return Wrapper;
};

export default compose(
  connect(mapStateToProps),
  withToJS,
  withIsAuthenticated,
);
