// @flow
import React from 'react';
import type { Node } from 'react';

type SectionProps = {|
  children: Node,
  className?: string,
|};

const Section = ({ children, className }: SectionProps) => {
  return <div className={className}>{children}</div>;
};

export { default as SectionTitle } from './Title';
export { default as SectionSubtitle } from './Subtitle';
export { default as SectionContent } from './Content';

export default Section;
