// @flow
import React, { useCallback } from 'react';

import { SectionTitle, SectionContent } from 'components/Section';
import PerformanceCardEntry from 'components/PerformanceCardEntry';
import CheckboxLine from 'components/CheckboxLine';
import { useDisciplineModalHooks } from './hooks';

import type { EventType } from 'models/Event';
import { getNestedProperty } from 'helpers';

type Props = {|
  event: EventType,
  onPerformanceSelected: (performance: Object) => void,
  dispatch: (f: Function) => any,
|};
const SelectPreferences = (props: Props) => {
  const methods = useDisciplineModalHooks();
  const {
    performancePreferenceSelected,
    activeDiscipline,
    toggleDiscOutIfPrefNotMet,
  } = methods;
  const { onPerformanceSelected, event, dispatch } = props;

  const performances = getNestedProperty('performances', activeDiscipline);
  const { IsNomLastPerformance } = event;
  let availablePerformances = !!IsNomLastPerformance
    ? performances
    : [performances[0]].filter(Boolean);

  const activeDisciplineId =
    // $FlowFixMe
    activeDiscipline.DisciplineUID || activeDiscipline.value;

  const performancesWithEligiblePreference: any = event.performances.filter(
    (perf) =>
      activeDiscipline &&
      perf.Disciplines.some(
        (disc) =>
          disc.PreferenceEligible &&
          disc.id === activeDisciplineId &&
          (!activeDiscipline.ClassName ||
            disc.ClassName === activeDiscipline.ClassName),
      ),
  );
  availablePerformances = availablePerformances.filter((performance) =>
    performancesWithEligiblePreference.some(
      (eligiblePreference) =>
        eligiblePreference.PerformanceUID === performance.PerformanceUID,
    ),
  );
  const selectedDisciplineId = getNestedProperty('value', activeDiscipline);

  const hasPreference = useCallback((d) => {
    return d.Pref1 || d.Pref2 || d.Pref3 || d.Out1 || d.Out2;
  }, []);
  const toggleOut = useCallback(() => {
    if (activeDiscipline && hasPreference(activeDiscipline)) {
      toggleDiscOutIfPrefNotMet(activeDiscipline.value);
    }
  }, [activeDiscipline]);

  if (availablePerformances && availablePerformances.length === 0) return null;

  return (
    <>
      <SectionTitle optional>PREFERENCES</SectionTitle>
      <div>
        Select the dates you wish to perform. Preferences are not guaranteed.
      </div>
      <SectionContent className="competition-list-select">
        {availablePerformances.map((perf) => (
          <PerformanceCardEntry
            performance={perf}
            selectedPreference={performancePreferenceSelected(perf)}
            eventID={event.id}
            key={perf.PerformanceUID}
            onClick={onPerformanceSelected}
            dispatch={dispatch}
            disciplineId={parseInt(selectedDisciplineId, 10)}
            showEntryCountOnPerformance={event.ShowEntryCountOnPerformances}
          />
        ))}
        {event.AllowOutIfPrefNotMet && activeDiscipline ? (
          <>
            <hr />
            <div className="grid-x grid-margin-x align-middle martop-2">
              <CheckboxLine
                className="cell shrink"
                option={activeDiscipline.value}
                onChange={toggleOut}
                isChecked={activeDiscipline.OutIfPrefNotMet}
              />
              <span className="cell auto">
                CANCEL ENTRY IF PREFERENCE(S) NOT MET
              </span>
            </div>
          </>
        ) : null}
      </SectionContent>
    </>
  );
};

export default SelectPreferences;
