// @flow
import React from 'react';
import NominationContent from 'components/NominationContent';
import PriceFormat from 'components/PriceFormat';
import DisciplineSegmentsCheckList from 'components/DisciplineSegmentsCheckList';
import type { SegmentType } from 'context/SelectedSegments/type';
import { useDisciplineModalHooks } from '../EntryDisciplineModal/hooks';

type Props = {|
  toggleSegment: (SegmentType) => Promise<any>,
  isSelected: (SegmentType) => boolean,
  isYouthAthlete: ?boolean,
  isFemaleAthlete: ?boolean,
  event: Object,
|};

const EntrySegmentChecklist = (props: Props) => {
  const {
    toggleSegment,
    isSelected,
    isYouthAthlete,
    isFemaleAthlete,
    event,
  } = props;

  const { activeDiscipline } = useDisciplineModalHooks();

  const price =
    activeDiscipline && activeDiscipline.DivisionFee
      ? activeDiscipline.DivisionFee
      : event.nominationFee;

  return (
    <>
      <div className="nomination-price">
        {activeDiscipline && activeDiscipline.DivisionName ? (
          <span
            className="nomination-label-full-price"
            style={{ display: 'block' }}
          >
            {activeDiscipline.DivisionName.replace(' ', ': ')}
          </span>
        ) : null}
        <span className="nomination-label-full-price">
          Price:{' '}
          {event.NominationOnEntryDiscountPercentage > 0 ? (
            <s>
              <PriceFormat price={price} />
            </s>
          ) : (
            <PriceFormat price={price} />
          )}
        </span>{' '}
        {event.NominationOnEntryDiscountPercentage > 0 && (
          <span className="discounted-entry-nomination">
            <PriceFormat price={event.nominationOnEntryFee} />
            /segment
          </span>
        )}
      </div>
      <p>
        You can nominate this entry for WCRA. Select segments below you want any
        awarded points to apply to.
      </p>

      <NominationContent
        removeOriginalClassName={true}
        className="segments-selection"
      >
        <DisciplineSegmentsCheckList
          isYouthAthlete={isYouthAthlete}
          isFemaleAthlete={isFemaleAthlete}
          isSelected={isSelected}
          toggleSegment={toggleSegment}
          hideDisciplineTitle={true}
          onClassSegmentSelected={() => {}}
          selectedClassSegments={[]}
        />
      </NominationContent>
    </>
  );
};

export default EntrySegmentChecklist;
