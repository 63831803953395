import { useState, useCallback } from 'react';

export const useAfterDisciplineUpdate = (props) => {
  const [disciplineModalOpen, setDisciplineModalOpen] = useState(false);
  const toggleModal = useCallback(async () => {
    setDisciplineModalOpen(!disciplineModalOpen);
    return props.calculateEntryCharge();
  }, []);

  return [disciplineModalOpen, toggleModal];
};
