// @flow
import { withRouter } from 'react-router-dom';
import { reduxForm } from 'redux-form';
import { compose } from 'redux';
import { connect } from 'react-redux';

import { fetchUserByToken } from 'actions/user';
import { GET_USER_PROFILE } from 'queries/GetUserProfile';
import { getInitialUserValues } from 'selectors/user';
import EditProfile from 'components/EditProfile';
import { updateUser } from 'actions/user';

const mapStateToProps = (state) => ({
  loading: state.user.get('loading'),
  loadingAuth: state.auth.get('loading'),
  user: state.user.get('data'),
  initialValues: getInitialUserValues(state),
  userToken: state.auth.get('data').authPayload.token.refreshToken || '',
});

const mapDispatchToProps = (dispatch) => ({
  dispatch,
  onSubmit: (newUserValues) => dispatch(updateUser(newUserValues)),
  fetchUser: (token) => dispatch(fetchUserByToken(GET_USER_PROFILE, token)),
});

export default compose(
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
  reduxForm({
    form: 'profile',
    enableReinitialize: true,
  }),
)(EditProfile);
