// @flow
import React, { useState, useEffect } from 'react';

import {
  pluralize,
  preferencesAndOutCounts,
  getNestedProperty,
  preferencesAndOutDetails,
  formatDateTimeToCST,
} from 'helpers';

import { PartnerSection } from 'components/PartnerSection';
import { TotalLabel } from 'components/TotalLabel';
import PriceFormat from 'components/PriceFormat';

import {
  isTeamDiscipline,
  disciplinesByName,
  isPersonalHorseDiscipline,
} from 'constants/disciplines';

type DisciplineEnteredType = {
  entry: Object,
  event: Object,
  openInvitePartnerModal: Function,
  index?: number,
  isBuddyManager?: boolean,
  entryCountByDiscipline?: number,
};

export const DisciplineEntered = ({
  entry = {},
  event = {},
  openInvitePartnerModal,
  index = null,
  isBuddyManager = null,
  entryCountByDiscipline = 0,
}: DisciplineEnteredType) => {
  const { HorseName, totalFee, PayLater } = entry;
  const { NumberOfNots, NumberOfPrefs } = event;

  const [showPreferencesText, setShowPreferencesText] = useState(false);

  useEffect(() => {
    const isPrefEligible = event.performances
      .reduce((acc, curr) => {
        const disciplineInPerf = curr.Disciplines.find(
          (disc) => disc.id === entry.CompType,
        );
        if (!!disciplineInPerf) {
          if ('PreferenceEligible' in disciplineInPerf) {
            return [...acc, disciplineInPerf.PreferenceEligible];
          } else {
            return [...acc];
          }
        } else {
          return [...acc];
        }
      }, [])
      .some((PreferenceEligible) => !!PreferenceEligible);

    setShowPreferencesText(isPrefEligible);
  }, []);

  const renderPreferenceText = () => {
    if (!isBuddyManager && index < entryCountByDiscipline - 1) return null;

    const { preferenceCount, outCount } = preferencesAndOutCounts({
      ...entry,
      hasPreference: true,
    });
    const {
      selectedOutDetails,
      selectedPreferencesDetails,
    } = preferencesAndOutDetails(
      {
        ...entry,
        hasPreference: true,
      },
      event,
    );
    const getPreferenceAndOutsDetailsText = (
      { type, key, PerformanceDate, TimeZoneUID },
      _i,
      preferenceOrOuts,
    ) => {
      const moreThatOne = preferenceOrOuts.length > 1;
      const name = moreThatOne ? `${type} #${key}` : type;
      return `${name}: ${formatDateTimeToCST(PerformanceDate, TimeZoneUID)}`;
    };

    const preferenceAndOutsDetails = [
      ...selectedPreferencesDetails.map(getPreferenceAndOutsDetailsText),
      ...selectedOutDetails.map(getPreferenceAndOutsDetailsText),
    ];

    const preferenceText = NumberOfPrefs
      ? pluralize(`${preferenceCount} preference`, preferenceCount !== 1)
      : '';
    const outText = NumberOfNots
      ? pluralize(`${outCount} not`, outCount !== 1)
      : '';

    const preferenceAndOutsDetailsText = preferenceAndOutsDetails.map(
      (text) => (
        <div key={text} className={'buddy-entry-date marbot-0 martop-0'}>
          {text}
        </div>
      ),
    );

    if (preferenceText.length && outText.length) {
      return (
        <span className="entry-description">
          <span>
            Preferences: {preferenceText}, {outText}
          </span>
          <div className={'preferences-and-outs-container'}>
            {preferenceAndOutsDetailsText}
          </div>
        </span>
      );
    }

    if (preferenceText.length) {
      return (
        <span className="entry-description">
          <span>Preferences: {preferenceText}</span>
          <div className={'preferences-and-outs-container'}>
            {preferenceAndOutsDetailsText}
          </div>
        </span>
      );
    }
    if (outText.length) {
      return (
        <span className="entry-description">
          <span>Preferences: {outText}</span>
          <div className={'preferences-and-outs-container'}>
            {preferenceAndOutsDetailsText}
          </div>
        </span>
      );
    }

    return '';
  };

  const renderPartnerSection = () => {
    const { isPartnerInSystem, CompType, isPendingEntry } = entry;
    const { DisablePartnerSelection } = event;
    if (!isTeamDiscipline(CompType) || DisablePartnerSelection) return null;

    const shouldRenderInviteLinkForBuddyManager =
      isBuddyManager === null || isBuddyManager;

    return (
      <PartnerSection
        entry={entry}
        openInvitePartnerModal={openInvitePartnerModal}
        shouldRenderInviteLink={
          shouldRenderInviteLinkForBuddyManager &&
          !isPartnerInSystem &&
          !isPendingEntry
        }
        index={index}
        entryCountByDiscipline={entryCountByDiscipline}
      />
    );
  };

  const renderHorseName = () => {
    const { CompType } = entry;
    const entryIsHorseDiscipline = isPersonalHorseDiscipline(CompType);

    return entryIsHorseDiscipline ? (
      <div key={`${HorseName}-${index + 1}`} className="horse-label">
        {`Horse${entryCountByDiscipline > 1 ? ` #${index + 1}: ` : ': '}`}{' '}
        {HorseName}
      </div>
    ) : null;
  };

  const renderTotalFee = () => {
    return totalFee ? (
      <div className="grid-x martop-2">
        <TotalLabel payLater={PayLater} />
        <div className="small-6 price-total text-right">
          <PriceFormat price={totalFee} />
        </div>
      </div>
    ) : null;
  };

  const renderNominationsText = () => {
    if (!isBuddyManager && index < entryCountByDiscipline - 1) return null;
    const segments = getNestedProperty('segments', entry);
    return segments && segments.length > 0 ? (
      <div className="nominations">
        {segments.map((segment) => (
          <span key={segment.value} className="nomination-description">
            NOMINATION:{' '}
            {`SEGMENT ${segment.ShortName} ${
              segment.newSegmentCount > 1 ? `(X${segment.newSegmentCount})` : ''
            }`}
          </span>
        ))}
      </div>
    ) : null;
  };

  const renderMainComponent = (isBuddyManager, children) => {
    if (isBuddyManager) {
      const disciplineName = disciplinesByName[entry.CompType];
      return (
        <div className="discipline-entered nominate-totals">
          <div className="confirmation-discipline">{disciplineName}</div>
          {children}
        </div>
      );
    }

    return <>{children}</>;
  };

  return renderMainComponent(
    isBuddyManager,
    <>
      {renderPartnerSection()}
      {renderHorseName()}
      {showPreferencesText && renderPreferenceText()}
      {renderNominationsText()}
      {renderTotalFee()}
    </>,
  );
};
