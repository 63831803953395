// @flow
import React, { createContext, useState } from 'react';
import type { Node, AbstractComponent } from 'react';
import { useDispatch, connect, useSelector } from 'react-redux';
import get from 'lodash/get';
import { compose } from 'redux';
import moment from 'moment';
import withToJS from 'enhancers/withToJS';
import {
  createPayment,
  calculateCharge as calculateChargeAction,
} from 'actions/payment';
import { mapDispatchToProps, getNestedProperty } from 'helpers';
import type { EventType } from 'models/Event';
import { withNomination } from 'context/Nomination';
import type { NominationProps } from 'context/Nomination';
import { submitNominationPayment } from 'actions/payment';
import { disciplineSegmentsReset } from 'actions/disciplineSegments';
import { clearSelection } from '../reducers/disciplineClass';
import { saveAppliedGiftCardBalances } from '../reducers/giftCardBalances';

export type CalculateChargeRequestType = {
  ERAUID: string,
  eventId: ?string,
  nominationDate: ?string,
  eventDateStart: ?string,
  eventDateEnd: ?string,
  disciplineSegments: Object,
  selectedPerformances: Object[],
  eventDrawDate: ?string,
  eventRegistrationEnd: string | any,
  firstPerformance: ?string,
  firstPerformanceRiding: ?string,
  isSlack: ?boolean,
  selectedPerformanceUID: number[],
  TimeZoneUID: ?number,
};

export type ContextValue = {|
  charge: Object,
  hasFullDiscount: boolean,
  disciplineSegmentsSelected: Object,
  calculateCharge: () => Promise<string | null>,
  payNomination: () => Promise<Object | string>,
  submitPayment: () => Promise<Object | string>,
  resetNomination: () => void,
  setAppliedGiftCardBalance: (balances: Array<Object>) => void,
  appliedGiftCardBalance: Array<Object>,
|};

type NominationFlowProps = {|
  ...NominationProps,
  event: EventType,
  user: Object,
  children: Node,
  disciplineSegmentsSelected: Object,
|};

const { Provider, Consumer } = createContext<ContextValue>({
  charge: ({}: Object),
  hasFullDiscount: false,
  disciplineSegmentsSelected: {},
  calculateCharge: async () => null,
  payNomination: async () => '',
  submitPayment: async () => '',
  resetNomination: () => undefined,
  setAppliedGiftCardBalance: () => undefined,
  appliedGiftCardBalance: [],
});

const mapStateToProps = (state) => ({
  event: state.event.get('data'),
  user: state.user.get('data'),
});

const NominationFlowProviderBase = (props: NominationFlowProps) => {
  const {
    athletes,
    competitions,
    coupon,
    disciplineSegmentsSelected,
    event,
    disciplines,
  } = props;

  const { ERAUID, athleteFullName } = athletes;
  let { nominationDate, selectedCompetitions } = competitions;
  const { selectedCoupon } = coupon;
  const dispatch = useDispatch();

  const classSegmentsSelected = useSelector(
    (state) => state.disciplineClass.selectedSegments,
  );

  const classCompetitionsSelected = useSelector(
    (state) => state.disciplineClass.selectedCompetitions,
  );

  const appliedGiftCardBalance = useSelector(
    (state) => state.giftCardBalances.appliedGiftCardBalances,
  );

  const setAppliedGiftCardBalance = (giftCardBalances) => {
    dispatch(saveAppliedGiftCardBalances(giftCardBalances));
  };

  const [charge, setCharge] = useState({});
  const [hasFullDiscount, setHasFullDiscount] = useState(false);
  const [paymentSubmitDisabled, setPaymentSubmitDisabled] = useState(false);

  const getClassSegmentsWithCompetition = () => {
    const classSegmentsSelectedWithCompetition = classSegmentsSelected.map(
      (cs) => {
        const correspondingCompetition = classCompetitionsSelected.find(
          (sc) =>
            sc.disciplineId === cs.disciplineTypeUID &&
            sc.className === cs.className,
        );
        return {
          ...cs,
          competition: correspondingCompetition
            ? correspondingCompetition.competition
            : null,
        };
      },
    );
    return classSegmentsSelectedWithCompetition;
  };

  const getUpdateDisciplineSegments = (
    disciplineSegmentsSelectedObject = {},
  ) => {
    return Object.keys(disciplineSegmentsSelectedObject).reduce(
      (agg, disciplineKey) => {
        agg[disciplineKey] = get(
          disciplineSegmentsSelectedObject,
          disciplineKey,
          [],
        ).map((disciplineSegment) => disciplineSegment.value);
        return agg;
      },
      {},
    );
  };

  const disciplineSegments = getUpdateDisciplineSegments(
    disciplineSegmentsSelected,
  );

  const isCompetitionsAndDisciplineSegmentsEmpty =
    selectedCompetitions.length === 0 ||
    Object.keys(disciplineSegments).every(
      (disciplineKey) =>
        get(disciplineSegments, disciplineKey, []).length === 0,
    );

  const isClassSegmentsEmpty = classSegmentsSelected.length === 0;
  if (!isClassSegmentsEmpty) {
    // need to compare with the actual nominationDate value on disciplines no classess
    nominationDate = classCompetitionsSelected[0].competition.PerformanceDate;
  }

  const hasNoSegmentSelected =
    isCompetitionsAndDisciplineSegmentsEmpty && isClassSegmentsEmpty;

  const isInvalid = () => {
    return nominationDate === null || hasNoSegmentSelected;
  };

  const getCalculateChargeData = (): CalculateChargeRequestType => {
    const performances = selectedCompetitions.map((selectedCompetition) => {
      const { disciplineId, performance } = selectedCompetition;
      return {
        disciplineId,
        performanceDate: moment
          .utc(performance.PerformanceDate)
          .format('YYYY-MM-DD HH:mm:ss.SSS'),
      };
    });
    const performancesUID = selectedCompetitions.map(
      (perf) => perf.PerformanceUID,
    );
    const formattedNominationDate = nominationDate
      ? moment.utc(nominationDate).format('YYYY-MM-DD HH:mm:ss.SSS')
      : null;

    return {
      ERAUID,
      eventId: event.id,
      nominationDate: formattedNominationDate,
      eventDateStart: event.dateStart,
      eventDateEnd: event.dateEnd,
      disciplineSegments,
      classSegmentsSelected: getClassSegmentsWithCompetition(),
      selectedPerformances: performances,
      eventDrawDate: event.eventDrawDate,
      eventRegistrationEnd: !!event.dateRegistrationEnd
        ? event.dateRegistrationEnd
        : moment().subtract(1, 'days'),
      firstPerformance: !!event.firstPerformance
        ? event.firstPerformance
        : null,
      firstPerformanceRiding: !!event.firstPerformanceRiding
        ? event.firstPerformanceRiding
        : null,
      isSlack: !!event.isSlack ? event.isSlack : null,
      selectedPerformanceUID: performancesUID,
      TimeZoneUID: event.TimeZoneUID,
      couponCode: getNestedProperty('CouponCode', selectedCoupon),
      EventRank: event.EventRank,
      giftCardAccountBalances: appliedGiftCardBalance,
    };
  };

  const calculateCharge = async (): Promise<string | null> => {
    if (hasNoSegmentSelected) {
      setCharge({});
    }
    if (isInvalid()) {
      return null;
    }

    const chargeData = getCalculateChargeData();

    if (
      chargeData.eventDateStart &&
      chargeData.eventDateEnd &&
      chargeData.nominationDate !== null
    ) {
      const updatedCharge = await dispatch(calculateChargeAction(chargeData));

      if (updatedCharge && !updatedCharge.error) {
        setCharge(updatedCharge);
        if (updatedCharge.totalPayment === 0) {
          setHasFullDiscount(true);
        } else {
          setHasFullDiscount(false);
        }
        return null;
      }
      const error = getNestedProperty('error.message', updatedCharge);
      return error;
    }
    return null;
  };

  const payNomination = async () => {
    const performances = selectedCompetitions.map((selectedCompetition) => {
      const { disciplineId, performance } = selectedCompetition;
      return {
        disciplineId,
        performanceDate: moment
          .utc(performance.PerformanceDate)
          .format('YYYY-MM-DD HH:mm:ss.SSS'),
      };
    });

    const formattedNominationDate = nominationDate
      ? moment.utc(nominationDate).format('YYYY-MM-DD HH:mm:ss.SSS')
      : null;

    const nomination = {
      eventName: event.name,
      disciplineSegments,
      nominationDate: formattedNominationDate,
      selectedPerformances: performances,
      ERAUID,
      athleteFullName,
      classSegmentsSelected: getClassSegmentsWithCompetition(),
    };
    const { totalPaymentForCheckout } = charge;
    const result = await dispatch(
      createPayment({
        amount: totalPaymentForCheckout,
        nomination: { ...nomination, disciplineSegmentsSelected },
        giftCardAccountBalances: appliedGiftCardBalance,
      }),
    );
    return result;
  };

  // @TODO: Should be reworked when the reducer for payments is
  // refactored to support multiple nominations.
  const submitPayment = async () => {
    if (paymentSubmitDisabled) return;

    setPaymentSubmitDisabled(true);

    const { selectedCoupon } = coupon;
    const performances = selectedCompetitions.map((selectedCompetition) => {
      const { disciplineId, performance } = selectedCompetition;
      return {
        disciplineId,
        performanceDate: moment
          .utc(performance.PerformanceDate)
          .format('YYYY-MM-DD HH:mm:ss.SSS'),
      };
    });

    const formattedNominationDate = nominationDate
      ? moment.utc(nominationDate).format('YYYY-MM-DD HH:mm:ss.SSS')
      : null;

    const nomination = {
      eventName: event.name,
      disciplineSegments,
      nominationDate: formattedNominationDate,
      selectedPerformances: performances,
      ERAUID,
      athleteFullName,
      classSegmentsSelected: getClassSegmentsWithCompetition(),
    };

    const { totalPayment } = charge;

    const values = {
      stripeToken: null,
      EvantRank: event.EventRank,
      event,
      amount: totalPayment,
      nomination: { ...nomination, disciplineSegmentsSelected },
      coupon: selectedCoupon,
      saveCard: false,
      giftCardAccountBalances: appliedGiftCardBalance,
    };

    const response = await dispatch(submitNominationPayment(values));
    const chargedAmount = getNestedProperty('charge.amount', response) || 0;
    await dispatch(
      createPayment({
        amount: chargedAmount,
        nomination: { ...nomination, disciplineSegmentsSelected },
        giftCardAccountBalances: appliedGiftCardBalance,
      }),
    );

    setPaymentSubmitDisabled(false);

    return response;
  };

  const resetNomination = () => {
    competitions.resetCompetitions();
    athletes.resetSelectedAthlete();
    coupon.reset();
    disciplines.clearSelectedDisciplines();
    dispatch(disciplineSegmentsReset());
    dispatch(clearSelection());
  };

  return (
    <Provider
      value={{
        charge,
        hasFullDiscount,
        disciplineSegmentsSelected,
        calculateCharge: calculateCharge,
        payNomination: payNomination,
        submitPayment: submitPayment,
        resetNomination: resetNomination,
        setAppliedGiftCardBalance: setAppliedGiftCardBalance,
        appliedGiftCardBalance: appliedGiftCardBalance,
      }}
    >
      {props.children}
    </Provider>
  );
};

export const NominationFlowProvider = compose(
  // $FlowFixMe
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
  withToJS,
  withNomination,
)(NominationFlowProviderBase);

export const withNominationFlow = (
  ComposedComponent: AbstractComponent<any>,
) => {
  return function WithNominationFlow(props: any) {
    return (
      <Consumer>
        {(contextValues: ContextValue) => (
          <ComposedComponent {...props} {...contextValues} />
        )}
      </Consumer>
    );
  };
};
