import React from 'react';
import { Link } from 'react-router-dom';

import { PRIVACY_POLICY, TERMS_OF_USE, USER_AGREEMENT } from 'constants/routes';

const UserTerms = () => {
  return (
    <label className="terms-and-conditions" htmlFor="terms-and-conditions">
      I have read and accept the{' '}
      <Link to={TERMS_OF_USE} target="_blank">
        Terms of Use
      </Link>{' '}
      ,{' '}
      <Link to={PRIVACY_POLICY} target="_blank">
        Privacy Policy
      </Link>
      {' and '}
      <Link to={USER_AGREEMENT} target="_blank">
        User Agreements.
      </Link>
      {
        'I UNDERSTAND THAT I MAY ALSO BE SHARING PERSONAL INFORMATION THROUGH USE OF THESE PRODUCTS.'
      }
    </label>
  );
};

export default UserTerms;
