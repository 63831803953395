// @flow
import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { useHistory } from 'react-router-dom';
import { deauthenticateUser } from 'actions/auth';
import withIsAuthenticated from 'enhancers/withIsAuthenticated';
import PowerSettingsNew from '@material-ui/icons/PowerSettingsNew';
import { useAuth0 } from '@auth0/auth0-react';
import { hasFeatureFlag } from '../../utils/flagsmith';

type LogoutPropsType = {|
  onLogout: () => void,
|};

const returnTo = window.location.origin;
const mapDispatchToProps = (dispatch) => ({
  onLogout: () => dispatch(deauthenticateUser()),
});
const Logout = (props: LogoutPropsType) => {
  const history = useHistory();
  const { logout } = useAuth0();

  const handleLogout = async () => {
    const auth0 = hasFeatureFlag('auth0');
    if (auth0) {
      await logout({ returnTo });
    }
    history.push(auth0 ? '/' : '/login');
    props.onLogout();
    window.location.reload();
  };

  return (
    <a onClick={handleLogout}>
      Log Out <PowerSettingsNew className="float-right" />
    </a>
  );
};

export default compose(
  // $FlowIgnore
  connect(
    null,
    mapDispatchToProps,
  ),
  withIsAuthenticated,
)(Logout);
