// @flow
import React from 'react';
import { compose } from 'redux';

import { withSelectedDisciplines } from 'context/SelectedDisciplines';
import withToJS from 'enhancers/withToJS';
import Section from 'components/Section';
import type { DisciplineType } from 'context/SelectedDisciplines/type';
import ListBox from './List';
import DisciplineListWithClasses from './DisciplineListWithClasses';
import type { EventType } from 'models/Event';
import { NOMINATION } from 'constants/nomination';
import { YOUTH_DIVISIONS } from 'constants/event';
import {
  NO_EVENT_PERFORMANCES,
  NO_ADULT_DIVISION_CLASSES,
} from 'constants/entry';
import { withSelectedAthletes } from 'context/SelectedAthletes';

type MultiDisciplineSelectProps = {|
  selectedAthlete: ?string,
  category: ?string,
  event: EventType,
  availableDisciplines: DisciplineType[],
  activeDisciplineSelect: (discipline: DisciplineType) => Promise<any>,
  afterDisciplineUpdate: () => Promise<any>,
  user: any,
  isYouthAthlete?: boolean,
|};

const MultiDisciplineSelectBase = (props: MultiDisciplineSelectProps) => {
  const {
    availableDisciplines,
    category,
    event,
    selectedAthlete,
    activeDisciplineSelect,
    afterDisciplineUpdate,
    user,
    isYouthAthlete,
  } = props;
  const { AllowClasses, id } = event;
  const { restrictionData } = user;
  let restrictions = [];

  if (restrictionData.restrictions) {
    restrictions = restrictionData.restrictions
      .filter((r) => r.EventUID === id)
      .map((r) => r.CompType);
  }

  const availableDisciplinesWithPerfomance = availableDisciplines.map((i) =>
    'Classes' in i
      ? {
          ...i,
          Classes: i.Classes.filter((Class) => Class.performances.length >= 1),
        }
      : i,
  );
  const availableDisciplinesView =
    restrictions && restrictions.length > 0
      ? availableDisciplinesWithPerfomance.filter((d) =>
          restrictions.includes(d.value),
        )
      : availableDisciplinesWithPerfomance;
  const youthDisciplines = availableDisciplinesView.map((d) => {
    if ('Classes' in d) {
      return d.Classes.every((c) => YOUTH_DIVISIONS.includes(c.Division));
    }
  });
  const hasOnlyYouthDivisions =
    event.EventRank === 215 && youthDisciplines.every((d) => d === true);
  const isYouthOnlyEvent =
    YOUTH_DIVISIONS.includes(event.EventRank) || hasOnlyYouthDivisions;
  const getSelectionInstructions = () => {
    const selectedAthleteName = selectedAthlete ? ` ${selectedAthlete}` : '';
    const isNomination = category === NOMINATION;

    return isNomination
      ? {
          unavailableDiscTitle: 'Closed for Nominations',
          unavailableDiscDescription: `Nominations must be made before the ${
            event.IsNomLastPerformance !== 1
              ? 'first performance '
              : 'start of the last performance '
          } of each discipline.`,
          selectDiscSubtitle: `Only select discipline(s)${selectedAthleteName} entered for in this event.`,
        }
      : {
          unavailableDiscTitle: 'Closed for Entry',
          unavailableDiscDescription:
            'Entries must be made before the start of the performance.',
          selectDiscSubtitle: `Select the discipline(s) you want to enter for${selectedAthleteName}.`,
        };
  };

  const onSelect = async (discipline: DisciplineType) => {
    await activeDisciplineSelect(discipline);
    return afterDisciplineUpdate();
  };

  const { selectDiscSubtitle } = getSelectionInstructions();
  if (availableDisciplinesView.length === 0) {
    return <span className="feedback">{NO_EVENT_PERFORMANCES}</span>;
  }

  if (isYouthOnlyEvent && !isYouthAthlete) {
    return <span className="feedback">{NO_ADULT_DIVISION_CLASSES}</span>;
  }

  return (
    <Section className="selected-disciplines-container">
      <div className="competition-list-select">
        {AllowClasses ? (
          <DisciplineListWithClasses
            list={availableDisciplinesView}
            onClick={onSelect}
            subtitle={selectDiscSubtitle}
            isYouthAthlete={isYouthAthlete}
          />
        ) : (
          <ListBox
            list={availableDisciplinesView}
            onClick={onSelect}
            subtitle={selectDiscSubtitle}
          />
        )}
      </div>
    </Section>
  );
};

export const MultiDisciplineSelect = compose(
  withSelectedDisciplines,
  withToJS,
  withSelectedAthletes,
)(MultiDisciplineSelectBase);
