import { WCRA } from 'constants/theme/options';
import { getTheme } from 'helpers';
const theme = getTheme();

export const splashPageHeader =
  theme === WCRA ? 'Make every ride count' : 'RODEO ENTRY TOOL';

export const splashPageSubHeader =
  theme === WCRA
    ? 'Compete for your share of millions.'
    : 'All your rodeo entries in one place.';

export const splashPageSignIn =
  theme === WCRA
    ? 'HAVE A RODEO ENTRY TOOL ACCOUNT? USE THE SAME EMAIL AND PASSWORD TO SIGN IN.'
    : 'HAVE A WCRA ACCOUNT? USE THE SAME EMAIL AND PASSWORD TO SIGN IN.';

export const themeUrl =
  theme === WCRA ? 'https://app.wcrarodeo.com' : 'https://entrytool.com';
