// @flow
import { Record } from 'immutable';
import { isYouthEvent } from '../helpers/isYouthEvent';
import type { EventType } from './Event';
export type DisciplineSegmentType = {
  SegmentUID: ?number,
  Name: ?string,
  StartDate: string,
  EndDate: string,
  ShortName: ?string,
  NominatableByYouth: ?boolean,
  HasOpen: ?boolean,
  HasYouth: ?boolean,
  WomensOnly: ?boolean,
};

export type SegmentType = DisciplineSegmentType & {
  value: number,
  label: string,
};

const defaultValues: DisciplineSegmentType = {
  SegmentUID: null,
  Name: '',
  StartDate: '',
  EndDate: '',
  ShortName: '',
  NominatableByYouth: true,
  HasOpen: null,
  HasYouth: null,
  WomensOnly: null,
};

export default class DisciplineSegment extends Record(
  defaultValues,
  DisciplineSegment,
) {
  constructor(values: ?DisciplineSegmentType) {
    super({
      ...values,
    });
  }
}

export const getFilteredSegments = (
  segments: Array<SegmentType>,
  isYouthAthlete?: boolean,
  isFemaleAthlete?: boolean,
  event: EventType,
) => {
  const youthEvent = isYouthEvent(event.EventRank);
  // prettier-ignore
  return isYouthAthlete
    ? isFemaleAthlete
      ? (segments.filter((s) => (youthEvent ? s.NominatableByYouth && s.HasYouth : (s.HasOpen || s.WomensOnly))): Array<SegmentType>)
      : (segments.filter((s) => (youthEvent ? s.NominatableByYouth && s.HasYouth : (s.HasOpen) && !s.WomensOnly)): Array<SegmentType>)
    : isFemaleAthlete
      ? (segments.filter((s) => !youthEvent && s.HasOpen): Array<SegmentType>)
      : (segments.filter((s) => !youthEvent && s.HasOpen && !s.WomensOnly): Array<SegmentType>)
};

export const getFilteredClassSegments = (
  segments: Array<SegmentType>,
  isYouthAthlete?: boolean,
  isFemaleAthlete?: boolean,
  classDivision?: number,
) => {
  const preFilteredSegments = getFilteredSegments(
    segments,
    isYouthAthlete,
    isFemaleAthlete,
    // $FlowIgnore
    {
      EventRank: classDivision,
    },
  );
  const isYouthClassDivision = isYouthEvent(classDivision);
  if (!isYouthClassDivision) return preFilteredSegments;

  // $FlowIgnore
  return preFilteredSegments.filter((s) => s.HasYouth);
};
