import React from 'react';
import { formatDateTimeToCST } from 'helpers';

const DateAndTime = (props) => {
  const { performances } = props;

  return (
    <>
      <div className="entry-discipline-dateAndTime">DATE(S) AND TIME(S):</div>
      {performances && performances.length > 0 ? (
        performances.map(({ PerformanceUID, PerformanceDate, TimeZoneUID }) => {
          return (
            <div key={PerformanceUID}>
              {formatDateTimeToCST(PerformanceDate, TimeZoneUID)}
            </div>
          );
        })
      ) : (
        <div>No Performances available for this Discipline Class</div>
      )}
    </>
  );
};

export default DateAndTime;
