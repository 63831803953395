import React from 'react';
import Select from 'react-select';
import classNames from 'classnames';

const SelectFilter = (props) => {
  const {
    label,
    onChange,
    options,
    placeholder,
    value,
    optionRenderer,
    className,
    isOnBuddyEntryFlow,
    initialEmptyValue,
    disabled,
  } = props;

  // @TODO: Replace athletes for real buddies when those are available from the users
  const buddiesMock = options;

  return (
    <>
      <hr />
      <label htmlFor="events">{label}</label>
      <Select
        className={classNames('select-discipline', className)}
        clearable={false}
        label={isOnBuddyEntryFlow ? 'Search for athletes' : null}
        name="eventType"
        onChange={onChange}
        options={isOnBuddyEntryFlow ? buddiesMock : options}
        optionRenderer={optionRenderer}
        placeholder={placeholder}
        searchable={false}
        value={isOnBuddyEntryFlow && initialEmptyValue ? '0' : value}
        disabled={disabled}
      />
    </>
  );
};

export default SelectFilter;
