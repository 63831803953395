import React from 'react';
import NumberFormat from 'react-number-format';

const PriceFormat = ({ price }) => {
  return price >= 0 ? (
    <NumberFormat
      value={price}
      decimalScale={2}
      fixedDecimalScale={true}
      displayType={'text'}
      thousandSeparator={true}
      prefix={'$'}
    />
  ) : (
    ''
  );
};

export default PriceFormat;
