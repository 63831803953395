// @flow
import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import countries from 'node-countries';
import { getNestedProperty } from 'helpers';
import { Check } from 'react-feather';
import {
  cityTown,
  required,
  signatureRequirement,
  postalCode,
} from 'components/Forms/Validation';
import {
  AVAILABLE_COUNTRY_CODES,
  AVAILABLE_COUNTRIES_AND_DETAILS,
  ZIP_LENGTH_BY_COUNTRY,
} from 'constants/address';

type LiabilityAcceptanceFormPropsType = {|
  onAddChildAttributes: (f: Function) => any,
  child: Object,
|};

const countryOptions = countries.JSON.filter((country) =>
  AVAILABLE_COUNTRY_CODES.includes(country.alpha2),
);

const LiabilityAcceptanceForm = (props: LiabilityAcceptanceFormPropsType) => {
  const { child, onAddChildAttributes } = props;

  const loadCountry = () => {
    const loadedCountry = countryOptions.find(
      (option) => option.alpha2 === getNestedProperty('country', child, ''),
    );

    return loadedCountry
      ? loadedCountry
      : countryOptions.find((country) => country.alpha2 === 'US');
  };

  const loadState = (country) => {
    return country
      ? country.provinces.find(
          (province) =>
            province.short === getNestedProperty('state', child, ''),
        )
      : undefined;
  };

  const [country, setCountry] = useState(loadCountry());
  const [signature, setSignature] = useState(
    getNestedProperty('signature', child),
  );
  const [street, setStreet] = useState(getNestedProperty('street', child));
  const [city, setCity] = useState(getNestedProperty('city', child));
  const [state, setState] = useState(loadState(country));
  const [zip, setZip] = useState(getNestedProperty('zip', child));
  const [isDisclosable, setIsDisclosable] = useState(
    getNestedProperty('isDisclosable', child, false),
  );
  const [fieldError, setFieldError] = useState<boolean>(false);

  const handleCountry = (value) => {
    setCountry(value);
    setState(undefined);
    setZip(undefined);
  };

  useEffect(() => {
    onAddChildAttributes({
      country: getNestedProperty('alpha2', country),
      signature: signature,
      street: street,
      city: city,
      state: getNestedProperty('short', state),
      zip: zip,
      isDisclosable: isDisclosable,
      fieldError: fieldError,
    });
    checkIfFormIsErrored();
  }, [signature, country, street, city, state, zip, isDisclosable, fieldError]);

  const checkIfFormIsErrored = () => {
    const isFieldNotEmptyAndErrored =
      (signature && signatureRequirement(signature)) ||
      !country ||
      (city && cityTown(city)) ||
      (zip && postalCode(zip, getNestedProperty('alpha2', country)));

    setFieldError(isFieldNotEmptyAndErrored);
  };

  const getCountryDetails = () => {
    const countryToFind = country ? country.alpha2 : 'US';
    const { [countryToFind]: countryDetails } = AVAILABLE_COUNTRIES_AND_DETAILS;
    if (countryDetails === undefined) {
      return AVAILABLE_COUNTRIES_AND_DETAILS['US'];
    }
    return countryDetails;
  };

  const getStateOptions = () => {
    if (!country || !AVAILABLE_COUNTRY_CODES.includes(country.alpha2)) {
      return [];
    }

    return country ? country.provinces : [];
  };

  const isZipInvalid = (countryName: string, zip: string) => {
    const isUSZipInvalid =
      countryName === 'United States' &&
      (zip.length > ZIP_LENGTH_BY_COUNTRY.US ||
        (zip.length === ZIP_LENGTH_BY_COUNTRY.US &&
          (isNaN(zip) ||
            postalCode(zip, getNestedProperty('alpha2', country)))));

    const isCAZipInvalid =
      countryName === 'Canada' &&
      (zip.length > ZIP_LENGTH_BY_COUNTRY.CA ||
        (zip.length === ZIP_LENGTH_BY_COUNTRY.CA &&
          postalCode(zip, getNestedProperty('alpha2', country))));

    return isUSZipInvalid || isCAZipInvalid;
  };

  const countryDetails = getCountryDetails();
  const stateOptions = getStateOptions();
  const postalLabel = countryDetails.postalTerm;
  const stateProvinceLabel = countryDetails
    ? countryDetails.administrativeDivision
    : 'State';

  return (
    <>
      <form>
        <fieldset>
          <div className="section-signature">
            <div className="title">Electronic signature</div>
            <hr className="martop-1" />
            <label htmlFor="electronicSignature">
              Parent/guardian signature<span>*</span>
            </label>
            <input
              name="electronicSignature"
              placeholder="enter full legal name"
              onChange={(e) => {
                setSignature(e.target.value);
              }}
              type="text"
              required
              value={signature}
            />
            <hr className="marbot-0" />
            <>
              {signature && signature !== '' ? (
                <div className="error-message">
                  {signatureRequirement(signature)}
                </div>
              ) : null}
              {signature === '' ? (
                <div className="error-message">{required(signature)}</div>
              ) : null}
            </>
            <div className="fine-print">
              By typing my name I agree that the signature will be the
              electronic representation of my signature - just the same as a
              pen-and-paper signature.
            </div>
          </div>
          <div className="section-address">
            <div className="title">Your address</div>
            <hr className="martop-1 half" />
            <div className="row-wrapper">
              <div className="half">
                <label htmlFor="country">
                  Country<span>*</span>
                </label>
                <Select
                  clearable={false}
                  name="country"
                  onChange={(value) => handleCountry(value)}
                  options={countryOptions}
                  placeholder={'Select a country'}
                  searchable
                  valueKey="alpha2"
                  labelKey="name"
                  value={country}
                />
                <hr />
              </div>
              <div className="half-right" />
            </div>
            <div className="row-wrapper">
              <div className="full">
                <label htmlFor="street">
                  Street address<span>*</span>
                </label>
                <input
                  name="street"
                  placeholder="enter street address"
                  onChange={(e) => setStreet(e.target.value)}
                  type="text"
                  required
                  value={street}
                />
                <hr />
                {street === '' && (
                  <div className="error-message">
                    <div className="error-message"> {required(street)}</div>
                  </div>
                )}
              </div>
            </div>
            <div className="row-wrapper">
              <div className="half">
                <label htmlFor="city">
                  City<span>*</span>
                </label>
                <input
                  name="city"
                  placeholder="enter city"
                  onChange={(e) => setCity(e.target.value)}
                  type="text"
                  required
                  value={city}
                />
                <hr />
                <>
                  {city && city !== '' ? (
                    <div className="error-message"> {cityTown(city)}</div>
                  ) : null}
                  {city === '' ? (
                    <div className="error-message"> {required(city)}</div>
                  ) : null}
                </>
              </div>
              <div className="half-right">
                <label htmlFor="state">
                  {stateProvinceLabel}
                  <span>*</span>
                </label>
                <Select
                  clearable={false}
                  name="state"
                  className="state"
                  onChange={(value) => setState(value)}
                  options={stateOptions}
                  placeholder={`Select a ${stateProvinceLabel}`}
                  searchable
                  valueKey="short"
                  labelKey="name"
                  value={state}
                />
                <hr />
                {country &&
                state &&
                getNestedProperty('short', state) === null ? (
                  <div className="error-message"> {required(state)}</div>
                ) : null}
              </div>
            </div>
            <div className="row-wrapper">
              <div className="half">
                <label htmlFor="zip">
                  {postalLabel}
                  <span>*</span>
                </label>
                <input
                  name="zip"
                  placeholder={`Enter ${postalLabel}`}
                  onChange={(e) => {
                    setZip(e.target.value);
                  }}
                  required
                  type="text"
                  value={zip || ''}
                />
                <hr />
                <>
                  {zip === '' ? (
                    <div className="error-message">{required(zip)}</div>
                  ) : null}
                  {zip &&
                  zip !== '' &&
                  country &&
                  isZipInvalid(country.name, zip) ? (
                    <div className="error-message">
                      {postalCode(zip, getNestedProperty('alpha2', country))}
                    </div>
                  ) : null}
                </>
              </div>
            </div>
          </div>
          <div className="section-disclosure">
            <div className="form-checkbox-wrapper">
              <span
                className={`input-checkbox ${isDisclosable && 'checked'}`}
                onClick={() => setIsDisclosable(!isDisclosable)}
                value="checkbox-disclosure"
              >
                <Check className="checkmark" />
              </span>
              <label htmlFor="checkbox-disclosure" className="checkbox-label">
                I agree to disclose this information to 3rd parties
              </label>
            </div>
          </div>
        </fieldset>
      </form>
    </>
  );
};

export default LiabilityAcceptanceForm;
