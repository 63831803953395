// @flow
import React, { useEffect, useState } from 'react';
import { formatSSNDisplayingLastFourDigitsOnly } from 'helpers';
import { SSNDummyValue } from 'constants/personal';
import FormField from 'components/FormField';

type SSNFormFieldProps = {|
  input: Object,
  SSNLastFour?: string,
  touched?: ?boolean,
  active?: ?boolean,
  error: ?any,
|};

const regExp = new RegExp('^[0-9]+$');

export const SSNFormField = ({
  input: { value, onChange },
  SSNLastFour,
  touched,
  error,
  active,
}: SSNFormFieldProps) => {
  const [maskedValue, setMaskedValue] = useState('');

  useEffect(() => {
    setMaskedValue(formatSSNDisplayingLastFourDigitsOnly(value));
  }, [value]);

  useEffect(() => {
    if (SSNLastFour) {
      const initialValue = SSNDummyValue + SSNLastFour;
      onChange(initialValue);
    }
  }, [SSNLastFour]);

  const onChangeInternal = (e) => {
    const internalValue = e.target.value;
    const hasErasedACharacter = internalValue.length < maskedValue.length;
    if (hasErasedACharacter) {
      onChange('');
      return;
    }
    const lastInputKey = internalValue[internalValue.length - 1];
    if (regExp.test(lastInputKey) && value.length < 9) {
      const newValue = value + lastInputKey;
      onChange(newValue);
    }
  };

  return (
    <FormField
      type="tel"
      placeholder="###-##-####"
      input={{ value: maskedValue, onChange: onChangeInternal }}
      touched={touched}
      error={error}
      active={active}
    />
  );
};

SSNFormField.defaultProps = {
  input: { value: '' },
  SSNLastFour: '',
  touched: false,
  error: undefined,
  active: false,
};

export default SSNFormField;
