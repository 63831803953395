// @flow
import React, { PureComponent } from 'react';
import { Field, change, getFormValues } from 'redux-form';
import { connect } from 'react-redux';
import Select from 'react-select';
import { Check } from 'react-feather';
import moment from 'moment';

import { isEmpty } from 'lodash';

import {
  userAgeVerify,
  youthAgeVerify,
  isValidMomentDate,
  getNestedProperty,
} from 'helpers';

import { getEntryInvite } from 'actions/entryInvite';
import { addUserRegistration } from 'actions/registration';

import { getNominatableDisciplineOptions } from 'constants/disciplines';
import { gendersArray } from 'constants/personal';

import { required, name, email, password } from 'components/Forms/Validation';
import { phone } from 'components/Forms/Normalizer';
import GenderRadioButton from 'components/Forms/GenderRadioButton';
import DateOfBirthInput from 'components/DateOfBirthInput';
import ReduxFormCheckbox from 'components/Forms/ReduxFormCheckbox';
import FormField from 'components/Forms/FormField';
import NameField from 'components/NameField';
import Notification from 'components/Notification';

import UserTerms from './UserTerms';
import SignIn from './SignIn';

const mapStateToProps = (state) => ({
  values: getFormValues('registration')(state),
});

type RegisterUserFormPropsType = {|
  loading: boolean,
  youthInviteToken: string,
  errorMessage: string,
  isFormValid: boolean,
  values: Object,
  dispatch: Function,
  goToTerms: (f: Function) => void,
  addChildInfo: (f: Function) => void,
|};

type RegisterUserFormStateType = {|
  termsIsChecked: boolean,
  disciplineId: ?number,
  parentDateOfBirth: ?moment$Moment,
  youthDateOfBirth: ?moment$Moment,
  showDisciplineError: boolean,
  showDateOfBirthError: boolean,
  showYouthDateOfBirthError: boolean,
|};

type DisciplineObjectType = {|
  value: ?number,
|};

class RegisterYouthUserForm extends PureComponent<
  RegisterUserFormPropsType,
  RegisterUserFormStateType,
> {
  state = {
    termsIsChecked: false,
    disciplineId: null,
    showDisciplineError: false,
    showDateOfBirthError: false,
    showYouthDateOfBirthError: false,
    parentDateOfBirth: null,
    youthDateOfBirth: null,
  };

  async componentDidMount() {
    const { dispatch, values } = this.props;

    if (!values) {
      await this.loadEntryInvite();
    } else if (!isEmpty(values)) {
      this.loadParentDateOfBirth();
      this.loadYouthDateOfBirth();
      this.loadPreviousValuesToState(values);
    }

    dispatch(change('registration', 'isParent', true));
  }

  loadPreviousValuesToState = (userInfo: Object) => {
    const disciplineId = userInfo.child ? userInfo.child.disciplineId : null;
    this.setState({
      disciplineId,
      termsIsChecked: userInfo.terms,
    });
  };

  loadParentDateOfBirth = () => {
    const { values } = this.props;
    const reformattedDOB = this.reformatDOBString(values.DOB);
    const parentDateOfBirth = moment(reformattedDOB, 'MMDDYYYY');
    this.setState({ parentDateOfBirth });
  };

  loadYouthDateOfBirth = () => {
    const { values } = this.props;
    if (values.child) {
      const reformattedDOB = this.reformatDOBString(values.child.DOB);
      const youthDateOfBirth = moment(reformattedDOB, 'MMDDYYYY');
      this.setState({ youthDateOfBirth });
    }
  };

  reformatDOBString = (dateOfBirthString: string) => {
    const reformattedDOB = moment(dateOfBirthString)
      .format('MMDDYYYY')
      .toString();
    return reformattedDOB;
  };

  loadEntryInvite = async () => {
    const { dispatch, youthInviteToken } = this.props;
    const entryInvitePayload = await getEntryInvite(youthInviteToken);
    if (!entryInvitePayload) return;

    const { firstName, lastName, email } = entryInvitePayload;
    dispatch(change('registration', 'child.firstName', firstName));
    dispatch(change('registration', 'child.lastName', lastName));
    dispatch(change('registration', 'email', email));
  };

  onChangeDiscipline = (disciplineObject: DisciplineObjectType): Function => {
    const disciplineId = disciplineObject ? disciplineObject.value : null;
    const { dispatch } = this.props;
    dispatch(change('registration', 'child.disciplineId', disciplineId));
    this.setState({ disciplineId, showDisciplineError: false });
  };

  onCheckBoxClicked = () => {
    const { termsIsChecked } = this.state;
    const { dispatch } = this.props;
    dispatch(change('registration', 'terms', !termsIsChecked));
    this.setState({ termsIsChecked: !termsIsChecked });
  };

  onChangeParentDateOfBirth = (dateOfBirthString: string) => {
    const { dispatch } = this.props;
    const parentDateOfBirth = moment(dateOfBirthString, 'MMDDYYYY');
    const userDOB = parentDateOfBirth.format('YYYY-MM-DD').toString();
    dispatch(change('registration', 'DOB', userDOB));

    this.setState({ parentDateOfBirth });
  };

  onChangeYouthDateOfBirth = (dateOfBirthString: string) => {
    const { dispatch } = this.props;
    const youthDateOfBirth = moment(dateOfBirthString, 'MMDDYYYY');
    const childDOB = youthDateOfBirth.format('YYYY-MM-DD').toString();
    dispatch(change('registration', 'child.DOB', childDOB));

    this.setState({ youthDateOfBirth });
  };

  onGenderSelect = (event: SyntheticInputEvent<EventTarget>) => {
    const { dispatch } = this.props;
    const { value, name } = event.target;
    dispatch(change('registration', name, value));
  };

  onSubmit = async (e: Event) => {
    e.preventDefault();

    const { disciplineId, parentDateOfBirth, youthDateOfBirth } = this.state;
    const { addChildInfo, goToTerms, dispatch, values } = this.props;

    if (disciplineId === null || !disciplineId) {
      this.setState({ showDisciplineError: true });
      return null;
    }

    if (
      !isValidMomentDate(parentDateOfBirth) ||
      userAgeVerify(parentDateOfBirth)
    ) {
      this.setState({ showDateOfBirthError: true });
      return null;
    }

    if (
      !isValidMomentDate(youthDateOfBirth) ||
      youthAgeVerify(youthDateOfBirth)
    ) {
      this.setState({ showYouthDateOfBirthError: true });
      return null;
    }

    const validateEmail = await dispatch(addUserRegistration(values));

    if (validateEmail.isEmailValid) {
      addChildInfo();
      goToTerms();
    }
  };

  render() {
    const {
      termsIsChecked,
      showDisciplineError,
      disciplineId,
      parentDateOfBirth,
      youthDateOfBirth,
    } = this.state;

    const { loading, isFormValid, errorMessage, values } = this.props;

    const checkedYouthGender = getNestedProperty('child.Gender', values);

    return (
      <>
        <h2 className="section-title center marbot-2 padtop-1">Register</h2>
        Create an account to complete the entry for your youth athlete.
        <h5 className="section-registration-subtitle">Account details</h5>
        <hr />
        <form className="registration-form" onSubmit={this.onSubmit}>
          {errorMessage && <Notification text={errorMessage} />}

          <fieldset className="padbot-5">
            <label htmlFor="firstName">First Name*</label>
            <NameField
              name="firstName"
              component={FormField}
              placeholder="First Name"
              validate={[name]}
            />
            <hr className="martop-0" />

            <label htmlFor="lastName">Last Name*</label>
            <NameField
              name="lastName"
              component={FormField}
              placeholder="Last Name"
              validate={[name]}
            />
            <hr className="martop-0" />

            <label htmlFor="email">Your Email*</label>
            <Field
              name="email"
              component={FormField}
              type="email"
              placeholder="Email Address"
              validate={[required, email]}
            />
            <hr className="martop-0" />

            <label htmlFor="password">Password*</label>
            <Field
              name="password"
              component={FormField}
              className="form-control registration"
              type="password"
              placeholder="Password"
              validate={[required, password]}
            />
            <hr className="martop-0" />

            <label htmlFor="birthdate">Birth Date*</label>
            <DateOfBirthInput
              pickedDate={parentDateOfBirth}
              onChange={this.onChangeParentDateOfBirth}
            />
            {this.state.showDateOfBirthError && (
              <Notification text="Parents/guardians must register for athletes under 18" />
            )}
            <hr className="martop-0" />

            <label htmlFor="phone">Phone Number*</label>
            <Field
              name="phone"
              component={FormField}
              type="tel"
              placeholder="Phone Number"
              normalize={phone}
              validate={[required]}
            />
            <hr className="martop-0" />

            <h5 className="section-registration-subtitle">
              Youth Athlete Profile
            </h5>
            <hr />
            <label htmlFor="firstName">First Name*</label>
            <NameField
              name="child.firstName"
              component={FormField}
              placeholder="First Name"
              validate={[name]}
            />
            <hr className="martop-0" />

            <label htmlFor="lastName">Last Name*</label>
            <NameField
              name="child.lastName"
              component={FormField}
              placeholder="Last Name"
              validate={[name]}
            />
            <hr className="martop-0" />

            <label htmlFor="birthdate">Birth Date*</label>
            <DateOfBirthInput
              pickedDate={youthDateOfBirth}
              onChange={this.onChangeYouthDateOfBirth}
            />
            {this.state.showYouthDateOfBirthError && (
              <Notification text="Youth Athlete must be under the age of 18" />
            )}
            <hr className="martop-0" />

            <label htmlFor="discipline">Main Discipline*</label>
            <Select
              className="select-discipline"
              clearable={false}
              name="child.disciplineId"
              onChange={this.onChangeDiscipline}
              options={getNominatableDisciplineOptions()}
              placeholder="Select"
              searchable={false}
              value={disciplineId}
            />
            {showDisciplineError && (
              <span className="validation-message">
                Main discipline must be selected
              </span>
            )}

            <hr className="martop-0" />
            <div className="gender-container">
              <div className="gender-label label">GENDER</div>
              {gendersArray.map((gender) => {
                return (
                  <Field
                    component={GenderRadioButton}
                    type="radio"
                    name="child.Gender"
                    id="child.Gender"
                    gender={gender}
                    className="gender-option"
                    checkedGender={checkedYouthGender}
                    onGenderSelect={this.onGenderSelect}
                    key={gender.value}
                  />
                );
              })}
            </div>

            <Field
              component={ReduxFormCheckbox}
              type="checkbox"
              name="parent"
              id="parent"
              className="hide"
            />

            <div className="martop-3">
              <div className="form-checkbox-wrapper">
                <span
                  className={`input-checkbox ${
                    termsIsChecked ? 'checked' : ''
                  }`}
                  onClick={this.onCheckBoxClicked}
                >
                  <Check className="checkmark" />
                </span>
                <UserTerms />
              </div>
              <Field
                component={ReduxFormCheckbox}
                type="checkbox"
                name="terms"
                id="terms"
                validate={[required]}
                className="hide"
              />
            </div>
          </fieldset>
          <div className="text-center">
            {loading ? (
              <button className="btn-main marbot-5" type="button">
                Registering...
              </button>
            ) : (
              <button
                type="submit"
                className="btn-main marbot-5"
                disabled={!isFormValid}
              >
                Continue
              </button>
            )}
            <SignIn />
          </div>
        </form>
      </>
    );
  }
}

// $FlowIgnore
export default connect(mapStateToProps)(RegisterYouthUserForm);
