import gql from 'graphql-tag';

export const GET_ENTRY_INVITE = gql`
  query GET_ENTRY_INVITE($input: EntryInviteInput!) {
    entryInviteGet(input: $input) {
      firstName
      lastName
      email
      phone
    }
  }
`;
