import React from 'react';
import PropTypes from 'prop-types';

const CheckboxField = (props) => {
  const { input, className, name, meta } = props;
  const { touched, error } = meta;

  return (
    <div className="form-group">
      <input
        {...input}
        className={className}
        type="checkbox"
        id={name || props.id}
      />
      {touched && error && <span className="validation-message">{error}</span>}
    </div>
  );
};

CheckboxField.propTypes = {
  input: PropTypes.object.isRequired,
  className: PropTypes.string,
  name: PropTypes.string,
  touched: PropTypes.bool,
  error: PropTypes.string,
  meta: PropTypes.object,
};

CheckboxField.defaultProps = {
  className: '',
  name: '',
  label: '',
  error: '',
};

export default CheckboxField;
