// @flow
import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Tooltip } from '@material-ui/core';
import { Help } from '@material-ui/icons';
import { StylesProvider } from '@material-ui/core/styles';

import Section, { SectionTitle, SectionContent } from 'components/Section';
import { useDisciplineModalHooks } from './hooks';
import EntrySegmentChecklist from 'components/EntrySegmentChecklist';
import type { SegmentType } from 'context/SelectedSegments/type';
import { getDisciplineSegments } from 'selectors/disciplineSegments';
import {
  getDisciplinesNominatableBySegment,
  disciplineSegmentsDataReset,
} from 'actions/disciplineSegments';

type Props = {|
  event: Object,
  isYouthAthlete: ?boolean,
  isFemaleAthlete: ?boolean,
  filteredSegments: Array<any>,
  areDifferentEventDates: ?boolean,
|};

const SelectSegments = (props: Props) => {
  const { activeDiscipline, toggleSelectSegment } = useDisciplineModalHooks();
  const {
    event,
    isYouthAthlete,
    isFemaleAthlete,
    areDifferentEventDates,
    filteredSegments,
  } = props;
  const activeDisciplineId = activeDiscipline && activeDiscipline.value;
  const dispatch = useDispatch();
  const disciplineSegments = useSelector(getDisciplineSegments);

  useEffect(() => {
    const asyncGetDisciplineNominatableSegments = async () => {
      await dispatch(getDisciplinesNominatableBySegment([activeDisciplineId]));
    };

    asyncGetDisciplineNominatableSegments();
  }, [activeDisciplineId]);

  useEffect(() => {
    return () => {
      dispatch(disciplineSegmentsDataReset());
    };
  }, []);

  const toggleSegment = useCallback(
    async (segment) => {
      if (activeDisciplineId) {
        return toggleSelectSegment(activeDisciplineId, segment);
      }
    },
    [activeDisciplineId],
  );

  const isSelected = (option: SegmentType) => {
    if (activeDiscipline) {
      return !!activeDiscipline.segments.filter(
        (selected) => selected.value === option.value,
      ).length;
    }
    return false;
  };

  const toolTipText = `Nominating an entry for WCRA means WCRA tracks your results so you can earn points, cash bonuses, and qualify for WCRA semi-finals and major events.`;

  if (!activeDiscipline) return null;

  const activeDisciplineSegments = disciplineSegments[activeDisciplineId];

  if (!activeDisciplineSegments) return null;

  const nominatableSegments = filteredSegments.filter((fs) =>
    activeDisciplineSegments.includes(fs.value),
  );

  if (nominatableSegments.length === 0) return null;

  return (
    <Section>
      <SectionTitle
        optional
        titleClassName="section-title-wide"
        optionalClassName="section-optional-label-small"
      >
        WCRA Nomination{' '}
        <div
          style={{
            display: 'inline',
            verticalAlign: 'middle',
          }}
        >
          <StylesProvider injectFirst>
            <Tooltip
              classes={{
                arrow: 'black-tooltip-arrow',
                tooltip: 'black-tooltip',
                popper: 'black-popper',
              }}
              title={toolTipText}
              enterTouchDelay={0}
              leaveTouchDelay={5000}
              arrow
            >
              <Help className="tooltip-help-icon" fontSize="small" />
            </Tooltip>
          </StylesProvider>
        </div>
      </SectionTitle>
      {areDifferentEventDates ? (
        <p>
          There are currently no active segments to nominate for this event.
          Questions? Please call 833-213-9272
        </p>
      ) : (
        <SectionContent className="first-hr-no-margin">
          <div className="segment-check-list">
            <EntrySegmentChecklist
              isYouthAthlete={isYouthAthlete}
              isFemaleAthlete={isFemaleAthlete}
              isSelected={isSelected}
              toggleSegment={toggleSegment}
              event={event}
            />
          </div>
        </SectionContent>
      )}
    </Section>
  );
};

export default SelectSegments;
