import get from 'lodash/get';
import { createSelector } from 'reselect';

export const getAssociationMembership = (state) =>
  state.associationMembership.toJS().data;
export const getSelectedMembershipStatus = (state) => {
  return get(
    state.associationMembership.toJS().data,
    'selectedMembershipStatus',
  );
};
export const getSelectedAssociationTypeFeeUID = (state) => {
  return get(
    state.associationMembership.toJS().data,
    'selectedAssociationTypeFeeUID',
  );
};

export const getSelectedMembershipFee = createSelector(
  getAssociationMembership,
  (associationMembership) => {
    const {
      selectedAssociationTypeFeeUID,
      membershipFees,
    } = associationMembership;

    return get(
      membershipFees.find(
        (membershipFee) =>
          membershipFee.AssociationTypeFeeUID === selectedAssociationTypeFeeUID,
      ),
      'FeeAmount',
    );
  },
);
