// @flow
import React, { type Node } from 'react';
import Header from 'components/Header';
import Footer from 'components/Footer';
import Loader from 'components/Loader';

type LayoutPropsType = {|
  className: string,
  loading: boolean,
  children: Node,
|};

const Layout = (props: LayoutPropsType) => {
  const { className, children, loading } = props;
  return (
    <div className={`layout ${className}`}>
      <Header />
      <Loader loading={loading} />
      <main>{children}</main>
      <Footer />
    </div>
  );
};

Layout.defaultProps = {
  className: '',
  loading: false,
  children: null,
};
export default Layout;
