// @flow
import React, { useState } from 'react';
import { change, untouch } from 'redux-form';

import RegisterUserForm from './RegisterUserForm';
import RegisterYouthUserForm from './RegisterYouthUserForm';
import RegisterChildForm from './RegisterChildForm';
import YouthRegistrationStatus from './YouthRegistrationStatus';
import YouthLiabilityTerms from './YouthLiabilityTerms';
import {
  changeRoute,
  getNestedProperty,
  getPendingEntriesCount,
  getTheme,
} from 'helpers';
import {
  EVENT_ENTRY_SEARCH,
  EVENT_ENTRY_UPCOMING_EVENTS,
  MANAGE_YOUTH_ATHLETES,
  NOMINATE_SEARCH,
} from 'constants/routes';
import {
  addChildRegistration,
  removeChildRegistration,
  resetChildrenRegistration,
  addUserRegistration,
  resetRegistration,
  setCurrentRegistrationInfo,
  setCurrentRegistrationConsent,
  resetCurrentRegistration,
} from 'actions/registration';

import { useToasts } from 'react-toast-notifications';
import { connect } from 'react-redux';
import { hasFeatureFlag } from 'utils/flagsmith';

type RegistrationViewPropsType = {|
  handleSubmit: (f: Function) => any,
  dispatch: (f: Function) => any,
  errorMessage: string,
  inviteToken: string,
  youthInviteToken: string,
  loading: boolean,
  onSubmit: (f: Function) => any,
  registerNewChildForm: boolean,
  registration: Object,
  history: Object,
  youthAthletes: Object[],
  currentAthlete: CurrentAthleteType,
  authenticated: boolean,
  isFormValid: boolean,
|};

type ChildDataType = {|
  firstName: string,
  lastName: string,
  DOB: string,
  Gender: string,
  disciplineId: string,
|};

type CurrentAthleteType = {|
  terms: ConsentDataType,
  info: ChildDataType,
|};

type ChildDataFormType = {|
  child: ChildDataType,
|};

type ConsentDataType = {|
  fullName: string,
  signature: string,
  country: string,
  street: string,
  city: string,
  state: string,
  zip: string,
  isDisclosable: boolean,
|};

const mapStateToProps = (state) => ({
  authenticated: state.auth.data.get('authenticated'),
});

const RegistrationView = ({
  dispatch,
  handleSubmit,
  inviteToken,
  youthInviteToken,
  loading,
  errorMessage,
  registerNewChildForm,
  registration,
  youthAthletes,
  currentAthlete,
  onSubmit,
  history,
  authenticated,
  isFormValid,
}: RegistrationViewPropsType) => {
  const viewStateInitialValue = youthInviteToken
    ? 'register-youth-user'
    : 'register-user';
  const [viewState, setViewState] = useState(viewStateInitialValue);
  const [isFormIncomplete, setIsFormIncomplete] = useState(false);
  const { addToast } = useToasts();

  const resetFields = () => {
    const resetFields = [
      'child.firstName',
      'child.lastName',
      'child.DOB',
      'child.Gender',
      'child.disciplineId',
    ];

    resetFields.forEach((field) => {
      dispatch(change('registration', field, ''));
      dispatch(untouch('registration', field));
    });
  };

  const addChildConsent = (values: ConsentDataType) => {
    dispatch(addChildRegistration({ ...currentAthlete.info, ...values }));
    dispatch(setCurrentRegistrationConsent(values));
  };

  const addChildInfo = (values: ChildDataFormType) => {
    const { child } = values;

    dispatch(addChildRegistration(child));
    dispatch(setCurrentRegistrationInfo(child));
  };

  const goToYouthStatus = () => {
    if (authenticated || youthInviteToken) {
      setIsFormIncomplete(false);
      onFinalSubmit();
      return;
    }
    setViewState('register-youth-status');
    setIsFormIncomplete(true);
  };

  const goToRegisterYouth = (): Function => {
    setViewState('register-youth');
    dispatch(resetCurrentRegistration());
    resetFields();
  };

  const removeYouthAthlete = (athlete: ChildDataType): Function => {
    const previousChild =
      youthAthletes.length > 1 ? youthAthletes[youthAthletes.length - 2] : null;

    dispatch(removeChildRegistration(athlete));

    if (previousChild) {
      dispatch(setCurrentRegistrationInfo(previousChild));
      dispatch(setCurrentRegistrationConsent(previousChild));
    } else {
      dispatch(resetCurrentRegistration());
    }
  };

  const resetYouthAthletes = (): Function => {
    dispatch(resetChildrenRegistration());
    dispatch(resetCurrentRegistration());
  };

  const onUserSubmit = async (values: Object): Function => {
    const isParent = getNestedProperty('isParent', values, false);

    const validateEmail = await dispatch(addUserRegistration(values));
    // Needed when the user goes back to initial registration.
    dispatch(resetChildrenRegistration());

    if (isParent && validateEmail.isEmailValid) {
      setViewState('register-youth-status');
    }
  };

  const onFinalSubmit = async (values: Object = null) => {
    if (getNestedProperty('email', values)) {
      await onUserSubmit(values);

      // Don't submit if the athlete will be adding youth athletes
      if (getNestedProperty('isParent', values)) return;
    }

    const response = await onSubmit();

    if (getNestedProperty('user.id', response)) {
      // flow for registering a user and children
      addToast('Registration complete!');
      dispatch(resetRegistration());

      const user = response.user;
      const hasPendingEntries = getPendingEntriesCount(user) > 0;
      const theme = getTheme();
      const hasMyEntries = theme === 'ret' || !hasFeatureFlag('hide_enter_vrq');

      if (hasPendingEntries) {
        return changeRoute({
          history,
          route: hasMyEntries ? EVENT_ENTRY_UPCOMING_EVENTS : NOMINATE_SEARCH,
        });
      }

      return changeRoute({
        history,
        route: hasMyEntries ? EVENT_ENTRY_SEARCH : NOMINATE_SEARCH,
      });
    }

    if (getNestedProperty('success', response)) {
      // flow for user registering children
      addToast('Youth athlete successfully added!');
      dispatch(resetRegistration());
      changeRoute({ history, route: MANAGE_YOUTH_ATHLETES });
      return;
    }

    if (response === null) {
      setViewState(viewStateInitialValue);
    }
  };

  const isParentalConsentApproved = (
    isParentContactInfoComplete?: boolean,
    consentFormInfo: Object,
  ): Function => {
    if (isParentContactInfoComplete) {
      setIsFormIncomplete(!isParentContactInfoComplete);
      addChildConsent(consentFormInfo);
    } else {
      setIsFormIncomplete(true);
    }
  };

  return (
    <>
      {(() => {
        if (!registerNewChildForm) {
          switch (viewState) {
            case 'register-user':
              return (
                // $FlowFixMe
                <RegisterUserForm
                  loading={loading}
                  onSubmit={handleSubmit(onFinalSubmit)}
                  errorMessage={errorMessage}
                  dispatch={dispatch}
                  inviteToken={inviteToken}
                  registration={registration}
                />
              );
            case 'register-youth-user':
              return (
                <RegisterYouthUserForm
                  loading={loading}
                  goToTerms={() => {
                    setViewState('register-youth-terms');
                  }}
                  addChildInfo={handleSubmit(addChildInfo)}
                  errorMessage={errorMessage}
                  dispatch={dispatch}
                  youthInviteToken={youthInviteToken}
                  isFormValid={isFormValid}
                />
              );
            case 'register-youth-status':
              return (
                <YouthRegistrationStatus
                  athletesList={youthAthletes}
                  goBackToTerms={() => {
                    if (youthAthletes.length > 0) {
                      setViewState('register-youth-terms');
                    } else {
                      setViewState('register-user');
                    }
                  }}
                  onFinalSubmit={onFinalSubmit}
                  goToRegisterYouth={goToRegisterYouth}
                  removeYouthAthlete={removeYouthAthlete}
                />
              );
            case 'register-youth':
              return (
                <RegisterChildForm
                  dispatch={dispatch}
                  errorMessage={errorMessage}
                  child={currentAthlete.info}
                  addChildInfo={handleSubmit(addChildInfo)}
                  goToTerms={() => {
                    setViewState('register-youth-terms');
                  }}
                  goBack={() => {
                    setViewState('register-user');
                    resetYouthAthletes();
                  }}
                  resetYouthAthletes={resetYouthAthletes}
                  loading={loading}
                  registerNewChildForm={registerNewChildForm}
                  isFormValid={isFormValid}
                />
              );
            case 'register-youth-terms':
              return (
                <YouthLiabilityTerms
                  loading={loading}
                  goToYouthStatus={goToYouthStatus}
                  goBackToRegistrationForm={() => {
                    const previousView = youthInviteToken
                      ? 'register-youth-user'
                      : 'register-youth';

                    setViewState(previousView);
                  }}
                  child={currentAthlete}
                  registerNewChildForm
                  onParentalConsentSubmit={isParentalConsentApproved}
                  isButtonDisabled={isFormIncomplete}
                />
              );
          }
        } else {
          // Path for registering a child from an existing parent or athlete user account
          switch (viewState) {
            case 'register-user':
              return (
                <RegisterChildForm
                  dispatch={dispatch}
                  errorMessage={errorMessage}
                  child={currentAthlete.info}
                  addChildInfo={handleSubmit(addChildInfo)}
                  goToTerms={() => {
                    setViewState('register-youth-terms');
                  }}
                  resetYouthAthletes={resetYouthAthletes}
                  loading={loading}
                  registerNewChildForm={registerNewChildForm}
                  isFormValid={isFormValid}
                />
              );
            case 'register-youth':
              return (
                <RegisterChildForm
                  dispatch={dispatch}
                  errorMessage={errorMessage}
                  child={currentAthlete.info}
                  addChildInfo={handleSubmit(addChildInfo)}
                  resetYouthAthletes={resetYouthAthletes}
                  goToTerms={() => {
                    setViewState('register-youth-terms');
                  }}
                  loading={loading}
                  registerNewChildForm={registerNewChildForm}
                  isFormValid={isFormValid}
                />
              );
            case 'register-youth-status':
              return (
                <YouthRegistrationStatus
                  athletesList={youthAthletes}
                  goBackToTerms={() => {
                    if (youthAthletes.length > 0) {
                      setViewState('register-youth-terms');
                    } else {
                      setViewState('register-youth');
                    }
                  }}
                  removeYouthAthlete={removeYouthAthlete}
                  onFinalSubmit={onFinalSubmit}
                  goToRegisterYouth={goToRegisterYouth}
                />
              );
            case 'register-youth-terms':
              return (
                <YouthLiabilityTerms
                  loading={loading}
                  goToYouthStatus={goToYouthStatus}
                  goBackToRegistrationForm={() => {
                    setViewState('register-youth');
                  }}
                  registerNewChildForm={registerNewChildForm}
                  child={currentAthlete}
                  onParentalConsentSubmit={isParentalConsentApproved}
                  isButtonDisabled={isFormIncomplete}
                />
              );
          }
        }
      })()}
    </>
  );
};

// $FlowIgnore
export default connect(mapStateToProps)(RegistrationView);
