import moment from 'moment';
import DatePicker from 'react-datepicker';
import React from 'react';
import FormControl from '@material-ui/core/FormControl';
import { InputLabel } from '@material-ui/core';

const CalendarPiker = ({
  meta,
  showError,
  invalid,
  label,
  required,
  placeholder,
  input: { value, onChange, ...inputProps },
  meta: { touched, error },
  ...rest
}) => {
  const minDate = moment.utc();
  const hasError = showError
    ? meta && meta.touched && meta.invalid && meta.error
    : (touched || invalid) && error;

  const handleChange = (date) => {
    onChange(date);
  };

  return (
    <FormControl error={!!hasError} margin="none" fullWidth>
      <InputLabel
        shrink
        className="form-label-bold form-label"
        required={required}
        htmlFor={name}
      >
        {label}
      </InputLabel>
      <DatePicker
        {...inputProps}
        {...rest}
        onBlur={() => {}}
        autocomplete="new-password"
        placeholderText={placeholder}
        selected={value}
        onChange={handleChange}
        value={value}
        minDate={minDate}
        dateFormat="MM/dd/yyyy"
      />
      {touched && error && <span className="error_field">{error}</span>}
    </FormControl>
  );
};

export default CalendarPiker;
