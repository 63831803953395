// @flow
import React from 'react';
import { FacebookShareButton, TwitterShareButton } from 'react-share';

import { getEventInfoWithDates } from '../../helpers';
import { themeUrl } from 'constants/theme';
import dbVars from 'constants/dbvars';
import type { EventType } from 'models/Event';
import type { UserType } from 'models/User';

type EntrySocialInvitePropsType = {|
  ERAUID: string,
  athleteFullName: string,
  event: EventType,
  user: UserType,
|};

export const EntrySocialInvite = ({
  user,
  event,
  ERAUID,
  athleteFullName,
}: EntrySocialInvitePropsType) => {
  const { ERAUID: userERAUID, socialTags } = user;
  const { Website: eventWebsite } = event;
  const eventInfoWithDates = getEventInfoWithDates(event);

  const socialHashtags = socialTags ? `#${socialTags}` : '';
  const baseMessage = dbVars.ENTRY_SOCIAL_SHARE.replace(
    '%event%',
    eventInfoWithDates,
  );
  const shareMessage = `${baseMessage} ${socialHashtags.replace(/,/g, ' #')}`;
  const shareMessagePlusWebsite = eventWebsite
    ? `${shareMessage} ${eventWebsite}`
    : shareMessage;

  return (
    <div className="theme-content-container card-content stacked-content-container">
      <div className="section-preferences-subtitle">Social</div>
      <div className="social-links-text-area">
        {`Share with the world that ${
          userERAUID === ERAUID ? "you're" : `${athleteFullName} is `
        } competing!`}
      </div>
      <div className="text-center">
        <div className="share-buttons-wrp">
          <FacebookShareButton url={themeUrl} quote={shareMessagePlusWebsite}>
            <span className="share-button facebook">Facebook</span>
          </FacebookShareButton>
          <TwitterShareButton url={themeUrl} title={shareMessage}>
            <span className="share-button twitter">Twitter</span>
          </TwitterShareButton>
        </div>
      </div>
    </div>
  );
};
