// @flow
import { withRouter } from 'react-router-dom';
import { reduxForm, formValueSelector } from 'redux-form';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { getInitialUserValues } from 'selectors/user';
import { mapDispatchToProps } from 'helpers';
import { LegalInfo } from './LegalInfo';

import { withSelectedDisciplines } from 'context/SelectedDisciplines';

const selector = formValueSelector('legalInfo');

const mapStateToProps = (state: any): Object => ({
  userErrorMessage: state.user.get('errorMessage'),
  loading: state.user.get('loading'),
  loadingAuth: state.auth.get('loading'),
  loadingCharge: state.entryChargeRequest.get('loading'),
  initialValues: getInitialUserValues(state),
  charge: state.entryChargeRequest.get('data'),
  USCitizen: selector(state, 'USCitizen'),
  event: state.event.get('data'),
});

export default compose(
  withSelectedDisciplines,
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
  reduxForm({
    form: 'legalInfo',
  }),
  withRouter,
)(LegalInfo);
