import { getFormattedDateString } from 'helpers';

export const ENTRY_TYPE = 'entry';
export const NO_EVENT_PERFORMANCES = 'NO PERFORMANCES AVAILABLE FOR THIS EVENT';
export const NO_ADULT_DIVISION_CLASSES =
  'NO DISCIPLINES OR CLASSES ARE AVAILABLE FOR ADULT ATHLETES';

export const eventInfoWithDates = (event) => {
  const { EventName, dateStart, dateEnd, location } = event;
  const eventName = EventName ? EventName : '';
  const startDateString = getFormattedDateString(dateStart);
  const endDateString = getFormattedDateString(dateEnd);
  const dateRangeString =
    startDateString && endDateString
      ? `from ${startDateString} to ${endDateString}`
      : '';
  const loc = location ? `${location}` : '';
  const eventInfo = loc ? `${eventName} in ${loc}` : `${eventName}`;
  const eventInfoWithDates = dateRangeString
    ? `${eventInfo} ${dateRangeString}`
    : `${eventInfo}`;

  return eventInfoWithDates;
};
