import { get } from 'lodash';
import React from 'react';
import { getHundredth } from 'helpers';
import { useDisciplineModalHooks } from './hooks';

const EntryFees = ({ teamDisciplineFee }) => {
  const { activeDiscipline } = useDisciplineModalHooks();
  const Fee = teamDisciplineFee || get(activeDiscipline, 'Fee', 0);

  const newEntryCount = get(activeDiscipline, 'newEntryCount', 0);

  if (!activeDiscipline) return null;
  return (
    <>
      <div className="entry-fee-container">
        <div>ENTRY FEE:</div>
        <div className="fee-amount">${getHundredth(newEntryCount * Fee)}</div>
      </div>
      {activeDiscipline.DisciplineFeeName ? (
        <div>
          {activeDiscipline.DisciplineFeeName}: $
          {getHundredth(newEntryCount * activeDiscipline.DisciplineFee)}
        </div>
      ) : null}
    </>
  );
};

export default EntryFees;
