// @flow
import React from 'react';
import classNames from 'classnames';

import { getTheme } from 'helpers';

type CountIndicatorPropType = {|
  count: number,
  active: boolean,
  className?: string,
|};

const theme = getTheme();

const CountIndicator = (props: CountIndicatorPropType) => {
  const { className, count, active } = props;
  if (count < 1) return null;
  return (
    <div
      className={classNames(
        'count-indicator',
        className,
        `count-indicator-${theme}`,
        {
          [`count-indicator-${theme}-selected`]: active,
        },
      )}
    >
      {count}
    </div>
  );
};

export default CountIndicator;
