import React from 'react';
import partnerBadge from 'assets/img/icons/alliancepartner.svg';
import { MULTIPLE_DIVISIONS } from '../../constants/event';

const EventDivisionPartner = (props) => {
  const { rank, rankLabel, isAlliancePartner } = props;
  const rankLabelRenamed =
    rank === MULTIPLE_DIVISIONS.rank ? MULTIPLE_DIVISIONS.name : rankLabel;

  const renderRank = () => {
    return rank !== null ? (
      <span className="event-division">
        <span className={'division-rank division-' + rank} />
        {rankLabelRenamed}
      </span>
    ) : (
      ''
    );
  };

  const renderAlliancePartnerBadge = () => {
    return isAlliancePartner ? (
      <span className="partner-badge">
        <img src={partnerBadge} alt="Alliance Partner Badge" /> alliance
      </span>
    ) : (
      ''
    );
  };
  return (
    <div className="division-and-partner-container">
      {renderRank()}
      {renderAlliancePartnerBadge()}
    </div>
  );
};

export default EventDivisionPartner;
