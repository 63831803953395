// @flow
import React, { PureComponent } from 'react';
import classNames from 'classnames';
import { ArrowLeft } from 'react-feather';
import LiabilityText from './LiabilityText';
import { Link } from 'react-router-dom';
import { MANAGE_YOUTH_ATHLETES } from 'constants/routes';

type YouthLiabilityTermsPropsType = {|
  loading: boolean,
  registerNewChildForm: boolean,
  child: Object,
  onParentalConsentSubmit: (f: Function) => any,
  isButtonDisabled: boolean,
  goBackToRegistrationForm: (f: Function) => any,
  goToYouthStatus: (f: Function) => any,
|};

class YouthLiabilityTerms extends PureComponent<YouthLiabilityTermsPropsType> {
  render() {
    const {
      loading,
      goBackToRegistrationForm,
      registerNewChildForm,
      child,
      onParentalConsentSubmit,
      isButtonDisabled,
      goToYouthStatus,
    } = this.props;

    return (
      <>
        <div>
          {registerNewChildForm ? (
            <button
              className="back-button martop-0"
              type="button"
              onClick={goBackToRegistrationForm}
            >
              <ArrowLeft className="arrow-left" />
              Go Back
            </button>
          ) : (
            <Link className="back-button martop-0" to={MANAGE_YOUTH_ATHLETES}>
              <ArrowLeft className="arrow-left" />
              Go Back
            </Link>
          )}
          <LiabilityText
            child={child}
            onParentalConsentSubmit={onParentalConsentSubmit}
          />
        </div>
        <div
          className={classNames('text-center', {
            'manage-youth-register-button': registerNewChildForm,
          })}
        >
          <button
            className="btn-main btn-main-end-of-page"
            onClick={goToYouthStatus}
            disabled={isButtonDisabled || loading}
          >
            {loading ? 'REGISTERING...' : 'I AGREE'}
          </button>
        </div>
      </>
    );
  }
}

export default YouthLiabilityTerms;
