// @flow
import get from 'lodash/get';
import React, { useState, useCallback, useEffect } from 'react';
import { useQuery } from '@apollo/react-hooks';
import { reset } from 'redux-form';

import { clearEntryCharge } from 'actions/entryChargeRequest';
import { capitalizeString, changeRoute, getNestedProperty } from 'helpers';
import Layout from 'components/Layout';
import EntryEventDescription from 'components/EntryEventDescription';
import EntryPartnerInviteModal from 'components/EntryPartnerInviteModal';
import { DisplayMembershipFees } from 'components/DisplayMembershipFees';
import { EntryAddBuddyCard } from 'components/EntryAddBuddyCard';
import { EntrySocialInvite } from 'components/EntrySocialInvite';
import { OpenStallsLink } from 'components/OpenStallsLink';
import EntryTransactions from 'containers/EntryTransactions/components/EntryTransactions';
import { EVENT_ENTRY } from 'constants/routes';
import type { EntryChargeType } from 'models/EntryChargeRequest';
import type { EventType } from 'models/Event';
import type { BuddyGroupType } from 'models/Buddy';
import { cleanMembership } from 'actions/associationMembership';
import { EVENT_ENTRY_COLLECT_MEMBERSHIP_NUMBER } from 'constants/routes';
import { ASSOCIATION_MEMBER_FEE } from 'queries/AssociationMemberFees';
import type { DisciplineType } from 'context/SelectedDisciplines/type';

type ConfirmPropsType = {|
  athleteFullName: string,
  charge: EntryChargeType,
  ERAUID: string,
  event: EventType,
  buddyGroup: BuddyGroupType,
  selectedDisciplines: DisciplineType[],
  user: Object,
  history: Object,
  isBuddyEntry: boolean,
  dispatch: Function,
  setIsBuddyEntry: Function,
  addBuddy: Function,
  setSelectedBuddyOption: Function,
  clearSelectedDisciplines: Function,
  getEventBuddies: () => Promise<Object>,
  loading: boolean,
  setBuddyManagerEntryCharge: (EntryChargeType) => void,
  toggleIsBuddySelected: (boolean) => void,
  calculateEntryCharge: Function,
  transactions: Object[],
  eventErrorMsg: string,
  match: Object,
  selectEvent: (string, string) => Promise<any>,
  location: string,
  getBuddyGroup: (string, string) => void,
  getBuddyGroupTransactions: (string, string) => void,
|};

export const EntryTransactionsBase = ({
  athleteFullName,
  charge,
  ERAUID,
  event,
  user,
  history,
  loading,
  setIsBuddyEntry,
  isBuddyEntry,
  addBuddy,
  setSelectedBuddyOption,
  clearSelectedDisciplines,
  selectedDisciplines,
  getEventBuddies,
  setBuddyManagerEntryCharge,
  toggleIsBuddySelected,
  calculateEntryCharge,
  dispatch,
  buddyGroup,
  transactions,
  eventErrorMsg,
  match,
  selectEvent,
  getBuddyGroup,
  getBuddyGroupTransactions,
  location,
}: ConfirmPropsType) => {
  const { ShowStallsLink, id: eventUID } = event;
  const { EventUID } = match.params;
  const parentERAUID = user.ERAUID;
  const selectedERAUID = getNestedProperty(
    'state.selectedERAUID',
    location,
    parentERAUID,
  );
  const isParent = selectedERAUID === parentERAUID;

  const associations = get(event, 'Associations', []);
  const isCollectMembershipNumber = associations.some(
    (association) => association.CollectMembershipNumber,
  );

  const {
    loading: loadingMembershipFees,
    data: associationTypeFeesResponse,
  } = useQuery(ASSOCIATION_MEMBER_FEE, {
    variables: {
      EventUID: eventUID,
      ERAUID,
    },
    skip: !isCollectMembershipNumber,
  });
  const associationTypeFees = associationTypeFeesResponse
    ? associationTypeFeesResponse.associationMemberFees
    : [];
  useEffect(() => {
    setIsLoading(true);
    selectedERAUID &&
      selectEvent(EventUID, selectedERAUID).then(() => {
        setIsLoading(false);
        getBuddyGroup(EventUID, selectedERAUID);
        getBuddyGroupTransactions(EventUID, selectedERAUID);
      });
  }, [selectedERAUID]);

  useEffect(() => {
    if (eventUID) {
      getEventBuddies()
        .then((eventBuddies) => {
          setBuddyInfo(eventBuddies);
        })
        .catch(() => {
          return null;
        });
      calculateEntryCharge();
    }
  }, [eventUID]);

  const [isLoading, setIsLoading] = useState(false);
  const [isPartnerInviteModalOpen, setIsPartnerInviteModalOpen] = useState(
    false,
  );
  const [partner, setPartner] = useState(null);
  const [buddyInfo, setBuddyInfo] = useState({
    buddyGroupContestants: [],
    isBuddyManager: false,
    buddyManager: {
      ERAUID: '',
      FullName: '',
    },
  });

  const canBeBuddyManager =
    buddyInfo &&
    (buddyInfo.isBuddyManager || !buddyInfo.buddyGroupContestants.length);
  let associationMembers = [];

  if (canBeBuddyManager && buddyGroup.length) {
    const managerInfo = buddyGroup.find((bgroup) => bgroup.ERAUID == ERAUID);
    associationMembers = managerInfo ? managerInfo.associationMembers : [];
  }

  const openInvitePartnerModal = useCallback((partner) => {
    setPartner(partner);
    setIsPartnerInviteModalOpen(true);
  }, []);

  const closeInvitePartnerModal = useCallback(() => {
    setIsPartnerInviteModalOpen(false);
    dispatch(reset('partnerContactInfo'));
  }, []);

  const startNewBuddyEntry = () => {
    if (!isBuddyEntry) {
      setBuddyManagerEntryCharge(charge);
    }

    setIsBuddyEntry(true);
    addBuddy();
    dispatch(cleanMembership());
    setSelectedBuddyOption(null);
    toggleIsBuddySelected(false);
    clearSelectedDisciplines();
    dispatch(clearEntryCharge());
    changeRoute({
      history,
      persistState: true,
      state: {
        EventUID,
        isFromMyEntries: true,
      },
      route: isCollectMembershipNumber
        ? EVENT_ENTRY_COLLECT_MEMBERSHIP_NUMBER
        : EVENT_ENTRY,
    });
  };

  if (eventErrorMsg) {
    return <div>Unable to find event, please select a valid event</div>;
  }

  return eventUID ? (
    <Layout
      className="bg-nominate"
      loading={loading || isLoading || loadingMembershipFees}
    >
      <div id="main-top">
        <div className="grid-container">
          <div className="grid-x">
            <div className="small-12 desktop-only">
              <h2 className="section-title padbot-0">Confirmation</h2>
            </div>
            <div className="small-12 large-6 large-offset-3 nomination-confirmation entry-confirmation theme-content-container stacked-content-container">
              <h2 className="mobile-title mobile-only">Confirmation</h2>
              <div className="event-result entry">
                <EntryEventDescription event={event} />
              </div>
              <hr className="marbot-0" />
              <div className="confirmation-text-container athlete">
                <h3 className="entry-details-text">ENTRY DETAILS</h3>
                <div className="confirmation-title disciplines-entered">
                  ATHLETE
                </div>
                <div className="confirmation-athlete-name">
                  {athleteFullName}
                </div>
                {associationMembers &&
                  associationMembers.map((amd) => (
                    <div
                      key={`association_sn_${amd.Name}`}
                      className="confirmation-athlete-name padtop-1"
                    >
                      {amd.Name} #: {amd.MemberId}
                    </div>
                  ))}
                <div className="confirmation-title disciplines-entered">
                  Entries
                </div>
                <EntryTransactions transactions={transactions} />
                <DisplayMembershipFees
                  associationTypeFees={associationTypeFees}
                />
              </div>
            </div>
            <div className="small-12 large-6 large-offset-3 nomination-confirmation entry-confirmation stacked-content-container">
              {isParent && event.AllowsBuddyGroup == true && (
                <EntryAddBuddyCard
                  event={event}
                  charge={charge}
                  loading={loading || isLoading}
                  handleAddBuddy={startNewBuddyEntry}
                  isBuddyManager={canBeBuddyManager}
                  buddyManager={getNestedProperty('buddyManager', buddyInfo)}
                  openInvitePartnerModal={openInvitePartnerModal}
                  buddyGroup={buddyGroup}
                  selectedDisciplines={selectedDisciplines}
                />
              )}
              <OpenStallsLink ShowStallsLink={ShowStallsLink} />
              <EntrySocialInvite
                user={user}
                event={event}
                ERAUID={ERAUID}
                athleteFullName={athleteFullName}
              />
            </div>
          </div>
        </div>
      </div>
      <EntryPartnerInviteModal
        closeModal={closeInvitePartnerModal}
        isOpen={isPartnerInviteModalOpen}
        partner={partner}
        event={event}
        charge={charge}
        athleteName={capitalizeString(athleteFullName)}
      />
    </Layout>
  ) : null;
};
