// @flow
import React from 'react';
import type { Node } from 'react';
import classNames from 'classnames';

type SectionTitleProps = {|
  children: Node,
  className?: string,
  titleClassName?: string,
  optional?: boolean,
  optionalClassName?: string,
|};

const SectionTitle = ({
  children,
  className,
  titleClassName,
  optional,
  optionalClassName,
}: SectionTitleProps) => {
  return (
    <div
      className={classNames(
        'grid-x martop-15 marbot-1 align-justify align-middle',
        className,
      )}
    >
      <div className={classNames(titleClassName, 'cell small-6')}>
        <div className="section-enter-landing-view-subtitle padtop-0 padbot-0">
          {children}
        </div>
      </div>
      {optional && (
        <div className={classNames(optionalClassName, 'cell small-6')}>
          <span className="section-optional-label padtop-0 martop-0">
            optional
          </span>
        </div>
      )}
    </div>
  );
};

export default SectionTitle;
