// @flow
import React from 'react';
import classNames from 'classnames';
import type { Node } from 'react';

type SectionSubtitleProps = {|
  children: Node,
  className?: string,
|};

const SectionSubtitle = ({ children, className }: SectionSubtitleProps) => {
  return (
    <div className={classNames('section-enter-landing-view-text', className)}>
      {children}
    </div>
  );
};

export default SectionSubtitle;
