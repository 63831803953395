// @flow
import React from 'react';
import classNames from 'classnames';
import withToJS from 'enhancers/withToJS';
import { Check } from 'react-feather';
import type { Node, StatelessFunctionalComponent } from 'react';

export type CheckboxLineOption = {
  value: string,
  label: string,
  ...
};

type CheckboxLineProps = {|
  option: CheckboxLineOption | string | number,
  children?: Node | Function,
  isChecked: boolean,
  className?: string,
  onChange: Function,
  disabled?: boolean,
|};

type CheckboxLineComponent = StatelessFunctionalComponent<CheckboxLineProps>;

const CheckboxLine: CheckboxLineComponent = withToJS(
  (props: CheckboxLineProps) => {
    const {
      option,
      children,
      className,
      isChecked,
      onChange,
      disabled,
    } = props;

    return (
      <div className={classNames('segments-visible', className)}>
        <span
          className={classNames('input-checkbox', {
            checked: isChecked,
          })}
          onClick={disabled ? () => {} : onChange.bind(this, option)}
          style={{
            borderColor: disabled ? 'grey' : '',
            cursor: disabled ? 'not-allowed' : 'pointer',
          }}
        >
          <Check className="checkmark" />
        </span>
        {typeof children === 'function' ? children(option) : children}
      </div>
    );
  },
);

export default CheckboxLine;
