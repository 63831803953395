// @flow
import React, { PureComponent } from 'react';
import { isEmpty } from 'lodash';
import { Field, change } from 'redux-form';
import Select from 'react-select';
import moment from 'moment';
import { ArrowLeft } from 'react-feather';

import { required } from 'components/Forms/Validation';
import FormField from 'components/Forms/FormField';
import GenderRadioButton from 'components/Forms/GenderRadioButton';
import NameField from 'components/NameField';
import Notification from 'components/Notification';
import DateOfBirthInput from 'components/DateOfBirthInput';
import { Link } from 'react-router-dom';
import { MANAGE_YOUTH_ATHLETES } from 'constants/routes';
import { getNominatableDisciplineOptions } from 'constants/disciplines';
import { gendersArray } from 'constants/personal';
import { youthAgeVerify, getNestedProperty, isValidMomentDate } from 'helpers';

type ChildDataType = {|
  firstName: string,
  lastName: string,
  DOB: string,
  disciplineId: string,
  Gender: string,
|};

type RegisterChildFormPropsType = {|
  dispatch: (f: Function) => void,
  errorMessage: string,
  addChildInfo: (f: Function) => void,
  goBack?: (f: ?Function) => void,
  resetYouthAthletes?: (f: ?Function) => void,
  goToTerms: (f: Function) => void,
  loading: boolean,
  registerNewChildForm?: boolean,
  child: ChildDataType,
  isFormValid: boolean,
|};

type RegisterChildFormStateType = {|
  showDisciplineError: boolean,
  disciplineId: ?number | ?string,
  dateOfBirth: ?moment$Moment,
  showDateOfBirthError: boolean,
  checkedGender: string,
|};

type DisciplineObjectType = {|
  value: ?number,
|};

class RegisterChildForm extends PureComponent<
  RegisterChildFormPropsType,
  RegisterChildFormStateType,
> {
  constructor(props: RegisterChildFormPropsType) {
    super(props);
    const { dispatch, child } = props;

    const currentAthlete = child
      ? child
      : { DOB: null, Gender: '', disciplineId: null };

    if (!isEmpty(currentAthlete)) {
      dispatch(
        change(
          'registration',
          'child.firstName',
          getNestedProperty('firstName', child, ''),
        ),
      );
      dispatch(
        change(
          'registration',
          'child.lastName',
          getNestedProperty('lastName', child, ''),
        ),
      );
    }

    this.state = {
      showDisciplineError: false,
      showDateOfBirthError: false,
      disciplineId: currentAthlete.disciplineId,
      dateOfBirth: currentAthlete.DOB
        ? moment(currentAthlete.DOB, 'YYYY-MM-DD')
        : null,
      checkedGender: currentAthlete.Gender,
    };
  }

  onChangeDiscipline = (disciplineObject: DisciplineObjectType): Function => {
    const disciplineId = disciplineObject ? disciplineObject.value : null;
    const { dispatch } = this.props;
    dispatch(change('registration', 'child.disciplineId', disciplineId));
    this.setState({ disciplineId, showDisciplineError: false });
  };

  validForm = (submitFormCallback: Function) => {
    const { disciplineId } = this.state;

    if (disciplineId === null || !disciplineId) {
      this.setState({ showDisciplineError: true });
      return null;
    }

    if (youthAgeVerify(this.state.dateOfBirth)) {
      this.setState({ showDateOfBirthError: true });
      return null;
    }

    submitFormCallback();
  };

  finishForm = () => {
    this.props.addChildInfo();
    this.validForm(() => {
      this.props.goToTerms();
    });
  };

  onChangeDateOfBirth = (dateOfBirthString: string) => {
    const { dispatch } = this.props;
    const dateOfBirth = moment(dateOfBirthString, 'MMDDYYYY');
    const childDOB = dateOfBirth.format('YYYY-MM-DD').toString();
    dispatch(change('registration', 'child.DOB', childDOB));

    this.setState({ dateOfBirth });
  };

  onGenderSelect = (genderSelection: SyntheticInputEvent<EventTarget>) => {
    const { dispatch } = this.props;
    const { value } = genderSelection.target;

    dispatch(change('registration', 'child.Gender', value));

    this.setState({ checkedGender: value });
  };

  render() {
    const {
      loading,
      goBack,
      registerNewChildForm,
      resetYouthAthletes,
      isFormValid,
    } = this.props;
    const {
      showDisciplineError,
      disciplineId,
      dateOfBirth,
      checkedGender,
    } = this.state;

    return (
      <>
        <>
          {!registerNewChildForm ? (
            <button className="back-button" onClick={goBack}>
              <ArrowLeft className="arrow-left" />
              Go Back
            </button>
          ) : (
            <Link
              className="back-button martop-0 "
              to={MANAGE_YOUTH_ATHLETES}
              onClick={resetYouthAthletes}
            >
              <ArrowLeft className="arrow-left" />
              Go Back
            </Link>
          )}
          <h2 className="section-title center">Add Youth Athlete</h2>
          <hr />
        </>

        <form className="registration-form">
          {this.props.errorMessage && (
            <Notification text={this.props.errorMessage} />
          )}
          <fieldset>
            <label htmlFor="child.firstName">First Name*</label>
            <NameField
              name="child.firstName"
              component={FormField}
              placeholder="Enter First Name"
              validate={[required]}
            />
            <hr className="martop-0" />
            <label htmlFor="child.lastName">Last Name*</label>
            <NameField
              name="child.lastName"
              component={FormField}
              placeholder="Enter Last Name"
              validate={[required]}
            />
            <hr className="martop-0" />
            <label htmlFor="birthdate">
              Birth Date* <small>(MM/DD/YYYY)</small>
            </label>
            <DateOfBirthInput
              pickedDate={dateOfBirth}
              onChange={this.onChangeDateOfBirth}
            />
            {this.state.showDateOfBirthError && (
              <Notification text="Youth Athlete must be between the ages of 8 and 18" />
            )}
            <hr className="martop-0" />
            <label htmlFor="child.discipline">Main Discipline*</label>
            <Select
              className="select-discipline"
              clearable={false}
              name="child.discipline"
              onChange={this.onChangeDiscipline}
              options={getNominatableDisciplineOptions()}
              placeholder="Choose Discipline"
              searchable={false}
              value={disciplineId}
            />

            {showDisciplineError && (
              <span className="validation-message">
                Main discipline must be selected
              </span>
            )}
            <hr className="martop-0" />
            <div className="gender-container">
              <div className="gender-label label">GENDER*</div>
              {gendersArray.map((gender) => {
                return (
                  <Field
                    component={GenderRadioButton}
                    type="radio"
                    name="child.Gender"
                    id="child.Gender"
                    gender={gender}
                    className="gender-option"
                    onGenderSelect={this.onGenderSelect}
                    checkedGender={checkedGender}
                    key={gender.value}
                    validate={[required]}
                  />
                );
              })}
            </div>
          </fieldset>
          <button
            className="btn-main child-registration-form-button"
            type="button"
            onClick={this.finishForm}
            disabled={
              loading ||
              !isFormValid ||
              !disciplineId ||
              !isValidMomentDate(dateOfBirth)
            }
          >
            {loading ? 'REGISTERING...' : 'CONTINUE'}
          </button>
        </form>
      </>
    );
  }
}

export default RegisterChildForm;
