// @flow
import React from 'react';

import type { DisciplineType } from 'context/SelectedDisciplines/type';

type DisciplineAddButtonPropsType = {
  addDiscipline: () => void,
  selectedDisciplines: DisciplineType[],
};

const DisciplineAddButton = (props: DisciplineAddButtonPropsType) => {
  const { addDiscipline, selectedDisciplines } = props;

  const addDisciplineText = selectedDisciplines.length
    ? 'Add another entry'
    : 'Add entry to continue';
  return (
    <button type="button" onClick={addDiscipline}>
      + {addDisciplineText}
    </button>
  );
};

export default DisciplineAddButton;
