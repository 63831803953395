import { compose } from 'redux';
import { connect } from 'react-redux';

import withToJS from 'enhancers/withToJS';
import withCalculateEntryCharge from 'enhancers/withCalculateEntryCharge';
import { withSelectedDisciplines } from 'context/SelectedDisciplines';
import { withBuddyGroups } from 'context/BuddyGroups';
import { selectEvent } from 'actions/event';
import { getEventBuddies } from 'actions/event';
import { getBuddyGroup, getEntryTransactionsForEvent } from 'actions/entryPool';
import { EntryTransactionsBase } from './EntryTransactions';

const mapStateToProps = (state) => {
  return {
    event: state.event.get('data'),
    buddyGroup: state.entryPool.get('buddyGroup'),
    transactions: state.entryPool.get('transactions'),
    eventErrorMsg: state.event.get('errorMessage'),
    charge: state.entryChargeRequest.get('data'),
    user: state.user.get('data'),
    loading: state.event.get('loading') || state.entryPool.get('loading'),
  };
};

const mapDispatchToProps = (dispatch) => ({
  selectEvent: (eventUID, eraUID) => dispatch(selectEvent(eventUID, eraUID)),
  getEventBuddies: () => dispatch(getEventBuddies()),
  getBuddyGroupTransactions: (id, ERAUID) =>
    dispatch(getEntryTransactionsForEvent(id, ERAUID)),
  getBuddyGroup: () => dispatch(getBuddyGroup()),
  dispatch,
});

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
  withCalculateEntryCharge,
  withSelectedDisciplines,
  withBuddyGroups,
  withToJS,
)(EntryTransactionsBase);
