// @flow
import React, { PureComponent } from 'react';
import moment from 'moment-timezone';
import {
  FormControl,
  FormHelperText,
  InputLabel,
  Input,
} from '@material-ui/core';

type renderFieldProps = {|
  input: ?Object,
  name: ?string,
  placeholder: ?string,
  label: ?string,
  className: ?string,
  parentClass: string,
  type: ?string,
  helperText: ?string,
  startAdornment: ?any,
  endAdornment: ?any,
  touched: ?boolean,
  error: ?any,
  multiline: ?boolean,
  rows: ?number,
  maxLength: ?number,
  min: ?any,
  max: ?any,
  disabled: ?boolean,
  variant: ?string,
  shrink: ?boolean,
  autoComplete: ?string,
  required: ?boolean,
  inputProps: ?Object,
  inputComponent: ?Node,
  invalid?: boolean,
  showCounter?: boolean,
  blockNonNumericChars?: boolean,
  blockPaste?: boolean,
  validate: ?any,
  showError: ?boolean,
  meta: ?Object,
|};

export const TextField = (props: renderFieldProps) => {
  const {
    input,
    name,
    placeholder,
    label,
    className,
    parentClass,
    type,
    helperText,
    startAdornment,
    endAdornment,
    disabled,
    touched,
    error,
    variant,
    shrink,
    autoComplete,
    min,
    max,
    rows,
    multiline,
    maxLength,
    required,
    inputProps,
    inputComponent,
    invalid,
    showCounter,
    blockNonNumericChars,
    blockPaste,
    validate,
    showError,
    meta,
  } = props;

  return (
    <FormField
      input={input}
      name={name}
      placeholder={placeholder}
      label={label}
      className={className}
      parentClass={parentClass}
      type={type}
      inputProps={inputProps}
      inputComponent={inputComponent}
      helperText={helperText}
      startAdornment={startAdornment}
      endAdornment={endAdornment}
      disabled={disabled}
      touched={touched}
      error={error}
      invalid={invalid}
      variant={variant}
      shrink={shrink}
      autoComplete={autoComplete}
      min={min}
      max={max}
      rows={rows}
      multiline={multiline}
      maxLength={maxLength}
      required={required}
      showCounter={showCounter}
      blockNonNumericChars={blockNonNumericChars}
      blockPaste={blockPaste}
      validate={validate}
      showError={showError}
      meta={meta}
    />
  );
};

// eslint-disable-next-line
class FormField extends PureComponent<renderFieldProps> {
  static defaultProps = {
    input: {},
    name: '',
    placeholder: '',
    label: '',
    className: '',
    parentClass: '',
    helperText: '',
    startAdornment: null,
    endAdornment: null,
    error: '',
    multiline: false,
    rows: null,
    disabled: false,
    variant: '',
    shrink: true,
    autoComplete: 'off',
    min: null,
    max: null,
    touched: false,
    type: 'text',
    maxLength: null,
    required: false,
    inputProps: {},
    inputComponent: null,
    invalid: false,
    showCounter: false,
    blockNonNumericChars: false,
    blockPaste: false,
    validate: {},
    showError: false,
    meta: {},
  };

  render() {
    const {
      input,
      name,
      placeholder,
      label,
      className,
      parentClass,
      type,
      helperText,
      startAdornment,
      endAdornment,
      disabled,
      touched,
      error,
      multiline,
      variant,
      shrink,
      autoComplete,
      min,
      max,
      rows = 4,
      maxLength,
      required,
      inputComponent,
      inputProps,
      invalid,
      showCounter,
      blockNonNumericChars,
      blockPaste,
      validate,
      showError,
      meta,
    } = this.props;
    const hasError = showError
      ? (meta &&
          meta.touched &&
          meta.invalid &&
          meta.error &&
          input &&
          !input.value) ||
        (input && !input.value && meta && meta.touched)
      : (touched || invalid) && error;
    let errorMessage = (touched || invalid) && error ? error : '';
    if (
      (meta &&
        meta.touched &&
        meta.invalid &&
        meta.error &&
        input &&
        !input.value) ||
      (meta && meta.touched && input && !input.value)
    ) {
      errorMessage = meta.error;
    }

    const blockInvalidChar = (e) =>
      ['e', 'E', '+', '-'].includes(e.key) && e.preventDefault();

    if (type === 'datetime-local' && input) {
      const formattedDateTime = moment
        .utc(input.value)
        .format('YYYY-MM-DD[T]HH:mm');

      input.value =
        formattedDateTime === 'Invalid date' ? input.value : formattedDateTime;
    }

    let variantValClass = '';

    if (variant === 'filled') {
      variantValClass += 'filled-input ';
    }

    variantValClass += parentClass;

    return (
      <FormControl
        className={variantValClass}
        error={!!hasError}
        fullWidth
        margin="none"
        disabled={disabled}
      >
        {!!label && (
          <InputLabel
            className="form-label-bold form-label"
            required={required}
            shrink={shrink}
            htmlFor={name}
          >
            {label}
          </InputLabel>
        )}
        <Input
          {...input}
          fullWidth
          autoComplete={autoComplete}
          margin="none"
          placeholder={placeholder}
          className={className}
          type={type}
          name={name}
          inputComponent={inputComponent ? inputComponent : undefined}
          inputProps={{ min, max, maxLength, ...inputProps }}
          startAdornment={startAdornment}
          endAdornment={endAdornment}
          multiline={multiline}
          rows={multiline ? rows : null}
          variant={variant}
          onKeyDown={blockNonNumericChars ? blockInvalidChar : () => true}
          onPaste={(e) => (blockPaste ? e.preventDefault() : true)}
          disabled={disabled}
          validate={validate}
        />
        {helperText && !error && (meta && !meta.error) && (
          <FormHelperText className="form-helper">{helperText}</FormHelperText>
        )}
        {errorMessage && (
          <FormHelperText className="form-helper">
            {errorMessage}
          </FormHelperText>
        )}
        {showCounter && maxLength && input && (
          <span className="form-counter">
            ({input.value.length}/{maxLength})
          </span>
        )}
      </FormControl>
    );
  }
}

export default TextField;
