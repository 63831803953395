// @flow
import React from 'react';
import { ArrowLeft } from 'react-feather';

type BackButtonPropsType = {
  goBack: () => any,
};

const BackButton = (props: BackButtonPropsType) => {
  const { goBack } = props;
  return (
    <button className="back-button" onClick={goBack}>
      <ArrowLeft className="arrow-left" />
      Go Back
    </button>
  );
};

export default BackButton;
