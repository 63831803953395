// @flow
import React from 'react';

import dbVars from 'constants/dbvars';

type OpenStallsLinkPropsType = {|
  ShowStallsLink: number,
|};

export const OpenStallsLink = ({ ShowStallsLink }: OpenStallsLinkPropsType) => {
  const OpenStallsAppURL = dbVars.STALLS_APP_URL;

  return ShowStallsLink === 1 ? (
    <div className="theme-content-container card-content stacked-content-container">
      <div className="confirmation-text-container">
        <div className="section-preferences-subtitle">Skip The Lines</div>
        <div className="confirmation-text padbot-3">
          Reserve your stalls in advance with Open Stalls.
        </div>
      </div>
      <div className="text-center">
        <a
          href={OpenStallsAppURL}
          target="_blank"
          className="btn-main link"
          rel="noopener noreferrer"
        >
          Reserve Stalls
        </a>
      </div>
    </div>
  ) : null;
};
