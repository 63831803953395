// @flow
import { useEffect } from 'react';
import { get } from 'lodash';
import moment from 'moment';

import { getValueLabelObject } from 'helpers';
import { useSelector } from 'hooks/useSelector';
import type { DisciplineType } from './type';
import {
  getDefaultSelectedDisciplines,
  getUniqueDisciplines,
  getUnavailableDisciplines,
  getEventPreferences,
} from './helpers';
import useDisciplinesReducer, {
  initialState,
  LOAD_DISCIPLINES,
  SET_DEFAULT_DISCIPLINES,
  SET_AVAILABLE_PREFERENCES,
  SET_ENTRIES_COUNT,
} from './disciplinesReducer';

import {
  disciplinesByName,
  nominatableDisciplines,
} from 'constants/disciplines';
import { NOMINATION } from 'constants/nomination';
import type { EventType } from 'models/Event';

export const useDisciplines = (category: string, selectedERAUID: string) => {
  const event = useSelector((state) => state.event.get('data'));
  const charge = useSelector((state) => state.entryChargeRequest.get('data'));
  const user = useSelector((state) => state.user.get('data'));
  const [state, dispatch] = useDisciplinesReducer();

  const getAvailableDisciplines = (
    disciplines: Object[],
    unavailableMap: Object,
    AllowClasses: boolean,
  ): DisciplineType[] => {
    const {
      availableExclusiveDisciplines,
      IsEntryExclusive,
      IsNomLastPerformance,
    } = event;

    const shouldAddDiscipline = (id) => {
      if (category === 'entry') {
        if (!IsEntryExclusive) {
          return true;
        }

        return availableExclusiveDisciplines.includes(id);
      }

      return nominatableDisciplines.includes(id);
    };

    if (category === NOMINATION && IsNomLastPerformance !== 1) {
      event.performances.map((perf) => {
        const perfDate = moment(perf.PerformanceDateWithTimezone).format();
        if (moment(perfDate).isBefore()) {
          disciplines = disciplines.filter((disc) => {
            return !perf.Disciplines.some((perfDisc) => {
              return (
                disc.id === perfDisc.id &&
                nominatableDisciplines.includes(perfDisc.id)
              );
            });
          });
        }
      });
    }

    return AllowClasses
      ? disciplines
          .filter((discipline) => shouldAddDiscipline(discipline.id))
          .map((discipline) => {
            return {
              ...getValueLabelObject(discipline.id, discipline.name),
              hasPreference: false,
              Classes: discipline.Classes.map((Class) => {
                const isSelected = !!state.defaultDisciplines[Class.id];
                const pastPerformances = unavailableMap[Class.DisciplineUID]
                  ? unavailableMap[Class.DisciplineUID].performances
                  : [];
                return {
                  ...getValueLabelObject(Class.DisciplineUID, Class.Discipline),
                  ...Class,
                  isSelected,
                  pastPerformances,
                  PartnerMultipleEntries: get(
                    Class,
                    'PartnerMultipleEntries',
                    false,
                  ),
                  displineFee: 0,
                  disciplineFeeName: '',
                  RequirePartnerSelection: get(
                    Class,
                    'RequirePartnerSelection',
                    false,
                  ),
                  performances: Class.performances || [],
                  athleteEntryCount: 0,
                  newEntryCount: 0,
                  partners: {},
                };
              }),
            };
          })
      : disciplines
          .filter((discipline) => shouldAddDiscipline(discipline.id))
          .map((discipline) => {
            const isSelected = !!state.defaultDisciplines[discipline.id];
            const pastPerformances = unavailableMap[discipline.id]
              ? unavailableMap[discipline.id].performances
              : [];
            return {
              ...getValueLabelObject(discipline.id, discipline.name),
              isSelected,
              Fee: discipline.Fee,
              pastPerformances,
              DisciplineFee: discipline.DisciplineFee,
              DisciplineFeeName: discipline.DisciplineFeeName,
              PartnerMultipleEntries: get(
                discipline,
                'PartnerMultipleEntries',
                false,
              ),
              RequirePartnerSelection: get(
                discipline,
                'RequirePartnerSelection',
                false,
              ),
              performances: discipline.performances || [],
              hasPreference: false,
              maxNumberOfEntries: discipline.maxNumberOfEntries,
              athleteEntryCount: 0,
              newEntryCount: 0,
              partners: {},
            };
          });
  };

  const getSelectedDisciplines = (disciplines: DisciplineType[]) => {
    return disciplines.filter((d) => !!state.defaultDisciplines[d.value]);
  };

  const getDefaultDisciplines = (event: EventType) => {
    const performances = getDefaultPerformances(event);

    return Object.keys(disciplinesByName).map((key) => ({
      value: key,
      label: disciplinesByName[key],
      performances,
    }));
  };

  const getDefaultPerformances = (event: EventType) => {
    const { dateStart, dateEnd } = event;

    if (!dateStart || !dateEnd) return [];

    const firstDate = moment(dateStart).startOf('day');
    const lastDate = moment(dateEnd).startOf('day');
    const numberOfDays = lastDate.diff(firstDate, 'days') + 1;

    return [...Array(numberOfDays)].map((dayNumber, idx) => {
      const currentDate = moment(firstDate).add(idx, 'days');
      return {
        PerformanceUID: idx,
        isUpcoming: true,
        PerformanceDate: moment(currentDate).format('YYYY-MM-DD'),
      };
    });
  };

  const getDisciplines = () => {
    if (!event.id) {
      const defaultDisciplineData = {
        ...initialState,
        availableDisciplines: getDefaultDisciplines(event),
      };
      return dispatch({ type: LOAD_DISCIPLINES, data: defaultDisciplineData });
    }

    const { DisciplineFees, AllowClasses } = event;
    const performanceDisciplines = event.performances.reduce(
      (prev, curr) => {
        const { available, unavailable } = prev;
        const { Disciplines, isUpcoming, ...performance } = curr;

        if (Disciplines && isUpcoming) {
          return {
            available: {
              ...getUniqueDisciplines(
                AllowClasses,
                available,
                Disciplines,
                performance,
                DisciplineFees,
              ),
            },
            unavailable,
          };
        }
        if (Disciplines && !isUpcoming) {
          return {
            unavailable: {
              ...getUniqueDisciplines(
                AllowClasses,
                unavailable,
                Disciplines,
                performance,
                DisciplineFees,
              ),
            },
            available,
          };
        }
      },
      { available: {}, unavailable: {} },
    );

    const { unavailable, available } = performanceDisciplines;
    const pastPerfDisciplines = getUnavailableDisciplines(
      Object.values(unavailable),
    );
    const availableDisciplines = getAvailableDisciplines(
      Object.values(available),
      unavailable,
      AllowClasses,
    );

    const availableDisciplineIds = availableDisciplines.map(
      (discipline) => discipline.value,
    );
    const unavailableDisciplines = pastPerfDisciplines.filter((discipline) => {
      return availableDisciplineIds.includes(discipline.value) ? false : true;
    });
    const selectedDisciplines = getSelectedDisciplines(availableDisciplines);

    const data = {
      availableDisciplines,
      selectedDisciplines,
      unavailableDisciplines,
      event,
    };
    return dispatch({ type: LOAD_DISCIPLINES, data });
  };

  const setDefaultDisciplines = () => {
    if (category === NOMINATION) {
      const defaultDisciplines = getDefaultSelectedDisciplines(charge, user);
      dispatch({ type: SET_DEFAULT_DISCIPLINES, data: defaultDisciplines });
    }
  };

  const setUsersEntriesCount = () => {
    /*
    Creates the data used for storing the entries af an athlete account
    and it's youth athlete accounts.
    ERAUIDs and disciplineIds are used as keys to easily update the
    availableDisciplines array when a different athlete is selected
    for making entries.

    returned example:
    entries: {
     ERAUID: {discId1: count1, discId2: count2},
     youth1ERAUID: {discId1: count1, discId2: count2},
     youth2ERAUID: {discId1: count1, discId2: count2},
    }
    */
    const youthAthletes = user.youthAthletes.map((athlete) => athlete.ERAUID);
    const parentAthlete = [user.ERAUID];
    const contestandIds = [].concat(parentAthlete, youthAthletes);
    const { AllowClasses } = event;

    let entries = {};
    if (event.entryCounts) {
      AllowClasses
        ? event.entryCounts.forEach((user) => {
            let discObj = {};
            user.disciplines.forEach((disc) => {
              discObj[disc.eventEntryDisciplineFeeUID] = disc.entryCount;
            });
            entries[user.ERAUID] = discObj;
            contestandIds.forEach((ERAUID) => {
              if (!(ERAUID in entries)) {
                entries[ERAUID] = {};
              }
            });
          })
        : event.entryCounts.forEach((user) => {
            let discObj = {};
            user.disciplines.forEach((disc) => {
              discObj[disc.compType] = disc.entryCount;
            });
            entries[user.ERAUID] = discObj;
            contestandIds.forEach((ERAUID) => {
              if (!(ERAUID in entries)) {
                entries[ERAUID] = {};
              }
            });
          });
    }

    dispatch({
      type: SET_ENTRIES_COUNT,
      data: { entries, selectedERAUID },
    });
  };

  useEffect(() => {
    setDefaultDisciplines();
  }, []);

  useEffect(() => {
    setDefaultDisciplines();
    getDisciplines();
    setUsersEntriesCount();

    const availablePreferences = getEventPreferences(event);
    dispatch({ type: SET_AVAILABLE_PREFERENCES, data: availablePreferences });
  }, [event.id, event.dateStart, event.dateEnd, selectedERAUID]);

  return [state, dispatch];
};
