// @flow
import type { Node } from 'react';
import React, { useEffect, useState } from 'react';
import moment from 'moment';
import classNames from 'classnames';
import { selectedPreferencesPerEvent } from 'actions/event';
import { mapDispatchToProps } from 'helpers';
import { compose } from 'redux';
import { connect } from 'react-redux';

type PerformanceCardEntryProps = {|
  selectedPreference: string,
  onClick: Function,
  performance: Object,
  children?: Node,
  eventID: number,
  disciplineId: number,
  dispatch: (f: Function) => any,
  showEntryCountOnPerformance: boolean,
|};

const PerformanceCardEntry = (props: PerformanceCardEntryProps) => {
  const {
    performance,
    selectedPreference,
    onClick,
    children,
    eventID,
    disciplineId,
    showEntryCountOnPerformance,
  } = props;

  const [countCurrentEntries, setCount] = useState(0);

  function filterArray(entriesWithPerformancePreferences) {
    const performanceSelector = performance.PerformanceUID;

    const preferencesForSpecificPerformance = entriesWithPerformancePreferences.filter(
      (performance) => {
        return performance.Pref1 === performanceSelector;
      },
    );

    setCount(preferencesForSpecificPerformance.length);
  }

  // Total count of current entries for the performance
  useEffect(() => {
    let isFetching = true;
    getEntriesPerformancesPreferences(eventID, disciplineId).then(
      (entriesWithPerformancePreferences) => {
        if (isFetching && entriesWithPerformancePreferences) {
          filterArray(entriesWithPerformancePreferences);
        }
      },
    );
    return () => {
      isFetching = false;
    };
  }, []);

  const getEntriesPerformancesPreferences = async (eventID, disciplineId) => {
    const { dispatch } = props;
    return await dispatch(selectedPreferencesPerEvent(eventID, disciplineId));
  };

  const performanceDate = moment.utc(performance.PerformanceDate);
  const { PerformanceName } = performance;
  const outPreferences = ['Out1', 'Out2'];
  const isAnOut = outPreferences.includes(selectedPreference);
  const isAPreference = selectedPreference !== '' && !isAnOut;

  return (
    <div className="entry-performance-card text-center">
      <div
        className={classNames('date-item', {
          selected: selectedPreference !== '',
          'selected-preference': isAPreference,
          'selected-out': isAnOut,
        })}
        onClick={() => onClick(performance)}
      >
        <div className="date-item-left">
          <div className="month">{performanceDate.format('MMM')}</div>
          <div className="week-day">
            <span>{performanceDate.format('D')}</span>
            <span>{performanceDate.format('ddd')}</span>
          </div>
        </div>
        <div className="date-item-right">
          <div className="preference-description">
            <div
              className={classNames('text-container', {
                selected: isAPreference || isAnOut,
              })}
            >
              <span className="time">
                {performanceDate.format('LT')}{' '}
                {performance.TimezoneAbbreviation}
              </span>
              <div className="preference-name">{PerformanceName}</div>
            </div>
            <div>
              {isAPreference && (
                <div className="preference-bullet">
                  <span>{selectedPreference}</span>
                </div>
              )}
              {isAnOut && <span className="preference-bullet not">NOT</span>}
            </div>
          </div>

          {showEntryCountOnPerformance && (
            <span className="entries-counter">
              Current entries: {countCurrentEntries}
            </span>
          )}

          {children}
        </div>
      </div>
    </div>
  );
};

PerformanceCardEntry.defaultProps = {
  selectedPreference: '',
};

// $FlowIgnore
export default compose(connect(mapDispatchToProps))(PerformanceCardEntry);
