import { WCRA } from 'constants/theme/options';

const { THEME } = process.env;

const retToggleColors = {
  colors: {
    activeThumb: {
      base: '#FFFFFF',
    },
    inactiveThumb: {
      base: '#FFFFFF',
    },
    active: {
      base: '#10ac84',
      hover: '#10ac84',
    },
    inactive: {
      base: '#C8D6E5',
      hover: '#C8D6E5',
    },
  },
  trackStyle: {
    border: `1px 0 0 4px rgba(16, 72, 132,.16), 0 4px 4px rgba(16, 72, 132,.32) solid`,
    height: '27px',
    width: '75px',
  },
  thumbStyle: {
    border: `1px 0 0 4px rgba(16, 72, 132,.16), 0 4px 4px rgba(16, 72, 132,.32) solid`,
    height: '35px',
    width: '35px',
  },
  boxShadow: 'box-shadow: 0 1px 1px 0 #8395A7',
};

const wcraToggleColors = {
  colors: {
    activeThumb: {
      base: 'rgb(255,196,4)',
    },
    inactiveThumb: {
      base: 'rgb(0,0,0)',
    },
    active: {
      base: 'rgb(0,0,0)',
      hover: 'rgb(0,0,0)',
    },
    inactive: {
      base: 'rgb(0,0,0)',
      hover: 'rgb(0,0,0)',
    },
  },
  trackStyle: {
    border: '1px rgb(255,196,4) solid',
    height: '27px',
    width: '75px',
  },
  thumbStyle: {
    border: '1px rgb(255,196,4) solid',
    height: '35px',
    width: '35px',
  },
  boxShadow:
    'box-shadow: 0 0 4px rgba(255,196,4,.16), 0 4px 4px rgba(255,196,4,.32)',
};

export const toggleButtonColors =
  THEME === WCRA ? wcraToggleColors : retToggleColors;
