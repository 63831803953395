const base = process.env.REACT_APP_URL;

const authGetter = () => {
  try {
    const localStorageData = localStorage['persist:root'];
    const authJsonLocalStorage = localStorageData
      ? JSON.parse(localStorageData)
      : null;
    const authData = authJsonLocalStorage
      ? JSON.parse(authJsonLocalStorage.auth)
      : null;
    const token =
      authData && authData.data
        ? authData.data.authPayload.token.accessToken
        : null;

    return {
      Authorization: token ? `Bearer ${token}` : '',
      base,
    };
  } catch (e) {
    // eslint-disable-next-line no-console
    console.info('Error:', e.message);
  }
};

export default authGetter;
