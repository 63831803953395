// @flow
import React from 'react';
import classNames from 'classnames';
// $FlowIgnore
import shoppingCart from 'assets/img/icons/cart.svg';
import { getTheme, getLengthOfNominations } from 'helpers';

const theme = getTheme();

type ViewCartButtonProps = {|
  onClick: Function,
  disabled?: boolean,
  charge: Object,
  paymentValue: number,
  className: ?string,
  membershipFeeCount: ?number,
  selectedDisciplines: Array<any>,
|};

const ViewCartButton = ({
  onClick,
  disabled,
  selectedDisciplines,
  charge,
  paymentValue,
  className,
  membershipFeeCount,
}: ViewCartButtonProps) => {
  const handleClick = () => {
    if (!disabled) {
      onClick();
    }
  };

  const itemsInCart =
    selectedDisciplines.reduce((acc, disc) => acc + disc.newEntryCount, 0) +
    membershipFeeCount;
  const { disciplineFees } = charge;
  const nominationsLength = getLengthOfNominations(disciplineFees);

  return (
    <div className="view-cart-button-sticky-wrapper">
      <div
        onClick={handleClick}
        className={classNames(
          'view-cart-button grid-x align-justify align-middle',
          { disabled },
          className,
        )}
      >
        <div className="cell small-2 cart-icon">
          <img src={shoppingCart} alt="shopping cart" />
        </div>
        <div className="cell small-8 button-text">
          View cart ${paymentValue.toFixed(2)}
        </div>
        <div className="cell small-2 cart-count-indicator">
          <div className={theme}>{itemsInCart + nominationsLength}</div>
        </div>
      </div>
    </div>
  );
};

export default ViewCartButton;
