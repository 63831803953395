// @flow
import React, { PureComponent } from 'react';
import { Tooltip } from '@material-ui/core';
import { Help } from '@material-ui/icons';
import { StylesProvider } from '@material-ui/core/styles';
import { Field, change, SubmissionError } from 'redux-form';
import { MapPin } from 'react-feather';
import moment from 'moment';
import {
  changeRoute,
  getNestedProperty,
  omitKeys,
  toJS,
  getTheme,
} from 'helpers';
import Select from 'react-select';
import countries from 'node-countries';

import { updateUser } from 'actions/user';
import { submitEntryPayment } from 'actions/entryChargeRequest';

import {
  required,
  cityTown,
  exactLength,
  hometown,
} from 'components/Forms/Validation';
import Layout from 'components/Layout';
import BackButton from 'components/BackButton';
import FormField from 'components/FormField';
import ErrorMessage from 'components/ErrorMessage';
import SSNFormField from 'components/Forms/SSNFormField';
import { postalCode } from 'components/Forms/Validation';
import RadioButton from 'components/Forms/RadioButton';

import {
  EVENT_ENTRY_CHECKOUT,
  EVENT_ENTRY_REVIEW,
  EVENT_ENTRY_SEARCH,
} from 'constants/routes';
import { SSNDummyValue } from 'constants/personal';
import { PAY_LATER } from 'constants/paymentTypes';
import type { EventType } from 'models/Event';

import type { DisciplineType } from 'context/SelectedDisciplines/type';

// $FlowIgnore
import warningIcon from 'assets/img/icons/warning-icon.svg';

type LegalInfoPropsType = {|
  history: Object,
  handleSubmit: (() => Promise<void>) => void,
  dispatch: (T: any) => void,
  userErrorMessage: string,
  loading: boolean,
  loadingAuth: boolean,
  loadingCharge: boolean,
  charge: Object,
  initialValues: Object,
  getEntryDisciplines: Function,
  selectedDisciplines: DisciplineType[],
  event: EventType,
|};

type LegalInfoStateType = {|
  country: string,
  stateOptions: Array<Object>,
  USCitizen: string,
  SSNLastFour: string,
  HasAgreed: boolean,
|};

const SUPPORTED_COUNTRIES = new Set(['US', 'CA']);
const SSN_LENGTH = 9;
const US_ZIPCODE_LENGTH = 5;
const CA_ZIPCODE_LENGTH = 6;

const labelOverrides = {
  CA: {
    stateLabel: 'Province',
    zipcodeLabel: 'Postal Code',
  },
  US: {
    stateLabel: 'State',
    zipcodeLabel: 'Zip Code',
  },
};

const countryOptions = Object.keys(countries)
  .filter((country) => SUPPORTED_COUNTRIES.has(country))
  .map((value) => ({
    value,
    label: countries[value].name,
  }));

const getStateOptions = (country) =>
  countries[country].provinces.map(({ short, name }, id) => ({
    id,
    value: short,
    label: name,
  }));

const exactlyNine = exactLength(SSN_LENGTH);
const exactlyFive = exactLength(US_ZIPCODE_LENGTH);
const exactlySix = exactLength(CA_ZIPCODE_LENGTH);

export class LegalInfo extends PureComponent<
  LegalInfoPropsType,
  LegalInfoStateType,
> {
  static defaultProps = {
    userErrorMessage: '',
  };

  constructor(props: Object) {
    super(props);

    const { initialValues, history } = props;
    const { country, USCitizen, SSNLastFour } = initialValues;
    const defaultCountry = country || 'US';

    const HasAgreed = getNestedProperty(
      'location.state.acceptedTerms',
      history,
      false,
    );
    this.state = {
      stateOptions: getStateOptions(defaultCountry),
      country: defaultCountry,
      USCitizen,
      SSNLastFour,
      HasAgreed,
    };
  }

  componentDidMount() {
    const { history, charge, dispatch } = this.props;

    if (!charge.ERAUID) {
      history.push(EVENT_ENTRY_SEARCH);
    }

    dispatch(change('legalInfo', 'USCitizen', '1'));
    window.scrollTo(0, 0);
  }

  handleUsCitizenChange = (e: Object) => {
    const { dispatch } = this.props;
    this.setState({ USCitizen: e.target.value });
    dispatch(change('legalInfo', 'USCitizen', e.target.value));
  };

  handleCountryChange = (selection: Object) => {
    const { dispatch } = this.props;
    const country = selection ? selection.value : 'US';

    this.setState({
      country,
      stateOptions: getStateOptions(country),
    });

    dispatch(change('legalInfo', 'state', null));
  };

  handleStateChange = (selection: Object) => {
    const { dispatch } = this.props;
    dispatch(change('legalInfo', 'state', selection.value));
  };

  submitPayLater = () => {
    const {
      dispatch,
      getEntryDisciplines,
      selectedDisciplines,
      event,
    } = this.props;
    const charge = toJS(this.props.charge);
    const { AllowClasses } = event;

    const values = {
      theme: getTheme(),
      paymentType: PAY_LATER,
      charge: {
        ...charge,
        Disciplines: getEntryDisciplines(selectedDisciplines, AllowClasses),
      },
    };
    dispatch(submitEntryPayment(values));
  };

  onSubmit = async (values: Object) => {
    const { dispatch, history } = this.props;
    const { country, USCitizen, HasAgreed } = this.state;
    const { zip, SSN } = values;

    if (postalCode(zip, country)) {
      throw new SubmissionError({
        zip: 'Invalid format',
        _error: 'Invalid zip or postal code',
      });
    }

    const TaxInfoUpdateDate = moment.utc().format('YYYY-MM-DDTHH:mm:ss.SSSSZ');
    const SSNLastFour = SSN && SSN.length === SSN_LENGTH ? SSN.slice(-4) : null;
    const keysToOmit = ['SSNLastFour', 'TaxInfoUpdateDate'];
    if (!SSN || SSN.startsWith(SSNDummyValue)) {
      keysToOmit.push('SSN');
    }
    const user = omitKeys(values, keysToOmit);

    let pendingEntries = [];

    if (user && !!user.entryData && !!user.entryData.pendingEntries) {
      pendingEntries = user.entryData.pendingEntries.map((entry) => {
        const newentries = entry.entries.map((e) => {
          e.HasAgreed = HasAgreed;
          return e;
        });
        entry.entries = newentries;
        return entry;
      });
    }

    user.entryData.pendingEntries = pendingEntries;

    const response = await dispatch(
      updateUser({
        ...user,
        country,
        USCitizen,
        TaxInfoUpdateDate,
        SSNLastFour,
      }),
    );

    if (response && response.id) {
      const showTaxInfoViewOnGoBack = getNestedProperty(
        'location.state.showTaxInfoViewOnGoBack',
        history,
        false,
      );

      const payLater = getNestedProperty(
        'location.state.payLater',
        history,
        false,
      );

      if (payLater) {
        return this.submitPayLater();
      }

      changeRoute({
        history,
        route: EVENT_ENTRY_CHECKOUT,
        state: {
          showTaxInfoViewOnGoBack: showTaxInfoViewOnGoBack,
        },
      });
    }
  };

  goBack = () => {
    const { history } = this.props;
    const showTaxInfoViewOnGoBack = getNestedProperty(
      'location.state.showTaxInfoViewOnGoBack',
      history,
      false,
    );

    if (showTaxInfoViewOnGoBack) {
      return changeRoute({
        history,
        route: EVENT_ENTRY_REVIEW,
        state: {
          showTaxInfoViewOnGoBack: true,
        },
      });
    }

    return history.goBack();
  };

  renderField = ({
    input,
    name,
    placeholder,
    className,
    type,
    min,
    max,
    maxLength,
    onKeyPress,
    meta: { touched, error, active },
  }: Object) => {
    return (
      <FormField
        input={input}
        placeholder={placeholder}
        className={className}
        type={type}
        name={name}
        touched={touched}
        error={error}
        active={active}
        min={min}
        max={max}
        maxLength={maxLength}
        onKeyPress={onKeyPress}
      />
    );
  };

  renderStateField = ({ input, meta: { error, touched } }: Object) => {
    const { stateOptions } = this.state;

    return (
      <>
        <Select
          onChange={this.handleStateChange}
          clearable={false}
          options={stateOptions}
          searchable={true}
          placeholder="Select"
          value={input.value}
        />
        {error && touched ? (
          <span className="validation-message">{error}</span>
        ) : (
          ''
        )}
      </>
    );
  };

  renderSSNField = ({ input, meta: { touched, error, active } }: Object) => {
    const { SSNLastFour } = this.state;

    return (
      <SSNFormField
        input={input}
        touched={touched}
        error={error}
        active={active}
        SSNLastFour={SSNLastFour}
      />
    );
  };

  render() {
    const {
      handleSubmit,
      loading,
      loadingAuth,
      loadingCharge,
      history,
      event,
    } = this.props;
    const { country, USCitizen } = this.state;
    const { stateLabel = 'State', zipcodeLabel = 'Zip Code' } =
      labelOverrides[country] || {};

    const sendButton = loading ? (
      <button className="btn-main" disabled>
        Saving...
      </button>
    ) : (
      <button action="submit" className="btn-main">
        Continue
      </button>
    );

    const shouldUpdateTaxInfo = getNestedProperty(
      'location.state.isTimeToUpdateTaxInfo',
      history,
      false,
    );

    const sectionTitle = shouldUpdateTaxInfo
      ? 'Review Athlete Info'
      : 'Confirm Athlete Info';

    const taxInfoDescriptionWithWarning = () => {
      return (
        <span className="warning-icon">
          <img src={warningIcon} alt="Alliance Partner Badge" />
          <span>
            Please review your tax info entered last year to ensure it is still
            correct. This info is crucial to you getting paid.
          </span>
        </span>
      );
    };

    const sectionDescription = shouldUpdateTaxInfo
      ? taxInfoDescriptionWithWarning()
      : 'Please confirm the info is correct. The below information is\n crucial to you getting paid.';

    const isLoading = loading || loadingAuth || loadingCharge;

    const toolTipText = `This is generally your social security number (SSN).
    However, for a U.S. resident alien or non-U.S. citizen, if you do not have 
    an SSN and are not eligible to get one your TIN is your IRS individual 
    taxpayer identification number (ITIN).`;

    return (
      <Layout className="bg-dashboard" loading={isLoading}>
        <div className="grid-container legal-info-view">
          <div className="grid-x">
            <div className="small-12 large-12 desktop-only">
              <BackButton goBack={this.goBack} />
              <h2 className="section-title padtop-0 padbot-0">
                {sectionTitle}
              </h2>
            </div>
            <div className="small-12 large-6 large-offset-3 theme-content-container">
              <div className="small-12 large-12 mobile-only">
                <BackButton goBack={this.goBack} />
                <h2 className="section-title padtop-0">{sectionTitle}</h2>
              </div>
              <p>{sectionDescription}</p>

              <div className="legal-section-title">Legal Name</div>

              <hr />

              <form
                onSubmit={handleSubmit(this.onSubmit)}
                className="profile-edit"
              >
                <ErrorMessage errorMessage={this.props.userErrorMessage} />

                <fieldset>
                  <label htmlFor="LegalFirstName">Legal First Name *</label>
                  <Field
                    name="LegalFirstName"
                    component={this.renderField}
                    type="text"
                    placeholder="Legal First Name"
                    validate={[required]}
                  />
                  <hr className="martop-0" />
                  <label htmlFor="LegalLastName">Legal Last Name *</label>
                  <Field
                    name="LegalLastName"
                    component={this.renderField}
                    type="text"
                    placeholder="Legal Last Name"
                    validate={[required]}
                  />
                  <hr className="martop-0" />
                  <label htmlFor="nickname">Nickname (if applicable)</label>
                  <Field
                    name="nickname"
                    component={this.renderField}
                    type="text"
                    placeholder="Enter"
                  />

                  <hr className="martop-0" />

                  <div className="legal-section-title">Hometown</div>
                  <hr />

                  <div className="small-12">
                    <label htmlFor="hometown">city and state *</label>
                    <Field
                      name="hometown"
                      component={this.renderField}
                      type="text"
                      placeholder="city and state"
                      validate={[required, hometown]}
                    />
                  </div>
                  <hr className="martop-0" />

                  <div className="legal-section-title">Mailing Address</div>

                  <hr />

                  <div className="small-12">
                    <label htmlFor="country">Country *</label>
                    <Select
                      clearable={false}
                      name="country"
                      onChange={this.handleCountryChange}
                      options={countryOptions}
                      placeholder={'Select'}
                      searchable={false}
                      value={country}
                    />
                  </div>

                  <hr className="martop-0" />

                  <div className="small-12">
                    <label htmlFor="street">
                      <MapPin size="14" className="icon-map-pin" /> Street
                      address *
                    </label>
                    <Field
                      name="street"
                      component={this.renderField}
                      type="text"
                      className="street"
                      placeholder="Street Address"
                      validate={[required]}
                    />
                  </div>

                  <hr className="martop-0" />

                  <div className="small-12">
                    <label htmlFor="street2">Apartment, Suite, Etc.</label>
                    <Field
                      name="street2"
                      component={this.renderField}
                      type="text"
                      className="street2"
                      placeholder="Apartment, suite, unit, etc."
                    />
                  </div>

                  <hr className="martop-0" />

                  <div className="grid-x grid-margin-x align-justify">
                    <div className="cell small-12 large-4 marbot-2">
                      <div className="underline-field">
                        <label htmlFor="city">City *</label>
                        <Field
                          name="city"
                          component={this.renderField}
                          type="text"
                          className="city"
                          placeholder="City / Town"
                          validate={[required, cityTown]}
                        />
                      </div>
                    </div>
                    <div className="cell small-12 large-4 marbot-2">
                      <div className="underline-field">
                        <label htmlFor="state">{stateLabel} *</label>
                        <Field
                          name="state"
                          component={this.renderStateField}
                          type="text"
                          validate={[required]}
                        />
                      </div>
                    </div>
                    <div className="cell small-12 large-4">
                      <div className="underline-field">
                        <label htmlFor="zip">{zipcodeLabel} *</label>
                        <Field
                          name="zip"
                          component={this.renderField}
                          maxLength={country === 'US' ? 5 : 6}
                          onKeyPress={(event) => {
                            if (
                              (country === 'US' && !/[0-9]/.test(event.key)) ||
                              (country === 'CA' &&
                                !/[0-9]|[A-Z]|[a-z]/.test(event.key))
                            ) {
                              event.preventDefault();
                            }
                          }}
                          type="text"
                          className="zipcode"
                          placeholder="Zip Code"
                          validate={
                            country === 'US'
                              ? [required, exactlyFive]
                              : [required, exactlySix]
                          }
                        />
                      </div>
                    </div>
                  </div>
                  {event && event.ShouldCollectSNNAndCitizenshipInfo && (
                    <>
                      <div className="legal-section-title">Citizenship</div>

                      <div className="citizen-container">
                        <Field
                          component={RadioButton}
                          type="radio"
                          name="USCitizen"
                          id="USCitizen"
                          selection="1"
                          label="I am a U.S. Citizen"
                          className="citizen-option"
                          onSelect={this.handleUsCitizenChange}
                          isChecked={String(USCitizen) === '1'}
                        />
                      </div>

                      <div className="citizen-container">
                        <Field
                          component={RadioButton}
                          type="radio"
                          name="USCitizen"
                          id="USCitizen"
                          selection="0"
                          label="I am a U.S. Resident Alien"
                          className="citizen-option"
                          onSelect={this.handleUsCitizenChange}
                          isChecked={String(USCitizen) === '0'}
                        />
                      </div>

                      <div className="citizen-container">
                        <Field
                          component={RadioButton}
                          type="radio"
                          name="USCitizen"
                          id="USCitizen"
                          selection="2"
                          label="I have no citizenship in the U.S"
                          className="citizen-option"
                          onSelect={this.handleUsCitizenChange}
                          isChecked={String(USCitizen) === '2'}
                        />
                      </div>

                      <div className="legal-section-title">
                        Taxpayer Identification Number (TIN)
                        <div
                          style={{
                            display: 'inline',
                            verticalAlign: 'middle',
                            paddingLeft: '5px',
                          }}
                        >
                          <StylesProvider injectFirst>
                            <Tooltip
                              classes={{
                                arrow: 'black-tooltip-arrow',
                                tooltip: 'black-tooltip',
                              }}
                              title={toolTipText}
                              enterTouchDelay={0}
                              leaveTouchDelay={5000}
                              arrow
                            >
                              <Help
                                className="tooltip-help-icon"
                                fontSize="small"
                              />
                            </Tooltip>
                          </StylesProvider>
                        </div>
                      </div>

                      <br />
                      <hr />

                      <label
                        htmlFor="SSN"
                        title="Social Security Number or Individual Taxpayer Identification Number"
                      >
                        SSN or ITIN *
                      </label>
                      <Field
                        component={this.renderSSNField}
                        name="SSN"
                        validate={[required, exactlyNine]}
                      />
                      <hr className="martop-0" />
                    </>
                  )}
                </fieldset>
                <div className="text-center">{sendButton}</div>
              </form>
            </div>
          </div>
        </div>
      </Layout>
    );
  }
}
