import { connect } from 'react-redux';
import { compose } from 'redux';
import { withRouter } from 'react-router';

import withToJS from 'enhancers/withToJS';
import withCalculateEntryCharge from 'enhancers/withCalculateEntryCharge';
import { withSelectedDisciplines } from 'context/SelectedDisciplines';
import { withBuddyGroups } from 'context/BuddyGroups';
import { getEventBuddies } from 'actions/event';
import { getBuddyGroup } from 'actions/entryPool';
import { SuccessBase } from './Success';

const mapStateToProps = (state) => ({
  event: state.event.get('data'),
  buddyGroup: state.entryPool.get('buddyGroup'),
  charge: state.entryChargeRequest.get('data'),
  user: state.user.get('data'),
  loading: state.event.get('loading') && state.entryPool.get('loading'),
  associationMembership: state.associationMembership.get('data'),
});

const mapDispatchToProps = (dispatch) => ({
  getEventBuddies: () => dispatch(getEventBuddies()),
  getBuddyGroup: () => dispatch(getBuddyGroup()),
  dispatch,
});

export default compose(
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
  withCalculateEntryCharge,
  withSelectedDisciplines,
  withBuddyGroups,
  withToJS,
)(SuccessBase);
