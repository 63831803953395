import React from 'react';
import hoistNonReactStatics from 'hoist-non-react-statics';
import { toJS, getDisplayName } from 'helpers';

const withToJS = (WrappedComponent) => {
  const Wrapper = (props) => {
    const { ...rest } = props;

    const propsJS = toJS(rest);
    return <WrappedComponent {...propsJS} />;
  };

  Wrapper.displayName = `withImmutablePropsToJS(${getDisplayName(
    WrappedComponent,
  )})`;

  hoistNonReactStatics(Wrapper, WrappedComponent);

  return Wrapper;
};

export default withToJS;
