const specialCases = new Set([
  19738,
  23206,
  22806,
  2339,
  13205,
  21069,
  23010,
  17454,
  22777,
  21083,
  16815,
  12522,
  23244,
  14366,
  15338,
  1284,
  23262,
  21008,
  22881,
  20223,
  20140,
  23245,
  21298,
  20757,
  5098,
  17545,
  23243,
  22001,
  22527,
  21686,
  22330,
  9266,
  20415,
  22441,
  14783,
  22208,
  21067,
  18878,
  19985,
  20189,
  22377,
  22879,
  31294,
  22450,
  21419,
  23200,
  22840,
  14990,
  10484,
  22852,
  20208,
  20537,
  23226,
  22272,
  20881,
  20198,
  22417,
  22008,
  21552,
  23292,
  22855,
  23269,
  23214,
  14823,
  20118,
  19711,
  23319,
  31923,
  13066,
  17385,
  19499,
  18174,
  23294,
  19641,
  17896,
  8679,
  22622,
  1934,
  19142,
  19164,
  19967,
  22193,
  7041,
  5197,
  16843,
  19802,
  16862,
  20052,
  22649,
  19193,
  21068,
  14399,
  23212,
  14572,
  16865,
  20547,
  19854,
  23281,
  18824,
  23248,
  23223,
  21667,
  20423,
  21664,
  19818,
  18930,
  17553,
  4552,
  20175,
  8479,
  16883,
  19215,
  19853,
  23274,
  14544,
  14487,
  13187,
  8997,
  7011,
  4238,
  4296,
  4867,
  7236,
  8153,
  17067,
  10766,
  19331,
  16729,
  14918,
  12994,
  18327,
  14954,
  14953,
  19121,
  17211,
  19125,
  18584,
  19015,
  21868,
]);

export default process.env.REACT_APP_ONLY_DEV ? new Set([37730]) : specialCases;
