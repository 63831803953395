// @flow
import { get } from 'lodash';
import React, { useContext, useCallback } from 'react';
import { compose } from 'redux';
import { uniqBy, sumBy } from 'lodash';

import withCalculateEntryCharge from 'enhancers/withCalculateEntryCharge';
import { DisciplineContext } from 'context/SelectedDisciplines';
import {
  preferencesAndOutCounts,
  getPreferencesAndNotsData,
  getPartnersData,
} from 'helpers';
import type { EventType } from 'models/Event';
import type { DisciplineType } from 'context/SelectedDisciplines/type';
import { isTeamDiscipline } from 'constants/disciplines';
import DisciplineCardDescriptionWithClasses from './DisciplineCardDescriptionWithClasses';
import { pluralize, changeRoute } from 'helpers';
import { EVENT_ENTRY } from 'constants/routes';

type SelectedDisciplinesListProps = {|
  event: EventType,
  loadingCharge: boolean,
  charge: Object,
  history: Object,
  showConfirmPartnerRemoveModal: boolean,
  shouldHideNomination: boolean,
|};

const SelectedDisciplinesListWithClasses = (
  props: SelectedDisciplinesListProps,
) => {
  let { selectedDisciplines } = useContext(DisciplineContext);
  const { activeDisciplineSelect } = useContext(DisciplineContext);

  const onEditDisciplineWithClass = useCallback(
    async (discipline: DisciplineType) => {
      await activeDisciplineSelect(discipline);
      changeRoute({ history: props.history, route: EVENT_ENTRY });
    },
    [],
  );

  const getHorseNames = (discipline: DisciplineType) => {
    const { horseNames } = discipline;
    return horseNames && horseNames.length ? horseNames : [];
  };

  const { event, charge } = props;
  const disciplineFees = get(charge, 'disciplineFees', []);

  if (charge) {
    let disciplineIds = selectedDisciplines.map(
      (discipline) => discipline.DisciplineUID,
    );

    disciplineIds = uniqBy(disciplineIds, (disciplineId) => disciplineId);

    selectedDisciplines = [...selectedDisciplines].sort(
      (a, b) =>
        disciplineIds.indexOf(a.DisciplineUID) -
        disciplineIds.indexOf(b.DisciplineUID),
    );
  }

  const { NumberOfNots, NumberOfPrefs, DisablePartnerSelection } = event;
  return (
    <>
      {selectedDisciplines.map((dis, index) => {
        const discipline = { ...dis };
        let totalFee = 0;
        let customTotalFee = 0;
        const currentChargeDiscipline = disciplineFees.find(
          (disciplineFee) =>
            disciplineFee.classesFees &&
            disciplineFee.disciplineId === discipline.DisciplineUID &&
            disciplineFee.classesFees.some(
              (classFee) => classFee.ClassName === discipline.ClassName,
            ),
        );
        let currentClassName = '';
        let preferencesAndNots = [];
        let currentFeeCustomName = '';

        if (currentChargeDiscipline) {
          const currentChargeClass = currentChargeDiscipline.classesFees.find(
            (classFee) => classFee.ClassName === discipline.ClassName,
          );

          const totalPartnerEntryFee = sumBy(
            currentChargeDiscipline.classesFees.filter(
              (classFee) => classFee.ClassName == discipline.ClassName,
            ),
            (classFee) => classFee.partnerEntryFee,
          );

          const totalPartnerEntryCustomFee = sumBy(
            currentChargeDiscipline.classesFees.filter(
              (classFee) => classFee.ClassName == discipline.ClassName,
            ),
            (classFee) => classFee.partnerCustomFee,
          );

          currentClassName = `${
            discipline.ClassName ? discipline.ClassName : ''
          }${
            discipline.newEntryCount > 1
              ? ` (X${discipline.newEntryCount})`
              : ''
          }`;
          totalFee =
            currentChargeClass.entryFee * discipline.newEntryCount +
            totalPartnerEntryFee;
          customTotalFee =
            currentChargeClass.disciplineFee * discipline.newEntryCount +
            totalPartnerEntryCustomFee;
          currentFeeCustomName =
            discipline.DisciplineFeeName ||
            currentChargeClass.disciplineFeeName;

          if (!discipline.DisciplineFeeName || !discipline.DisciplineFee) {
            discipline.DisciplineFeeName = currentFeeCustomName;
            discipline.DisciplineFee = currentChargeClass.disciplineFee;
          }

          preferencesAndNots = getPreferencesAndNotsData(
            currentChargeDiscipline.classesFees,
            discipline,
            true,
          );
        }

        const { preferenceCount, outCount } = preferencesAndOutCounts(
          discipline,
        );
        const preferenceText = NumberOfPrefs
          ? pluralize(`${preferenceCount} preference`, preferenceCount !== 1)
          : '';
        const outText = NumberOfNots
          ? pluralize(`${outCount} not`, outCount !== 1)
          : '';

        const horseNames = getHorseNames(discipline);

        const prefComma = NumberOfPrefs && NumberOfNots ? ', ' : ' ';

        const elKey = `${discipline.Discipline}-${String(
          discipline.EPUID,
        )}-${index}`;

        let { partners } = discipline;
        const { isPendingEntry, partner } = discipline;

        if (isPendingEntry) {
          partners = [partner];
        }

        const partnersData =
          !DisablePartnerSelection && isTeamDiscipline(discipline.value)
            ? getPartnersData(partners)
            : [];

        const showPreferenceText = event.performances
          .reduce((acc, curr) => {
            const foundDiscipline = curr.Disciplines.find(
              ({ id }) => id === discipline.value,
            );

            if (!!foundDiscipline) {
              if ('PreferenceEligible' in foundDiscipline) {
                return [...acc, foundDiscipline.PreferenceEligible];
              } else {
                return [...acc];
              }
            } else {
              return [...acc];
            }
          }, [])
          .some((preferenceEligible) => !!preferenceEligible);

        return (
          <DisciplineCardDescriptionWithClasses
            key={elKey}
            onClick={onEditDisciplineWithClass}
            discipline={discipline}
            classNames={currentClassName}
            feeTotal={totalFee}
            preferenceText={preferenceText}
            customFee={customTotalFee}
            customFeeName={currentFeeCustomName}
            outText={outText}
            prefComma={prefComma}
            horseNames={horseNames}
            preferencesAndNots={preferencesAndNots}
            partnersData={partnersData}
            showPreferenceText={showPreferenceText}
          />
        );
      })}
    </>
  );
};

export default compose(withCalculateEntryCharge)(
  SelectedDisciplinesListWithClasses,
);
