// @flow
import React, { PureComponent } from 'react';
import { ArrowLeft, Trash } from 'react-feather';

type ChildDataType = {|
  firstName: string,
  lastName: string,
  DOB: string,
  disciplineId: string,
  Gender: string,
|};

type YouthRegistrationStatusPropsType = {|
  athletesList: ChildDataType[],
  removeYouthAthlete: (f: Function) => any,
  goToRegisterYouth: (f: Function) => void,
  onFinalSubmit: () => Promise<void>,
  goBackToTerms: (f: Function) => void,
|};

class YouthRegistrationStatus extends PureComponent<YouthRegistrationStatusPropsType> {
  render() {
    const {
      athletesList,
      removeYouthAthlete,
      onFinalSubmit,
      goToRegisterYouth,
      goBackToTerms,
    } = this.props;
    const isAthletesListEmpty = athletesList.length < 1;
    const youthAthletes = () => {
      if (isAthletesListEmpty) {
        return (
          <div className="youth-athlete-container">
            Add youth athletes so you will be able to nominate and enter them in
            rodeos
          </div>
        );
      }

      return athletesList.map((item, index) => (
        <div
          id={'athlete' + index}
          className="youth-athlete-container has-athletes"
          key={item.firstName + item.lastName}
        >
          {item.firstName + ' ' + item.lastName}
          <button
            className="trash-athlete-button"
            type="button"
            onClick={(e) => {
              e.preventDefault();
              removeYouthAthlete(item);
            }}
          >
            <Trash className="float-right" />
          </button>
        </div>
      ));
    };

    const onClickGoBack = () => {
      goBackToTerms();
    };

    const renderAddAnotherYouthAthleteButton = () => {
      if (isAthletesListEmpty) return null;
      return (
        <div className="text-center padtop-5 padbot-5 add-another-youth-athlete-link">
          <button type="button" onClick={goToRegisterYouth}>
            ADD ANOTHER YOUTH ATHLETE
          </button>
        </div>
      );
    };

    return (
      <div className="youth-registration-status">
        <button className="back-button martop-0" onClick={onClickGoBack}>
          <ArrowLeft className="arrow-left" />
          Go Back
        </button>
        <h2 className="section-title center marbot-2 padtop-1">
          Youth Athletes
        </h2>
        <>
          {youthAthletes()}
          {renderAddAnotherYouthAthleteButton()}
          <div className="text-center">
            <button
              className="btn-main h-auto"
              type="button"
              onClick={isAthletesListEmpty ? goToRegisterYouth : onFinalSubmit}
            >
              {isAthletesListEmpty
                ? 'ADD YOUTH ATHLETE'
                : 'FINISH REGISTRATION'}
            </button>
          </div>
        </>
      </div>
    );
  }
}

export default YouthRegistrationStatus;
