// @flow
import React, { useContext } from 'react';
import type { AbstractComponent } from 'react';
import { useSelector } from 'react-redux';

import { AthletesContext } from './SelectedAthletes';
import type { ContextValue as Athletes } from './SelectedAthletes';
import { CompetitionsContext } from './SelectedCompetitions';
import type { ContextValue as Competitions } from './SelectedCompetitions';
import { DisciplineContext } from './SelectedDisciplines';
import type { ContextValue as Disciplines } from './SelectedDisciplines/type';
import { SegmentsContext } from './SelectedSegments';
import type { ContextValue as Segments } from './SelectedSegments/type';
import { CouponContext } from './SelectedCoupon';
import type { ContextValue as Coupon } from './SelectedCoupon';
import { getDisciplineSegmentsSelected } from 'selectors/disciplineSegments';

export type NominationProps = {|
  athletes: Athletes,
  disciplines: Disciplines,
  competitions: Competitions,
  segments: Segments,
  coupon: Coupon,
  disciplineSegmentsSelected: Object,
|};

export const withNomination = (ComposedComponent: AbstractComponent<any>) => {
  return function WithNomination(props: any) {
    const athletes = useContext(AthletesContext);
    const disciplines = useContext(DisciplineContext);
    const competitions = useContext(CompetitionsContext);
    const segments = useContext(SegmentsContext);
    const coupon = useContext(CouponContext);
    const disciplineSegmentsSelected = useSelector(
      getDisciplineSegmentsSelected,
    );

    return (
      <ComposedComponent
        {...props}
        athletes={athletes}
        disciplines={disciplines}
        competitions={competitions}
        segments={segments}
        coupon={coupon}
        disciplineSegmentsSelected={disciplineSegmentsSelected}
      />
    );
  };
};
