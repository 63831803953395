import gql from 'graphql-tag';

export const BUDDY_SEARCH = gql`
  query buddySearch($input: BuddySearchInput!) {
    buddySearch(input: $input) {
      fullName
      fullNameWithNickname
      ERAUID
      isYouth
      isAlreadyInBuddyGroup
      isEligibleBuddy
      ineligibleReason
    }
  }
`;
