// @flow
import React, { useEffect, useContext } from 'react';
import moment from 'moment';
import { Check, X } from 'react-feather';

import { SectionTitle } from 'components/Section';

import type { EventPreferenceType } from 'containers/EntryPreferences';
import { DisciplineContext } from 'context/SelectedDisciplines';
import type { ContextValue } from 'context/SelectedDisciplines/type';

type Props = {|
  preferences: Array<EventPreferenceType>,
  disciplineId?: string,
  outIfPrefNotMet: boolean,
|};

const ViewPreferences = (props: Props) => {
  const { preferences = [], outIfPrefNotMet, disciplineId = '' } = props;
  const context: ContextValue = useContext<ContextValue>(DisciplineContext);
  const { setPreference } = context;

  // Set the preferences for a buddy's entry
  useEffect(() => {
    if (disciplineId) {
      preferences.forEach((preference) => {
        setPreference(
          disciplineId,
          preference.prefName,
          preference.PerformanceUID,
        );
      });
    }
  }, [disciplineId]);

  const isPreference = (preference: EventPreferenceType) => {
    return preference.prefName.toLowerCase().includes('pref');
  };

  const renderPerformanceDate = (preference: EventPreferenceType) => {
    const date = isPreference(preference) ? preference.date : preference.out;
    return (
      <div className="preference-date">
        {moment.utc(date).format('ddd MMM D [at] h:mm a')}
      </div>
    );
  };

  const renderPreference = (preference: EventPreferenceType) => {
    const preferenceLabel = isPreference(preference) ? (
      <div className="preference-label">
        <Check className="check-mark-icon" />
        <div className="text">{`Preference ${preference.prefNumber}`}</div>
      </div>
    ) : (
      <div className="preference-label">
        <X className="x-icon" />
        <div className="text">{"Can't Compete"}</div>
      </div>
    );
    return (
      <div key={preference.prefName} className="preference-item">
        {preferenceLabel}
        {renderPerformanceDate(preference)}
      </div>
    );
  };

  const renderPreferences = () => {
    return preferences.length ? (
      preferences.map((preference) => renderPreference(preference))
    ) : (
      <div className="no-preference">
        <div className="info-icon">i</div>
        <div className="text">{'No preferences were set'}</div>
      </div>
    );
  };

  const renderOptOutText = () => {
    return outIfPrefNotMet ? (
      <div className="opt-out-text">
        Entry will be canceled if preferences are not met.
      </div>
    ) : null;
  };

  return (
    <div className="view-preferences">
      <SectionTitle>TEAM PREFERENCES</SectionTitle>
      <div>
        Preference are set for the team when the first partner enters.
        Preferences are not guaranteed.
      </div>
      <div className="competition-list-select">
        {renderPreferences()}
        {renderOptOutText()}
      </div>
    </div>
  );
};

export default ViewPreferences;
