// @flow
import { get } from 'lodash';
import { getValueLabelObject, getNestedProperty } from 'helpers';
import {
  defaultDisciplineId,
  isPersonalHorseDiscipline,
} from 'constants/disciplines';
import type { DisciplineType } from './type';
import type { NominationPayloadType } from 'models/EntryChargeRequest';
import type { EventType } from 'models/Event';
import type { DisciplineFeeType } from 'models/DisciplineFee';

export const getEntryDisciplines = (
  disciplines: DisciplineType[],
  allowClasses: boolean,
): Object[] => {
  let currentDisciplines: Object[] = [];

  disciplines.map(
    ({
      value,
      isPayingForPartner,
      hasPreference,
      Pref1,
      Pref2,
      Pref3,
      Out1,
      Out2,
      OutIfPrefNotMet,
      partners,
      EPUID,
      HorseName,
      horseNames,
      segments,
      ClassName,
      newEntryCount,
      EventEntryDisciplineFeeUID,
      isPendingEntry,
      partner,
    }) => {
      let currentPartners = isPendingEntry ? [partner] : partners;
      let discipline: Object = {
        id: parseInt(value),
        isPayingForPartner: isPayingForPartner,
        Pref1: hasPreference && Pref1 ? Pref1 : null,
        Pref2: hasPreference && Pref2 ? Pref2 : null,
        Pref3: hasPreference && Pref3 ? Pref3 : null,
        Out1: hasPreference && Out1 ? Out1 : null,
        Out2: hasPreference && Out2 ? Out2 : null,
        OutIfPrefNotMet: hasPreference && OutIfPrefNotMet,
        segments: segments ? segments : [],
        partners: currentPartners,
        EPUID,
        HorseName,
        horseNames,
        hasPreference,
        isPendingEntry,
      };

      if (allowClasses) {
        discipline.ClassName = ClassName;
        discipline.EventEntryDisciplineFeeUID = EventEntryDisciplineFeeUID;
      }

      currentDisciplines = currentDisciplines.concat(
        Array(newEntryCount).fill(discipline),
      );

      return discipline;
    },
  );

  let indexPartner = 0;
  let currentEPUID = currentDisciplines.length
    ? currentDisciplines[0].EPUID
    : null;
  let currentDisciplineValue = currentDisciplines.length
    ? currentDisciplines[0].id
    : 0;

  let currentClassName = currentDisciplines.length
    ? currentDisciplines[0]['ClassName']
    : undefined;
  let indexHorse = 0;
  currentDisciplines = currentDisciplines.map((discipline) => {
    const { horseNames, id } = discipline;
    const entryIsHorseDiscipline = isPersonalHorseDiscipline(id);

    const EPUIDValidation =
      !!discipline.EPUID && currentEPUID != discipline.EPUID;
    if (
      currentDisciplineValue != id ||
      discipline.ClassName != currentClassName ||
      EPUIDValidation
    ) {
      indexPartner = 0;
      indexHorse = 0;
      currentEPUID = discipline.EPUID;
      currentDisciplineValue = id;
      currentClassName = discipline['ClassName'];
    }

    let currentDiscipline = {
      ...discipline,
      partner: discipline.partners ? discipline.partners[indexPartner] : null,
    };
    delete currentDiscipline.partners;
    indexPartner = indexPartner + 1;

    if (entryIsHorseDiscipline && horseNames && horseNames.length) {
      currentDiscipline.HorseName = horseNames[indexHorse].toUpperCase();
      indexHorse = indexHorse + 1;
    }

    return currentDiscipline;
  });

  return currentDisciplines;
};

export const getNominationDisciplines = (
  disciplines: DisciplineType[],
  performanceDate: ?string,
  AllowClasses: ?boolean,
): NominationPayloadType[] => {
  if (!performanceDate) return [];

  return AllowClasses
    ? disciplines
        .filter(({ segments }) => getNestedProperty('length', segments))
        .map(({ value, segments, ClassName, EventEntryDisciplineFeeUID }) => {
          const currentSegments: Object = segments.map((s) => {
            return { id: s.value, count: s.newSegmentCount };
          });
          return {
            ClassName,
            disciplineId: parseInt(value),
            EventEntryDisciplineFeeUID,
            performances: [performanceDate],
            segments: currentSegments,
          };
        })
    : disciplines
        .filter(({ segments }) => getNestedProperty('length', segments))
        .map(({ value, segments }) => {
          const currentSegments: Object = segments.map((s) => {
            return { id: s.value, count: s.newSegmentCount };
          });
          return {
            ClassName: null,
            EventEntryDisciplineFeeUID: null,
            disciplineId: parseInt(value),
            segments: currentSegments,
            performances: [performanceDate],
          };
        });
};

export const getSelectedDisciplinesId = (
  selectedDisciplines: DisciplineType[],
): number[] => selectedDisciplines.map((d) => parseInt(d.value, 10));

export const getDefaultSelectedDisciplines = (charge: Object, user: Object) => {
  const selectedDisciplines = getNestedProperty('Disciplines', charge, []);
  const defaultDiscipline = getNestedProperty(
    'disciplineId',
    user,
    defaultDisciplineId,
  );
  if (selectedDisciplines.length) {
    return selectedDisciplines.reduce((prev, curr) => {
      prev[curr] = true;
      return prev;
    }, {});
  }
  return { [defaultDiscipline]: true };
};

export const getUniqueDisciplines = (
  AllowClasses: boolean,
  obj: Object,
  disciplines: Object[],
  newPerformance: Object,
  disciplineFees: DisciplineFeeType[],
) => {
  AllowClasses
    ? disciplines.forEach((discipline) => {
        const disciplineFee = disciplineFees.find(
          (fee: DisciplineFeeType) => fee.DisciplineUID == discipline.id,
        );
        if (obj[discipline.id]) {
          const { Classes } = obj[discipline.id];

          Classes.forEach((Class) => {
            const { performances } = Class;
            const isNewPerformance =
              performances.findIndex(
                ({ PerformanceUID }) =>
                  PerformanceUID == newPerformance.PerformanceUID,
              ) === -1 &&
              disciplines.some(
                ({ EventEntryDisciplineFeeUID }) =>
                  Class.EventEntryDisciplineFeeUID ===
                  EventEntryDisciplineFeeUID,
              );

            isNewPerformance &&
              (Class.performances = [].concat(performances, newPerformance));
            Class.maxNumberOfEntries = Class.MaxNumberOfEntriesPerAthlete;
          });
        } else {
          const Classes = get(disciplineFee, 'Classes', []);
          const newClasses = Classes.map((Class) => {
            return {
              ...Class,
              performances: disciplines.some(
                ({ EventEntryDisciplineFeeUID }) =>
                  Class.EventEntryDisciplineFeeUID ===
                  EventEntryDisciplineFeeUID,
              )
                ? [newPerformance]
                : [],
              maxNumberOfEntries: Class.MaxNumberOfEntriesPerAthlete,
            };
          });
          obj[discipline.id] = { ...discipline, Classes: newClasses };
        }
      })
    : disciplines.forEach((discipline) => {
        const disciplineFee = disciplineFees.find(
          (fee: DisciplineFeeType) => fee.DisciplineUID == discipline.id,
        );
        const PartnerMultipleEntries = get(
          disciplineFee,
          'PartnerMultipleEntries',
          null,
        );

        const RequirePartnerSelection = get(
          disciplineFee,
          'RequirePartnerSelection',
          null,
        );

        if (obj[discipline.id]) {
          const { performances } = obj[discipline.id];
          obj[discipline.id].performances = [].concat(
            performances,
            newPerformance,
          );
          obj[discipline.id].maxNumberOfEntries =
            disciplineFee === undefined
              ? 1
              : disciplineFee.MaxNumberOfEntriesPerAthlete;
        } else {
          obj[discipline.id] = {
            ...discipline,
            Fee: get(disciplineFee, 'Fee', 0),
            PartnerMultipleEntries,
            DisciplineFee: get(disciplineFee, 'DisciplineFee', 0),
            DisciplineFeeName: get(disciplineFee, 'DisciplineFeeName', ''),
            RequirePartnerSelection,
            performances: [newPerformance],
          };
          obj[discipline.id].maxNumberOfEntries =
            disciplineFee === undefined
              ? 1
              : disciplineFee.MaxNumberOfEntriesPerAthlete;
        }
      });
  return obj;
};

export const getUnavailableDisciplines = (
  disciplines: Object[],
): DisciplineType[] =>
  disciplines.map((d) => ({
    ...getValueLabelObject(d.id, d.name),
    performances: d.performances,
  }));

export const getEventPreferences = (event: EventType) => {
  const { NumberOfPrefs, NumberOfNots } = event;

  if (!NumberOfNots && !NumberOfPrefs) return [];

  const preferenceIds = !!NumberOfPrefs
    ? [...Array(NumberOfPrefs).keys()].map((prefId) => prefId + 1)
    : [];
  const preferences = preferenceIds.map((prefId) => {
    return {
      prefType: 'pref',
      prefNumber: prefId,
      prefName: `Pref${prefId}`,
    };
  });

  const outIds = !!NumberOfNots
    ? [...Array(NumberOfNots).keys()].map((outId) => outId + 1)
    : [];
  const outs = outIds.map((outId) => {
    return {
      prefType: 'out',
      prefNumber: outId,
      prefName: `Out${outId}`,
    };
  });

  return [...preferences, ...outs];
};

export const getEntryCount = (
  entries: Object,
  ERAUID: string,
  disc: Object,
  AllowClasses: boolean,
): number => {
  if (AllowClasses) {
    if (entries[ERAUID] === undefined) {
      return 0;
    } else if (entries[ERAUID][disc.EventEntryDisciplineFeeUID] === undefined) {
      return 0;
    }
    return entries[ERAUID][disc.EventEntryDisciplineFeeUID];
  } else {
    if (entries[ERAUID] === undefined) {
      return 0;
    } else if (entries[ERAUID][disc.value] === undefined) {
      return 0;
    }
    return entries[ERAUID][disc.value];
  }
};

export const getAvailableDisciplinesForAthlete = (
  AllowClasses: boolean,
  entries: Object,
  ERAUID: string,
  availableDisciplines: Object[],
  getEntryCount: (
    entries: Object,
    ERAUID: string,
    disc: Object,
    AllowClasses: boolean,
  ) => number,
): Object[] =>
  AllowClasses
    ? availableDisciplines.map((disc) => {
        const { Classes } = disc;
        const newClasses = Classes.map((Class) => {
          return {
            ...Class,
            athleteEntryCount: getEntryCount(
              entries,
              ERAUID,
              Class,
              AllowClasses,
            ),
            newEntryCount: 0,
          };
        });
        return {
          ...disc,
          Classes: newClasses,
        };
      })
    : availableDisciplines.map((disc) => {
        return {
          ...disc,
          athleteEntryCount: getEntryCount(entries, ERAUID, disc, AllowClasses),
          newEntryCount: 0,
        };
      });

export const updateSamePartners = (partners: Object) => {
  Object.getOwnPropertyNames(partners).length > 1 &&
    Object.keys(partners).forEach((key) => {
      if (
        get(partners[key], 'samePartnerKey') !== null &&
        get(partners[key], 'samePartnerKey') !== undefined
      ) {
        const samePartnerKey = partners[key].samePartnerKey;
        if (Object.keys(partners[samePartnerKey]).length) {
          partners[key] = {
            ...partners[samePartnerKey],
            samePartnerKey,
          };
        }
      }
    });

  return partners;
};

export const setEntryAddedPartners = (partners: Object) => {
  Object.getOwnPropertyNames(partners).length > 1 &&
    Object.keys(partners).forEach((key) => {
      partners[key].isEntryAdded = true;
    });

  return partners;
};
