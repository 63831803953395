// @flow
import React from 'react';
import { RotateCw } from 'react-feather';

type LoaderPropsType = {|
  className?: string,
  loading: boolean,
|};

const Loader = (props: LoaderPropsType) => (
  <div
    className={`spinner-container ${props.className ? props.className : ''} ${
      props.loading ? 'show' : ''
    }`}
  >
    <RotateCw className="spinner" />
  </div>
);

export default Loader;
