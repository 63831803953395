// @flow
import React from 'react';
import { FormSection } from 'redux-form';
import EditProfileForm from './EditProfileForm';

type ProfileEditPropsType = {|
  dispatch: (f: Function) => any,
  handleSubmit: (f: Function) => any,
  history: Object,
  loading: boolean,
  loadingAuth: boolean,
  user: Object,
  onSubmit: (user: Object) => any,
  fetchUser: (user: Object) => any,
  userToken: (user: Object) => any,
|};

const ProfileEdit = (props: ProfileEditPropsType) => {
  const {
    dispatch,
    handleSubmit,
    history,
    loading,
    loadingAuth,
    user,
    onSubmit,
    fetchUser,
    userToken,
  } = props;

  return (
    <FormSection name="">
      <EditProfileForm
        onSubmit={onSubmit}
        dispatch={dispatch}
        handleSubmit={handleSubmit}
        history={history}
        loading={loading}
        loadingAuth={loadingAuth}
        user={user}
        fetchUser={fetchUser}
        userToken={userToken}
      />
    </FormSection>
  );
};

export default ProfileEdit;
