// @flow

// External Libraries
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import get from 'lodash/get';
import { isEmpty } from 'lodash';

import { differenceInYears } from 'date-fns';
import { useToasts } from 'react-toast-notifications';
import classNames from 'classnames';

// Internal libraries/components
import Layout from 'components/Layout';
import BackButton from 'components/BackButton';
import EntryEventDescription from 'components/EntryEventDescription';
import ErrorMessage from 'components/ErrorMessage';
import EntryDisciplineModal from 'components/EntryDisciplineModal';
import { useAfterDisciplineUpdate } from 'hooks/useAfterDisciplineUpdate';
import Notification from 'components/Notification';
import { useDisciplineModalHooks } from 'components/EntryDisciplineModal/hooks';
import {
  EVENT_ENTRY_REVIEW,
  EVENT_ENTRY_SEARCH,
  EVENT_ENTRY_COLLECT_MEMBERSHIP_FEES,
  EVENT_ENTRY_COLLECT_MEMBERSHIP_NUMBER,
} from 'constants/routes';
import EntrySelectFilter from 'components/Event/EntrySelectFilter';
import {
  changeRoute,
  getAssociationWithCollectMembershipNumber,
  getNestedProperty,
  getUserAthleteOption,
  getYouthAthletesLabel,
} from 'helpers';
import MultiDisciplineList from 'components/MultiDisciplineList';
import type { EventType } from 'models/Event';
import ViewCartButton from 'components/ViewCartButton';
import {
  removeManagerFromBuddyGroup,
  clearBuddyGroup,
} from 'actions/entryPool';

import { setShowEventMessagingBanner } from 'actions/event';
import specialCases from 'constants/specialCases';
import { getSelectedMembershipFee } from 'selectors/associationMembership';
import { getShowEventMessagingBanner } from 'selectors/event';
import { isYouthEvent } from '../../helpers/isYouthEvent';
import { isYouthRank } from '../../constants/event';

type EntryBaseProps = {|
  calculateEntryCharge: () => Promise<any>,
  history: Object,
  dispatch: Function,
  event: EventType,
  user: Object,
  loadingCharge: boolean,
  loading: boolean,
  errorMessage: ?string,
  ERAUID: ?number,
  charge: Object,
  athleteFullName: ?string,
  selectAthlete: (Object, Function) => void,
  switchAthlete: (ERAUID: string) => Promise<any>,
  hasError: () => boolean,
  selectedDisciplines: Array<any>,
  toggleIsEditing: (boolean) => void,
  isBuddyEntry: ?boolean,
  isBuddySelected: ?boolean,
  toggleIsBuddySelected: (boolean) => null,
  setIsBuddyEntry: (boolean) => null,
  removeBuddy: Function,
|};

export const EntryBase = (props: EntryBaseProps) => {
  const methods = useDisciplineModalHooks();
  const {
    calculateEntryCharge,
    event,
    dispatch,
    loadingCharge,
    loading,
    user,
    ERAUID,
    athleteFullName,
    hasError,
    history,
    selectedDisciplines,
    charge,
    toggleIsEditing,
    isBuddyEntry,
    toggleIsBuddySelected,
    isBuddySelected,
    setIsBuddyEntry,
    removeBuddy,
    selectAthlete,
    switchAthlete,
  } = props;
  const { activeDiscipline } = methods;
  const noActiveDiscipline = isEmpty(activeDiscipline);

  const showEventMessagingBanner = useSelector(getShowEventMessagingBanner);

  const { totalPayment, totalTransactionFee } = useSelector(
    ({ entryChargeRequest }) => entryChargeRequest.data,
  );

  const [disciplineModalOpen, afterDisciplineUpdate] = useAfterDisciplineUpdate(
    props,
  );
  const { addToast } = useToasts();

  const membershipFee = useSelector(getSelectedMembershipFee);

  const athletes = getYouthAthletesLabel(user, event.rank, false, true);
  const [association, setAssociation] = useState({});
  const [shouldHideNomination, setShouldHideNomination] = useState<boolean>(
    false,
  );
  const { CollectMembershipNumber, CollectMembershipFees } = association;

  useEffect(() => {
    window.onpopstate = clearBuddyState;
    initSelectedAthlete();
    associationForMembership();
    toggleIsEditing(false);

    if (!event.name) {
      goBack();
    }

    // Exit buddy mode when going back to event search
    history.listen(() => {
      if (get(history, 'location.pathname') === EVENT_ENTRY_SEARCH) {
        setIsBuddyEntry(false);
        toggleIsBuddySelected(false);
        dispatch(removeManagerFromBuddyGroup());
        dispatch(clearBuddyGroup());
      }
    });
  }, []);

  useEffect(() => {
    if (ERAUID) {
      calculateEntryCharge();
    }
  }, []);

  useEffect(() => {
    if (Array.isArray(athletes) && !isNaN(Number(ERAUID))) {
      const defaultAthlete = athletes.find(
        ({ value }) => parseInt(value) === parseInt(ERAUID),
      );

      if (defaultAthlete) {
        setShouldHideNomination(!!defaultAthlete.hideNomination ? true : false);
      }
    }
  }, [athletes, ERAUID]);

  const selectAndSwitch = async (athlete) => {
    !!athlete.hideNomination
      ? setShouldHideNomination(true)
      : setShouldHideNomination(false);

    await selectAthlete(athlete);
    await switchAthlete(athlete.value);
  };

  const associationForMembership = () => {
    const association = getAssociationWithCollectMembershipNumber(event);
    setAssociation(association);
  };

  const initSelectedAthlete = () => {
    if (!isBuddyEntry && !user.youthAthletes.length) {
      props.selectAthlete(getUserAthleteOption(user));
    }
    // Set first youth athlete as default for youth events
    if (!isBuddyEntry && user.isParent && isYouthRank(event.rank)) {
      const youthAthlete =
        athletes.find((athlete) => athlete.value === ERAUID) ||
        getNestedProperty('[0]', athletes);
      selectAthlete(youthAthlete);
    }
  };

  const clearBuddyState = () => {
    if (isBuddyEntry) {
      removeBuddy();
      dispatch(removeManagerFromBuddyGroup());
    }
    setIsBuddyEntry(false);
    toggleIsBuddySelected(false);
  };

  const goBack = () => {
    if (!isBuddyEntry) {
      clearBuddyState();
      return goBackRoute();
    }
    const isCollectMembership =
      !!CollectMembershipFees || !!CollectMembershipNumber;
    if (!(isCollectMembership && isBuddyEntry)) {
      selectAndSwitch(getUserAthleteOption(user));
    }

    return goBackRoute();
  };

  const goBackRoute = () => {
    let previousRoute;
    if (!!CollectMembershipFees) {
      previousRoute = EVENT_ENTRY_COLLECT_MEMBERSHIP_FEES;
    } else if (!!CollectMembershipNumber) {
      previousRoute = EVENT_ENTRY_COLLECT_MEMBERSHIP_NUMBER;
    } else {
      previousRoute = EVENT_ENTRY_SEARCH;
    }
    changeRoute({
      history,
      route: previousRoute,
      state: { isReading: true },
      persistState: true,
    });
  };

  const isValidEntry = () => {
    if (isYouthEvent(event.EventRank)) {
      const userAge = differenceInYears(new Date(), new Date(user.DOB));
      return [18, 19].includes(userAge) ||
        specialCases.has(parseInt(user.ERAUID))
        ? user.ERAUID === ERAUID
        : user.ERAUID !== ERAUID;
    }
    return true;
  };

  const onSubmit = async () => {
    const nextRoute = await EVENT_ENTRY_REVIEW;
    changeRoute({
      history,
      route: nextRoute,
      state: { isReading: true },
      persistState: true,
    });
  };

  if (!event.name) {
    return <div />;
  }

  const isParent = user.isParent;
  // Make sure the selected athlete belongs to the user or the user's youth athletes
  const selectedERAUID = athletes.some((athlete) => athlete.value === ERAUID)
    ? ERAUID
    : user.ERAUID;
  const submitIsDisabled = !isValidEntry();
  const formIsDisabled = hasError();

  const onCloseEventNotification = () => {
    dispatch(setShowEventMessagingBanner(false));
  };

  if (loadingCharge) {
    return (
      <Layout
        className={`bg-nominate entry ${formIsDisabled ? 'form-disabled' : ''}`}
        loading={loading || loadingCharge}
      />
    );
  }
  return (
    <Layout
      className={`bg-nominate entry ${formIsDisabled ? 'form-disabled' : ''}`}
      loading={loading || loadingCharge}
    >
      {noActiveDiscipline ? (
        <div className="grid-container padbot-0 enter-landing-view">
          <div className="grid-x">
            <div className="small-12 large-12 desktop-only">
              <BackButton goBack={goBack} />
              <h2 className="section-title padtop-0 padbot-0">
                {isBuddyEntry ? 'Enter buddy' : 'Enter'}
              </h2>
            </div>
            <div className="small-12 large-6 large-offset-3 theme-content-container entry">
              <div className="small-12 large-12 mobile-only back-btn-top-mobile">
                <BackButton goBack={goBack} />
              </div>
              <div className="small-12 large-12 mobile-only">
                {showEventMessagingBanner && (
                  <Notification
                    type="info"
                    showCloseButton
                    text={event.EventMessaging}
                    onClose={onCloseEventNotification}
                  />
                )}

                <h2 className="section-title padtop-0">Enter</h2>
              </div>
              <div className="desktop-only">
                {showEventMessagingBanner && (
                  <Notification
                    type="info"
                    showCloseButton
                    text={event.EventMessaging}
                    onClose={onCloseEventNotification}
                  />
                )}
              </div>
              <ErrorMessage errorMessage={props.errorMessage} />
              <EntryEventDescription event={event} />
              <hr />
              {!CollectMembershipNumber ? (
                <EntrySelectFilter
                  isParent={isParent}
                  ERAUID={selectedERAUID}
                  athletes={athletes}
                  selectAthlete={selectAndSwitch}
                  isOnBuddyEntryFlow={isBuddyEntry ? isBuddyEntry : undefined}
                  EventUID={event.id}
                  disabled={false}
                />
              ) : null}

              {!isBuddyEntry || (isBuddyEntry && isBuddySelected) ? (
                <MultiDisciplineList
                  afterDisciplineUpdate={afterDisciplineUpdate}
                  category="entry"
                  event={event}
                  selectedAthlete={athleteFullName}
                />
              ) : null}
              {(selectedDisciplines.length > 0 || !!membershipFee) && (
                <ViewCartButton
                  selectedDisciplines={selectedDisciplines}
                  charge={charge}
                  paymentValue={totalPayment - totalTransactionFee}
                  membershipFeeCount={Number(!!membershipFee)}
                  onClick={() => {
                    if (submitIsDisabled || formIsDisabled) {
                      addToast(
                        'Please enter a discipline before checking out',
                        {
                          appearance: 'error',
                        },
                      );
                    } else {
                      onSubmit();
                    }
                  }}
                  className={classNames('martop-1 marbot-1', {
                    disabled: submitIsDisabled || formIsDisabled,
                  })}
                />
              )}
            </div>
          </div>
        </div>
      ) : (
        <EntryDisciplineModal
          isOpen={disciplineModalOpen}
          onSave={afterDisciplineUpdate}
          shouldHideNomination={shouldHideNomination}
          isEditing={false}
          isReading={getNestedProperty(
            'location.state.isReading',
            history,
            false,
          )}
        />
      )}
    </Layout>
  );
};
