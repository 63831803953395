// @flow
import React from 'react';
import Modal from 'components/Modal';
import Section from 'components/Section';
import { email, phoneNumber } from 'components/Forms/Validation';
import { phone } from 'components/Forms/Normalizer';
import { Field, reduxForm } from 'redux-form';
import { renderField } from 'helpers';
import Footer from './Footer';
import type { PartnerAthleteType } from 'models/PartnerAthlete';
import type { EntryChargeType } from 'models/EntryChargeRequest';
import type { EventType } from 'models/Event';
import { getNestedProperty } from 'helpers';

type EntryPartnerInviteModalBaseProps = {
  closeModal: () => void,
  isOpen: boolean,
  partner: PartnerAthleteType,
  event: EventType,
  charge: EntryChargeType,
  athleteName: string,
};

const modalTitle = 'Invite Partner';
const modalDescription =
  "Add either your partner's phone number or email to invite them.";

const EntryPartnerInviteModalBase = (
  props: EntryPartnerInviteModalBaseProps,
) => {
  const { athleteName, closeModal, charge, event, isOpen, partner } = props;

  return (
    <Modal
      isOpen={isOpen}
      onClose={closeModal}
      title={modalTitle}
      className="entry-partner-invite-modal full-screen-mobile"
    >
      <Section className="entry-partner-invite-modal-content">
        <div className="description-text">{modalDescription}</div>
        <hr className="martop-1" />
        <form className="partner-contact-info">
          <fieldset>
            <label htmlFor="partnerName">{"Partner's Name"}</label>
            <Field
              name="partnerName"
              input={{
                value: getNestedProperty('fullName', partner, ''),
                onChange: () => {},
                disabled: true,
                name: 'partnerName',
              }}
              component={renderField}
              type="text"
              className={'partner-name-field'}
            />
            <hr className="martop-0" />
            <label htmlFor="phoneNumber">{"Partner's Phone Number"}</label>
            <Field
              name="phoneNumber"
              component={renderField}
              type="text"
              placeholder="Enter Partner's Phone Number"
              normalize={phone}
              validate={[phoneNumber]}
            />
            <hr className="martop-0" />
            <label htmlFor="email">{"Partner's Email"}</label>
            <Field
              name="email"
              component={renderField}
              type="email"
              placeholder="Enter Partner's Email"
              validate={[email]}
            />
          </fieldset>
        </form>
        <hr className="martop-0" />
      </Section>
      <Footer
        closeModal={closeModal}
        partner={partner}
        event={event}
        charge={charge}
        inviterName={athleteName}
      />
    </Modal>
  );
};

const EntryPartnerInviteModal = reduxForm({
  form: 'partnerContactInfo',
})(EntryPartnerInviteModalBase);

export default EntryPartnerInviteModal;
