// @flow
import React from 'react';
import Modal from 'react-modal';
import { getSelectedPreference } from 'helpers';
import PreferenceOption from 'components/PreferenceOption';
import type { PreferenceType } from 'context/SelectedDisciplines';

const entryPreferenceModalStyle = {
  overlay: {
    backgroundColor: 'rgba(0, 0, 0, 0.75)',
  },
  content: {
    outline: 'none',
    border: 'none',
    borderColor: '#cccccc',
    boxShadow: '0 0 10px #ccc',
    padding: '0',
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
  },
};

type EntryPreferenceModalProps = {|
  availablePreferences: PreferenceType[],
  isOpen: boolean,
  closeModal: () => void,
  selectedPerformance: ?Object,
  setPreference: (string, number) => any,
  unsetPreference: (string) => any,
  selectedPreferences: Object[],
|};

const EntryPreferenceModal = (props: EntryPreferenceModalProps) => {
  const {
    availablePreferences,
    isOpen,
    closeModal,
    selectedPerformance,
    setPreference,
    unsetPreference,
    selectedPreferences,
  } = props;
  const setPreferenceCall = (pref) => {
    if (selectedPerformance) {
      setPreference(pref, selectedPerformance.PerformanceUID);
    }
  };

  const preferenceOptions = availablePreferences.map((availablePref) => {
    const { prefType, prefName, prefNumber } = availablePref;

    const selectedPref = getSelectedPreference(prefName, selectedPreferences);

    return (
      <PreferenceOption
        key={prefName}
        prefNumber={prefNumber}
        prefType={prefType}
        selectedPref={selectedPref}
        setPreference={() => setPreferenceCall(prefName)}
        unsetPreference={() => unsetPreference(prefName)}
      />
    );
  });

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={closeModal}
      ariaHideApp={false}
      style={entryPreferenceModalStyle}
      contentLabel="Set preference"
    >
      <div className="performances-pref-modal-content">
        <ul>{preferenceOptions}</ul>
      </div>
    </Modal>
  );
};

EntryPreferenceModal.defaultProps = {
  selectedPreferences: [],
};

export default EntryPreferenceModal;
