import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { compose } from 'redux';
import { withBuddyGroups } from 'context/BuddyGroups';
import { withSelectedAthletes } from 'context/SelectedAthletes';
import { withSelectedDisciplines } from 'context/SelectedDisciplines';
import withToJS from 'enhancers/withToJS';
import withCalculateEntryCharge from 'enhancers/withCalculateEntryCharge';
import { EntryBase } from './Entry';

const mapStateToProps = (state) => ({
  event: state.event.get('data'),
  user: state.user.get('data'),
  userNominations: state.userNominations.get('data'),
  loadingNominations: state.userNominations.get('loading'),
  loading: state.event.get('loading'),
  loadingCharge: state.entryChargeRequest.get('loading'),
  charge: state.entryChargeRequest.get('data'),
  errorMessage: state.entryChargeRequest.get('errorMessage'),
});

const mapDispatchToProps = (dispatch) => ({
  dispatch,
});

export default compose(
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
  withBuddyGroups,
  withSelectedAthletes,
  withSelectedDisciplines,
  withCalculateEntryCharge,
  withToJS,
)(EntryBase);
