// @flow
import React from 'react';

import { EnteredDisciplines } from 'containers/EntryTransactions/components/EnteredDisciplines';
import BuddyEntryDate from 'components/EntryAddBuddyCard/BuddyEntryDate';
import { TotalLabel } from 'components/TotalLabel';
import PriceFormat from 'components/PriceFormat';

type EntryTransactionsPropsType = {|
  transactions: Object[],
|};

const EntryTransactions = ({ transactions }: EntryTransactionsPropsType) => {
  return (
    <>
      {transactions.map((transaction, index) => {
        const {
          entryChargeRequestUID,
          date,
          isPaying,
          isBuddyTransaction,
          payeeFullName,
          entries,
          totalFee,
          PayLater,
        } = transaction;
        const isLastTransaction = index !== transactions.length - 1;

        return (
          <div key={entryChargeRequestUID}>
            <BuddyEntryDate entryDate={date} />
            {!isPaying && (
              <div className="entry-buddy-manager">
                {`Entered by${
                  isBuddyTransaction ? ' Buddy Manager: ' : ': '
                } ${payeeFullName}`}
              </div>
            )}
            <EnteredDisciplines entries={entries} />
            <div className="total-container">
              <TotalLabel payLater={PayLater} defaultLabel="YOUR TOTAL:" />
              <div className="confirmation-title total-paid">
                <PriceFormat price={totalFee} />
              </div>
            </div>
            {isLastTransaction && <hr />}
          </div>
        );
      })}
    </>
  );
};

export default EntryTransactions;
