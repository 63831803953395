import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Grid } from '@material-ui/core';
import { Field, reduxForm } from 'redux-form';
import CalendarPiker from 'components/FormField/CalendarPicker';
import { renderSelectBoxField } from 'components/FormField/SelectBox';
import { getDisciplineFilterOptions } from 'constants/disciplines';
import { compose } from 'redux';
import { listEventTypes } from 'actions/eventType';
import TextField from 'components/FormField/TextField';
import {
  openDivisionsWithNames,
  youthDivisionsWithNames,
} from 'constants/event';
import FormFieldSelectCheckBoxes from 'components/FormField/FormFieldSelectCheckBoxes';
import get from 'lodash/get';
import {
  EVENT_DIVISIONS_TYPE,
  OPEN_DIVISIONS,
  YOUTH_DIVISIONS,
} from 'constants/event';

const {
  YOUTH_EVENT_TYPE,
  OPEN_EVENT_TYPE,
  OPEN_YOUTH_EVENT_TYPE,
} = EVENT_DIVISIONS_TYPE;
import {
  allianceOptions,
  defaultCompetitionLevelOptions,
  eventTypeOptions,
  FILTER_FORM_NAME,
  filtersFields,
} from './constants';
import FiltersResults from './FiltersResults';

const Filters = ({ showFilters, reset, onSubmit, handleSubmit, isYouth }) => {
  const dispatch = useDispatch();
  const [showFilterResults, setShowFilterResults] = useState(false);
  const [fieldsFilterResults, setFieldsFilterResults] = useState(filtersFields);
  const formValues = useSelector((state) =>
    get(state, `form.${FILTER_FORM_NAME}.values`, {}),
  );
  const [divisionOptions, setDivisionOptions] = useState([
    { label: 'Open Divisions:', value: '1', isLabel: true },
    ...openDivisionsWithNames,
    { label: 'Youth Divisions:', value: '2', isLabel: true },
    ...youthDivisionsWithNames,
  ]);
  const [competitionLevelOptions, setCompetitionLevelOptions] = useState(
    defaultCompetitionLevelOptions,
  );
  const { CompetitionLevel, Division } = formValues;

  const hasValues = formValues && Object.keys(formValues).length > 0;

  useEffect(() => {
    const rodeoField = filtersFields.Rodeo;
    if (eventTypeOptions.length > 0) {
      const newFields = {
        ...fieldsFilterResults,
        [rodeoField.name]: {
          ...rodeoField,
          getStringResult: (value) =>
            eventTypeOptions.find((item) => item.value === value)?.label,
        },
      };
      setFieldsFilterResults(newFields);
    }
  }, []);

  useEffect(() => {
    let options = [...defaultCompetitionLevelOptions];
    const hasOpenDivision = Division?.some((item) =>
      OPEN_DIVISIONS.includes(item.value),
    );
    const hasYouthDivision = Division?.some((item) =>
      YOUTH_DIVISIONS.includes(item.value),
    );
    if (hasOpenDivision || !isYouth) {
      options = options.map((item) => {
        if (item.value === YOUTH_EVENT_TYPE) {
          return {
            ...item,
            disabled: true,
          };
        }
        return item;
      });
    }
    if (hasYouthDivision) {
      options = options.map((item) => {
        if (item.value === OPEN_EVENT_TYPE) {
          return {
            ...item,
            disabled: true,
          };
        }
        return item;
      });
    }
    setCompetitionLevelOptions(options);
  }, [Division]);

  useEffect(() => {
    if (CompetitionLevel === OPEN_EVENT_TYPE || !isYouth) {
      setDivisionOptions([
        { label: 'Open Divisions:', value: '1', isLabel: true },
        ...openDivisionsWithNames,
        { label: 'Youth Divisions:', value: '2', isLabel: true },
        ...youthDivisionsWithNames.map((item) => ({
          ...item,
          isDisabled: true,
        })),
      ]);
    } else if (CompetitionLevel === YOUTH_EVENT_TYPE) {
      setDivisionOptions([
        { label: 'Open Divisions:', value: '1', isLabel: true },
        ...openDivisionsWithNames.map((item) => ({
          ...item,
          isDisabled: true,
        })),
        { label: 'Youth Divisions:', value: '2', isLabel: true },
        ...youthDivisionsWithNames,
      ]);
    } else {
      setDivisionOptions([
        { label: 'Open Divisions:', value: '1', isLabel: true },
        ...openDivisionsWithNames,
        { label: 'Youth Divisions:', value: '2', isLabel: true },
        ...youthDivisionsWithNames,
      ]);
    }
  }, [CompetitionLevel]);

  const renderOption = (option) => {
    return (
      <div
        className={
          option.value === OPEN_YOUTH_EVENT_TYPE ? 'option-margin-bottom' : ''
        }
      >
        {option.label} <br />
        {option.value === OPEN_YOUTH_EVENT_TYPE ? (
          <small className="option-description">
            Events with Open and Youth classes within one event
          </small>
        ) : (
          ''
        )}
      </div>
    );
  };

  const weekday = (day) => {
    return day[0].toUpperCase();
  };

  useEffect(() => {
    dispatch(listEventTypes());
  }, []);

  const submitFilters = (values) => {
    onSubmit(values);
    setShowFilterResults(true);
  };

  const renderRow = (LeftComponent, RightComponent) => {
    return (
      <Grid container spacing={2}>
        <Grid item xs={6}>
          {LeftComponent}
        </Grid>
        <Grid item xs={6}>
          {RightComponent}
        </Grid>
      </Grid>
    );
  };
  const StartDateField = (
    <Field
      name="StartDate"
      className="date-field"
      label={'Start Date'}
      formatWeekDay={weekday}
      component={CalendarPiker}
      placeholder="MM/DD/YYYY"
    />
  );
  const EndDateField = (
    <Field
      name="EndDate"
      className="date-field"
      startDate={formValues.StartDate}
      selectsEnd
      label={'End Date'}
      formatWeekDay={weekday}
      component={CalendarPiker}
      placeholder="MM/DD/YYYY"
    />
  );

  const DisciplineField = (
    <Field
      name={filtersFields.Discipline.name}
      label={filtersFields.Discipline.label}
      component={renderSelectBoxField}
      placeholder="Select"
      helpertext=""
      searchable={false}
      closeOnSelect
      multi={false}
      valueKey="value"
      labelKey="label"
      options={getDisciplineFilterOptions()}
      variant="filled"
      showError
    />
  );

  const RodeoField = (
    <Field
      name={filtersFields.Rodeo.name}
      label={filtersFields.Rodeo.label}
      component={renderSelectBoxField}
      placeholder="Select"
      helpertext=""
      searchable={false}
      closeOnSelect
      multi={false}
      valueKey="value"
      labelKey="label"
      options={eventTypeOptions}
      variant="filled"
      showError
    />
  );

  const CompetitionLevelField = (
    <Field
      name={filtersFields.CompetitionLevel.name}
      label={filtersFields.CompetitionLevel.label}
      component={renderSelectBoxField}
      options={competitionLevelOptions}
      optionRenderer={renderOption}
      placeholder="Select"
      helpertext=""
      searchable={false}
      closeOnSelect
      multi={false}
      valueKey="value"
      labelKey="label"
      variant="filled"
    />
  );

  const AllianceField = (
    <Field
      name={filtersFields.Alliance.name}
      label={filtersFields.Alliance.label}
      component={FormFieldSelectCheckBoxes}
      placeholder="SELECT"
      options={allianceOptions}
      helpertext=""
      searchable={false}
      closeOnSelect
      multi={true}
      valueKey="value"
      labelKey="label"
      variant="filled"
    />
  );

  const DivisionField = (
    <Field
      name="Division"
      label={'Division'}
      component={FormFieldSelectCheckBoxes}
      placeholder="SELECT"
      sliceLabel={true}
      options={divisionOptions}
    />
  );

  const LocationField = (
    <Field
      name={filtersFields.Location.name}
      label={filtersFields.Location.label}
      component={TextField}
      showError={false}
      validate={[]}
      placeholder="city, state"
    />
  );

  const onClearAll = () => {
    setShowFilterResults(false);
    onSubmit({});
    reset();
  };

  return (
    showFilters && (
      <div className="grid-x">
        <div className="nominations-filter-container large-6 large-offset-3 small-12">
          <form
            onSubmit={handleSubmit(submitFilters)}
            autoComplete="off"
            role="presentation"
            className={!showFilterResults ? 'show' : 'hide'}
          >
            {renderRow(DisciplineField, RodeoField)}
            {renderRow(StartDateField, EndDateField)}
            {renderRow(LocationField, CompetitionLevelField)}
            {renderRow(AllianceField, DivisionField)}
            <div className="buttons-container">
              <button
                className="btn-main secondary"
                onClick={() => {
                  onSubmit({});
                  reset();
                }}
                disabled={!hasValues}
              >
                RESET
              </button>
              <button
                action="submit"
                className="btn-main"
                type="submit"
                disabled={!hasValues}
              >
                SHOW RESULTS
              </button>
            </div>
          </form>
          {showFilterResults && (
            <FiltersResults
              fieldsFilterResults={fieldsFilterResults}
              onClearAll={onClearAll}
            />
          )}
        </div>
      </div>
    )
  );
};

export default compose(
  reduxForm({
    form: FILTER_FORM_NAME,
  }),
)(Filters);
