// @flow
import React from 'react';

type NoEvensResultsProps = {|
  showCreateEventForm: () => void,
  clearFilters: ?() => void,
|};

const NoEventsResults = (props: NoEvensResultsProps) => {
  const { showCreateEventForm, clearFilters } = props;

  return (
    <div>
      {showCreateEventForm ? (
        <div className="event-no-results text-center">
          <p>
            Sorry, we couldn‘t find that rodeo. Would you like to add it to our
            system?
          </p>
          <button
            className="btn-main"
            onClick={showCreateEventForm}
            type="button"
          >
            Add Event
          </button>
        </div>
      ) : (
        <div className="entry-no-results text-center">
          <h3>No results</h3>
          <p>
            Your search returned no results. Try adjusting your search or
            filters.
          </p>
          <button className="btn-main" onClick={clearFilters}>
            Remove All Filters
          </button>
        </div>
      )}
    </div>
  );
};

export default NoEventsResults;
