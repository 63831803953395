// @flow
import React, { useState, useCallback, useEffect } from 'react';
import ErrorIcon from '@material-ui/icons/Error';
import { reset } from 'redux-form';
import get from 'lodash/get';
import { useSelector } from 'react-redux';

import { clearEntryCharge } from 'actions/entryChargeRequest';
import { getShowEventMessagingBanner } from 'selectors/event';
import { setShowEventMessagingBanner } from 'actions/event';
import { cleanMembership } from 'actions/associationMembership';
import {
  addManagerToBuddyGroup,
  removeManagerFromBuddyGroup,
} from 'actions/entryPool';
import { capitalizeString, changeRoute, getGroupedEntries } from 'helpers';
import Layout from 'components/Layout';
import EntryEventDescription from 'components/EntryEventDescription';
import EntryPartnerInviteModal from 'components/EntryPartnerInviteModal';
import { EntryAddBuddyCard } from 'components/EntryAddBuddyCard';
import { EntrySocialInvite } from 'components/EntrySocialInvite';
import { OpenStallsLink } from 'components/OpenStallsLink';
import { DisciplineEntered } from 'components/DisciplineEntered';
import { TotalLabel } from 'components/TotalLabel';
import BuddyEntryDate from 'components/EntryAddBuddyCard/BuddyEntryDate';
import { DisplayMembershipFees } from 'components/DisplayMembershipFees';
import { PAY_LATER } from 'constants/paymentTypes';
import { disciplinesByName } from 'constants/disciplines';
import {
  EVENT_ENTRY,
  EVENT_ENTRY_SEARCH,
  EVENT_ENTRY_COLLECT_MEMBERSHIP_NUMBER,
  EVENT_ENTRY_SUCCESS,
} from 'constants/routes';
import type { EntryChargeType } from 'models/EntryChargeRequest';
import type { EventType } from 'models/Event';
import type { BuddyGroupType } from 'models/Buddy';
import type { DisciplineType } from 'context/SelectedDisciplines/type';
import type { AssociationMembershipType } from 'models/AssociationMembership';
import { getAthleteAssociationMembershipNumbers } from 'models/AssociationMembership';
import { IS_MEMBER } from 'constants/associationMembership';
import Notification from 'components/Notification';

type SuccessPropsType = {|
  athleteFullName: string,
  charge: EntryChargeType,
  ERAUID: string,
  event: EventType,
  buddyGroup: BuddyGroupType,
  user: Object,
  history: Object,
  isBuddyEntry: boolean,
  dispatch: Function,
  setIsBuddyEntry: Function,
  addBuddy: Function,
  setSelectedBuddyOption: Function,
  selectedDisciplines: DisciplineType[],
  clearSelectedDisciplines: Function,
  getEventBuddies: () => Promise<Object>,
  getBuddyGroup: () => void,
  loading: boolean,
  toggleIsBuddySelected: (boolean) => void,
  associationMembership: AssociationMembershipType,
|};

const iconStyle = {
  marginRight: '20px',
  color: '#FF9F43',
};

export const SuccessBase = ({
  athleteFullName,
  charge,
  ERAUID,
  event,
  user,
  history,
  loading,
  setIsBuddyEntry,
  isBuddyEntry,
  addBuddy,
  setSelectedBuddyOption,
  clearSelectedDisciplines,
  getEventBuddies,
  toggleIsBuddySelected,
  getBuddyGroup,
  dispatch,
  buddyGroup,
  selectedDisciplines,
  associationMembership,
}: SuccessPropsType) => {
  const { ShowStallsLink, id: eventUID, AllowClasses } = event;
  const {
    associationTypeFees,
    CollectMembershipFees,
    CollectMembershipNumber,
    totalPayment,
    entries,
    paymentType,
  } = charge;
  const showEventMessagingBanner = useSelector(getShowEventMessagingBanner);
  const onCloseEventNotification = () => {
    dispatch(setShowEventMessagingBanner(false));
  };

  const { AssociationShortName, IsNonMember, IsPermitFee, IsRenewalFee } =
    associationTypeFees[0] || {};

  const { selectedMembershipStatus } = associationMembership;
  const athleteMembershipNumbers =
    selectedMembershipStatus === IS_MEMBER
      ? getAthleteAssociationMembershipNumbers(
          associationMembership.athleteMembershipData,
          event.Associations,
          event.PrimaryAssociationUID || 0,
        )
      : [];

  useEffect(() => {
    if (eventUID) {
      getBuddyGroup();
      getEventBuddies()
        .then((eventBuddies) => {
          setBuddyInfo(eventBuddies);
        })
        .catch(() => {
          return null;
        });
    }
  }, [eventUID]);

  // Exit buddy mode when going back to event search
  useEffect(
    () =>
      history.listen(() => {
        if (get(history, 'location.pathname') === EVENT_ENTRY_SEARCH) {
          setIsBuddyEntry(false);
          toggleIsBuddySelected(false);
          dispatch(removeManagerFromBuddyGroup());
        }
      }),
    [],
  );

  const [partner, setPartner] = useState(null);
  const [isPartnerInviteModalOpen, setIsPartnerInviteModalOpen] = useState(
    false,
  );
  const [buddyInfo, setBuddyInfo] = useState({
    buddyGroupContestants: [],
    isBuddyManager: false,
    buddyManager: {
      ERAUID: '',
      FullName: '',
      associationMembers: [],
    },
  });

  const canBeBuddyManager =
    buddyInfo &&
    (buddyInfo.isBuddyManager || !buddyInfo.buddyGroupContestants.length);

  const openInvitePartnerModal = useCallback((partner) => {
    setPartner(partner);
    setIsPartnerInviteModalOpen(true);
  }, []);

  const closeInvitePartnerModal = useCallback(() => {
    setIsPartnerInviteModalOpen(false);
    dispatch(reset('partnerContactInfo'));
  }, []);

  const startNewBuddyEntry = () => {
    const associations = get(event, 'Associations', []);
    const isCollectMembershipNumber = associations.some(
      (association) => association.CollectMembershipNumber,
    );
    if (!isBuddyEntry) {
      dispatch(
        addManagerToBuddyGroup({
          entries: selectedDisciplines,
        }),
      );
    }
    setIsBuddyEntry(true);
    addBuddy();
    setSelectedBuddyOption(null);
    toggleIsBuddySelected(false);
    clearSelectedDisciplines();
    dispatch(clearEntryCharge());
    dispatch(cleanMembership());
    changeRoute({
      history,
      route: isCollectMembershipNumber
        ? EVENT_ENTRY_COLLECT_MEMBERSHIP_NUMBER
        : EVENT_ENTRY,
    });
  };

  const renderDisciplinesEnteredEvent = () => {
    const entriesByGroup = getGroupedEntries(
      entries,
      selectedDisciplines,
      AllowClasses,
    );
    // Added if condition because when i come back from ENTER BUDDY page then entriesByGroup object is empty and throwing error on console while rendering loop
    if (entriesByGroup) {
      return Object.keys(entriesByGroup).map((value) => {
        const disciplineName = AllowClasses
          ? disciplinesByName[value.split('-')[0]]
          : disciplinesByName[value];
        const className = AllowClasses ? value.split('-')[1] : null;
        return (
          <div key={value} className="discipline-entered nominate-totals">
            <div className="confirmation-discipline">
              {disciplineName}{' '}
              {!AllowClasses && entriesByGroup[value].length > 1
                ? ` (X${entriesByGroup[value].length})`
                : null}
            </div>
            {AllowClasses && (
              <div key={className} className="confirmation-classname">
                {className}
                {entriesByGroup[value].length > 1
                  ? ` (X${entriesByGroup[value].length})`
                  : null}
              </div>
            )}
            {entriesByGroup[value].map((entry, index) => (
              <DisciplineEntered
                event={event}
                entry={entry}
                key={entry.EPUID}
                index={index}
                openInvitePartnerModal={openInvitePartnerModal}
                entryCountByDiscipline={entriesByGroup[value].length}
              />
            ))}
          </div>
        );
      });
    }
  };

  const renderEnteredByBuddyManager = () => {
    if (isBuddyEntry && !canBeBuddyManager) {
      return (
        <div className="entry-buddy-manager">
          {`Entered by Buddy Manager: ${buddyInfo.buddyManager.FullName}`}
        </div>
      );
    }
  };

  const renderEntryAddBuddyCard = () => {
    const shouldDisplayBuddyCard =
      history.location.pathname !== EVENT_ENTRY_SUCCESS || canBeBuddyManager;
    return shouldDisplayBuddyCard && event.AllowsBuddyGroup == true ? (
      <EntryAddBuddyCard
        event={event}
        charge={charge}
        loading={loading}
        buddyGroup={buddyGroup}
        handleAddBuddy={startNewBuddyEntry}
        isBuddyManager={canBeBuddyManager}
        buddyManager={buddyInfo.buddyManager}
        openInvitePartnerModal={openInvitePartnerModal}
        selectedDisciplines={selectedDisciplines}
      />
    ) : null;
  };

  if (!eventUID) changeRoute({ history, route: EVENT_ENTRY_SEARCH });

  return eventUID ? (
    <Layout className="bg-nominate" loading={loading}>
      <div id="main-top">
        <div className="grid-container">
          <div className="grid-x">
            <div className="small-12 desktop-only">
              <h2 className="section-title padbot-0">Confirmation</h2>
            </div>
            <div className="small-12 large-6 large-offset-3 nomination-confirmation entry-confirmation theme-content-container stacked-content-container entry">
              <h2 className="mobile-title mobile-only">Confirmation</h2>

              <EntryEventDescription event={event} />

              <hr className="marbot-0" />
              <div className="confirmation-text-container athlete">
                <h3 className="entry-details-text">ENTRY DETAILS</h3>
                {showEventMessagingBanner && (
                  <div className="show-event-messaging-confirmation-container">
                    <div className="small-12 large-12 mobile-only">
                      <Notification
                        type="info"
                        showCloseButton
                        text={event.EventMessaging}
                        onClose={onCloseEventNotification}
                      />
                    </div>
                    <div className="desktop-only">
                      <Notification
                        type="info"
                        showCloseButton
                        text={event.EventMessaging}
                        onClose={onCloseEventNotification}
                      />
                    </div>
                  </div>
                )}
                <div className="confirmation-title disciplines-entered">
                  ATHLETE
                </div>
                <div className="confirmation-athlete-name padtop-1">
                  {athleteFullName}
                </div>
                {athleteMembershipNumbers.map((amd) => (
                  <div
                    key={`association_sn_${amd.associationShortName}`}
                    className="confirmation-athlete-name padtop-1"
                  >
                    {amd.associationShortName} #: {amd.membershipNumber}
                  </div>
                ))}
                <div className="confirmation-title disciplines-entered">
                  {buddyInfo ? 'Entries' : 'Discipline(s) entered'}
                </div>
                <BuddyEntryDate className="martop-1" />
                {renderEnteredByBuddyManager()}
              </div>
              <div className="confirmation-text-container confirmation-partner-container">
                {renderDisciplinesEnteredEvent()}
              </div>
              <DisplayMembershipFees
                associationTypeFees={associationTypeFees}
              />
              <hr className="marbot-0" />
              <div className="confirmation-text-container">
                <div className="total-container">
                  <TotalLabel
                    payLater={paymentType === PAY_LATER}
                    defaultLabel="YOUR TOTAL:"
                  />
                  <div className="confirmation-title total-paid">
                    ${totalPayment}
                  </div>
                </div>
              </div>
              {CollectMembershipNumber ? (
                <div className="martop-3 membership-language">
                  <div className="alert-icon-container">
                    <ErrorIcon fontSize="inherit" style={iconStyle} />
                  </div>
                  <div>
                    {CollectMembershipFees &&
                    associationTypeFees.length > 0 &&
                    ((!IsNonMember && !IsPermitFee) || IsRenewalFee) ? (
                      <span>
                        You will receive your card information from the{' '}
                        <strong>{AssociationShortName}</strong> office after
                        processing.
                      </span>
                    ) : null}
                    If there are any fines on file, you have to pay before
                    competing.
                  </div>
                </div>
              ) : null}
            </div>
            <div className="small-12 large-6 large-offset-3 nomination-confirmation entry-confirmation stacked-content-container">
              {renderEntryAddBuddyCard()}
              <OpenStallsLink ShowStallsLink={ShowStallsLink} />
              <EntrySocialInvite
                ERAUID={ERAUID}
                athleteFullName={athleteFullName}
                event={event}
                user={user}
              />
            </div>
          </div>
        </div>
      </div>
      <EntryPartnerInviteModal
        closeModal={closeInvitePartnerModal}
        isOpen={isPartnerInviteModalOpen}
        partner={partner}
        event={event}
        charge={charge}
        athleteName={capitalizeString(athleteFullName)}
      />
    </Layout>
  ) : null;
};
