import React from 'react';
import Select from 'react-select';

import { getStateAbbreviationOptions } from 'constants/states';

const StateSelect = ({ input, options }) => (
  <Select
    {...input}
    onChange={({ value }) => input.onChange(value)}
    options={options || getStateAbbreviationOptions}
    clearable={false}
    searchable={false}
  />
);

export default StateSelect;
