import React from 'react';
import SelectCheckboxes from '../SelectCheckboxes';
import FormControl from '@material-ui/core/FormControl';
import { InputLabel } from '@material-ui/core';

const FormFieldSelectCheckBoxes = ({
  label,
  input: { value, onChange, ...inputProps },
  ...props
}) => {
  return (
    <FormControl margin="none" fullWidth>
      <InputLabel shrink className="form-label-bold form-label" htmlFor={name}>
        {label}
      </InputLabel>
      <SelectCheckboxes
        {...props}
        {...inputProps}
        onBlur={() => {}}
        value={value}
        onChange={onChange}
      />
    </FormControl>
  );
};

export default FormFieldSelectCheckBoxes;
