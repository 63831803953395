// @flow
import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import Sticky from 'react-stickynode';
import { changeRoute, history } from 'helpers';
import { clearEntryCharge } from 'actions/entryChargeRequest';
import { useDisciplineModalHooks } from 'components/EntryDisciplineModal/hooks';
import { HOME } from 'constants/routes';
import Menu from 'components/Menu';
import withIsAuthenticated from 'enhancers/withIsAuthenticated';
import HeaderLogo from '../HeaderLogo';

const mapStateToProps = (state) => ({
  user: state.user.get('data'),
});

type HeaderProps = {|
  user: Object,
  dispatch: Function,
|};

const HeaderBase = (props: HeaderProps) => {
  const methods = useDisciplineModalHooks();
  const { user, dispatch } = props;
  const { activeDisciplineDeselect } = methods;

  const goHome = async () => {
    const homeRoute = await HOME;
    await activeDisciplineDeselect();
    dispatch(clearEntryCharge());

    changeRoute({
      history,
      route: homeRoute,
      state: { isReading: true },
      persistState: false,
    });
  };

  return (
    <header>
      <Sticky>
        <div className="desktop-menu-wrapper">
          <div className="grid-container">
            <div className="grix-x clearfix">
              <span onClick={() => goHome()}>
                <HeaderLogo />
              </span>
              <div className="float-right">
                <Menu menuId="desktop-menu" user={user} />
              </div>
            </div>
          </div>
        </div>
      </Sticky>
    </header>
  );
};

export const Header = compose(
  // $FlowIgnore
  connect(mapStateToProps),
  withIsAuthenticated,
)(HeaderBase);

export default Header;
