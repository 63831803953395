import React from 'react';
import { Check } from 'react-feather';
import classNames from 'classnames';

const EntryAgreement = (props) => {
  const { onClick, acceptedTerms, agreementUrl } = props;
  return (
    <div className="grid-x">
      <div className="agreements" onClick={onClick}>
        <div className="checkbox-wrp">
          <span
            className={classNames('input-checkbox', {
              checked: acceptedTerms,
            })}
          >
            <Check className="checkmark" />
          </span>
        </div>
        <label htmlFor="USCitizenCheck">
          I`ve read and accepted the{' '}
          <a href={agreementUrl} target="_blank" rel="noopener noreferrer">
            Contestant Agreements
          </a>
        </label>
      </div>
    </div>
  );
};

export default EntryAgreement;
