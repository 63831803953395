// @flow
import React, { useContext, useState } from 'react';

import SelectFilter from 'components/Event/SelectFilter';
import SectionSubtitle from 'components/Section/Subtitle';
import BuddySearch from 'components/Event/BuddySearch';
import { DisciplineContext } from 'context/SelectedDisciplines';
import type { ContextValue } from 'context/SelectedDisciplines/type';

type EntrySelectFilterProps = {|
  isParent: boolean,
  athletes: Array<any>,
  selectAthlete: (Object, Function) => Promise<void>,
  ERAUID: ?number,
  isOnBuddyEntryFlow: ?boolean,
  EventUID: ?string,
  disabled: ?boolean,
|};

const EntrySelectFilter = ({
  isParent,
  ERAUID,
  athletes,
  selectAthlete,
  isOnBuddyEntryFlow,
  EventUID,
  disabled,
}: EntrySelectFilterProps) => {
  const context: ContextValue = useContext<ContextValue>(DisciplineContext);
  const { switchAthlete } = context;
  const [initialEmptyValue, setInitialValue] = useState(true);

  if (isOnBuddyEntryFlow) {
    return (
      <>
        <div className="section-preferences-subtitle">Buddy</div>
        <SectionSubtitle>
          Select the athlete you want to enter in the event.
        </SectionSubtitle>
        <BuddySearch
          EventUID={EventUID}
          selectAthlete={selectAthlete}
          managerERAUID={ERAUID}
        />
      </>
    );
  }

  const setAthlete = (athlete) => {
    setInitialValue(false);
    selectAthlete(athlete);
    switchAthlete(athlete.value);
  };

  return isParent ? (
    <>
      <div className="section-preferences-subtitle">Athlete</div>
      <SectionSubtitle>
        Select the athlete you want to enter in the event.
      </SectionSubtitle>
      <SelectFilter
        isOnBuddyEntryFlow={isOnBuddyEntryFlow}
        placeholder="Search for athletes"
        label="Athlete *"
        value={ERAUID}
        options={athletes}
        onChange={setAthlete}
        initialEmptyValue={initialEmptyValue}
        disabled={disabled}
      />
      <hr className="martop-0" />
    </>
  ) : null;
};

export default EntrySelectFilter;
