// @flow
import React, { PureComponent } from 'react';
import dbVars from 'constants/dbvars';
import LiabilityAcceptanceForm from './LiabilityAcceptanceForm';
import { getTheme, getNestedProperty } from 'helpers';
import { RET, WCRA } from 'constants/theme';

type YouthLiabilityPropsType = {|
  onParentalConsentSubmit: (isApproved: boolean, val: Object) => any,
  child: Object,
|};

export class YouthLiability extends PureComponent<YouthLiabilityPropsType> {
  handleChildConsentInfo = (valuesFromConsentForm: Object) => {
    const {
      street,
      city,
      state,
      zip,
      signature,
      isDisclosable,
      fieldError,
      country,
    } = valuesFromConsentForm;

    const isParentalConsentApproved =
      valuesFromConsentForm &&
      getNestedProperty('length', signature && signature.trim(), '') > 0 &&
      getNestedProperty('length', country && country.trim(), '') > 0 &&
      getNestedProperty('length', street && street.trim(), '') > 0 &&
      getNestedProperty('length', city && city.trim(), '') > 0 &&
      getNestedProperty('length', state && state.trim(), '') > 0 &&
      getNestedProperty('length', zip && zip.trim(), '') > 0 &&
      isDisclosable &&
      !fieldError;

    const { onParentalConsentSubmit } = this.props;
    onParentalConsentSubmit(isParentalConsentApproved, valuesFromConsentForm);
  };

  render() {
    const { child } = this.props;

    const theme = getTheme();

    return (
      <div id="liability-content">
        <div className="container-liability">
          <h2 className="section-title left padtop-3 padbot-3">
            Consent to Use of the Virtual Qualifier by Minor Competitor
          </h2>
          <hr />
          <div className="section-content">
            <div
              dangerouslySetInnerHTML={{
                __html:
                  dbVars.LIABILITY_MINOR_COMPETITOR_HTML_PART_1_WCRA_AND_RET,
              }}
              className="legal-consent-minor-p1"
            />
            <div className="youth-athlete-name">
              &nbsp;
              <div> {` ${child.info.firstName} ${child.info.lastName} `} </div>
              &nbsp;
            </div>

            {theme === WCRA ? (
              <div
                dangerouslySetInnerHTML={{
                  __html: dbVars.LIABILITY_MINOR_COMPETITOR_HTML_PART_2_WCRA,
                }}
                className="legal-consent-minor-p2"
              />
            ) : (
              theme === RET && (
                <div
                  dangerouslySetInnerHTML={{
                    __html: dbVars.LIABILITY_MINOR_COMPETITOR_HTML_PART_2_RET,
                  }}
                  className="legal-consent-minor-p2"
                />
              )
            )}

            <LiabilityAcceptanceForm
              onAddChildAttributes={this.handleChildConsentInfo}
              child={child.consent}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default YouthLiability;
