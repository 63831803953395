// @flow
import React from 'react';
import { getNestedProperty, capitalizeString } from 'helpers';
import classnames from 'classnames';
import type { EntryType } from 'models/Entry';
import { Check, Mail } from 'react-feather';
import { getTheme } from '../../helpers';

type PartnerSectionPropsType = {
  entry: EntryType,
  openInvitePartnerModal: (Object) => void,
  shouldRenderInviteLink: boolean,
  index?: number,
  entryCountByDiscipline: number,
};

export const PartnerSection = (props: PartnerSectionPropsType) => {
  const {
    entry,
    openInvitePartnerModal,
    shouldRenderInviteLink,
    index,
    entryCountByDiscipline,
  } = props;

  const partnerInvited = getNestedProperty('invited', entry);
  const disciplineId = getNestedProperty('CompType', entry);
  const partnerName = getNestedProperty('partnerName', entry);
  const TeammateERAUID = getNestedProperty('TeammateERAUID', entry);
  const isPendingEntry = getNestedProperty('isPendingEntry', entry);
  const isPayingForPartner = getNestedProperty(
    'isPayingForPartner',
    entry,
    false,
  );

  const partner = { fullName: partnerName, ERAUID: TeammateERAUID };
  const partnerPaidText = isPayingForPartner
    ? "Paid partner's entry fees"
    : null;

  const inviteStatusIcon = partnerInvited ? (
    <Check className={'check-mark-icon'} />
  ) : (
    <Mail className={'email-icon'} />
  );
  const inviteStatusText = partnerInvited
    ? 'Partner Invited'
    : `Invite Partner to ${getTheme().toUpperCase()}`;

  const renderOpenInvitePartnerModalLink = () => {
    if (shouldRenderInviteLink) {
      return (
        <div
          className={classnames({
            ['email-invite-link']: true,
            ['email-invite-link-invited']: partnerInvited,
          })}
          onClick={() => openInvitePartnerModal(partner)}
        >
          {inviteStatusIcon}
          <span className="invite">{inviteStatusText}</span>
        </div>
      );
    }
  };

  return (
    <div key={disciplineId} className={'partner-section'}>
      <div>{partnerPaidText}</div>
      <div>
        {' '}
        {entryCountByDiscipline > 0 || isPendingEntry
          ? `Partner #${index + 1}:`
          : 'Partner:'}{' '}
        {capitalizeString(partnerName)}
      </div>
      {renderOpenInvitePartnerModalLink()}
    </div>
  );
};
