// @flow
import React, { PureComponent } from 'react';
import { minBirthDateYear, maxBirthDateYear, isValidMomentDate } from 'helpers';

type DateOfBirthInputProps = {|
  pickedDate: ?moment$Moment,
  disabled?: boolean,
  onChange: (birthDate: string) => void,
|};
type DateOfBirthInputState = {|
  day: string,
  month: string,
  year: string,
|};

class DateOfBirthInput extends PureComponent<
  DateOfBirthInputProps,
  DateOfBirthInputState,
> {
  constructor(props: DateOfBirthInputProps) {
    super(props);

    const { pickedDate } = this.props;

    this.state = {
      day: pickedDate ? pickedDate.format('DD') : 'DD',
      month: pickedDate ? pickedDate.format('MM') : 'MM',
      year: pickedDate ? pickedDate.format('YYYY') : 'YYYY',
    };
  }

  componentDidUpdate(prevProps: DateOfBirthInputProps) {
    const { pickedDate } = this.props;

    const shouldResetComponent = !!prevProps.pickedDate && !pickedDate;

    if (shouldResetComponent) {
      this.setState({
        day: 'DD',
        month: 'MM',
        year: 'YYYY',
      });
      return;
    }

    if (pickedDate && isValidMomentDate(pickedDate)) {
      this.setState({
        day: pickedDate.format('DD'),
        month: pickedDate.format('MM'),
        year: pickedDate.format('YYYY'),
      });
      return;
    }
  }

  dayInput: Object = {};
  monthInput: Object = {};
  yearInput: Object = {};

  getFormattedMonth = (month: string) => {
    const monthFiltered = this.getFilteredNumber(month);
    if (!monthFiltered.length) return '';

    if (monthFiltered.length === 1 && parseInt(monthFiltered.charAt(0)) > 1) {
      return `0${monthFiltered.charAt(0)}`;
    }

    if (parseInt(monthFiltered) > 13) return '12';

    if (monthFiltered === '00') return '01';

    return monthFiltered;
  };

  getFormattedDay = (day: string) => {
    const dayFiltered = this.getFilteredNumber(day);
    if (!dayFiltered.length) return '';

    if (dayFiltered.length === 1 && parseInt(dayFiltered.charAt(0)) > 3) {
      return `0${dayFiltered.charAt(0)}`;
    }

    if (parseInt(dayFiltered) > 31) return '31';

    if (dayFiltered === '00') return '01';

    return dayFiltered;
  };

  getFormattedYear = (year: string) => {
    const yearFiltered = this.getFilteredNumber(year);
    if (yearFiltered.length < 4) return yearFiltered;

    if (parseInt(yearFiltered) < minBirthDateYear)
      return minBirthDateYear.toString();
    if (parseInt(yearFiltered) > maxBirthDateYear)
      return maxBirthDateYear.toString();

    return yearFiltered;
  };

  getFilteredNumber = (numberString: string) => {
    return numberString.replace(/[^\d]/g, '');
  };

  onUpdateMonth = (e: Object) => {
    const { day, year } = this.state;
    const { onChange } = this.props;

    const month = this.getFormattedMonth(e.target.value);
    this.setState({ month });
    onChange(`${month}${day}${year}`);

    if (month && month.length > 1) {
      this.dayInput.focus();
    }
  };

  onUpdateDay = (e: Object) => {
    const { month, year } = this.state;
    const { onChange } = this.props;

    const day = this.getFormattedDay(e.target.value);
    this.setState({ day });
    onChange(`${month}${day}${year}`);

    if (day && day.length > 1) {
      this.yearInput.focus();
    }
  };

  onUpdateYear = (e: Object) => {
    const { month, day } = this.state;
    const { onChange } = this.props;

    const year = this.getFormattedYear(e.target.value);
    this.setState({ year });
    onChange(`${month}${day}${year}`);
  };

  onFocus = (e: Object) => {
    e.target.setSelectionRange(0, e.target.value.length);
  };

  render() {
    const { month, day, year } = this.state;
    const { disabled } = this.props;

    return (
      <div className={`birthday-picker ${disabled ? 'disabled' : ''}`}>
        <input
          name="month"
          type="tel"
          value={month}
          className="month"
          disabled={disabled}
          maxLength={2}
          onChange={this.onUpdateMonth}
          onFocus={this.onFocus}
          ref={(input) => {
            this.monthInput = input;
          }}
        />
        /{' '}
        <input
          name="day"
          type="tel"
          value={day}
          className="day"
          disabled={disabled}
          maxLength={2}
          onChange={this.onUpdateDay}
          onFocus={this.onFocus}
          ref={(input) => {
            this.dayInput = input;
          }}
        />{' '}
        /{' '}
        <input
          name="year"
          type="tel"
          value={year}
          className="year"
          disabled={disabled}
          maxLength={4}
          onChange={this.onUpdateYear}
          onFocus={this.onFocus}
          ref={(input) => {
            this.yearInput = input;
          }}
        />
      </div>
    );
  }
}

export default DateOfBirthInput;
