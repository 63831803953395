// @flow
import React, { useState, useEffect } from 'react';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';

type NumberOfNominationsProps = {
  activeDiscipline: any,
  setCountActiveSegment: any,
  segmentValue: string,
  newSegmentCount: number,
};

export const NumberOfNominations = (props: NumberOfNominationsProps) => {
  const {
    activeDiscipline,
    newSegmentCount,
    segmentValue,
    setCountActiveSegment,
  } = props;

  const { newEntryCount } = activeDiscipline;
  const [nominationCount, setNominationCount] = useState(newSegmentCount);
  const leftDisabled = nominationCount <= 1;

  const rightDisabled = newEntryCount <= 1 || newEntryCount <= newSegmentCount;

  useEffect(() => {
    if (activeDiscipline.value)
      setCountActiveSegment(
        activeDiscipline.value,
        segmentValue,
        nominationCount,
      );
  }, [nominationCount, activeDiscipline.value]);

  useEffect(() => {
    if (newSegmentCount && newEntryCount < newSegmentCount) {
      setNominationCount(newEntryCount);
    }
  }, [newEntryCount]);

  const onClickLeftArrow = () => {
    setNominationCount(nominationCount - 1);
  };

  const onClickRightArrow = () => {
    setNominationCount(nominationCount + 1);
  };
  ``;

  return (
    <>
      <div className="entry-discipline-numberOfNominations-container">
        <div className="entry-discipline-numberOfNominations-title">
          # OF NOMINATIONS:
        </div>
        <div className="numberOfNominations-arrow-container">
          <button
            className="numberOfNominations-arrow"
            disabled={leftDisabled}
            onClick={onClickLeftArrow}
          >
            <ArrowBackIosIcon
              className={leftDisabled ? 'disabled' : ''}
              fontSize="small"
            />
          </button>
          <span className="numberOfNominations">{nominationCount}</span>
          <button
            className="numberOfNominations-arrow"
            disabled={rightDisabled}
            onClick={onClickRightArrow}
          >
            <ArrowForwardIosIcon
              className={rightDisabled ? 'disabled' : ''}
              fontSize="small"
            />
          </button>
        </div>
      </div>
    </>
  );
};
