import React from 'react';
import PropTypes from 'prop-types';
import { Search } from 'react-feather';

import DebounceOnChangeHOC from '../HOC/DebounceOnChangeHOC';

/* eslint-disable jsx-a11y/no-autofocus */
const EventsSearch = ({ onChange }) => {
  return (
    <div className="event-search-container">
      <hr />
      <input
        autoFocus
        id="event-search"
        type="text"
        placeholder="FIND EVENT"
        onChange={onChange}
      />
      <Search className="search-icon" />
      <hr />
    </div>
  );
};

EventsSearch.propTypes = {
  onChange: PropTypes.func.isRequired,
};

export default DebounceOnChangeHOC(EventsSearch);
