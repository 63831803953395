// @flow
import React, { PureComponent } from 'react';
import { pick, isEmpty } from 'lodash';
import ReactPixel from 'react-facebook-pixel';
import { withRouter } from 'react-router-dom';
import { reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import { compose } from 'redux';

import { clearAuthState, registerUser } from 'actions/auth';
import { addYouthAthletes } from 'actions/youthAthlete';
import Layout from 'components/Layout';
import RegistrationView from 'components/RegistrationView';
import { MANAGE_YOUTH_ATHLETES, EVENT_ENTRY_SEARCH } from 'constants/routes';
import { disciplinesByAbbreviation } from 'constants/states';
import { getURLQueryString, getNestedProperty, getTheme } from 'helpers';
// $FlowIgnore
import retLogo from 'assets/img/ret-black-logo.svg';

import { RET, WCRA, VRQ } from 'constants/theme/options';

const { THEME } = process.env;

type UserType = {|
  email: string,
  firstName: string,
  lastName: string,
  password: string,
  phone: string,
  DOB: string,
  Gender: string,
  disciplineId: string,
|};

type YouthType = {|
  firstName: string,
  lastName: string,
  DOB: string,
  Gender: string,
  disciplineId: string,
  signature: string,
  country: string,
  street: string,
  city: string,
  state: string,
  zip: string,
  isDisclosable: boolean,
|};

type RegistrationPayloadType = {|
  user: UserType,
  youthAthletes?: YouthType[],
|};

type RegistrationPropsType = {|
  handleSubmit: (f: Function) => void, // from redux-form
  dispatch: (f: Function) => void, // from react-redux connect
  errorMessage: string,
  loading: boolean,
  addYouthAthletes: Function,
  registerUser: Function,
  clearAuth: Function,
  location: Object,
  history: Object,
  registration: Object,
  valid: boolean,
|};

const mapStateToProps = (state) => ({
  errorMessage: state.auth.get('errorMessage'),
  loading: state.auth.get('loading') || state.youthAthlete.get('loading'),
  registration: state.registration.data,
});

const mapDispatchToProps = (dispatch) => ({
  clearAuth: () => dispatch(clearAuthState()),
  registerUser: (user) => dispatch(registerUser(user)),
  addYouthAthletes: (athlete) => dispatch(addYouthAthletes(athlete)),
});

class Registration extends PureComponent<RegistrationPropsType> {
  componentDidMount() {
    const { location } = this.props;
    if (!location.aboutProps || !location.aboutProps.registerNewChildForm) {
      return this.props.clearAuth();
    }
  }

  getRegistrationPayload = (): RegistrationPayloadType => {
    const { location, registration } = this.props;
    const user = getNestedProperty('user', registration);
    const youthAthletes: YouthType[] = (Object.values(
      registration.youthAthletes,
    ): any);

    const youthInviteToken = getURLQueryString(location, 'youthinvite');
    const inviteToken = getURLQueryString(location, 'invite');
    user.street = user.address;
    if (user.country === 'US') {
      user.state = disciplinesByAbbreviation[user.stateId];
    } else {
      user.state = user.stateId;
    }
    const OriginSource =
      getTheme() === WCRA ? VRQ.toUpperCase() : getTheme().toUpperCase();

    const registrationPayload: RegistrationPayloadType = {
      user: {
        ...pick(user, [
          'DOB',
          'email',
          'firstName',
          'lastName',
          'country',
          'password',
          'phone',
          'disciplineId',
          'Gender',
          'state',
          'street',
          'city',
          'zip',
        ]),
        inviteToken,
        youthInviteToken,
        OriginSource,
      },
    };

    // Add youth athletes if a user is a parent and signing up with children
    if (youthAthletes.length > 0 && user.isParent) {
      registrationPayload.youthAthletes = youthAthletes;
    }
    return registrationPayload;
  };

  onSubmit = async () => {
    const {
      registerUser,
      addYouthAthletes,
      history,
      registration,
    } = this.props;

    const user = getNestedProperty('user', registration);
    const youthAthletes = Object.values(registration.youthAthletes);
    // Register user or user with youth athletes
    if (!isEmpty(user)) {
      const registeredUser = await registerUser(this.getRegistrationPayload());

      if (getNestedProperty('user.id', registeredUser)) {
        ReactPixel.track('CompleteRegistration', {});
        history.push(EVENT_ENTRY_SEARCH);
      }

      return registeredUser;
    }

    // Register youth athletes
    if (youthAthletes.length > 0) {
      const res = await addYouthAthletes(youthAthletes);
      if (getNestedProperty('success', res))
        history.push(MANAGE_YOUTH_ATHLETES);

      return res;
    }
  };

  render() {
    const {
      handleSubmit,
      loading,
      errorMessage,
      dispatch,
      location,
      history,
      registration,
      valid,
    } = this.props;

    const currentAthlete = getNestedProperty('currentAthlete', registration);
    const inviteToken = getURLQueryString(location, 'invite');
    const youthInviteToken = getURLQueryString(location, 'youthinvite');

    return (
      <Layout className="bg-user" loading={loading}>
        <div className="grid-container">
          <div className="grid-x padtop-4">
            <div className="small-12 large-6 centered ret-login-form">
              {THEME === RET && (
                <img alt="ret" className="brand-image" src={retLogo} />
              )}
              <RegistrationView
                loading={loading}
                inviteToken={inviteToken}
                youthInviteToken={youthInviteToken}
                handleSubmit={handleSubmit}
                errorMessage={errorMessage}
                dispatch={dispatch}
                onSubmit={this.onSubmit}
                youthAthletes={Object.values(registration.youthAthletes)}
                currentAthlete={currentAthlete}
                registration={registration}
                registerNewChildForm={
                  location.aboutProps
                    ? location.aboutProps.registerNewChildForm
                    : false
                }
                history={history}
                isFormValid={valid}
              />
            </div>
          </div>
        </div>
      </Layout>
    );
  }
}

export default compose(
  withRouter,
  // prettier-ignore
  connect<_, RegistrationPropsType, _, _, _, _>(mapStateToProps, mapDispatchToProps),
  reduxForm({
    form: 'registration',
  }),
)(Registration);
