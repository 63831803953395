import React, { PureComponent } from 'react';
import debounce from 'lodash.debounce';

const DebounceOnChangeHOC = (PassedComponent) => {
  class DebounceOnChange extends PureComponent {
    onChange = (e) => {
      e.persist();

      if (!this.debouncedFn) {
        this.debouncedFn = debounce((e) => {
          this.props.onChange(e);
        }, 500);
      }

      this.debouncedFn(e);
    };

    render() {
      return <PassedComponent {...this.props} onChange={this.onChange} />;
    }
  }

  return DebounceOnChange;
};

export default DebounceOnChangeHOC;
