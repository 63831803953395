// @flow
import { useContext, useCallback } from 'react';
import moment from 'moment-timezone';

import { DisciplineContext } from 'context/SelectedDisciplines';
import type {
  ContextValue,
  DisciplineType,
} from 'context/SelectedDisciplines/type';
import { getNestedProperty } from 'helpers';

export type EventPreferenceType = {|
  out?: string,
  date?: string,
  PerformanceUID: number,
  prefName: string,
  prefNumber?: ?number,
|};

export const useDisciplineModalHooks = () => {
  const context: ContextValue = useContext<ContextValue>(DisciplineContext);
  const { activeDiscipline, setPreference, availablePreferences } = context;

  const performancePreferenceSelected = useCallback(
    (performance: Object) => {
      if (activeDiscipline) {
        const { PerformanceUID } = performance;
        const { Pref1, Pref2, Pref3, Out1, Out2 } = activeDiscipline;
        switch (PerformanceUID) {
          case Pref1: {
            return '1';
          }
          case Pref2: {
            return '2';
          }
          case Pref3: {
            return '3';
          }
          case Out1: {
            return 'Out1';
          }
          case Out2: {
            return 'Out2';
          }
          default: {
            return '';
          }
        }
      }
    },
    [activeDiscipline],
  );

  const unsetPreference = useCallback(
    async (preferenceCategory: string) => {
      if (activeDiscipline) {
        await setPreference(activeDiscipline.value, preferenceCategory);
      }
    },
    [activeDiscipline],
  );

  const disciplineHasPreferences = useCallback((discipline: DisciplineType) => {
    const { Pref1, Pref2, Pref3, Out1, Out2 } = discipline;
    return !!Pref1 || !!Pref2 || !!Pref3 || !!Out1 || !!Out2;
  }, []);

  const getPerformanceDatetime = (
    PerformanceUID: number,
    performances: Object[],
  ) => {
    const selectedPerformance = performances.filter((perf) => {
      return perf.PerformanceUID === PerformanceUID;
    });

    return selectedPerformance &&
      selectedPerformance.length > 0 &&
      selectedPerformance[0].PerformanceDate
      ? moment.utc(selectedPerformance[0].PerformanceDate)
      : '';
  };

  const getEntryPreferences = (selectedDiscipline: DisciplineType) => {
    if (!selectedDiscipline || !disciplineHasPreferences(selectedDiscipline)) {
      return [];
    }

    const preferences: EventPreferenceType[] = availablePreferences.reduce(
      (prev, curr) => {
        const { prefNumber, prefName, prefType } = curr;
        const PerformanceUID = selectedDiscipline[prefName];
        const prefDate = getNestedProperty(
          `${prefName}AsDate`,
          selectedDiscipline,
        );
        if (PerformanceUID) {
          const date = prefDate
            ? prefDate
            : getPerformanceDatetime(
                PerformanceUID,
                getNestedProperty('performances', selectedDiscipline, []),
              );
          const preference: EventPreferenceType =
            prefType === 'out'
              ? { out: date, PerformanceUID, prefName }
              : { date, prefNumber, PerformanceUID, prefName };

          prev.push(preference);
        }
        return prev;
      },
      [],
    );
    return preferences;
  };

  const methods = {
    ...context,
    getEntryPreferences,
    unsetPreference,
    performancePreferenceSelected,
  };
  return methods;
};
